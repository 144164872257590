<span
  [style.display]="displayCss"
  [life]="3000"
  [hideDelay]="250"
  pTooltip="{{ tooltip }}"
  [escape]="false"
  tooltipZIndex="2100"
  tooltipPosition="top"
  [showDelay]="500"
  [style.margin-top.px]="data && data.marginTop > 0 ? data.marginTop : null"
  (contextmenu)="onContextMenu($event)"
  data-cy="input-location"
  [attr.data-cy-columnName]="data ? data.columnName : columnName">
  <label *ngIf="this.isLabelDisplay && label" [ngClass]="{ 'iu-field-only-label': data?.isFieldOnly }">
    <span (click)="zoomAcross()" class="adressLabelZoom">{{
      label | textLimit : this.elementRef.nativeElement.firstElementChild.firstElementChild.clientWidth / 7
    }}</span></label
  >
  <input
    #input
    [ngModel]="fieldValue ? fieldValue.displayValue : ''"
    (ngModelChange)="fieldValue ? (fieldValue.displayValue = $event.target.value) : ''"
    [readonly]="isReadOnly ? 'disabled' : null"
    [tabindex]="isReadOnly ? '-1' : null"
    [attr.value]="fieldValue ? fieldValue.displayValue : ''"
    type="text"
    (keydown)="blocInput()"
    pInputText
    [ngClass]="[hasConflict ? 'iu-input-text-conflict' : '', mandatoryCss || '', 'ui-corner-all']"
    autocomplete="no" />
  <i
    data-cy="button-location"
    class="iconeLocation fa-map-marker"
    (click)="!isReadOnly && toggleOverlay($event)"
    aria-hidden="true"
    type="button"
    [attr.readonly]="isReadOnly ? 'disabled' : null"></i>
  <span
    *ngIf="hasConflict && dataStored"
    #spanInput
    class="p-autocomplete-conflict-star"
    (mouseenter)="opConflict.show($event)">
    /!\
  </span>
  <iu-prime-overlay #opValuePref *ngIf="showValuePrefPanel" [dismissable]="false" appendTo="body">
    <iu-value-preference-panel
      [overlayPanel]="opValuePref"
      [fieldValue]="fieldValue"
      [dataStored]="this.dataStored"
      [sourceComponent]="this"
      [data]="this.data"
      (closeEmitter)="toggleValuePreferencePanel($event)"></iu-value-preference-panel>
  </iu-prime-overlay>
</span>

<iu-prime-overlay #opConflict>
  <a class="data-conflict" (click)="chooseData($event, (dataStored?.data)[data.columnName], true); opConflict.hide()">{{
    (dataStored?.data)[data.columnName]
  }}</a>
  |
  <a class="data-conflict" (click)="chooseData($event, conflictedData, false); opConflict.hide()">{{
    conflictedData
  }}</a>
</iu-prime-overlay>
<iu-prime-overlay #overlayPanel [styleClass]="'locationPanelWrapper'" [dismissable]="false"
  ><div class="locationPanel" data-cy="location-panel" [attr.data-cy-columnName]="data ? data.columnName : columnName">
    <div class="locationPanel-content">
      <ng-template #vcrLocationPanel> </ng-template>
    </div></div
></iu-prime-overlay>
