import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { DataStoreRequest } from '@compiere-ws/models/compiere-data-json';
import { CompiereField } from '@compiere-ws/models/window-json';
import { CompiereProcessService } from '@compiere-ws/services/compiere-process/compiere-process.service';
import { ProcessInProgressService } from '@compiere-ws/services/process-in-progress/process-in-progress.service';
import { SocketService } from '@compiere-ws/services/socket/socket.service';
import { AutocompleteUiComponent } from '@iupics-components/standard/fields/autocomplete-ui/autocomplete-ui.component';
import { CalendarUiComponent } from '@iupics-components/standard/fields/calendar-ui/calendar-ui.component';
import { InputTextUiComponent } from '@iupics-components/standard/fields/input-text-ui/input-text-ui.component';
import { GridViewUiComponent } from '@iupics-components/standard/grid/grid-view-ui/grid-view-ui.component';
import { EditViewUiComponent } from '@iupics-components/standard/layouts/edit-view-ui/edit-view-ui.component';
import { DataStoreService } from '@iupics-manager/managers/data-store/data-store.service';
import { MessageManagerService } from '@iupics-manager/managers/message/message-manager.service';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { UICreatorService } from '@iupics-manager/managers/ui-creator/ui-creator.service';
import { UICreatorUtils } from '@iupics-manager/managers/ui-creator/utils/ui-creator.utils';
import { WindowFactoryService } from '@iupics-manager/managers/ui-creator/window-factory/window-factory.service';
import { AbstractDataContainer } from '@iupics-manager/models/abstract-datacontainer';
import { IupicsField } from '@iupics-manager/models/iupics-data';
import { IupicsMessage } from '@iupics-manager/models/iupics-message';
import { TranslateService } from '@ngx-translate/core';
import { CellDoubleClickedEvent } from 'ag-grid-community';
import { has, isNil } from 'lodash';
import * as moment from 'moment';
import { zip } from 'rxjs';
import { SpecificWindowUiComponent } from '../specific-window-ui/specific-window-ui.component';
@Component({
  selector: 'iu-product-attribute',
  templateUrl: './product-attribute.component.html',
  styleUrls: ['./product-attribute.component.scss'],
})
export class ProductAttributeComponent extends SpecificWindowUiComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input()
  attributeInstance_id = 0;
  private baseAttributeSetID: number;
  ctxFromWs = {};
  fields: IupicsField[] = [];
  loaded = false;

  @ViewChild('grid', { read: ViewContainerRef, static: true }) gridVcr: ViewContainerRef;

  private _transformedFields: any;

  constructor(
    windowFactory: WindowFactoryService,
    uiCreator: UICreatorService,
    store: DataStoreService,
    processService: CompiereProcessService,
    translateService: TranslateService,
    socketService: SocketService,
    private messageManager: MessageManagerService,
    connectorService: SecurityManagerService,
    progressService: ProcessInProgressService
  ) {
    super(
      windowFactory,
      uiCreator,
      store,
      processService,
      socketService,
      connectorService,
      progressService,
      translateService
    );
  }

  ngOnInit() {
    const product_id =
      this.sourceComponent.dataStored.data['M_Product_ID'] instanceof Object
        ? this.sourceComponent.dataStored.data['M_Product_ID'].id
        : this.sourceComponent.dataStored.data['M_Product_ID'];
    let attributeInstanceId = this.sourceComponent.dataStored.data['M_AttributeSetInstance_ID']
      ? this.sourceComponent.dataStored.data['M_AttributeSetInstance_ID'] instanceof Object
        ? this.sourceComponent.dataStored.data['M_AttributeSetInstance_ID'].id
        : this.sourceComponent.dataStored.data['M_AttributeSetInstance_ID']
      : this.attributeInstance_id;
    const currentAttributeSetID = this.sourceComponent.dataStored.data['M_AttributeSet_ID']
      ? this.sourceComponent.dataStored.data['M_AttributeSet_ID'] instanceof Object
        ? this.sourceComponent.dataStored.data['M_AttributeSet_ID'].id
        : this.sourceComponent.dataStored.data['M_AttributeSet_ID']
      : undefined;
    if (this.attributeInstance_id && this.baseAttributeSetID !== currentAttributeSetID) {
      attributeInstanceId = this.attributeInstance_id;
    }
    this.init(attributeInstanceId, product_id);
  }

  notifyFromDatacontainerInit(datacontainer: AbstractDataContainer) {
    if (has(this.ctxFromWs, datacontainer.data.columnName)) {
      datacontainer.updateStore(this.ctxFromWs[datacontainer.data.columnName]);
    }
    if (datacontainer.data.columnName === 'M_Lot_ID') {
      (datacontainer as AutocompleteUiComponent).autoComplete.onSelect.subscribe((data) => {
        const lot = this.dataContainers.filter((el) => el.data.columnName === 'Lot')[0] as InputTextUiComponent;
        if (data === null || data.id === null) {
          lot.isReadOnly = false;
          lot.updateStore('');
        } else {
          lot.isReadOnly = true;
          lot.fieldValue = data.displayValue;
          lot.updateStore(data.displayValue);
        }
      });
    }
    if (datacontainer.data.columnName === 'LotNew') {
      datacontainer.elementRef.nativeElement.addEventListener('click', () => {
        this.newLot();
      });
    }
  }

  init(attributeInstanceId: number, product_id: number) {
    const request_form: DataStoreRequest = {
      windowId: null,
      parent_constraint: '',
      compiereRequest: {
        startRow: 0,
        endRow: 0,
        tableName:
          ' ( select a.name as columnName,CASE WHEN ai.M_AttributeValue_ID is not null THEN CAST(ai.M_AttributeValue_ID AS NVARCHAR2(100)) ELSE ai.value END AS value,asi.lot,asi.serno,asi.m_lot_id,asi.guaranteedate FROM m_attributesetinstance asi inner join m_attributeinstance ai on ai.m_attributesetinstance_id=asi.m_attributesetinstance_id inner join m_attribute a on a.m_attribute_id=ai.m_attribute_id WHERE ai.m_attributesetinstance_id= ' +
          attributeInstanceId +
          ' ) attribute_data',
        filterModel: {},
      },
    };
    this.subscriptions.push(
      this.store.getDataGrid(request_form, true).subscribe((formWS) => {
        if (formWS && formWS.data[0]) {
          this.ctxFromWs['Lot'] = formWS.data[0]['LOT'];
          this.ctxFromWs['M_Lot_ID'] = formWS.data[0]['M_LOT_ID'];
          this.ctxFromWs['SerNo'] = formWS.data[0]['SERNO'];
          for (let i = 0; i < formWS.data.length; i++) {
            const data = formWS.data[i];
            this.ctxFromWs[data['COLUMNNAME']] = data['VALUE'];
          }
          this.ctxFromWs['GuaranteeDate'] = formWS.data[0]['GUARANTEEDATE'];
        }

        zip(
          this.uiCreator.getSpecificWindow(this.formId),
          this.uiCreator.getProductAttribute(attributeInstanceId, product_id, this.sourceComponent.data.tabId)
        ).subscribe(([specific, fieldsWS]) => {
          const fields: IupicsField[] = [];
          for (let i = 0; i < fieldsWS.length; i++) {
            const field = fieldsWS[i];
            // if (field.ColumnName !== 'NewEdit' && field.ColumnName !== 'SelectExisting' && field.ColumnName !== 'Description') {
            // Remove this when WS is OK
            const compiereField: CompiereField = {
              field,
              details: null,
              data: null,
              urlList: null,
              urlCallouts: null,
              urlSearch: field.ColumnName === 'SelectExisting' ? field.selectClause : null,
            };
            // compiereField.field.AD_Reference_ID = 10; // Remove this when WS is OK
            if (field.listBoxVO && field.listBoxVO.options) {
              // compiereField.field.AD_Reference_ID = 30; // Remove this when WS is OK
              compiereField.data = [];
              for (let j = 0; j < field.listBoxVO.options.length; j++) {
                const listValue = field.listBoxVO.options[j];
                compiereField.data.push({ id: listValue.id, displayValue: listValue.name });
              }
            }
            const fieldTransformed = UICreatorUtils.transformField(compiereField);
            if (fieldTransformed) {
              fields.push(fieldTransformed);
            }
            // }
          }

          this.buildProductPanel(fields);
          this._transformedFields = super.transformFields(specific.items);
          this._transformedFields.mainItems[0].cssClass = 'p-col-12';
        });
      })
    );
  }
  buildProductPanel(fields: IupicsField[], isCssOnComponent = true) {
    if (!this.dataStore) {
      this.dataStore = this.store.newSpecificWindowData(this.formId);
    }
    this.fields = fields;
    const transformedFields = this.transformFields(fields);
    for (let i = 0; i < transformedFields.length; i++) {
      const item = transformedFields[i];
      /* on ajoute un defaultValue pour qu'on set les valeurs venants du store */
      this.addComponent(item, isCssOnComponent);
    }
  }

  transformFields(items: IupicsField[]): any {
    const itemsOrdered = [];
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      if (['NewEdit', 'SelectExisting'].includes(item.data.columnName)) {
        item.cssClass = 'p-col-6';
      } else {
        item.cssClass = 'p-col-12';
      }
      if (item.data.columnName === 'SelectExisting') {
        item.data['events'] = {
          click: (_item: any) => {
            if (!this.gridVcr.length) {
              this.addComponent(this._transformedFields.mainItems[0].children[0], true, this.gridVcr);
            }
          },
        };
      }
      if (item.data.columnName === 'Lot') {
        item.data.readOnlyLogic = item.data.readOnlyLogic
          ? item.data.readOnlyLogic + ' | @M_Lot_ID@>0'
          : '@M_Lot_ID@>0';
      }
      if (!isNil(item.data.defaultValue) && item.data.defaultValue !== '') {
        this.dataStore.data[item.data.columnName] = item.data.defaultValue;
      }
      itemsOrdered.push(item);
    }
    return itemsOrdered;
  }

  validAttributes(isNew = false) {
    // permet d'annuler le refresh de l'editViewParent à la fermeture de la fenetre
    if (<EditViewUiComponent>this.parentComponent) {
      this.parentComponent.isProcessLaunch = false;
    }
    const selection = this.getGridSelection('Product Attribute Table');
    if (selection?.length) {
      // Selectionne l'attribut depuis la grille
      this.selectAttributeFromGrid(selection[0]);
      this.closeModalEmitter.emit();
    } else {
      // Sinon vérifie qu'on a bien entré toutes les données
      const jsonToSave = {};
      const errors = [];
      if (this.dataContainers.length > 0) {
        for (let i = 0; i < this.dataContainers.length; i++) {
          const el = this.dataContainers[i];
          if (el instanceof CalendarUiComponent) {
            if (
              el.calendar.inputFieldValue !== null &&
              el.calendar.inputFieldValue !== undefined &&
              el.fieldValue !== ''
            ) {
              jsonToSave[el.data.columnName] =
                '' + moment(el.calendar.inputFieldValue, 'DD/MM/YYYY').toDate().getTime();
            } else {
              if (el.data.isMandatory) {
                errors.push(el.data.label);
              }
            }
          } else {
            if (el.fieldValue !== null && el.fieldValue !== undefined && el.fieldValue !== '') {
              jsonToSave[el.data.columnName] = el.fieldValue instanceof Object ? el.fieldValue.id : el.fieldValue + '';
            } else {
              if (el.data.isMandatory) {
                errors.push(el.data.label);
              }
            }
          }
        }
        if (errors.length === 0) {
          const product_id =
            this.sourceComponent.dataStored.data['M_Product_ID'] instanceof Object
              ? this.sourceComponent.dataStored.data['M_Product_ID'].id
              : this.sourceComponent.dataStored.data['M_Product_ID'];
          const attributeInstanceId = this.sourceComponent.dataStored.data['M_AttributeSetInstance_ID']
            ? this.sourceComponent.dataStored.data['M_AttributeSetInstance_ID'] instanceof Object
              ? this.sourceComponent.dataStored.data['M_AttributeSetInstance_ID'].id
              : this.sourceComponent.dataStored.data['M_AttributeSetInstance_ID']
            : this.attributeInstance_id;
          this.uiCreator
            .saveProductAttribute(
              attributeInstanceId,
              product_id,
              this.sourceComponent.data.tabId,
              this.sourceComponent.data.fieldId,
              jsonToSave
            )
            .subscribe((result) => {
              if (result) {
                this.store.updateStoreWithoutFields(this.parentComponent.currentDataStoreKey, null, {
                  [this.sourceComponent.data.columnName]: result[0],
                });
                if (this.parentComponent instanceof EditViewUiComponent) {
                  (<EditViewUiComponent>this.parentComponent).editTabs[0].dataStored.data[
                    this.sourceComponent.data.columnName
                  ] = result[0];
                  (<EditViewUiComponent>this.parentComponent).editTabs[0].updateData(
                    (<EditViewUiComponent>this.parentComponent).editTabs[0].dataStored
                  );
                }
              }
              this.closeModalEmitter.emit();
            });
        } else {
          this.messageManager.newMessage(
            new IupicsMessage(this.translateService.instant('specificWindow.fillMandatory'), errors.join(', '), 'error')
          );
        }
      }
    }
  }
  newLot() {
    const product_id =
      this.sourceComponent.dataStored.data['M_Product_ID'] instanceof Object
        ? this.sourceComponent.dataStored.data['M_Product_ID'].id
        : this.sourceComponent.dataStored.data['M_Product_ID'];
    this.uiCreator.saveProductAttributeLot(product_id).subscribe((response) => {
      if (response) {
        this.getDatacontainer('M_Lot_ID').data.items.push({ id: response['M_Lot_ID'], displayValue: response['Name'] });
        this.dataStore.data['M_Lot_ID'] = { id: response['M_Lot_ID'], displayValue: response['Name'] };
        this.getDatacontainer('M_Lot_ID').updateStore(this.dataStore.data['M_Lot_ID']);
        const lot = this.getDatacontainer('Lot') as InputTextUiComponent;
        lot.isReadOnly = true;
        lot.updateStore(response['Name']);
      }
    });
  }

  notifyFromGridAfterViewInit(gridView: GridViewUiComponent) {
    super.notifyFromGridAfterViewInit(gridView);
    gridView.GridTabInfinityScrollUiComponent.rowSelection = 'single';
    const dbClickListener = (event: CellDoubleClickedEvent) => {
      // permet la selection d'un attribut via un double click
      this.selectAttributeFromGrid(event.data);
      this.closeModalEmitter.emit();
    };
    gridView.GridTabInfinityScrollUiComponent.agGrid.api.addEventListener('cellDoubleClicked', dbClickListener);
  }

  private selectAttributeFromGrid(data: any) {
    const productAttribute = { id: data.Data_UUID.split(',')[1], displayValue: data.Description };
    if (this.parentComponent instanceof EditViewUiComponent) {
      this.store.updateStoreWithoutFields(this.parentComponent.currentDataStoreKey, null, {
        [this.sourceComponent.data.columnName]: productAttribute,
      });
      (<EditViewUiComponent>this.parentComponent).editTabs[0].dataStored.data[this.sourceComponent.data.columnName] =
        productAttribute;
      (<EditViewUiComponent>this.parentComponent).editTabs[0].updateData(
        (<EditViewUiComponent>this.parentComponent).editTabs[0].dataStored
      );
    }
    return;
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
    if (this.sourceModal) {
      this.sourceModal.modalClass = 'p-col-10 p-md-8 p-lg-6';
    }
  }
}
