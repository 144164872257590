<span
  [life]="3000"
  [hideDelay]="250"
  pTooltip="{{ tooltip }}"
  [escape]="false"
  tooltipPosition="top"
  [showDelay]="500"
  tooltipZIndex="2100"
  tooltipPosition="top">
  <label *ngIf="this.isLabelDisplay" for="float-input" [ngClass]="{ 'iu-field-only-label': data?.isFieldOnly }">{{
    label | textLimit : this.elementRef.nativeElement.firstElementChild.firstElementChild.clientWidth / 7
  }}</label>
  <textarea
    #input
    (change)="dataChange(input.value)"
    (contextmenu)="onContextMenu($event)"
    (press)="onContextMenu($any($event))"
    [(ngModel)]="fieldValue"
    [readonly]="isReadOnly ? 'disabled' : null"
    [tabindex]="isReadOnly ? '-1' : null"
    [ngClass]="[
      hasConflict ? 'iu-input-text-conflict' : '',
      isReadOnly ? 'p-disabled' : '',
      cssClassMandatory || '',
      'ui-textarea iu-field',
      mandatoryCss || ''
    ]"
    [rows]="shouldSyncHeightWithLine ? rows : 5"
    [style.margin-top.px]="data && data.marginTop > 0 ? data.marginTop : null"
    autocomplete="no"
    autoResize="autoResize"
    pInputTextarea
    [attr.value]="fieldValue"
    [style.resize]="resize"
    data-cy="input-text"
    [attr.data-cy-columnName]="data ? data.columnName : columnName"
    [style.font-size]="overridedCSS?.inputFontSize"></textarea>

  <span
    *ngIf="hasConflict && dataStored"
    #spanInput
    class="p-autocomplete-conflict-star"
    (mouseenter)="opConflict.show($event)">
    /!\
  </span>
  <iu-prime-overlay #opValuePref *ngIf="showValuePrefPanel" [dismissable]="false" appendTo="body">
    <iu-value-preference-panel
      [overlayPanel]="opValuePref"
      [fieldValue]="fieldValue"
      [dataStored]="this.dataStored"
      [sourceComponent]="this"
      [data]="this.data"
      (closeEmitter)="toggleValuePreferencePanel($event)"></iu-value-preference-panel>
  </iu-prime-overlay>
</span>

<iu-prime-overlay #opConflict>
  <a class="data-conflict" (click)="chooseData($event, (dataStored?.data)[data.columnName], true); opConflict.hide()">{{
    (dataStored?.data)[data.columnName]
  }}</a>
  |
  <a class="data-conflict" (click)="chooseData($event, conflictedData, false); opConflict.hide()">{{
    conflictedData
  }}</a>
</iu-prime-overlay>
