import { Pipe, PipeTransform } from '@angular/core';
import { Utils } from '@iupics-util/tools/util';
import { TranslateService } from '@ngx-translate/core';
import { MenuItemUI } from '@web-desktop/models/menu-item-ui';

@Pipe({
  name: 'favoriteFilter',
})
export class FavoriteFilterPipe implements PipeTransform {
  constructor(private translator: TranslateService) {}

  /**
   * Si un filtre est renseigné retourne l'ensemble des menu par ordre alphabétique sans être groupés par catégorie
   * @param groups
   * @param filter
   */
  transform(groups: { category: string; items: MenuItemUI[] }[], filter?: string): any {
    if (filter && filter !== '' && filter !== '!' && filter !== '#' && filter !== '!#') {
      const menuFiltered = this.filter(
        groups,
        filter,
        filter.startsWith('#', 0) || filter.startsWith('#', 1),
        filter.startsWith('!')
      );

      const mostAccurate = menuFiltered
        .filter((m) => this.findAccurate(m, filter))
        .sort((menu1: MenuItemUI, menu2: MenuItemUI) =>
          menu1.name < menu2.name ? -1 : menu1.name > menu2.name ? 1 : 0
        );
      const leastAccurate = menuFiltered
        .filter((m) => !this.findAccurate(m, filter))
        .sort((menu1: MenuItemUI, menu2: MenuItemUI) =>
          menu1.name < menu2.name ? -1 : menu1.name > menu2.name ? 1 : 0
        );

      return mostAccurate.concat(...leastAccurate);
    }
    return groups;
  }

  private findAccurate(m: MenuItemUI, filter: string) {
    let searchValue = filter;
    if (searchValue.startsWith('!')) {
      searchValue = searchValue.replace(/!/, '');
    }
    if (searchValue.startsWith('#')) {
      searchValue = searchValue.replace(/#/, '');
    }
    return Utils.cleanUpSpecialChars(m.name).startsWith(Utils.cleanUpSpecialChars(searchValue));
  }

  /**
   * les Filtres fonctionnent selon les règles suivantes:
   * - 'filtre' = filtre sur le nom du menu
   * - '!filtre' = négation du filtre sur le nom du menu
   * - '#filtre' = filtre sur le nom de la catégorie
   * - '!#filtre' = négation du filtre sur le nom de la catégorie
   * @param groups
   * @param filter
   * @param category
   * @param inverted
   */
  private filter(
    groups: { category: string; items: MenuItemUI[] }[],
    filter: string,
    category: boolean = false,
    inverted: boolean = false
  ) {
    filter = inverted ? filter.substring(1, 1 + filter.length) : filter;
    filter = category ? filter.substring(1, 1 + filter.length) : filter;
    const newArray: MenuItemUI[] = [];
    groups.forEach((group) => {
      newArray.push(...group.items);
    });
    const regex = '.*'.concat(Utils.cleanUpSpecialChars(filter, true).split(' ').join('.*'), '.*');

    if (category) {
      const otherCategory = {
        id: -1,
        name: this.translator.instant('dashboard.menu.others'),
        isSelected: false,
        icon: undefined,
        isDisplay: true,
      };
      return newArray
        .map((menu) => {
          if (menu.menuCategory === undefined) {
            menu.menuCategory = otherCategory;
          }
          return menu;
        })
        .filter((menu) => (Utils.cleanUpSpecialChars(menu.menuCategory.name).match(regex) === null) === inverted);
    }
    return newArray.filter((menu) => (Utils.cleanUpSpecialChars(menu.name).match(regex) === null) === inverted);
  }
}
