import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { MessageService } from 'primeng/api';
import { ZIndexUtils } from 'primeng/utils';
import * as shajs from 'sha.js';

export enum InfoDialogType {
  ERROR = 'error',
  INFO = 'info',
  CONFIRM = 'confirm',
  CONFIRM_YESNO = 'confirmyesno',
}

@Component({
  selector: 'iu-info-dialog',
  templateUrl: './info-dialog.component.html',
  styleUrls: ['./info-dialog.component.scss'],
})
export class InfoDialogComponent implements OnInit {
  @Input()
  message: {
    summary: string;
    detail: string;
  };
  @Input()
  dialogType: InfoDialogType;

  @Output()
  confirm = new EventEmitter<any>();
  @Output()
  cancel = new EventEmitter<any>();
  @ViewChild('dialogContainer', { static: true }) dialogContainerRef: ElementRef<HTMLInputElement>;
  key: string;

  isOpen = false;

  baseZIndex = 5000;

  constructor(
    private messageService: MessageService,
    private el: ElementRef
  ) {}

  ngOnInit() {
    if (this.dialogContainerRef) ZIndexUtils.set('info-dialog', this.dialogContainerRef.nativeElement, this.baseZIndex);
    if (this.message) {
      this.key = shajs('sha256')
        .update(this.message.summary + this.message.detail + this.dialogType + moment().valueOf())
        .digest('hex');
    } else {
      this.key = shajs('sha256')
        .update('global' + moment().valueOf())
        .digest('hex');
    }
  }

  confirmDialog() {
    this.isOpen = false;
    this.confirm.emit();
    this.messageService.clear(this.key);
    if (this.dialogContainerRef) ZIndexUtils.clear(this.dialogContainerRef.nativeElement);
  }

  cancelDialog() {
    this.isOpen = false;
    this.cancel.emit();
    this.messageService.clear(this.key);
    if (this.dialogContainerRef) ZIndexUtils.clear(this.dialogContainerRef.nativeElement);
  }

  showInfoDialog() {
    this.isOpen = true;
    this.messageService.add({
      key: this.key,
      sticky: true,
      closable: false,
      severity:
        this.dialogType === InfoDialogType.CONFIRM || this.dialogType === InfoDialogType.CONFIRM_YESNO
          ? 'warn'
          : this.dialogType,
      summary: this.message.summary,
      detail: this.message.detail,
    });
  }
}
