import { AfterViewInit, Component, OnInit, ViewChild, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { CompiereProcessService } from '@compiere-ws/services/compiere-process/compiere-process.service';
import { CompiereWorkflowService } from '@compiere-ws/services/compiere-workflow/compiere-workflow.service';
import { PoService } from '@compiere-ws/services/po/po.service';
import { ProcessInProgressService } from '@compiere-ws/services/process-in-progress/process-in-progress.service';
import { SocketService } from '@compiere-ws/services/socket/socket.service';
import { CustomDesignItemType } from '@iupics-components/models/custom-design';
import { AutocompleteUiComponent } from '@iupics-components/standard/fields/autocomplete-ui/autocomplete-ui.component';
import { DataStoreService } from '@iupics-manager/managers/data-store/data-store.service';
import { MessageManagerService } from '@iupics-manager/managers/message/message-manager.service';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { UICreatorService } from '@iupics-manager/managers/ui-creator/ui-creator.service';
import { WindowFactoryService } from '@iupics-manager/managers/ui-creator/window-factory/window-factory.service';
import { DynamicComponent } from '@iupics-manager/models/dynamic-component';
import { IupicsTypeEvent } from '@iupics-manager/models/iupics-event';
import { IupicsMessage } from '@iupics-manager/models/iupics-message';
import { TranslateService } from '@ngx-translate/core';
import { SpecificWindowUiComponent } from '../specific-window-ui/specific-window-ui.component';

@Component({
  selector: 'iu-direct-printing-documents',
  templateUrl: './direct-printing-documents.component.html',
  styleUrls: ['./direct-printing-documents.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DirectPrintingDocumentsComponent extends SpecificWindowUiComponent implements OnInit, AfterViewInit {
  @ViewChild('vcrParameters', { read: ViewContainerRef, static: true })
  vcrParameters: ViewContainerRef;

  @ViewChild('vcrProcess', { read: ViewContainerRef, static: true })
  vcrProcess: ViewContainerRef;

  @ViewChild('printerNameAutocomplete', { static: true })
  autoComplete: AutocompleteUiComponent;

  printerNames = { items: [] };
  printProcessID;

  constructor(
    windowFactory: WindowFactoryService,
    uiCreator: UICreatorService,
    store: DataStoreService,
    processService: CompiereProcessService,
    protected translateService: TranslateService,
    socketService: SocketService,
    private poService: PoService,
    private workflowService: CompiereWorkflowService,
    private messageManager: MessageManagerService,
    connectorService: SecurityManagerService,
    progressService: ProcessInProgressService
  ) {
    super(
      windowFactory,
      uiCreator,
      store,
      processService,
      socketService,
      connectorService,
      progressService,
      translateService
    );
  }

  ngOnInit() {
    this.uiCreator.getCupsPrinters().subscribe((cups) => {
      cups.forEach((printer) => {
        this.printerNames.items.push({ id: printer.name, displayValue: printer.name });
      });
    });
    this.uiCreator.getSpecificWindow(this.formId).subscribe((specificWindow) => {
      specificWindow.items.forEach((item) => {
        if (item.data.columnName === 'FileName') {
          this.customDesignArray.push({
            vcr: 'vcrParameters',
            type: CustomDesignItemType.FIELD,
            columnName: 'FileName',
            cssClass: 'p-col-12 p-md-6',
          });
        } else if (item.data.columnName === 'StartDirectImpression') {
          this.printProcessID = item.processId;
          this.customDesignArray.push({
            vcr: 'vcrProcess',
            type: CustomDesignItemType.FIELD,
            columnName: 'StartDirectImpression',
            cssClass: 'p-col-12 p-md-6',
          });
        }
      });
    });
    const item: DynamicComponent = {
      container: this,
      DOMParentComponent: this,
      component: 'SpecificWindowUiComponent',
      cssClass: 'p-col-12',
      isCssOnComponent: false,
      tabId: this.formId,
      gridPaginator: false,
    };
    this.windowFactory.newEventHandler({
      type: IupicsTypeEvent.showSpecificWindow,
      item: item,
    });
  }

  processStartDirectImpression() {
    if (this.autoComplete && this.autoComplete.fieldValue && this.autoComplete.fieldValue.displayValue) {
      this.executeProcess(this.printProcessID, { PrinterName: this.autoComplete.fieldValue.displayValue });
    } else {
      this.messageManager.newMessage(
        new IupicsMessage(
          'Error',
          this.translateService.instant('specificWindow.DirectPrintingDocumentsComponent.toolTipSelectPrinter')
        )
      );
    }
  }
}
