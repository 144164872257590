<!-- #region widgets -->
<div class="apiz-p-grid" style="position: relative">
    <div
            [ngClass]="[
      isMobileWidth() ? 'menu-mobile' : '',
      isMobileWidth() && showList ? 'menu-mobile-show' : '',
      'p-col-11 p-md-11 p-lg-11 menu-closed'
    ]">
        <!-- faire switcher menu-closed en menu-opened via le clic sur le button ci-dessous -->
        <iu-dashboard-group-ui
                data-cy="widget-group-favorites"
                (changeMenuItemList)="onChangeCategory($event)"
                [groups]="groups"
                (addGroupItem)="onAddGroupItem($event)"
                (renameGroupItem)="onRenameGroupItem($event)"
                (removeGroupItem)="onRemoveGroupItem($event)"
                (dragEndEmitter)="onSwitchMenuGroup($event)"
                keybinding="dashboard.widget-group"></iu-dashboard-group-ui>
    </div>
    <h2 class="p-col-10 mobileOnly">
        <div class="dashboard-section-header">
            <button
                    data-cy="mobile_widget_favorite_toggle"
                    class="mobile_menu_bar_toggle"
                    iuToggleButton
                    [iuToggleButtonSwitch]="true"
                    [(iuToggleButtonVar)]="showList">
                <i
                        [ngClass]="[showList ? 'icon-menu-bars-toggle-opened' : 'icon-menu-bars-toggle-closed']"
                        aria-hidden="true"></i>
            </button>
            <div class="menu_name">{{ groups | dashboardGroupName : currentGroupID }}</div>
        </div>
    </h2>
    <div class="p-col-1 p-md-1 p-lg-1 ui-sd-2 shortManage">
        <ul
                id="widgetManager-container"
                class="shortcutsManager"
                iuAddActive
                [iuAddActive-idToCheck]="'widgetManager-add'"
                [iuAddActive-otherToClose]="['shortcutsManager-container']"
                iuKeybinding
                [iuKeybinding-active]="true"
                iuKeybinding-code="dashboard.widget-menu">
            <li>
                <a
                        id="widgetManager-add"
                        data-cy="widget-add-favorite"
                        href="#"
                        tooltipPosition="left"
                        [showDelay]="250"
                        [life]="3000"
                        [hideDelay]="250"
                        pTooltip="{{ 'dashboard.addWidget' | translate }}"
                        class="add"
                        (click)="openList($event)">
                    <i class="icon-add" aria-hidden="true"></i>
                </a>
                <ul>
                    <ng-container *ngIf="widgets$ | async as widgets">
                        <li class="title">Widgets disponibles</li>
                        <li class="searchSC">
                            <input
                                #searchInput
                                data-cy="widget-search-favorite"
                                class="searchInput p-inputtext"
                                (keydown)="handleInputKeydown($event)"
                                (keyup)="handleInputKeyup($event, widgets)"
                                [(ngModel)]="searchWidgetValue"
                                type="text"
                                iuAutofocusDirective/>
                            <i class="iconeSearch icon-search-bolder" aria-hidden="true" #ddBtn type="button"></i>
                        </li>
                        <span id="iu_widget_container" class="listNiv-1">
              <ng-container *ngFor="let widget of widgets | objectArrayFilter : [searchWidgetValue, 'name']">
                <li>
                  <a
                          id="iu_widget_menu_{{ widget.menuId }}"
                          data-cy="widget-favorite"
                          href="#"
                          [ngClass]="[widget.menuId === arrowFocusedWidget?.menuId ? 'item-arrow-focused' : '', 'add-icon']"
                          (click)="$event.preventDefault(); addItem(widget)">
                    <span>{{ widget.name }}</span>
                    <i class="icon-add" aria-hidden="true"></i>
                  </a>
                </li>
              </ng-container>
            </span>
                    </ng-container>
                </ul>
            </li>
        </ul>
    </div>
</div>

<div class="apiz-p-grid boardList" data-cy="widget-favorites">
    <ng-container *ngIf="currentItemList$ | async as currentItemList; else widgetsLoading">
        <ng-container *ngFor="let widget of currentItemList; let i = index">
            <div
                #dragElem
                class="drag-item {{ widgetUi.class }}"
                (dragstart)="handleDragStart($event, dragElem, widget)"
                (mouseover)="isDragMode && dragElem !== activeDiv ? handleMouseOver($event, dragElem) : null">
                <iu-widget-ui
                    #widgetUi
                    [index]="i"
                    [widget]="widget"
                    (changeWidgetColor)="onUpdateItem(widget)"
                    (deleteWidget)="onDeleteItem(widget)"
                    (openTab)="onOpenTab($event)"></iu-widget-ui>
            </div>
        </ng-container>
    </ng-container>
</div>
<!-- #endregion -->

<ng-template #widgetsLoading> <!-- On peut mettre le loader ici ou des ghost elements --> </ng-template>
