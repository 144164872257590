import { Component, EventEmitter, HostListener, Input, Output, Renderer2 } from '@angular/core';
import { InfoDialogType } from '@iupics-components/specific/window/info-dialog/info-dialog.component';
import { KeybindConfigService } from '@iupics-config/keybind.config.service';
import { Global } from '@iupics-manager/models/global-var';
import { TranslateService } from '@ngx-translate/core';
import { MenuFavoritesCategoryUI } from '@web-desktop/models/menu-item-ui';

@Component({
  selector: 'iu-dashboard-group-ui',
  templateUrl: './dashboard-group-ui.component.html',
  styleUrls: ['./dashboard-group-ui.component.scss'],
})
export class DashboardGroupUiComponent {
  @Input() groups: MenuFavoritesCategoryUI[] = [];
  @Input() draggedElt: any;
  @Input() dragOverElement: any;
  @Input() keybinding: string;

  @Output() changeMenuItemList = new EventEmitter<any>();
  @Output() addGroupItem = new EventEmitter<any>();
  @Output() renameGroupItem = new EventEmitter<any>();
  @Output() removeGroupItem = new EventEmitter<any>();
  @Output() draggedElementChangeEmitter: EventEmitter<any> = new EventEmitter();
  @Output() dragOverElementChangeEmitter: EventEmitter<any> = new EventEmitter();
  @Output() dragEndEmitter: EventEmitter<any> = new EventEmitter();

  currentActiveIDX = 0;
  private idxDragElt = -1;

  indexToRename = -1;
  showRename = false;
  current_menu_name = '';
  menuItems: Array<any> = [];
  isMobile = false;
  screenHeight: number;
  screenWidth: number;
  isMenuShown = false;
  selectedGroup: { id: number; name: string };

  private _timeoutResize: NodeJS.Timeout;

  @HostListener('window:resize')
  onResize() {
    if (this._timeoutResize) {
      clearTimeout(this._timeoutResize);
      this._timeoutResize = undefined;
    }
    let width = 0;
    let height = 0;
    width = Global.getDeviceWidth();
    height = Global.getDeviceHeight();

    this._timeoutResize = setTimeout(() => {
      this.isMobile = width < 480;
      this.screenHeight = height;
      this.screenWidth = width;
      this._timeoutResize = undefined;
    }, 150);
  }

  constructor(
    private keybindConfig: KeybindConfigService,
    private translator: TranslateService,
    private renderer: Renderer2
  ) {
    this.onResize();
  }

  onSelectitem(id: number, index: number) {
    this.changeMenuItemList.emit(id);
    this.currentActiveIDX = index;
  }

  onDelete(index: number) {
    Global.infoDialog.message = {
      summary: this.translator.instant('infodialog.dialogs.delete.title'),
      detail: this.translator.instant('infodialog.dialogs.delete.message'),
    };
    Global.infoDialog.dialogType = InfoDialogType.CONFIRM_YESNO;
    Global.infoDialog.showInfoDialog();
    const confirm = Global.infoDialog.confirm.subscribe((e: any) => {
      this.deleteGroup(index);
      confirm.unsubscribe();
      cancel.unsubscribe();
    });
    const cancel = Global.infoDialog.cancel.subscribe((e: any) => {
      confirm.unsubscribe();
      cancel.unsubscribe();
    });
  }

  deleteGroup(index: number) {
    this.removeGroupItem.emit({ id: this.groups[index].id });
    if (this.groups.length > 0) {
      this.currentActiveIDX = 0;
    }
  }

  onAdd() {
    this.addGroupItem.emit();
  }

  onRename(index: number, name: string) {
    this.renameGroupItem.emit({
      name,
      id: this.groups[index].id,
      seqNo: this.groups[index].seqNo,
    });
    this.showRename = false;
  }

  dragstart(event: DragEvent, dragElt: HTMLElement, i: number) {
    event.dataTransfer.setData('fireEvent', 'dragend');
    this.draggedElt = dragElt;
    this.idxDragElt = i;
    this.renderer.addClass(dragElt, 'dragged');
  }

  dragend(overElt: HTMLElement) {
    const idxDragEndElt = Array.prototype.indexOf.call(overElt.parentNode.children, overElt);
    this.renderer.removeClass(this.draggedElt, 'dragged');
    if (idxDragEndElt !== -1 && this.idxDragElt !== idxDragEndElt) {
      this.currentActiveIDX = Array.prototype.findIndex.call(overElt.parentNode.children, (elt: Element) =>
        elt.classList.contains('active')
      );
      this.dragEndEmitter.emit({ from: this.idxDragElt, to: idxDragEndElt });
    }
  }

  dragOver(event: DragEvent, overElt: HTMLElement) {
    if (this.draggedElt) {
      if (event.offsetX < overElt.offsetWidth / 2) {
        overElt.before(this.draggedElt);
      } else {
        overElt.after(this.draggedElt);
      }
    }
    event.preventDefault();
  }

  switchCategory(event: KeyboardEvent, key: string) {
    const keyCode = this.keybindConfig.getKeyCode(key);
    // eslint-disable-next-line deprecation/deprecation
    if (keyCode !== undefined && keyCode !== null && keyCode !== -1 && event.keyCode === keyCode) {
      if (this.groups.length > 0) {
        let index: number;
        if (event.shiftKey === true) {
          index = this.groups.findIndex((group) => group.id === this.groups[this.currentActiveIDX].id) - 1;
          if (index < 0) {
            index = this.groups.length - 1;
          }
        } else {
          index = this.groups.findIndex((group) => group.id === this.groups[this.currentActiveIDX].id) + 1;
          if (index >= this.groups.length) {
            index = 0;
          }
        }
        this.onSelectitem(this.groups[index].id, index);
      }
    }
  }

  displayMenu(event: Event, item: { id: number; name: string }) {
    event.stopPropagation();
    event.preventDefault();
    this.selectedGroup = item;
    this.isMenuShown = !this.isMenuShown;
  }
}
