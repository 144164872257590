<iu-prime-fieldset
  [legend]="label"
  [toggleable]="toggleable && !this.isTopEditTab"
  [isSingleRow]="this.data.isSingleRow"
  [isAdditionalInfo]="this.data.isAdditionalInfo ? this.data.isAdditionalInfo : false"
  [(collapsed)]="collapsed"
  [style.display]="isDisplay"
  (openEmitter)="openInNewBlade()"
  [tableName]="this.data ? this.data.tableName : ''"
  [isReadOnly]="this.isReadOnly || this.data.isReadOnly">
  <p-accordion>
    <ng-template iuDynamicContainer></ng-template>
  </p-accordion>
</iu-prime-fieldset>
