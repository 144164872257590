import { Component, OnInit } from '@angular/core';
import { CompiereProcessService } from '@compiere-ws/services/compiere-process/compiere-process.service';
import { ProcessInProgressService } from '@compiere-ws/services/process-in-progress/process-in-progress.service';
import { SocketService } from '@compiere-ws/services/socket/socket.service';
import { DataStoreService } from '@iupics-manager/managers/data-store/data-store.service';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { UICreatorService } from '@iupics-manager/managers/ui-creator/ui-creator.service';
import { WindowFactoryService } from '@iupics-manager/managers/ui-creator/window-factory/window-factory.service';
import { TranslateService } from '@ngx-translate/core';
import { SpecificWindowUiComponent } from '../specific-window-ui/specific-window-ui.component';

@Component({
  selector: 'iu-create-from-invoice-window-ui',
  templateUrl: './create-from-invoice-window-ui.component.html',
  styleUrls: ['./create-from-invoice-window-ui.component.scss'],
})
export class CreateFromInvoiceWindowUiComponent extends SpecificWindowUiComponent implements OnInit {
  orderGrid: any;
  inoutGrid: any;
  consignedTransferGrid: any;
  orderSelections: any;
  inoutSelections: any;
  consignedTransferSelections: any;

  constructor(
    windowFactory: WindowFactoryService,
    uiCreator: UICreatorService,
    store: DataStoreService,
    processService: CompiereProcessService,
    protected translateService: TranslateService,
    socketService: SocketService,
    connectorService: SecurityManagerService,
    progressService: ProcessInProgressService
  ) {
    super(
      windowFactory,
      uiCreator,
      store,
      processService,
      socketService,
      connectorService,
      progressService,
      translateService
    );
    this.isModal = false;
  }
  ngOnInit() {
    super.ngOnInit();
    // this.dataStore.data['C_Order_ID'] = null;
    // this.dataStore.data['M_InOut_ID'] = null;
    // this.dataStore.data['M_ConsignedTransfer_ID'] = null;
    if (this.dataStore.data['IsSOTrx'] === 'N') {
      this.dataContainers[1].label = this.translateService.instant('specificWindow.createFromInvoice.purchaseOrder');
      this.dataContainers[1].data.label = this.translateService.instant(
        'specificWindow.createFromInvoice.purchaseOrder'
      );
    }
  }

  notifyFromDataChange(item: any) {
    if (item.data.columnName === 'C_Order_ID') {
      this.dataStore.data['M_InOut_ID'] = null;
      this.dataStore.data['M_ConsignedTransfer_ID'] = null;
      this.clearSelections();
    } else if (item.data.columnName === 'M_InOut_ID') {
      this.dataStore.data['C_Order_ID'] = null;
      this.dataStore.data['M_ConsignedTransfer_ID'] = null;
      this.clearSelections();
    } else if (item.data.columnName === 'M_ConsignedTransfer_ID') {
      this.dataStore.data['C_Order_ID'] = null;
      this.dataStore.data['M_InOut_ID'] = null;
      this.clearSelections();
    }
    super.notifyFromDataChange(item);
  }

  clearSelections() {
    this.dataStore.data['selections'].forEach((grid) => {
      grid['selection'] = [];
    });
    this.gridViews.forEach((grid) => {
      grid.GridTabInfinityScrollUiComponent.agGrid.api.deselectAll();
    });
  }
}
