import { ElementRef, EventEmitter, Injectable, OnDestroy, Renderer2, ViewChild, inject } from '@angular/core';
import { CompiereMenu } from '@compiere-ws/models/compiere-menu-json';
import { InfoDialogType } from '@iupics-components/specific/window/info-dialog/info-dialog.component';
import { KeyCode } from '@iupics-config/keycode.enum';
import { UICreatorService } from '@iupics-manager/managers/ui-creator/ui-creator.service';
import { Global } from '@iupics-manager/models/global-var';
import { TranslateService } from '@ngx-translate/core';
import { DashboardManagerService } from '@web-desktop/components/workspace/controllers/dashboard-manager/dashboard-manager.service';
import { MenuFavoritesCategoryUI, MenuItemUI } from '@web-desktop/models/menu-item-ui';
import { Observable, Subscription, of } from 'rxjs';

@Injectable()
export abstract class DragDropGroup implements OnDestroy {
  public isDragMode = false;
  public activeDiv: HTMLDivElement;
  protected indexGroupDrag: number;
  protected indexGroupOver: number;
  protected activeDude: any;
  protected emptyDiv: HTMLDivElement;
  protected indexOfPreview: number;
  protected mouseupListener: Function;
  protected mousemoveListener: Function;
  protected windowFocusListener: Function;

  protected abstract newItemLabel: string;
  public showList: boolean = false;
  public groups: MenuFavoritesCategoryUI[] = [];
  protected _currentItemList: MenuItemUI[];
  public currentItemList$: Observable<MenuItemUI[]>;
  public currentGroupID: number = 0;
  public itemsList: { [parentId: number]: MenuItemUI[] } = {};
  public isMobileWidth = Global.isMobileWidth;

  public abstract itemClicked: EventEmitter<MenuItemUI>;
  @ViewChild('searchInput', { read: ElementRef }) searchInputEl: ElementRef;

  protected translator = inject(TranslateService);
  protected uiCreatorService = inject(UICreatorService);
  protected dashboardManager = inject(DashboardManagerService);
  protected renderer = inject(Renderer2);

  //#region draggable v2
  protected createEmptyDiv(cssClass: string) {
    this.emptyDiv = this.renderer.createElement('div');
    this.renderer.addClass(this.emptyDiv, 'drag-item');
    this.renderer.addClass(this.emptyDiv, 'empty');
    for (const _cssClass of cssClass.trim().split(' ')) {
      if (_cssClass) {
        this.renderer.addClass(this.emptyDiv, _cssClass);
      }
    }
    this.renderer.appendChild(this.emptyDiv, this.renderer.createText('empty'));
  }

  handleDragStart($event: MouseEvent, dragElem: HTMLDivElement, item: any) {
    this.isDragMode = true;
    this.renderer.insertBefore(dragElem.parentElement, this.emptyDiv, dragElem);
    this.renderer.addClass(dragElem, 'drag-mode');
    this.renderer.setStyle(dragElem, 'top', `${this.getY($event, dragElem) - 35}px`);
    this.renderer.setStyle(dragElem, 'left', `${this.getX($event, dragElem)}px`);

    this.mouseupListener = this.renderer.listen(document.documentElement, 'mouseup', (event: MouseEvent) => {
      this.handleDrop(event, dragElem);
    });

    this.mousemoveListener = this.renderer.listen(document.documentElement, 'mousemove', (event: MouseEvent) => {
      this.handleDrag(event, dragElem);
    });

    this.windowFocusListener = this.renderer.listen(window, 'blur', (event) => {
      this.handleDrop(event, dragElem);
      this.windowFocusListener();
    });
    this.activeDiv = dragElem;
    this.activeDude = item;
    this.indexOfPreview = Array.from(this.activeDiv.parentElement.children).indexOf(this.emptyDiv);
  }

  protected handleDrag($event: MouseEvent, dragElem: HTMLDivElement) {
    // top = $event.pageY / left = $event.pageX
    this.renderer.setStyle(dragElem, 'top', `${this.getY($event, dragElem) - 35}px`);
    this.renderer.setStyle(dragElem, 'left', `${this.getX($event, dragElem)}px`);
  }

  protected handleDrop($event: MouseEvent, dragElem: HTMLDivElement) {
    $event.stopPropagation();
    this.isDragMode = false;
    this.renderer.removeChild(this.emptyDiv.parentElement, this.emptyDiv);
    this.renderer.removeClass(dragElem, 'drag-mode');
    this.renderer.removeStyle(dragElem, 'top');
    this.renderer.removeStyle(dragElem, 'left');
    this.mouseupListener();
    this.mousemoveListener();
    this.windowFocusListener();

    // changement de place dans le tableau
    this._currentItemList.splice(this._currentItemList.indexOf(this.activeDude), 1);
    this._currentItemList.splice(this.indexOfPreview, 0, this.activeDude);

    this.saveItemPos();
  }

  protected getY($event: MouseEvent, dragElem: HTMLDivElement) {
    return $event.pageY + dragElem.clientHeight + 2 > document.documentElement.clientHeight
      ? document.documentElement.clientHeight - (dragElem.clientHeight + 2)
      : $event.pageY < 0
      ? 0
      : $event.pageY;
  }

  protected getX($event: MouseEvent, dragElem: HTMLDivElement) {
    return $event.pageX + dragElem.clientWidth + 2 > document.documentElement.clientWidth
      ? document.documentElement.clientWidth - (dragElem.clientWidth + 2)
      : $event.pageX < 0
      ? 0
      : $event.pageX;
  }

  handleMouseOver($event: MouseEvent, overElem: HTMLDivElement) {
    $event.stopPropagation();
    const elems = Array.from(overElem.parentElement.children).filter((elem) => elem !== this.activeDiv);
    const indexOfOver = Array.from(elems).indexOf(overElem);
    const isLast = indexOfOver === elems.length - 1;
    this.indexOfPreview > indexOfOver
      ? isLast
        ? this.renderer.appendChild(overElem.parentElement, this.emptyDiv)
        : this.renderer.insertBefore(overElem.parentElement, this.emptyDiv, overElem)
      : isLast
      ? this.renderer.appendChild(overElem.parentElement, this.emptyDiv)
      : this.renderer.insertBefore(overElem.parentElement, this.emptyDiv, overElem.nextSibling);
    this.indexOfPreview = Array.from(this.emptyDiv.parentElement.children)
      .filter((elem) => elem !== this.activeDiv)
      .indexOf(this.emptyDiv);
  }
  //#endregion

  //#region common method
  public ngOnDestroy(): void {
    ['mouseupListener', 'mousemoveListener', 'windowFocusListener'].forEach((key) => {
      if (this[key] !== undefined) {
        this[key]();
      }
    });
  }
  public refresh() {
    this.getItems(true);
  }

  public onOpenTab(favorite: MenuItemUI) {
    this.itemClicked.emit(favorite);
  }

  /**
   * afficher la liste d'une autre catégorie
   */
  public onChangeCategory(id: number) {
    if (id !== this.currentGroupID) {
      this.showList = false;
      this.currentGroupID = id;
      this._currentItemList = [];
      if (this.itemsList[this.currentGroupID]) {
        this._currentItemList = this.itemsList[this.currentGroupID];
      }
      this.currentItemList$ = of(this._currentItemList);
    }
  }

  /**
   * ajoute une catégorie
   */
  public onAddGroupItem(item?: MenuItemUI) {
    const name = this.getDefaultName();
    const arrayToSave: CompiereMenu[] = [];
    const dashboardGroupItem: CompiereMenu = {
      cssColor: null,
      name: name.trim(),
      isSummary: true,
      parentId: 0,
      seqNo: this.groups.length > 0 ? this.groups[this.groups.length - 1].seqNo + 10 : 10,
    };
    arrayToSave.push(dashboardGroupItem);
    this.save(arrayToSave).subscribe((res) => {
      if (Object.keys(this.itemsList).length === 0) {
        this.currentGroupID = res[0].menuId;
      }
      this.itemsList[res[0].menuId] = [];
      this._currentItemList = this.itemsList[this.currentGroupID];
      this.groups.push({ id: res[0].menuId, name: name, seqNo: res[0].seqNo });
      if (item) {
        this.addItem(item);
      }
      this.currentItemList$ = of(this._currentItemList);
    });
  }

  private getDefaultName(): string {
    const base = this.translator.instant(this.newItemLabel);
    if (this.groups.length > 0) {
      return (base + ' ' + (this.groups.length + 1)).trim();
    }
    return base.trim();
  }

  protected addItem(item: MenuItemUI) {
    if (this.groups.length === 0) {
      this.onAddGroupItem(item);
    } else {
      let exist = false;
      let i = 0;
      while (this._currentItemList.length > i && !exist) {
        if (this._currentItemList[i++].menuId === item.menuId) {
          exist = true;
        }
      }
      if (!exist) {
        const dashboardMenuItem: CompiereMenu = {
          angularClass: item.angularClass,
          description: item.description,
          menuId: item.menuId,
          parentId: this.currentGroupID,
          isSummary: item.isSummary,
          isEditable: true,
          tags: [],
          cssColor: '',
          name: item.name,
          seqNo:
            this._currentItemList.length > 0 ? this._currentItemList[this._currentItemList.length - 1].seqNo + 10 : 10,
        };
        const arrayToSave = [];
        arrayToSave.push(dashboardMenuItem);
        item.seqNo = dashboardMenuItem.seqNo;
        item.parentId = this.currentGroupID;
        item.color = '';
        this.save(arrayToSave).subscribe(() => {
          this._currentItemList.push(item);
          this.currentItemList$ = of(this._currentItemList);
        });
      }
    }
  }

  /**
   * renome une catégorie
   * @param elem
   */
  protected onRenameGroupItem(elem: { name: string; id: number; seqNo: number }) {
    const arrayToSave = [];
    const dashboardGroupItem: CompiereMenu = {
      isSummary: true,
      menuId: elem.id,
      name: elem.name,
      parentId: 0,
      isEditable: true,
      tags: [],
      seqNo: elem.seqNo,
    };
    arrayToSave.push(dashboardGroupItem);
    this.save(arrayToSave).subscribe(() => {
      this.groups[this.groups.findIndex((group) => group.id === elem.id)].name = elem.name;
    });
  }

  /**
   * supprime une catégorie
   * @param elem
   */
  protected onRemoveGroupItem(elem: { id: number }) {
    this.delete(0, elem.id, true).subscribe(() => {
      this.groups.splice(
        this.groups.findIndex((group) => group.id === elem.id),
        1
      );
      delete this.itemsList[elem.id];
      if (this.groups.length > 0) {
        this.onChangeCategory(this.groups[0].id);
      } else {
        this._currentItemList = [];
        this.currentItemList$ = of(this._currentItemList);
      }
    });
  }

  protected onSwitchMenuGroup({ from, to }: { from: number; to: number }) {
    const menuToMove = this.groups.splice(from, 1)[0];
    this.groups.splice(to, 0, menuToMove);
    let seqNo = 10;
    const arrayToSave = [];
    this.groups.forEach((group) => {
      const dashboardGroupItem: CompiereMenu = {
        cssColor: null,
        menuId: group.id,
        name: group.name,
        isSummary: true,
        isEditable: true,
        tags: [],
        parentId: 0,
        seqNo: seqNo,
      };
      seqNo += 10;
      arrayToSave.push(dashboardGroupItem);
    });
    this.save(arrayToSave).subscribe();
  }

  protected onDeleteItem(item: MenuItemUI) {
    let confirm: Subscription, cancel: Subscription;
    Global.infoDialog.message = {
      summary: this.translator.instant('infodialog.dialogs.delete.title'),
      detail: this.translator.instant('infodialog.dialogs.delete.message'),
    };
    Global.infoDialog.dialogType = InfoDialogType.CONFIRM_YESNO;
    Global.infoDialog.showInfoDialog();
    confirm = Global.infoDialog.confirm.subscribe((e: any) => {
      this.deleteFavorite(item);
      if (confirm !== undefined) {
        confirm.unsubscribe();
      }
      if (cancel !== undefined) {
        cancel.unsubscribe();
      }
    });
    cancel = Global.infoDialog.cancel.subscribe((e: any) => {
      if (confirm !== undefined) {
        confirm.unsubscribe();
      }
      if (cancel !== undefined) {
        cancel.unsubscribe();
      }
    });
  }

  private deleteFavorite(favorite: MenuItemUI) {
    this.delete(favorite.parentId, favorite.menuId, favorite.isSummary).subscribe(() => {
      this._currentItemList.splice(
        this._currentItemList.findIndex((item) => item.menuId === favorite.menuId),
        1
      );
      this.currentItemList$ = of(this._currentItemList);
    });
  }

  protected onUpdateItem(item: MenuItemUI) {
    const dashboardMenuItem: CompiereMenu = {
      angularClass: item.angularClass,
      description: item.description,
      menuId: item.menuId,
      parentId: item.parentId,
      isSummary: item.isSummary,
      isEditable: true,
      tags: [],
      cssColor: item.color,
      name: item.name,
      seqNo: item.seqNo,
    };
    const arrayToSave = [];
    arrayToSave.push(dashboardMenuItem);
    this.save(arrayToSave).subscribe();
  }

  protected handleInputKeydown(event: KeyboardEvent) {
    // eslint-disable-next-line deprecation/deprecation
    switch (event.keyCode) {
      case KeyCode.LEFT_ARROW:
        event.preventDefault();
        break;
      case KeyCode.UP_ARROW:
        event.preventDefault();
        break;
      case KeyCode.RIGHT_ARROW:
        event.preventDefault();
        break;
      case KeyCode.DOWN_ARROW:
        event.preventDefault();
        break;
      default:
        break;
    }
  }

  protected getItems(forceRefresh = false) {
    this.getAll(forceRefresh).subscribe((favorites) => {
      this.groups = this.getGroups();
      this.groups.forEach((element) => {
        this.itemsList[element.id] = [];
      });
      if (this.groups[0] && this.currentGroupID === 0) {
        this.currentGroupID = this.groups[0].id;
      }
      favorites.forEach((favorite) => {
        this.itemsList[favorite.parentId].push(favorite);
      });
      if (this.itemsList[this.currentGroupID]) {
        this._currentItemList = this.itemsList[this.currentGroupID];
      } else {
        this._currentItemList = [];
      }
      this.currentItemList$ = of(this._currentItemList);
    });
  }

  protected saveItemPos() {
    if (!this.isListSorted()) {
      let seqNo = 10;
      const arrayToSave = [];
      this._currentItemList.forEach((item) => {
        item.seqNo = seqNo;
        const dashboardMenuItem: CompiereMenu = {
          angularClass: item.angularClass,
          description: item.description,
          menuId: item.menuId,
          parentId: item.parentId,
          isSummary: item.isSummary,
          isEditable: true,
          tags: [],
          cssColor: item.color,
          name: item.name,
          seqNo: item.seqNo,
        };
        arrayToSave.push(dashboardMenuItem);
        seqNo += 10;
      });
      this.save(arrayToSave).subscribe(() => (this.currentItemList$ = of(this._currentItemList)));
    }
  }

  protected isListSorted(): boolean {
    for (let i = 0; i < this._currentItemList.length - 1; i++) {
      if (this._currentItemList[i].seqNo > this._currentItemList[i + 1].seqNo) {
        return false;
      }
    }
    return true;
  }
  //#endregion

  //#region method to implement
  public abstract openList(event: Event): void;
  protected abstract getAll(forceRefresh: boolean): Observable<MenuItemUI[]>;
  protected abstract save(arrayToSave: CompiereMenu[]): Observable<CompiereMenu[]>;
  protected abstract delete(parentId: number, menuId: number, isSummary: boolean): Observable<boolean>;
  protected abstract selectItem(): void;
  protected abstract getGroups(): MenuFavoritesCategoryUI[];
  //#endregion
}
