import { Pipe, PipeTransform } from '@angular/core';
import { UICreatorService } from '@iupics-manager/managers/ui-creator/ui-creator.service';
import { MenuCategoryUI, MenuItemUI } from '@web-desktop/models/menu-item-ui';
import { MenuUiManagerService } from '../../controllers/menu-ui-manager/menu-ui-manager.service';

@Pipe({
  name: 'groupByCategory',
})
export class GroupByCategoryPipe implements PipeTransform {
  constructor(private menuUiManager: MenuUiManagerService, private uiCreator: UICreatorService) {}

  transform(menus: MenuItemUI[]): { category: MenuCategoryUI; items: MenuItemUI[] }[] {
    const categories = this.uiCreator.getIupicsMenusCategories();
    if (categories !== undefined && categories.length > 0) {
      return this.menuUiManager.groupByCategory(menus, categories);
    }
    return null;
  }
}
