<span
  #spanRef
  [style.display]="displayCss"
  (contextmenu)="onContextMenu($event)"
  (press)="onContextMenu($any($event))"
  [style.backgroundColor]="backgroundColorCss"
  [style.margin-top.px]="data && data.marginTop > 0 ? data.marginTop : null">
  <iu-prime-autocomplete
    (completeMethod)="search($event)"
    (mousedown)="onMouseDown($event)"
    [isLabelDisplay]="this.isLabelDisplay"
    (onFocus)="search($event)"
    (click)="onInputClick($event)"
    (onBlur)="blurEmitter.emit($event)"
    (onSelect)="dataChange(autoComplete.value)"
    (onUnselect)="dataChange(autoComplete.value)"
    (showFormPanelEmitter)="showFormPanel($event)"
    (showSearchPanelEmitter)="showSearchPanel()"
    [suggestions]="suggestions"
    [data]="data"
    [columnName]="columnName"
    [dataStored]="dataStored"
    [description]="tooltip"
    [readonly]="isReadOnly"
    [disabled]="isReadOnly"
    [dropdown]="true"
    [iconClass]="iconClass"
    [label]="label"
    [multiple]="multiple"
    [ngClass]="[hasConflict ? 'iu-field-conflict' : '']"
    [scrollHeight]="scrollHeight"
    [suggestionsFilter]="suggestionsFilter"
    [tabID]="data?.tabId"
    [isRendererView]="isRendererView"
    [isInsideOverflow]="isInsideOverflow"
    [isStandalone]="isStandalone"
    [overridedCSS]="overridedCSS"
    [appendTo]="appendTo"
    #autoComplete></iu-prime-autocomplete>
  <i
    *ngIf="hasConflict && dataStored"
    #spanInput
    class="rtconflict fas fa-exclamation-triangle iconClass iu-field-conflict-icon"
    (click)="showConflictPanel($event)"
    aria-hidden="true"></i>
  <iu-prime-overlay #opValuePref *ngIf="showValuePrefPanel" [dismissable]="true" appendTo="body">
    <iu-value-preference-panel
      [overlayPanel]="opValuePref"
      [fieldValue]="fieldValue"
      [dataStored]="this.dataStored"
      [sourceComponent]="this"
      [data]="this.data"
      (closeEmitter)="toggleValuePreferencePanel($event)"></iu-value-preference-panel>
  </iu-prime-overlay>
</span>

<iu-prime-overlay #opConflict>
  <a class="data-conflict" (click)="chooseData($event, (dataStored?.data)[data.columnName], true); opConflict.hide()">{{
    (dataStored?.data)[data.columnName]?.displayValue
  }}</a>
  |
  <a class="data-conflict" (click)="chooseData($event, conflictedData, false); opConflict.hide()">{{
    conflictedData?.displayValue
  }}</a>
</iu-prime-overlay>

<ng-container *ngIf="displaySearch">
  <iu-modal-ui
    data-cy="search-panel"
    [attr.data-cy-columnName]="this.autoComplete.data.columnName"
    [title]="this.autoComplete.label"
    [hasCloseBtn]="true"
    (closeModalEmitter)="displaySearch = false"
    [appendTo]="'body'">
    <div class="autocomplete-universal-filter">
      <iu-universal-filter-ui
        [tabId]="-1"
        [columns]="this.autoComplete.data.searchColumns"
        [gridTab]="gridTab"></iu-universal-filter-ui>
    </div>
    <iu-grid-tab-infinity-scroll-ui
      #gridTab
      (searchEmitter)="this.setSearchSelectItem($event); displaySearch = false; searchPanelValidation = undefined"
      [data]="this.autoComplete.data"
      [container]="parentContainer"
      [isSearch]="true"
      [dataStored]="this.autoComplete.dataStored"
      [validation]="searchPanelValidation"
      [windowType]="windowType"
      [getCurrentContext]="getCurrentContext"
      [isInUniversalFilter]="isInUniversalFilter">
    </iu-grid-tab-infinity-scroll-ui>
  </iu-modal-ui>
</ng-container>
<div *ngIf="displayFormUI" [ngClass]="[!displayFormUI ? 'hidden' : '', 'specificModal']">
  <iu-modal-ui
    #specificModal
    [title]="specificWindowTitle"
    [isModalDisplay]="displayFormUI"
    [angularStyle]="customFormModalBodyCss"
    (closeModalEmitter)="updateModalDisplay({ key: 'displayFormUI', value: false })"
    [appendTo]="'body'">
    <ng-template #vcrSpecific></ng-template>
  </iu-modal-ui>
</div>
