import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { environment } from 'environments/environment';

@Component({
  selector: 'iu-app-version-panel',
  templateUrl: './app-version-panel.component.html',
  styleUrls: ['./app-version-panel.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AppVersionPanelComponent implements OnInit {
  officialApizWebSiteUrl = `${environment.webSiteUrl}`;
  version =
    environment.CUST_version !== '{CUST_version}'
      ? `${environment.CUST_version} (v${environment.STD_version})`
      : `v${environment.STD_version}`;
  constructor() {}
  ngOnInit() {}
}
