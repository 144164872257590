import { Injectable } from '@angular/core';
import { CompiereMenu } from '@compiere-ws/models/compiere-menu-json';
import { WidgetDataCompiereJson } from '@compiere-ws/models/widget-center-json';
import { AppConfig } from '@iupics-config/app.config';
import { Observable } from 'rxjs';
import { ApiService } from '../api/api.service';

@Injectable()
export class WidgetCenterService {
  private readonly widgetsURL: string;
  private readonly widgetDataURL: string;

  constructor(private http: ApiService, private config: AppConfig) {
    this.widgetsURL = this.config.getBackendResource('widgets');
    this.widgetDataURL = this.config.getBackendResource('widgetData');
  }

  getWidgetList(): Observable<CompiereMenu[]> {
    return this.http.get<CompiereMenu[]>(`${this.widgetsURL}`);
  }

  getWidgetData(menuId: number): Observable<WidgetDataCompiereJson> {
    return this.http.get<WidgetDataCompiereJson>(`${this.widgetDataURL}/${menuId}`);
  }
}
