import { Injectable } from '@angular/core';
import { PrintReportJSON } from '@compiere-ws/models/compiere-print-report-json';
import { AppConfig } from '@iupics-config/app.config';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { ApiService } from '../api/api.service';

@Injectable()
export class PrintReportService {
  private urlPrint: string;

  constructor(private http: ApiService, private config: AppConfig) {}

  printReport(dataForReport: PrintReportJSON): Observable<any> {
    this.urlPrint = environment.config.backend.ws.url + environment.config.backend.ws.paths['printReport'];
    // this.urlPrint = this.config.getProcessRunnerResource('printReport');
    return this.http.post(this.urlPrint, dataForReport);
  }

  downloadReport(urlDownload: string): Observable<any> {
    return this.http.get<any>(environment.config.backend.ws.url + urlDownload.replace(/\\/g, '/'), {
      responseType: 'blob' as 'json',
      observe: 'response',
    });
  }
}
