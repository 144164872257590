import {
  AfterViewChecked,
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { DataStoreKey } from '@compiere-ws/models/compiere-data-json';
import { DetailsNoteService } from '@compiere-ws/services/details-note/details-note.service';
import { DocServerService } from '@compiere-ws/services/doc-server/doc-server.service';
import { UploadedFile } from '@iupics-components/models/uploaded-file';
import { InputMentionUiComponent } from '@iupics-components/standard/fields/input-mention-ui/input-mention-ui.component';
import { EditTabUiComponent } from '@iupics-components/standard/layouts/edit-tab-ui/edit-tab-ui.component';
import { EditViewUiComponent } from '@iupics-components/standard/layouts/edit-view-ui/edit-view-ui.component';
import { ModalContentType } from '@iupics-components/standard/layouts/modal-ui/modal-ui.component';
import { CacheManagerService } from '@iupics-manager/managers/cache-manager/cache-manager.service';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { AbstractDynamicView } from '@iupics-manager/models/abstract-dynamic-view';
import { Global } from '@iupics-manager/models/global-var';
import { IupicsEvent, IupicsTypeEvent } from '@iupics-manager/models/iupics-event';
import { UserAccount } from '@iupics-manager/models/user-account';
import moment from 'moment';
import { BehaviorSubject } from 'rxjs';
import { ChatEntry, ChatTag, NoteEntry } from '../notes.interface';
@Component({
  selector: 'iu-notes-panel-ui',
  templateUrl: './notes-panel-ui.component.html',
  styleUrls: ['./notes-panel-ui.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NotesPanelUiComponent
  extends AbstractDynamicView
  implements OnInit, AfterViewInit, AfterViewChecked, OnDestroy
{
  @Input() showTitle = true;
  @Input() table_id: number;
  @Input() currentDataStoreKey: DataStoreKey;
  @Input() parentEdit;
  @ViewChild('notesContainer') notesContainer: ElementRef<HTMLElement>;
  @ViewChild('notesWrapper') notesWrapper: ElementRef<HTMLElement>;
  subjectNote: string;
  isNotesLoaded = false;
  isNoteSending = false;

  //#region enum for template
  ModalContentType = ModalContentType;

  isFollow: boolean = false;
  chatID: number = -1;
  recordID: number;
  windowID: number;
  notes: NoteEntry[];
  displayJoinFilesPanel = false;
  entryAttachment: UploadedFile = null;

  /* Users infos */
  me: UserAccount;

  /* Input note */
  @ViewChild(InputMentionUiComponent) inputMentionRef: InputMentionUiComponent;
  isTagsLoaded = false;
  tags: ChatTag[];
  tags$ = new BehaviorSubject<ChatTag[]>([]);

  constructor(
    public connectorService: SecurityManagerService,
    private detailsNoteService: DetailsNoteService,
    private docServerService: DocServerService
  ) {
    super();
  }

  ngOnInit(): void {
    this.me = this.connectorService.getIupicsUserAccount();
    this.entryAttachment = null;
    this.loadNotesData();
    if (!this.parentEdit) {
      this.parentTab.dataStored.dataChange.subscribe({
        next: () => this.loadNotesData(),
      });
    }
  }

  ngAfterViewInit(): void {
    this.scrollNotesIntoView();
  }

  ngAfterViewChecked(): void {
    if (!this.isNotesLoaded) {
      this.scrollToEnd();
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => {
      if (sub) {
        sub.unsubscribe();
      }
    });
  }

  onSiblingUpdate(event: IupicsEvent): void {
    this.loadNotesData();
  }

  onRemoveComponent(event: IupicsEvent): void {
    if (event.type === IupicsTypeEvent.removeChildEvent) {
      event.type = IupicsTypeEvent.removeBreadcrumbItem;
      this.DOMParentComponent.onChildUpdate(event);
      this.notifierLinkedComponent.next({
        type: IupicsTypeEvent.expandEvent,
        item: undefined,
      });
    }
  }

  addTabToEditView(editTabUi: EditTabUiComponent): void {}

  loadNotesData() {
    this.isNotesLoaded = false;
    if (this.parentEdit) {
      if (this.parentEdit.editTabs?.[0]) {
        this.parentEdit.editTabs?.[0]?.dataStored.dataChange.subscribe({
          next: () => this.loadNotesData(),
        });
      }
      this.DOMParentComponent = this.parentEdit;
    }
    this.table_id = (<EditViewUiComponent>this.DOMParentComponent).table_id;
    this.currentDataStoreKey = (<EditViewUiComponent>this.DOMParentComponent).currentDataStoreKey;
    this.recordID = parseInt(this.currentDataStoreKey.recordId.split(',')[1]);
    this.windowID = this.currentDataStoreKey.windowId;
    if (!this.isNoteEmpty()) {
      this.clearText();
      this.entryAttachment = null;
    }
    this.getChatTags();
    this.showNotes();

    const dataStored = (<EditViewUiComponent>this.DOMParentComponent).editTabs?.[0]?.dataStored?.data;
    let subject = dataStored?.['Name'];
    subject ??= dataStored?.['Value'];
    subject ??= dataStored?.['DocumentNo'];
    subject ??= `ID : ${this.currentDataStoreKey.recordId.split(',')[1]}`;
    this.subjectNote = (<EditViewUiComponent>this.DOMParentComponent).data.label + ' — ' + subject;
  }

  showNotes() {
    this.notes = [];
    this.detailsNoteService.getChatEntry(this.table_id, this.recordID).subscribe((chat) => {
      if (chat) {
        this.isFollow = chat.follow;
        this.chatID = chat.chatID;
        this.notes = chat?.chatEntries?.map((chatEntry) => this.parseNoteData(chatEntry)) ?? [];
        this.isNotesLoaded = true;
      }
    });
  }

  getChatTags() {
    if (CacheManagerService.chatTags[this.windowID]) {
      this.tags = CacheManagerService.chatTags[this.windowID];
      this.tags$.next(CacheManagerService.chatTags[this.windowID]);
      this.isTagsLoaded = true;
    } else {
      this.subscriptions.push(
        this.detailsNoteService.getChatTags(this.chatID, this.windowID).subscribe({
          next: (tags) => {
            CacheManagerService.chatTags[this.windowID] = tags;
            this.tags = tags;
            this.tags$.next(tags);
            this.isTagsLoaded = true;
          },
        })
      );
    }
  }

  sendNote() {
    if (this.isNoteSending || this.isNoteEmpty()) {
      return;
    }

    const creatAt = new Date().toUTCString();
    const chatEntryToSend: ChatEntry = {
      chatEntryID: this.chatID,
      //created : creatAt.substring(0, 26) + creatAt.substring(27, creatAt.length),
      characterData: this.getText(),
      taggedUsers: this.getMentions(),
      subject: this.subjectNote,
      chatID: this.chatID,
      tableID: this.table_id,
      recordID: this.recordID,
      windowID: this.windowID,
      userID: this.me.id,
      userName: this.me.avatarInfos.fullname, // retrieve ad user fullname
      chatEntryAttachments: [],
    };

    if (this.entryAttachment != null) {
      chatEntryToSend.chatEntryAttachments = [
        {
          downloadURL: `${this.entryAttachment.src}`,
          fileName: `${this.entryAttachment.name}`,
          chatEntryID: 0,
          cm_ChatEntryAttachmentID: 0,
        },
      ];
    }

    this.isNoteSending = true;
    this.detailsNoteService.sendChatEntry(chatEntryToSend).subscribe((chatEntry) => {
      if (chatEntry) {
        this.entryAttachment = null;
        if (this.chatID == -1) {
          this.chatID = chatEntry.chatID;
        }

        chatEntry.created = creatAt;

        this.clearText();
        this.notes.push(this.parseNoteData(chatEntry));
        this.scrollToEnd();
        this.isNoteSending = false;
      }
    });
  }

  parseNoteData(chatEntry: ChatEntry): NoteEntry {
    const date = moment(chatEntry?.created ?? new Date());

    return {
      createdAt: `${date.format('L').slice(0, 10)} ${date.format('LT')}`,
      author: {
        id: chatEntry.userID,
        fullname: chatEntry.userName,
        // pictureURL:
      },
      content: chatEntry.characterData,
      mentions: chatEntry.taggedUsers,
      attachements: (chatEntry.chatEntryAttachments ?? []).map((attachment) => {
        return {
          src: attachment.downloadURL,
          filename: attachment.fileName,
          type: attachment?.fileName?.split('.')?.[attachment.fileName.split('.').length - 1] ?? undefined,
        };
      }),
    };
  }

  follow(action) {
    if (!action) {
      this.detailsNoteService.deleteFollowChat(this.chatID, this.me.id).subscribe((result) => {
        if (result) {
          this.isFollow = action;
        }
      });
    } else {
      this.detailsNoteService.followChat(this.chatID, this.me.id, this.table_id, this.recordID).subscribe((result) => {
        if (result) {
          this.chatID = result.chatID;
          this.isFollow = action;
        }
      });
    }
  }

  fileUpload(event) {
    this.entryAttachment = event;
  }

  deleteEntryAttachment() {
    this.subscriptions.push(
      this.docServerService.deleteDocument(this.entryAttachment, false).subscribe((res) => {
        this.entryAttachment = null;
      })
    );
  }

  downloadEntryAttachment(attachment) {
    const sub = this.docServerService.downloadDocument(attachment.src as string).subscribe((response) => {
      Global.downloadFile(response, attachment.filename as string);
      sub.unsubscribe();
    });
  }

  isNoteEmpty() {
    const text = this.getText();
    return (text === null || text === undefined || text.trim().length === 0) && this.entryAttachment === null;
  }

  getText() {
    if (this.inputMentionRef) {
      return this.inputMentionRef.getContent();
    }

    return null;
  }

  getMentions() {
    return this.inputMentionRef
      .getMentions()
      .map((m) => m.value)
      .join(',');
  }

  clearText() {
    this.inputMentionRef.setContent('');
  }

  scrollNotesIntoView() {
    // Scroll only if last edit view
    if (
      this.container &&
      this.container.DOMChildrenComponent?.[this.container.DOMChildrenComponent.length - 1] === this.DOMParentComponent
    ) {
      setTimeout(() => {
        this.notesContainer.nativeElement.scrollIntoView({
          behavior: 'smooth',
          inline: 'end',
        });
      }, 220);
    }
  }

  scrollToEnd() {
    setTimeout(() => (this.notesWrapper.nativeElement.scrollTop = this.notesWrapper.nativeElement.scrollHeight), 0);
  }
}
