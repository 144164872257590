import { Injectable, OnDestroy } from '@angular/core';
import { CompiereMenu } from '@compiere-ws/models/compiere-menu-json';
import { SpecificWindowCompiereWS } from '@compiere-ws/models/specific-window-json';
import { CompiereTab, TabsEntityCompiereJSON, WindowCompiereWS } from '@compiere-ws/models/window-json';
import { CompiereCalloutService } from '@compiere-ws/services/compiere-callout/compiere-callout.service';
import { CompiereDataService } from '@compiere-ws/services/compiere-data/compiere-data.service';
import { LocationService } from '@compiere-ws/services/compiere-location/location.service';
import { CompiereMenuService } from '@compiere-ws/services/compiere-menu/compiere-menu.service';
import { CompiereMenuFavoritesService } from '@compiere-ws/services/compiere-menufavorites/compiere-menufavorites.service';
import { CompiereProcessService } from '@compiere-ws/services/compiere-process/compiere-process.service';
import { PrinterService } from '@compiere-ws/services/printer-service/printer-service.service';
import { WidgetCenterService } from '@compiere-ws/services/widget-center/widget-center.service';
import { WindowsService } from '@compiere-ws/services/windows/windows.service';
import { CacheManagerService } from '@iupics-manager/managers/cache-manager/cache-manager.service';
import { DataStoreService } from '@iupics-manager/managers/data-store/data-store.service';
import {
  IupicsField,
  IupicsProcess,
  IupicsSpecificWindow,
  IupicsTab,
  IupicsTabUI,
} from '@iupics-manager/models/iupics-data';
import { IupicsMessage } from '@iupics-manager/models/iupics-message';
import { IupicsColumnInfo } from '@iupics-manager/models/iupics_column_info';
import { TranslateService } from '@ngx-translate/core';
import { IupicsMenuType, MenuCategoryUI, MenuFavoritesCategoryUI, MenuItemUI } from '@web-desktop/models/menu-item-ui';
import { cloneDeep } from 'lodash';
import { Observable, Subscription, of, throwError, zip } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { MessageManagerService } from '../message/message-manager.service';
import { SecurityManagerService } from '../security-manager/security-manager.service';
import { MenuCreatorUtils } from './utils/ui-creator-menu.utils';
import { SpecificWindowsCreatorUtils } from './utils/ui-creator-specific-windows.utils';
import { UICreatorUtils } from './utils/ui-creator.utils';

@Injectable()
export class UICreatorService implements OnDestroy {
  private subscriptions: Subscription[] = [];
  fieldGroup: IupicsField;

  private iupicsMenus$: Observable<MenuItemUI[]>;
  private iupicsMenuFavorites: MenuItemUI[];
  private iupicsWidgetFavorites: MenuItemUI[];
  private iupicsMenuCategories: MenuCategoryUI[];
  private iupicsMenuFavoritesCategories: MenuFavoritesCategoryUI[];
  private iupicsWidgets$: Observable<MenuItemUI[]>;
  private iupicsWidgetFavoritesCategories: MenuFavoritesCategoryUI[];

  private iupicsWindowDefaultTab: Map<number, number> = new Map();
  private iupicsTabs: Map<string, IupicsTabUI> = new Map();
  private iupicsWindowNames: Map<number, string> = new Map();
  private iupicsSpecificWindows: Map<number, IupicsSpecificWindow> = new Map();
  private iupicsSpecificWindowsErrors: Map<number, any> = new Map();
  constructor(
    private menuService: CompiereMenuService,
    private menuFavoritesService: CompiereMenuFavoritesService,
    private widgetCenterService: WidgetCenterService,
    private windowService: WindowsService,
    private translator: TranslateService,
    private calloutService: CompiereCalloutService,
    private processSevice: CompiereProcessService,
    private compiereDataService: CompiereDataService,
    private dataStore: DataStoreService,
    private messageManager: MessageManagerService,
    private printerService: PrinterService,
    private connectorService: SecurityManagerService,
    private translateService: TranslateService,
    private cacheService: CacheManagerService,
    private locationService: LocationService
  ) {
    UICreatorUtils.setDataStore(this.dataStore);
    UICreatorUtils.setUICreatorService(this);
    UICreatorUtils.setConnectorService(this.connectorService);
    UICreatorUtils.setTranslateService(this.translateService);
    UICreatorUtils.setCacheService(this.cacheService);
    SpecificWindowsCreatorUtils.setDataStore(this.dataStore);
    SpecificWindowsCreatorUtils.setConnectorService(this.connectorService);
  }

  resetCachingData() {
    this.iupicsWindowDefaultTab = new Map();
    this.iupicsTabs = new Map();
    this.iupicsMenus$ = undefined;
    this.iupicsMenuFavorites = undefined;
    this.iupicsMenuCategories = [];
    this.iupicsMenuFavoritesCategories = [];
    this.iupicsWidgets$ = undefined;
    this.iupicsWidgetFavorites = undefined;
    this.iupicsWidgetFavoritesCategories = [];
    this.iupicsSpecificWindows = new Map();
    this.cacheService.clearCache();
  }

  getIupicsMenusCategories() {
    return this.iupicsMenuCategories;
  }

  getIupicsMenuFavoritesCategories() {
    return this.iupicsMenuFavoritesCategories;
  }
  getIupicsWidgetFavoritesCategories() {
    return this.iupicsWidgetFavoritesCategories;
  }

  getIupicsMenus(): Observable<MenuItemUI[]> {
    if (!this.iupicsMenus$) {
      this.iupicsMenus$ = this.menuService.getMenus().pipe(
        map((compiereMenus) => {
          this.iupicsMenuCategories = [];
          const [iupicsMenus, iupicsMenuCategories] = MenuCreatorUtils.transformMenus([], compiereMenus);
          this.iupicsMenuCategories = iupicsMenuCategories;

          this.iupicsMenuCategories.push(
            {
              id: -1,
              name: this.translator.instant('menu.category_all'),
              icon: 'fas fa-folder',
            },
            {
              id: 0,
              name: this.translator.instant('menu.category_mostAccurate'),
              icon: '',
            },
            {
              id: Number.POSITIVE_INFINITY,
              name: this.translator.instant('menu.category_undefined'),
              icon: 'fas fa-not-equal',
            }
          );

          this.iupicsMenuCategories = MenuCreatorUtils.sortCategories(this.iupicsMenuCategories);

          return iupicsMenus;
        }),
        shareReplay({
          bufferSize: 1,
          refCount: true,
        })
      );
    }
    return this.iupicsMenus$;
  }

  getIupicsWidgets(): Observable<MenuItemUI[]> {
    if (!this.iupicsWidgets$) {
      this.iupicsWidgets$ = this.widgetCenterService.getWidgetList().pipe(
        map((compiereMenus) => {
          const [iupicsMenus, _] = MenuCreatorUtils.transformMenus([], compiereMenus);
          return iupicsMenus;
        }),
        shareReplay({
          bufferSize: 1,
          refCount: true,
        })
      );
    }
    return this.iupicsWidgets$;
  }

  getIupicsMenuFromWindow(window_id: number, menuType: IupicsMenuType) {
    return this.getIupicsMenus().pipe(
      map((menus) => menus.find((menu) => menu.actionID === window_id && menu.menuType === menuType))
    );
  }

  getIupicsMenuFavorites(forceRefresh = false): Observable<MenuItemUI[]> {
    if (!forceRefresh && this.iupicsMenuFavorites) {
      return of(this.iupicsMenuFavorites);
    } else {
      return this.menuFavoritesService.getMenuFavorites().pipe(
        map((compiereMenuFavorites) => {
          this.iupicsMenuFavoritesCategories = [];
          const [iupicsMenuFavorites, _] = MenuCreatorUtils.transformMenus(
            this.iupicsMenuFavoritesCategories,
            compiereMenuFavorites,
            true
          );
          this.iupicsMenuFavorites = iupicsMenuFavorites;
          return this.iupicsMenuFavorites;
        })
      );
    }
  }

  getIupicsWidgetFavorites(forceRefresh = false): Observable<MenuItemUI[]> {
    if (!forceRefresh && this.iupicsWidgetFavorites) {
      return of(this.iupicsWidgetFavorites);
    } else {
      return this.menuFavoritesService.getWidgetFavorites().pipe(
        map((compiereWidgetFavorites) => {
          this.iupicsWidgetFavoritesCategories = [];
          const [iupicsMenuFavorites, _] = MenuCreatorUtils.transformMenus(
            this.iupicsWidgetFavoritesCategories,
            compiereWidgetFavorites,
            true
          );
          this.iupicsWidgetFavorites = iupicsMenuFavorites;
          return this.iupicsWidgetFavorites;
        })
      );
    }
  }

  setIupicsWidgetFavorites(menuItem: CompiereMenu[]): Observable<CompiereMenu[]> {
    return this.menuFavoritesService.setWidgetFavorites(menuItem);
  }

  deleteIupicsWidgetFavorites(parentId: number, menuId: number, isSummary: boolean): Observable<boolean> {
    return this.menuFavoritesService.deleteWidgetFavorites(parentId, menuId, isSummary);
  }

  setIupicsFavorites(menuItem: CompiereMenu[]): Observable<CompiereMenu[]> {
    return this.menuFavoritesService.setMenuFavorites(menuItem);
  }

  deleteIupicsFavorites(parentId: number, menuId: number, isSummary: boolean): Observable<boolean> {
    return this.menuFavoritesService.deleteMenuFavorites(parentId, menuId, isSummary);
  }

  getProductAttribute(attributeInstance_id: number, product_ID: number, tab_id: number): Observable<any> {
    return this.windowService.getProductAttribute(attributeInstance_id, product_ID, tab_id);
  }

  saveProductAttribute(
    attributeInstance_id: number,
    product_ID: number,
    tab_id: number,
    fieldId: number,
    params: any
  ): Observable<any> {
    return this.windowService.saveProductAttribute(attributeInstance_id, product_ID, tab_id, fieldId, params);
  }
  saveProductAttributeLot(product_ID: number): Observable<any> {
    return this.windowService.saveProductAttributeLot(product_ID);
  }

  generateKey(windowType: 'WINDOW' | 'FORM', windowId: number, tabId?: number) {
    let tabPart = '';
    if (windowType === 'WINDOW') {
      tabPart = tabId !== undefined ? '_' + tabId : '';
    }
    return windowType + '_' + windowId + tabPart;
  }
  getWindow(windowId: number): Observable<IupicsTabUI> {
    if (this.iupicsWindowDefaultTab.has(windowId)) {
      const key = this.generateKey('WINDOW', windowId, this.iupicsWindowDefaultTab.get(windowId));
      return of(cloneDeep(this.iupicsTabs.get(key)));
    } else {
      return this.windowService.getWindowUI(windowId).pipe(
        map((compiereWindow) => {
          const definitionUI: {
            tabs: IupicsTabUI[];
            processes: IupicsProcess[];
            columnInfos: { adTabId: number; columnInfo: IupicsColumnInfo[] }[];
          } = compiereWindow.definitionUI;
          definitionUI.tabs.forEach((tabDef: IupicsTabUI) => {
            for (let key of Object.keys(tabDef.dataStructures)) {
              this.dataStore.addWindowDataStructure(parseInt(key, 10), tabDef.dataStructures[key]);
            }
            if (tabDef.firstTab.tabLevel === 0) {
              this.iupicsWindowDefaultTab.set(windowId, tabDef.tabId);
            }
            const func = (el) => {
              el.children.forEach((c) => {
                if (c.component === 'GridViewUiComponent' && c.data.columnsTableHeader) {
                  UICreatorUtils.prepareColumnsTableHeader(c.data.columnsTableHeader);
                }
                if (c.children && c.children.length > 0) {
                  func(c);
                }
              });
            };
            tabDef.editTabs.forEach((t) => {
              func(t);
            });
            tabDef.additionalTabs.forEach((t) => {
              func(t);
            });
            if (tabDef.firstTab.gridView?.data?.columnsTableHeader) {
              UICreatorUtils.prepareColumnsTableHeader(tabDef.firstTab.gridView.data.columnsTableHeader);
            }
            this.iupicsTabs.set(tabDef.key, tabDef);
          });
          definitionUI.columnInfos.forEach((columnInfoDef) => {
            this.dataStore.setColumnInfo(columnInfoDef.adTabId, columnInfoDef.columnInfo);
          });
          if (compiereWindow.forms) {
            for (const form of compiereWindow.forms) {
              this.loadSpecificWindow(form.AD_Form_ID, of(form));
            }
          }
          if (definitionUI.processes) {
            for (const process of definitionUI.processes) {
              this.cacheService.loadProcessInCache(process.process.AD_Process_ID, of(process));
            }
          }

          const key = this.generateKey('WINDOW', windowId, this.iupicsWindowDefaultTab.get(windowId));

          return cloneDeep(this.iupicsTabs.get(key));
        })
      );
    }
  }
  getSpecificWindow(ad_form_id: number): Observable<IupicsSpecificWindow> {
    if (this.iupicsSpecificWindows.has(ad_form_id)) {
      const errors = this.iupicsSpecificWindowsErrors.get(ad_form_id);
      if (errors && Object.keys(errors).length > 0) {
        this.messageManager.newMessage(
          new IupicsMessage(this.translator.instant('specificWindow.itemsError'), errors.keys, 'error', errors.values)
        );
      }
      return of(cloneDeep(this.iupicsSpecificWindows.get(ad_form_id)));
    } else {
      return this.windowService.getSpecificWindow(ad_form_id).pipe(
        map((specificWindow) => {
          this.iupicsWindowNames.set(ad_form_id, specificWindow.Name);
          this.transformColumnInfosSpecificWindow(specificWindow);
          const errors = SpecificWindowsCreatorUtils.transformSpecificWindow(
            this.iupicsSpecificWindows,
            specificWindow
          );
          //load in cache
          const specificWindowCached = this.iupicsSpecificWindows.get(ad_form_id);
          if (specificWindowCached) {
            const arr = [];
            specificWindowCached.items
              .filter((it) => it.component !== 'GridViewUiComponent')
              .forEach((item) => {
                if (item.processId) {
                  this.cacheService.loadProcessInCache(item.processId);
                } else if (item.formId) {
                  arr.push(this.getSpecificWindow(item.formId));
                }
              });
            if (arr.length > 0) {
              zip(...arr).subscribe();
            }
          }

          this.iupicsSpecificWindowsErrors.set(ad_form_id, errors);
          return cloneDeep(this.iupicsSpecificWindows.get(ad_form_id));
        })
      );
    }
  }
  loadSpecificWindow(ad_form_id: number, source?: Observable<SpecificWindowCompiereWS>) {
    if (!source) {
      source = this.windowService.getSpecificWindow(ad_form_id);
    }
    if (!this.iupicsWindowNames.has(ad_form_id)) {
      this.iupicsWindowNames.set(ad_form_id, 'loading');
      this.subscriptions.push(
        source.subscribe((specificWindow) => {
          this.iupicsWindowNames.set(ad_form_id, specificWindow.Name);
          this.transformColumnInfosSpecificWindow(specificWindow);
          const errors = SpecificWindowsCreatorUtils.transformSpecificWindow(
            this.iupicsSpecificWindows,
            specificWindow
          );
          this.iupicsSpecificWindowsErrors.set(ad_form_id, errors);
        })
      );
    }
  }

  getDBSelect(query: string, params: any[], tablenames: string[]): Observable<any[]> {
    return this.calloutService.getData(query, params, tablenames);
  }

  executeProcess(params: any): Observable<any> {
    return this.processSevice.executeProcess(params);
  }

  /**
   *
   * @param tabId number
   * @returns Observable<IupicsTab[]>: l'ensemble des tabs pour iupics
   */
  getTab(windowId: number, tabId: number): Observable<IupicsTabUI> {
    const key = this.generateKey('WINDOW', windowId, tabId);
    if (this.iupicsTabs.has(key)) {
      return of(cloneDeep(this.iupicsTabs.get(key)));
    } else {
      console.error('No tab id found : ' + tabId);
    }
  }

  getActualTab(windowId: number, tabId: number): Observable<IupicsTabUI> {
    const key = this.generateKey('WINDOW', windowId, tabId);
    if (this.iupicsTabs.has(key)) {
      return of(cloneDeep(this.iupicsTabs.get(key)));
    } else {
      console.error(`No tab found for ID: ${tabId}`);
    }
  }

  creatorComponentOS(
    windowID: number,
    tab: TabsEntityCompiereJSON,
    sortOrder: number,
    sortYesNo: number,
    tabId: number,
    adTabID: number
  ): IupicsTab {
    const tabTransformed: IupicsTab = {
      tabId: tab.tab.AD_Tab_ID,
      tabLevel: tab.tab.TabLevel,
      isSingleRow: tab.tab.IsSingleRow,
      editView: {
        children: [],
        component: 'EditTabUiComponent',
        data: {
          positionEditTab: tab.tab.PositionEdit,
          colspan: tab.tab.ColspanEdit,
          isCollapsable: true,
          label: tab.tab.Name,
          isSingleRow: tab.tab.IsSingleRow,
          tabLevel: tab.tab.TabLevel,
          displayLogic: tab.tab.DisplayLogic,
        },
      },
      gridView: {
        children: [],
        component: 'SelectOrderComponent',
        gridPaginator: false,
        data: {
          positionEditTab: tab.tab.TabLevel === 0 ? tab.tab.PositionEdit : tab.tab.PositionGrid,
          maxNbLines: tab.tab.MaxNbLines,
          colspan: tab.tab.TabLevel === 0 ? tab.tab.ColspanEdit : tab.tab.ColspanGrid,
          isCollapsable: true,
          label: tab.tab.Name,
          columnsTableHeader: [],
          displayLogic: tab.tab.DisplayLogic,
          items: [],
          AD_ColumnSortOrder_ID: sortOrder,
          AD_ColumnSortYesNo_ID: sortYesNo,
          recordID: 0,
          AD_Table_ID: tabId,
          AD_Tab_ID: adTabID,
          AD_Window_ID: windowID,
        },
      },
    };
    return tabTransformed;
  }

  getCompiereTab(tab_id): Observable<CompiereTab> {
    return this.windowService.getTab(tab_id).pipe(map((v) => cloneDeep(v)));
  }

  zoomAcross(
    tableName: string,
    columnKey: string,
    record_id: number,
    isSOTrx?: boolean,
    window_id?: number
  ): Observable<any> {
    let tmpColumnKey = columnKey;
    if (tmpColumnKey && tmpColumnKey.split('.').length > 1) {
      tmpColumnKey = tmpColumnKey.split('.')[1];
    }
    return this.windowService.getZoomAcross(tableName, tmpColumnKey, record_id, isSOTrx, window_id);
  }

  getZoomTarget(tab_id: number, columnKey: string, record_id: number) {
    return this.windowService.getZoomTarget(tab_id, columnKey, record_id);
  }

  transformColumnInfos(compiereWindow: WindowCompiereWS) {
    compiereWindow.tabs.forEach((tabEntity) => {
      const columnInfo: IupicsColumnInfo[] = [];
      tabEntity.fields.forEach((fieldEntity) => {
        columnInfo.push({
          fieldEntity: fieldEntity,
          filterType: UICreatorUtils.getFilterTypeFromReference(fieldEntity.field.AD_Reference_ID),
        });
      });
      this.dataStore.setColumnInfo(tabEntity.tab.AD_Tab_ID, columnInfo);
    });
  }
  transformColumnInfosSpecificWindow(specificWindow: SpecificWindowCompiereWS) {
    const items = specificWindow.detail.items.filter((item) => item.detail && item.detail.Type === 'T');
    for (let i = 0; i < items.length; i++) {
      const tableItem = items[i];
      const columnInfo: IupicsColumnInfo[] = [];
      for (let j = 0; j < tableItem.detail.items.length; j++) {
        const column = tableItem.detail.items[j];
        let fieldEntity = cloneDeep(column) as any;
        delete fieldEntity.field;
        fieldEntity = { ...fieldEntity, ...column.field };
        columnInfo.push({
          fieldEntity: fieldEntity,
          filterType: UICreatorUtils.getFilterTypeFromReference(column.field.field.AD_Reference_ID),
        });
      }
      this.dataStore.setColumnInfo(tableItem.detail.AD_FormDetail_ID, columnInfo, true);
    }
  }

  getColumnInfos(id: number, fromSpecific = false): Observable<IupicsColumnInfo[]> {
    const prefix = fromSpecific ? 'AD_FormDetail_ID' : 'AD_Tab_ID';
    if (this.dataStore.hasColumnInfo(id, fromSpecific)) {
      return of(this.dataStore.getColumnInfo(id, fromSpecific));
    } else {
      return throwError(() => new Error(`No column infos for ${prefix} : ${id}`));
    }
  }
  getFieldsData(url: string): Observable<any> {
    url = url.replace(/\/\//g, '/');
    return this.compiereDataService.getFieldsData(url);
  }

  getCupsPrinters(): Observable<any> {
    return this.printerService.getCupsPrinters();
  }

  /*getLocationPanel(ad_form_id: number, c_country_id: number): Observable<IupicsSpecificWindow> {
    c_country_id = c_country_id ? c_country_id : -1;
    return this.locationService.getLocationByCountry(ad_form_id, c_country_id).pipe(
      map((specificWindow) => {
        if (specificWindow) {
          this.iupicsWindowNames.set(ad_form_id, specificWindow.Name);
          this.transformColumnInfosSpecificWindow(specificWindow);
          const errors = SpecificWindowsCreatorUtils.transformSpecificWindow(this.iupicsSpecificWindows, specificWindow);

          this.iupicsSpecificWindowsErrors.set(ad_form_id, errors);
          return this.iupicsSpecificWindows.get(ad_form_id);
        }
      })
    );
  }*/

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => {
      if (sub) sub.unsubscribe();
    });
  }
}
