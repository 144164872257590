import { Injectable } from '@angular/core';
import { WFDataJSON } from '@compiere-ws/models/compiere-tree-json';
import { CompiereWFNextRequest, CompiereWorkFlow } from '@compiere-ws/models/compiere-workflow-json';
import { AppConfig } from '@iupics-config/app.config';
import { Observable } from 'rxjs';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root',
})
export class CompiereWorkflowService {
  private url: string;
  private urlRunWF: string;

  constructor(private http: ApiService, private config: AppConfig) {
    this.url = this.config.getBackendResource('Doc_Status');
    this.urlRunWF = this.config.getBackendResource('workflow');
  }

  getAvailaibleStatus(field_id): Observable<CompiereWorkFlow> {
    const query = '?field_id=';
    return this.http.get<CompiereWorkFlow>(`${this.url}${query}${field_id}`);
  }

  getNextStatus(status: CompiereWFNextRequest): Observable<any> {
    return this.http.post(this.url + '/next', status);
  }

  runWF(data: WFDataJSON): Observable<any> {
    return this.http.post(this.urlRunWF, data);
  }
}
