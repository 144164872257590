import { AfterViewInit, Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ProcessPingInfo } from '@compiere-ws/models/process-ping-info';
import { CompiereProcessService } from '@compiere-ws/services/compiere-process/compiere-process.service';
import { ProcessInProgressService } from '@compiere-ws/services/process-in-progress/process-in-progress.service';
import { SocketService } from '@compiere-ws/services/socket/socket.service';
import { CustomDesignItemType } from '@iupics-components/models/custom-design';
import { StandaloneItem } from '@iupics-components/models/standalone-item';
import { EditViewUiComponent } from '@iupics-components/standard/layouts/edit-view-ui/edit-view-ui.component';
import { DataStoreService } from '@iupics-manager/managers/data-store/data-store.service';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { UICreatorService } from '@iupics-manager/managers/ui-creator/ui-creator.service';
import { WindowFactoryService } from '@iupics-manager/managers/ui-creator/window-factory/window-factory.service';
import { AbstractDataContainer } from '@iupics-manager/models/abstract-datacontainer';
import { DynamicComponent } from '@iupics-manager/models/dynamic-component';
import { IupicsTypeEvent } from '@iupics-manager/models/iupics-event';
import { TranslateService } from '@ngx-translate/core';
import { SpecificWindowUiComponent } from '../specific-window-ui/specific-window-ui.component';

@Component({
  selector: 'iu-accounting-viewer-ui',
  templateUrl: './accounting-viewer-ui.component.html',
  styleUrls: ['./accounting-viewer-ui.component.scss'],
})
export class AccountingViewerUiComponent extends SpecificWindowUiComponent implements OnInit, AfterViewInit {
  @ViewChild('left', { read: ViewContainerRef, static: true })
  vcrLeft: ViewContainerRef;
  @ViewChild('right', { read: ViewContainerRef, static: true })
  vcrRight: ViewContainerRef;
  @ViewChild('bot', { read: ViewContainerRef, static: true })
  vcrBot: ViewContainerRef;
  switches: StandaloneItem[] = [];
  constructor(
    windowFactory: WindowFactoryService,
    uiCreator: UICreatorService,
    store: DataStoreService,
    processService: CompiereProcessService,
    protected translateService: TranslateService,
    socketService: SocketService,
    connectorService: SecurityManagerService,
    progressService: ProcessInProgressService
  ) {
    super(
      windowFactory,
      uiCreator,
      store,
      processService,
      socketService,
      connectorService,
      progressService,
      translateService
    );
    this.isModal = false;
    this.customDesignArray.push(
      {
        vcr: 'vcrLeft',
        type: CustomDesignItemType.FIELD,
        columnName: 'C_AcctSchema_ID',
        cssClass: 'p-col-6',
      },
      {
        vcr: 'vcrLeft',
        type: CustomDesignItemType.FIELD,
        columnName: 'SelectDocument',
        cssClass: 'p-col-12',
      },
      {
        vcr: 'vcrLeft',
        type: CustomDesignItemType.FIELD,
        columnName: 'AD_Table_ID',
        cssClass: 'p-col-12',
      },
      {
        vcr: 'vcrLeft',
        type: CustomDesignItemType.FIELD,
        columnName: 'Record_ID',
        cssClass: 'p-col-12',
      },
      {
        vcr: 'vcrLeft',
        type: CustomDesignItemType.FIELD,
        columnName: 'PostingType',
        cssClass: 'p-col-6',
      },
      {
        vcr: 'vcrBot',
        type: CustomDesignItemType.GRID,
        tableName: 'AcctViewerWindowModalImpl Table',
        cssClass: 'p-col-12',
      },
      {
        vcr: 'vcrBot',
        type: CustomDesignItemType.FIELD,
        columnName: 'force',
        cssClass: 'p-col-12 p-md-3 p-lg-2',
      },
      {
        vcr: 'vcrLeft',
        type: CustomDesignItemType.FIELD,
        columnName: 'AD_Client_ID',
        cssClass: 'p-col-6',
      },
      {
        vcr: 'vcrButtons',
        type: CustomDesignItemType.FIELD,
        columnName: 'Reposting',
        cssClass: 'p-col-12 p-md-3 p-lg-2',
      }
    );
    // on veut le total de ces colonnes
    this.pinnedColKeys = ['AmtAcctDr', 'AmtAcctCr', 'AmtSourceCr', 'AmtSourceDr', 'Qty'];
  }
  ngOnInit() {
    super.ngOnInit();
    if (!this.dataStore) {
      this.dataStore = this.store.newSpecificWindowData(this.formId);
    }
    this.setExcludedFiltersToGrid('AcctViewerWindowModalImpl Table', ['force']);
    let recordId = null;
    let documentType = null;
    let adTableId = null;
    let adOrgId = null;
    let adTableValue = null;
    if (this.parentComponent instanceof EditViewUiComponent) {
      recordId = (<EditViewUiComponent>this.parentComponent).editTabs[0].dataStored.data['Data_UUID'].split(',')[1];
      adOrgId = (<EditViewUiComponent>this.parentComponent).editTabs[0].dataStored.data['AD_Org_ID'];
      adTableId = {
        id: (<EditViewUiComponent>this.parentComponent).editTabs[0].data.AD_Table_ID,
        displayValue: (<EditViewUiComponent>this.parentComponent).editTabs[0].data.label,
      };
      adTableValue = (<EditViewUiComponent>this.parentComponent).editTabs[0].data['label'];
      documentType = { displayValue: adTableValue, id: adTableId };

      this.dataStore.data['AD_Org_ID'] = adOrgId;
      this.dataStore.data['Record_ID'] = recordId;
      this.dataStore.data['DocumentType'] = documentType;
      this.dataStore.data['AD_Table_ID'] = adTableId;
    }
    this.dataStore.data['SelectDocument'] = 'Y';
    const item: DynamicComponent = {
      container: this,
      DOMParentComponent: this,
      component: 'SpecificWindowUiComponent',
      cssClass: 'p-col-12',
      isCssOnComponent: false,
      tabId: this.formId,
      gridPaginator: false,
    };
    this.windowFactory.newEventHandler({
      type: IupicsTypeEvent.showSpecificWindow,
      item: item,
    });
  }
  ngAfterViewInit() {
    super.ngAfterViewInit();
  }
  notifyFromDatacontainerInit(dataContainer: AbstractDataContainer) {
    if (dataContainer.data.columnName === 'Reposting') {
      const isErrorPosting =
        this.dataStore &&
        this.dataStore.data &&
        this.dataStore.data['Posted'] &&
        (this.dataStore.data['Posted'].id === 'E' || this.dataStore.data['Posted'].id === 'N');
      dataContainer.label = this.translateService.instant(
        isErrorPosting ? 'specificWindow.accountingViewer.OK' : 'specificWindow.accountingViewer.Posting'
      );
    }
  }
  onEndProcess(ping: ProcessPingInfo) {
    this.parentComponent.updateAll(false, null, () => {
      const parentStore = this.getParentStore();
      const posted = parentStore.data['Posted'];
      if (posted) {
        const isErrorPosting = posted && (posted.id === 'E' || posted.id === 'N');
        this.setFormStoreData('Posted', posted);
        if (isErrorPosting) {
          setTimeout(() => {
            this.closeModalEmitter.emit();
          }, 1000);
        } else {
          const dataContainer = this.dataContainers.find((dc) => dc.data.columnName === 'Reposting');
          if (dataContainer) {
            dataContainer.label = this.translateService.instant('specificWindow.accountingViewer.Posting');
          }
          super.onEndProcess(ping);
        }
      }
    });
  }
}
