import { Component, HostListener, Input, OnInit } from '@angular/core';
import { DataKanban } from '@iupics-components/models/kanban-interface';

@Component({
  selector: 'iu-kanban-card-ui',
  templateUrl: './kanban-card-ui.component.html',
  styleUrls: ['./kanban-card-ui.component.scss'],
})
export class KanbanCardUiComponent implements OnInit {
  @Input()
  datasKanban: DataKanban;
  @Input()
  tableName: string;
  isMobile = false;
  constructor() {}

  ngOnInit() {
    this.isMobile = document.documentElement.clientWidth <= 480;
  }
  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    event.stopPropagation();
    this.isMobile = document.documentElement.clientWidth <= 480;
  }
}
