import { Component, Input, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { AvatarInfos } from '@iupics-manager/models/user-account';

@Component({
  selector: 'iu-tag-content-ui',
  templateUrl: './tag-content-ui.component.html',
  styleUrls: ['./tag-content-ui.component.scss'],
})
export class TagContentUiComponent implements OnInit {
  @Input() userId: number;
  @Input() value: string;
  @Input() pictureUrl: string;
  @Input() prefix: string;
  @Input() tagExpansion: string = 'true';

  avatarInfos: AvatarInfos;

  @ViewChild('tagExpandVcr', { read: ViewContainerRef }) tagExpandVcr: ViewContainerRef;
  @ViewChild('tagExpandTemplate') tagExpandTemplate: TemplateRef<any>;
  mouseTimeout: NodeJS.Timeout;

  constructor() {}

  ngOnInit(): void {
    this.avatarInfos = {
      id: this.userId,
      fullname: this.value,
      pictureURL: this.pictureUrl,
    };
  }

  getTagExpansionAsBoolean(): boolean {
    return this.tagExpansion === 'true';
  }

  onMouseEnter(event: MouseEvent) {
    if (!this.getTagExpansionAsBoolean()) {
      return;
    }

    this.clearTimeout();
    this.mouseTimeout = setTimeout(() => {
      this.positionElement(event);
    }, 150);
  }

  onMouseLeave(event: MouseEvent) {
    if (!this.getTagExpansionAsBoolean()) {
      return;
    }

    this.clearTimeout();
    this.mouseTimeout = setTimeout(() => {
      this.tagExpandVcr.clear();
    }, 0);
  }

  clearTimeout() {
    if (this.mouseTimeout) {
      clearTimeout(this.mouseTimeout);
    }
  }

  positionElement(event: MouseEvent) {
    // Get the bounding rectangle of the hovered element
    const { left, right, top, height } = (<HTMLElement>event.target).getBoundingClientRect();

    const viewRef = this.tagExpandVcr.createEmbeddedView(this.tagExpandTemplate);
    const element = <HTMLElement>viewRef.rootNodes[0];
    if (!element) {
      return;
    }

    const elBCR = element.getBoundingClientRect();
    element.style.left = `${left + elBCR.width > window.innerWidth ? right - elBCR.width : left}px`;
    element.style.top = `${top + height * (top + elBCR.height > window.innerHeight ? -1 : 1)}px`;

    document.body.appendChild(element);
  }

  convertRemToPixels(rem: number) {
    return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
  }
}
