<div [ngClass]="[!isDisplayed ? 'isHidden' : '', 'title']" *ngIf="isSpecificGrid && isSpecificGridTitleDisplay">
  <label>
    <strong>{{ gridTitle }}</strong>
  </label>
</div>
<div
  #divContent
  (swipeleft)="startAnimation('slideOutLeft')"
  (swiperight)="startAnimation('slideOutRight')"
  [ngClass]="[!isDisplayed ? 'isHidden' : '', 'compContainer']"
  style.width="{{ cssWidth }}"
  [attr.data-cy-grid-tablename]="
    this.data ? (this.data['AD_FormDetail_ID'] ? this.data.columnName : this.data.tableName) : 'none'
  ">
  <div
    *ngIf="!noColumnToDisplay"
    [ngClass]="[isGridCollapsed ? 'gridCollapsed' : '', cssClass || '']"
    style.width="{{ cssWidth }}">
    <iu-menu-bar-ui
      *ngIf="isMenuDisplay"
      [isFromForm]="fromForm"
      (changeViewEvent)="switchViewType($event)"
      (newEmitter)="openNew()"
      (removeEmitter)="remove()"
      (refreshEmitter)="refreshGrid()"
      (openExportDataModalEmitter)="openExportDataModal($event)"
      [(visibleTreeButton)]="this.isTree"
      [visibleButton]="visibleButtons"
      (filterChange)="displayHideFilters($event)"
      [isTabTopLevel]="isTabTopLevel"
      [isSplitView]="isSplitView"
      [activeTabID]="container?.activeTab?.id"
      [isReadOnly]="isReadOnly"
      [isInsertRecord]="isInsertRecord"
      [isDeleteable]="this.isDeleteable"
      [tabId]="tabId"
      [hasUniversalFilter]="hasUniversalFilter"
      [gridView]="this"></iu-menu-bar-ui>
    <p-progressBar
      *ngIf="isLoading"
      [mode]="'indeterminate'"
      [value]="0"
      [showValue]="false"
      [styleClass]="'loading-progressbar'"></p-progressBar>
    <iu-tree-ui *ngIf="viewType === 'tree'" (selectItem)="forceOpenEditView($event)" [data]="this.data"></iu-tree-ui>
    <iu-grid-tab-infinity-scroll-ui
      [ngStyle]="{ display: viewType === 'grid' ? 'block' : 'none' }"
      [style.height.%]="100"
      *ngIf="gridPaginator"
      [tabId]="tabId"
      [isTabTopLevel]="false"
      [isZoomTargetGrid]="isZoomTargetGrid"
      [data]="data"
      [gridTabFilter]="gridTabFilter"
      [gridTabValidator]="gridTabValidator"
      [container]="container"
      [query]="data ? data.query : undefined"
      [rowSelection]="rowSelection"
      [DOMParentComponent]="this"
      (multiSelectEmitter)="displayHideMultiButton($event)"
      [windowType]="windowType"
      (setFilterEmitter)="setFilterFromView($event)"
      [initFromSwitch]="initFromSwitch"
      [initRequest]="initRequest"
      [isChangeLog]="isChangelog"
      (gridViewCellClicked)="gridCellClicked($event)"
      (gridRefreshed)="gridRefreshed.emit($event)"
      (gridCellEditingStopped)="gridCellEditingStopped.emit($event)"
      [suppressRowClickSelection]="suppressRowClickSelection"
      (notifyGridTabAfterViewInitEmitter)="notifyGridTabAfterViewInitEmitter($event)"></iu-grid-tab-infinity-scroll-ui>
    <iu-grid-tab-infinity-scroll-ui
      [ngStyle]="{ display: viewType === 'grid' ? 'block' : 'none' }"
      *ngIf="!gridPaginator"
      [isTabTopLevel]="true"
      [isZoomTargetGrid]="isZoomTargetGrid"
      [tabId]="tabId"
      [data]="data"
      [gridTabFilter]="gridTabFilter"
      [gridTabValidator]="gridTabValidator"
      [container]="container"
      [query]="data ? data.query : undefined"
      [DOMParentComponent]="this"
      [rowSelection]="rowSelection"
      (multiSelectEmitter)="displayHideMultiButton($event)"
      [windowType]="windowType"
      (setFilterEmitter)="setFilterFromView($event)"
      [initFromSwitch]="initFromSwitch"
      [initRequest]="initRequest"
      [isChangeLog]="isChangelog"
      (gridViewCellClicked)="gridCellClicked($event)"
      (gridRefreshed)="gridRefreshed.emit($event)"
      (gridCellEditingStopped)="gridCellEditingStopped.emit($event)"
      [suppressRowClickSelection]="suppressRowClickSelection"
      (notifyGridTabAfterViewInitEmitter)="notifyGridTabAfterViewInitEmitter($event)"></iu-grid-tab-infinity-scroll-ui>
    <iu-kanban-ui
      *ngIf="viewType === 'kanban'"
      [gridTabFilter]="gridTabFilter"
      [gridTabValidator]="gridTabValidator"
      [DOMParentComponent]="this"
      [tabId]="tabId"
      [tableName]="this.data.tableName"
      [container]="container"
      [columnsDisplayAD]="this.data.columnsDisplayAD"
      [kanbanImageColumn]="this.data.kanbanImageColumn"
      [filter]="filter"
      (clickEmitter)="forceOpenEditView($event)"
      class="kanban-board"
      [initRequest]="initRequest"
      (setFilterEmitter)="setFilterFromView($event)"></iu-kanban-ui>
    <iu-calendar-view-ui
      *ngIf="viewType === 'calendar'"
      [gridTabFilter]="gridTabFilter"
      [gridTabValidator]="gridTabValidator"
      [DOMParentComponent]="this"
      [filter]="filter"
      [data]="data"
      [tabId]="tabId"
      [container]="container"
      (setFilterEmitter)="setFilterFromView($event)"
      [initRequest]="initRequest"
      (clickEmitter)="forceOpenEditView($event)"></iu-calendar-view-ui>
    <iu-chart-ui
      *ngIf="viewType === 'chart'"
      [gridTabFilter]="gridTabFilter"
      [gridTabValidator]="gridTabValidator"
      [DOMParentComponent]="this"
      [filter]="filter"
      [tabId]="tabId"
      [container]="container"
      [initRequest]="initRequest"
      (setFilterEmitter)="setFilterFromView($event)"></iu-chart-ui>
    <iu-cards-ui
      *ngIf="viewType === 'cards'"
      [gridTabFilter]="gridTabFilter"
      [gridTabValidator]="gridTabValidator"
      [DOMParentComponent]="this"
      [type]="data.tabType"
      (clickEmitter)="forceOpenEditView($event)"></iu-cards-ui>
  </div>
  <div *ngIf="displayExportDataModal">
    <iu-modal-ui
      #exportDataModal
      [title]="'exportData.title'"
      [hasCloseBtn]="false"
      (closeModalEmitter)="displayExportDataModal = false"
      [angularStyle]="{ 'iu-modal-body': { overflow: 'unset' } }">
      <iu-export-data-ui
        [datagridRequest]="filter"
        [ids]="exportIds"
        [tableId]="this.data.AD_Table_ID"
        [tabId]="this.tabId"
        [whereClause]="whereClause"
        [sourceModal]="exportDataModal"
        (cancelExportDataEmitter)="displayExportDataModal = false"></iu-export-data-ui>
    </iu-modal-ui>
  </div>

  <div *ngIf="displayProcessUI">
    <iu-modal-ui
      (closeModalEmitter)="updateModalDisplay({ key: 'displayProcessUI', value: false })"
      [hasCloseBtn]="true"
      [angularStyle]="{ 'iu-modal-body': { padding: 0 } }">
      <iu-process-ui
        (closeModalEmitter)="updateModalDisplay({ key: 'displayProcessUI', value: false })"
        [windowId]="processId"
        [parentComponent]="this"
        [isModal]="true"></iu-process-ui>
    </iu-modal-ui>
  </div>
  <div [ngClass]="[!displayFormUI ? 'hidden' : '', 'specificModal']">
    <iu-modal-ui
      [title]="specificWindowTitle"
      [isModalDisplay]="displayFormUI"
      (closeModalEmitter)="updateModalDisplay({ key: 'displayFormUI', value: false })"
      [modalClass]="modalClass">
      <ng-template #vcrSpecific></ng-template>
    </iu-modal-ui>
  </div>
</div>

<ng-container *ngIf="displaySearch">
  <iu-modal-ui
    data-cy="search-panel"
    [attr.data-cy-columnName]="this.searchLinkedComponent.autoComplete.data.columnName"
    [hidden]="!displaySearch"
    [title]="searchLinkedComponent.autoComplete.label"
    [hasCloseBtn]="true"
    (closeModalEmitter)="this.displaySearch = false">
    <div class="autocomplete-universal-filter">
      <iu-universal-filter-ui
        [tabId]="-1"
        [columns]="searchLinkedComponent.autoComplete.data.searchColumns"
        [gridTab]="gridTab"></iu-universal-filter-ui>
    </div>
    <iu-grid-tab-infinity-scroll-ui
      #gridTab
      (searchEmitter)="
        searchLinkedComponent.setSearchSelectItem($event); displaySearch = false; searchPanelValidation = undefined
      "
      [data]="searchLinkedComponent.autoComplete.data"
      [container]="this"
      [isSearch]="true"
      [dataStored]="searchLinkedComponent.autoComplete.dataStored"
      [windowType]="windowType"
      rowSelection="single"
      [validation]="searchPanelValidation"
      [hasCheckBox]="
        searchLinkedComponent.autoComplete.data.columnName === 'M_Product_ID'
      "></iu-grid-tab-infinity-scroll-ui>
  </iu-modal-ui>
</ng-container>
