<div
  #specificContainer
  class="iu-specific-window iu-specific-window-container operation-management-window no-overflow-x"
  [attr.data-cy-specific-window-name]="name">
  <div style="height: 20px">
    <a class="settings" (click)="isSidebarOpen = !isSidebarOpen">
      <i class="fas fa-cog" aria-hidden="true"></i>
    </a>
  </div>
  <!-- ganttHeight - 20 : le 20 est le padding/margin du container à retirer -->
  <iu-gantt
    (filterChange)="onFilterChange($event)"
    (pageChange)="onPageChange($event)"
    [actualPage]="actualPage"
    [buildCalendar]="buildCalendar"
    [columns]="columns"
    [dateFormat]="ganttDateFormat"
    [dragConfig]="dragConfiguration"
    [ganttData$]="ganttData$"
    [ganttHeight]="specificContainer.clientHeight - 40"
    [gridRowClass]="gridRowClass"
    [gridWidth]="gridWidth"
    [hasUniversalFilter]="true"
    [levelZoom]="'day'"
    [maximumPage]="!lastRow ? -1 : maximumPage"
    [onAfterTaskDrag]="onAfterTaskDrag"
    [onBeforeTaskDrag]="onBeforeTaskDrag"
    [onTaskOpened]="onOFOpened"
    [paginationEnable]="paginationEnable"
    [setFilterEmitter]="setFilterEmitter"
    [showCollapseButton]="false"
    [showExpandButton]="false"
    [showRedoButton]="false"
    [showUndoButton]="false"
    [taskClass]="taskClass"
    [taskRowClass]="gridRowClass"
    [taskText]="taskText"
    [uf_columns]="uf_columns"
    [uf_formId]="fields[0].formDetailId"
    [uf_isStandalone]="true"></iu-gantt>

  <p-sidebar
    [(visible)]="isSidebarOpen"
    [baseZIndex]="6000"
    position="right"
    styleClass="p-sidebar-md optionalFilterPanel">
    <h1 class="iu-notification-center-title">
      {{ 'specificWindow.optionalFilters' | translate }}
    </h1>
    <div class="optionalFields">
      <ng-template #optional></ng-template>
      <ng-container *ngFor="let column of _columns | objectArrayFilter : ['!_empty_', 'name']">
        <div>
          <iu-input-switch-ui
            [isStandalone]="true"
            [fieldValue]="column.show ? 'Y' : 'N'"
            (fieldValueModified)="updateDisplayColumns(column.name, $event)"
            [label]="column.label"></iu-input-switch-ui>
        </div>
      </ng-container>
      <div>
        <p>{{ 'planning-window.Legend' | translate }}</p>
        <div class="color-legend gantt-task-OF">{{ 'planning-window.legend.OF' | translate }}</div>
        <div class="color-legend gantt-task-OP">{{ 'planning-window.legend.OP' | translate }}</div>
        <div class="color-legend gantt-task-subcontract">{{ 'planning-window.legend.subcontract' | translate }}</div>
      </div>
    </div>
  </p-sidebar>
</div>
