import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'iu-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
})
export class BannerComponent implements OnInit {
  @HostBinding('style.display') display = 'block';

  @Input()
  type = 'error';

  @Input()
  icon: string;

  @Input()
  position: 'top' | 'bottom' | 'none' = 'none';

  @Input()
  closeable = false;

  @Input()
  delay: number;
  closeDelay: NodeJS.Timer;

  _hiddenMessage: string;

  @Input()
  set hiddenMessage(value: string) {
    if (value && typeof value === 'string') {
      this._hiddenMessage = value
        .split('\n')
        .map((line) => {
          const regexMatch = line.match(/\(.*:\d*:\d*\)/);
          if (regexMatch) {
            return (
              line.substring(0, regexMatch.index) +
              '<strong>' +
              line.substring(regexMatch.index, regexMatch.index + regexMatch[0].length) +
              '</strong>'
            );
          } else {
            return line;
          }
        })
        .join('\n');
    }
  }

  get hiddenMessage() {
    return this._hiddenMessage;
  }
  hiddenMessageDisplay = false;

  @Output()
  closeEmitter = new EventEmitter<any>();

  constructor() {}

  ngOnInit() {
    if (this.delay && this.delay > 0) {
      this.closeDelay = setTimeout(() => {
        this.closeBanner(document.createEvent('Event'));
      }, this.delay);
    }
  }

  /**
   * Ferme la banniere
   * @param {Event}event
   */
  closeBanner(event: Event) {
    event.stopPropagation();
    this.display = 'none';
    this.closeEmitter.emit();
  }

  /**
   * Annule le delai pour la disparition de la bannière
   * @param {Event}event
   */
  cancelCloseDelay(event: Event) {
    event.stopPropagation();
    if (this.delay && this.delay > 0 && this.closeDelay) {
      clearTimeout(this.closeDelay);
    }
  }

  /**
   * Affiche le message caché de la bannière
   * @param {MouseEvent}event
   */
  showHiddenMessage(event: MouseEvent) {
    event.stopPropagation();
    this.cancelCloseDelay(event);
    if (event.altKey) {
      this.hiddenMessageDisplay = !this.hiddenMessageDisplay;
    }
  }
}
