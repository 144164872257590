export class MongoSearchQuery {
  queryParts: MongoSearchQueryPart[] = [];
  combination?: MongoSearchQueryCombination;
  ctx: Map<String, any>;
  orgAccess: number[];
  hasOrgSecurity: boolean = true;
  constructor(
    ctx: Map<String, any>,
    orgAccess: number[],
    combination?: MongoSearchQueryCombination,
    queryParts: MongoSearchQueryPart[] = []
  ) {
    this.ctx = ctx;
    this.orgAccess = orgAccess;
    this.combination = combination;
    this.queryParts = queryParts;
  }
  addQueryPart(queryPart: MongoSearchQueryPart) {
    this.queryParts.push(queryPart);
  }
}
export class MongoSearchQueryPart {
  expressions: MongoSearchQueryPartExp[] = [];
  combinations: MongoSearchQueryCombination[] = [];

  constructor(expressions: MongoSearchQueryPartExp[] = [], combinations: MongoSearchQueryCombination[] = []) {
    this.expressions = expressions;
    this.combinations = combinations;
  }

  addExpression(expression: MongoSearchQueryPartExp) {
    this.expressions.push(expression);
  }
  addCombination(combination: MongoSearchQueryCombination) {
    this.combinations.push(combination);
  }
}
export class MongoSearchQueryPartExp {
  fieldName: string;
  fieldValue: any;
  operator: MongoSearchQueryOperator;
  constructor(fieldName: string, fieldValue: any, operator: MongoSearchQueryOperator) {
    this.fieldName = fieldName;
    this.fieldValue = fieldValue;
    this.operator = operator;
  }
}
export interface MongoSearchQueryOptions {
  attachmentInteraction: boolean;
  limit: number;
  start: number;
}
export enum MongoSearchQueryOperator {
  EQUALS = 'eq',
  NOT_EQUALS = 'ne',
  GREATER_THAN = 'gt',
  LESS_THAN = 'lt',
  GREATER_THAN_EQUALS = 'gte',
  LESS_THAN_EQUALS = 'lte',
  IN = 'in',
  NIN = 'nin',
  REGEX = 'regex',
  EXIST = 'exists',
}
export enum MongoSearchQueryCombination {
  AND = 'and',
  OR = 'or',
}
