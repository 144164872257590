<span
  [style.display]="displayCss"
  [style.margin-top.px]="data && data.marginTop > 0 ? data.marginTop : null"
  (contextmenu)="onContextMenu($event)"
  (press)="onContextMenu($any($event))"
  [life]="3000"
  [hideDelay]="250"
  pTooltip="{{ tooltip }}"
  [escape]="false"
  tooltipPosition="top"
  [showDelay]="500"
  tooltipZIndex="2100"
  [style.backgroundColor]="backgroundColorCss">
  <label
    *ngIf="this.isLabelDisplay && !(displayImage && fieldValue !== undefined && fieldValue !== null)"
    [ngClass]="{ 'iu-field-only-label': data?.isFieldOnly }"
    >{{ label | textLimit : this.elementRef.nativeElement.firstElementChild.firstElementChild.clientWidth / 7 }}</label
  >
  <input
    *ngIf="!(displayImage && fieldValue !== undefined && fieldValue !== null)"
    #input
    [(ngModel)]="fieldValue"
    [readonly]="isReadOnly ? 'disabled' : null"
    [tabindex]="isReadOnly ? '-1' : null"
    [ngClass]="[mandatoryCss || '', 'ui-corner-all']"
    value=""
    type="text"
    (change)="dataChange(input.value)"
    pInputText
    autocomplete="no"
    data-cy="input-text"
    [attr.data-cy-columnName]="data ? data.columnName : columnName" />
  <i
    *ngIf="!(displayImage && fieldValue !== undefined && fieldValue !== null && fieldValue !== '')"
    class="picto icon-img"
    aria-hidden="true"
    #ddBtn
    type="button"
    (click)="switchField()">
  </i>

  <img
    *ngIf="displayImage && fieldValue !== undefined && fieldValue !== null && fieldValue !== ''"
    [ngClass]="[!isReadOnly ? 'iu-image-hover' : '', 'iu-image']"
    [attr.width]="width"
    [attr.height]="height"
    [src]="fieldValue | docServerUrl | async"
    (click)="switchField()"
    data-cy="input-image"
    [attr.data-cy-columnName]="data ? data.columnName : columnName"
    alt="image" />
  <iu-prime-overlay #opValuePref *ngIf="showValuePrefPanel" [dismissable]="false" appendTo="body">
    <iu-value-preference-panel
      [overlayPanel]="opValuePref"
      [fieldValue]="fieldValue"
      [dataStored]="this.dataStored"
      [sourceComponent]="this"
      [data]="this.data"
      (closeEmitter)="toggleValuePreferencePanel($event)"></iu-value-preference-panel>
  </iu-prime-overlay>
</span>
