import { Injectable } from '@angular/core';
import { KeyCode } from '@iupics-config/keycode.enum';
import { UICreatorService } from '@iupics-manager/managers/ui-creator/ui-creator.service';
import { Utils } from '@iupics-util/tools/util';
import { MenuCategoryUI, MenuItemUI } from '@web-desktop/models/menu-item-ui';
import Fuse from 'fuse.js';
import { cloneDeep } from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class MenuUiManagerService {
  static keyCodeToAvoid = [
    KeyCode.ENTER,
    KeyCode.TAB,
    KeyCode.CTRL,
    KeyCode.LEFT_META,
    KeyCode.RIGHT_META,
    KeyCode.ALT,
    KeyCode.CAPS_LOCK,
    KeyCode.DOWN_ARROW,
    KeyCode.LEFT_ARROW,
    KeyCode.RIGHT_ARROW,
    KeyCode.UP_ARROW,
    KeyCode.PAGE_DOWN,
    KeyCode.PAGE_UP,
    KeyCode.SHIFT,
    KeyCode.NUM_LOCK,
    KeyCode.F1,
    KeyCode.F2,
    KeyCode.F3,
    KeyCode.F4,
    KeyCode.F5,
    KeyCode.F6,
    KeyCode.F7,
    KeyCode.F8,
    KeyCode.F9,
    KeyCode.F10,
    KeyCode.F11,
    KeyCode.F12,
    KeyCode.HOME,
    KeyCode.INSERT,
    KeyCode.PAUSE,
    KeyCode.SCROLL_LOCK,
    KeyCode.SELECT,
  ];

  private fuseOptions = {
    caseSensitive: false,
    shouldSort: true,
    tokenize: true,
    keys: ['name', 'tags'],
  };

  constructor(private uiCreator: UICreatorService) {}

  /**
   * Permet de charger les menus et les catégories de manière asynchrone
   * @return {Observable<MenuItemUI[]>} Un observable contenant un objet avec les categories de menus et les menus associés
   */
  public loadMenusList(): Observable<MenuItemUI[]> {
    return this.uiCreator.getIupicsMenus().pipe(
      map((m) => {
        const categories = this.uiCreator.getIupicsMenusCategories();
        const menus: MenuItemUI[] = [];
        m.forEach((menu) => menus.push(menu));
        menus
          .filter((menu) => menu.menuCategory === undefined)
          .forEach((menu) => (menu.menuCategory = categories.find((cat) => cat.id === Number.POSITIVE_INFINITY)));
        return menus;
      })
    );
  }

  /**
   * Permet de changer de catégories
   * @param {MenuCategoryUI}selectedCategory la catégorie à sélectionner
   * @returns {MenuCategoryUI} la nouvelle catégorie sélectionnée
   */
  public changeCategory(selectedCategory: MenuCategoryUI): MenuCategoryUI {
    const previousSelectedCategory = this.uiCreator.getIupicsMenusCategories().find((cat) => cat.isSelected === true);
    if (previousSelectedCategory) {
      previousSelectedCategory.isSelected = false;
    }
    selectedCategory.isSelected = true;
    return selectedCategory;
  }

  /**
   * Construit l'objet utilisé par le menu-ui.component
   * @param {MenuItemUI[]}menus les menus à grouper par catégories
   * @param {MenuCategoryUI[]}menuCategories les catégories pour faire les groupes
   * @returns {{ category: MenuCategoryUI; items: MenuItemUI[] }[]} l'objet groupé
   */
  public groupByCategory(
    menus: MenuItemUI[],
    menuCategories: MenuCategoryUI[]
  ): { category: MenuCategoryUI; items: MenuItemUI[] }[] {
    if (menus !== undefined && Array.isArray(menus) && menus.length > 0) {
      const groupedMenus = [];
      for (let i = 0; i < menuCategories.length; i++) {
        const category = menuCategories[i];
        const group = menus.filter((menu) => menu.menuCategory?.id === category.id);
        groupedMenus.push({
          category: category,
          items: group,
        });
      }

      return groupedMenus;
    } else {
      return [];
    }
  }

  /**
   * Lance la recherche selon l'input et retourne un observable pour être affiché de manière asynchrone
   * @param {string}value la valeur de recherche
   * @returns {Observable<MenuItemUI[]>} Un observable contenant un objet avec les categories de menus et les menus associés
   */
  public search(value: string): Observable<MenuItemUI[]> {
    return this.uiCreator.getIupicsMenus().pipe(
      map((menus) => {
        if (value === undefined || value === null || value === '') {
          return menus;
        }
        const filteredMenus = this.searchOnvalue(value, menus);
        filteredMenus.push(...this.searchMostAccurate(value, menus));
        return filteredMenus;
      })
    );
  }

  private searchMostAccurate(value: string, menus: MenuItemUI[]): MenuItemUI[] {
    const fuse = new Fuse(menus, this.fuseOptions);
    const category = this.uiCreator.getIupicsMenusCategories().find((c) => c.id === 0);

    return cloneDeep(fuse.search(value).splice(0, 10)).map<MenuItemUI>((result) => {
      result.item.menuCategory = category;
      return result.item;
    });
  }

  /**
   * Effectue la recherche dans les menus reçus par le web service et le cache
   * @param {string}value la valeur de recherche
   * @param {MenuCategoryUI}selectedCategory la catégorie sélectionnée
   * @param {MenuItemUI[]}menus l'ensemble des menus disponibles
   * @returns {MenuItemUI[]} l'objet groupé
   */
  private searchOnvalue(value: string, menus: MenuItemUI[]): MenuItemUI[] {
    /*
     * On construit une expression régulière qui permettra de faire un filtre plus intelligent
     */
    const regex = '.*'.concat(Utils.cleanUpSpecialChars(value, true).split(' ').join('.*'), '.*');
    return menus.filter(
      (menu) =>
        Utils.cleanUpSpecialChars(menu.name).match(regex) ||
        menu.tags.findIndex((tag) => Utils.cleanUpSpecialChars(tag).match(regex) !== null) >= 0
    );
  }

  /**
   * Permet de récupérer le premier élément disponible
   * @param {{ category: MenuCategoryUI; items: MenuItemUI[] }[]}groupedMenu
   * @returns {MenuItemUI}
   */
  public getFirstMenu(
    groupedMenu: { category: MenuCategoryUI; items: MenuItemUI[] }[],
    selectedCategory: MenuCategoryUI
  ): MenuItemUI {
    if (selectedCategory === undefined || selectedCategory.id === -1) {
      const possibilities = [].concat.apply(
        [],
        groupedMenu.filter((group) => group.items.length > 0).map((group) => group.items)
      );
      return possibilities !== undefined && possibilities.length > 0 ? possibilities[0] : undefined;
    } else {
      const possibilities = groupedMenu.find(
        (group) => group.items.length > 0 && group.category.name === selectedCategory.name
      ).items;
      return possibilities !== undefined && possibilities.length > 0 ? possibilities[0] : undefined;
    }
  }

  /**
   * Permet de récupérer l'élément suivant selon l'offset obtenu avec la touche entrée
   * @param {{ category: MenuCategoryUI; items: MenuItemUI[] }[]}groupedMenu
   * @param {MenuItemUI}selectedMenu
   * @param {number}offset
   * @returns {MenuItemUI}
   */
  public getNextMenu(
    groupedMenu: { category: MenuCategoryUI; items: MenuItemUI[] }[],
    selectedMenu: MenuItemUI,
    offset: number,
    selectedCategory: MenuCategoryUI
  ): MenuItemUI {
    const groupedMenuNotEmpty = groupedMenu.filter((group) => group.items.length > 0);
    if (selectedCategory === undefined || selectedCategory.id === -1) {
      // * si aucune catégorie n'est sélectionnée
      const currentCategoryIndex = groupedMenuNotEmpty.findIndex(
        (group) => group.category.name === selectedMenu.menuCategory.name
      );
      const indexInCategory = groupedMenuNotEmpty[currentCategoryIndex].items.findIndex(
        (menu) => menu.menuId === selectedMenu.menuId
      );

      if (indexInCategory + offset < 0) {
        // * si on tente de passer à la catégorie précédente
        /*
         * si la categorie précédente n'existe pas, on retourne le premier élément de la catégorie courante
         * sinon si un élément sur la dernière ligne de la catégorie précédente se trouve à la même position que l'élément courant sur sa propre ligne, alors on le retourne
         * sinon on retourne le dernier élément de la catégorie précédente
         */
        if (groupedMenuNotEmpty[currentCategoryIndex - 1] === undefined) {
          return groupedMenuNotEmpty[currentCategoryIndex].items[0];
        } else {
          const moduloRes =
            groupedMenuNotEmpty[currentCategoryIndex - 1].items.length % Math.abs(offset) === 0
              ? Math.abs(offset)
              : groupedMenuNotEmpty[currentCategoryIndex - 1].items.length % Math.abs(offset);

          return groupedMenuNotEmpty[currentCategoryIndex - 1].items[
            groupedMenuNotEmpty[currentCategoryIndex - 1].items.length - moduloRes + indexInCategory
          ] !== undefined
            ? groupedMenuNotEmpty[currentCategoryIndex - 1].items[
                groupedMenuNotEmpty[currentCategoryIndex - 1].items.length - moduloRes + indexInCategory
              ]
            : groupedMenuNotEmpty[currentCategoryIndex - 1].items[
                groupedMenuNotEmpty[currentCategoryIndex - 1].items.length - 1
              ];
        }
      } else if (indexInCategory + offset >= groupedMenuNotEmpty[currentCategoryIndex].items.length) {
        // * si on tente de passer à la catégorie suivante
        /*
         * si la catégorie suivante n'existe pas, on retourne le dernier élément de la catégorie courante
         * sinon si un élément sur la première ligne de la catégorie suivante se trouve à la même position que l'élément courant sur sa propre ligne, alors on le retourne
         * sinon on retourne le dernier élément de la catégorie suivante
         */
        return groupedMenuNotEmpty[currentCategoryIndex + 1] === undefined
          ? groupedMenuNotEmpty[currentCategoryIndex].items[groupedMenuNotEmpty[currentCategoryIndex].items.length - 1]
          : groupedMenuNotEmpty[currentCategoryIndex + 1].items[indexInCategory % Math.abs(offset)] !== undefined
          ? groupedMenuNotEmpty[currentCategoryIndex + 1].items[indexInCategory % Math.abs(offset)]
          : groupedMenuNotEmpty[currentCategoryIndex + 1].items[
              groupedMenuNotEmpty[currentCategoryIndex + 1].items.length - 1
            ];
      } else {
        const possibilities = [].concat.apply(
          [],
          groupedMenuNotEmpty.map((group) => group.items)
        );
        const index = possibilities.findIndex(
          (menu: MenuItemUI) =>
            menu.menuId === selectedMenu.menuId && menu.menuCategory.name === selectedMenu.menuCategory.name
        );
        return possibilities[index + offset];
      }
    } else {
      // * si une catégorie est sélectionnée
      const currentCategoryIndex = groupedMenuNotEmpty.findIndex(
        (group) => group.category.name === selectedCategory.name
      );
      const indexInCategory = groupedMenuNotEmpty[currentCategoryIndex].items.findIndex(
        (menu) => menu.menuId === selectedMenu.menuId
      );
      if (indexInCategory + offset < 0) {
        // * si on tente d'aller avant le premier élément de la catégorie sélectionnée
        return groupedMenuNotEmpty[currentCategoryIndex].items[0];
      } else if (indexInCategory + offset >= groupedMenuNotEmpty[currentCategoryIndex].items.length) {
        // * si on tente d'aller plus loin que le dernier élément de la catégorie sélectionnée
        return groupedMenuNotEmpty[currentCategoryIndex].items[
          groupedMenuNotEmpty[currentCategoryIndex].items.length - 1
        ];
      } else {
        // * si tout va bien
        return groupedMenuNotEmpty[currentCategoryIndex].items[indexInCategory + offset];
      }
    }
  }

  /**
   * Scroll sur l'élément spécifié dans le container spécifié
   * @param {string}selectedItemDomID
   * @param {string}containerDomID
   */
  public scrollToItem(selectedItemDomID: string, containerDomID: string) {
    const selectedItem_DOM: HTMLElement = document.getElementById(selectedItemDomID);
    const selectedItem_DOMRect: DOMRect = selectedItem_DOM.getBoundingClientRect();
    const container_DOM: HTMLElement = document.getElementById(containerDomID);
    const container_DOMRect: DOMRect = container_DOM.getBoundingClientRect();

    const selectedItem_topPosition: number = selectedItem_DOMRect.top;
    const container_topPosition: number = container_DOMRect.top;
    const selectedItem_bottomPosition: number = selectedItem_DOMRect.bottom;
    const container_bottomPosition: number = container_DOMRect.height;

    // * si le dessus du menu sélectionné se trouve au dessus du container
    if (selectedItem_topPosition < container_topPosition) {
      selectedItem_DOM.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'center',
      });
    }

    // * si le bas du menu sélectionné se trouve en dessous du container
    if (selectedItem_bottomPosition > container_bottomPosition) {
      container_DOM.scrollTo({
        top: selectedItem_bottomPosition - container_DOMRect.height + container_DOM.scrollTop,
        behavior: 'smooth',
      });
    }
  }

  /**
   * Retourne la prochaine catégorie disponible
   * @param {{ category: MenuCategoryUI; items: MenuItemUI[] }[]}groupedMenu
   * @param {MenuCategoryUI}selectedCategory
   * @param {number}offset
   * @returns {MenuCategoryUI}
   */
  public getNextCategory(
    groupedMenu: { category: MenuCategoryUI; items: MenuItemUI[] }[],
    selectedCategory: MenuCategoryUI,
    offset: number
  ): MenuCategoryUI {
    const groupedMenuNotEmpty = groupedMenu.filter((group) => group.items.length > 0 || group.category.id === -1);
    const currentCategoryIndex = groupedMenuNotEmpty.findIndex(
      (group) => group.category.name === selectedCategory.name
    );
    return this.getNextItem(groupedMenuNotEmpty, offset, currentCategoryIndex).category;
  }

  private getNextItem(possibilities: any[], offset: number, index: number): any {
    return offset + index <= 0
      ? possibilities[0]
      : offset + index >= possibilities.length
      ? possibilities[possibilities.length - 1]
      : possibilities[index + offset];
  }
}
