import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[iuAddActive]',
})
export class AddActiveOnClickDirective {
  @Input('iuAddActive-idToCheck') idToCheck: string;
  @Input('iuAddActive-otherToClose') otherToClose: string[] = [];

  private isActive = false;
  private docClickListener: Function;

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  /*
   // @HostListener('document:click', ['$event'])
   ! Il faut éviter les HostListener sur le document sauf si on est certain que
   ! l'évenement ne sera trigger qu'une seule fois et que son évenement est nécessaire
   ! sinon il faut gérer l'évenement manuellement
   ! afin qu'il écoute le document uniquement quand il en a besoin
  */
  clickout(event: MouseEvent) {
    if (this.isActive) {
      this.isActive = false;
      this.renderer.removeClass(this.el.nativeElement, 'active');
      if (this.docClickListener !== undefined) {
        this.docClickListener();
        this.docClickListener = undefined;
      }
    }
    event.stopPropagation();
  }

  @HostListener('click', ['$event'])
  onClick(e: MouseEvent) {
    const path = e.composedPath();
    if (path.findIndex((target: any) => target.id === this.idToCheck) >= 0) {
      this.isActive = !this.el.nativeElement.classList.contains('active'); // check if active class is still there
      this.isActive
        ? this.renderer.addClass(this.el.nativeElement, 'active')
        : this.renderer.removeClass(this.el.nativeElement, 'active');
      this.otherToClose.forEach((id) => this.renderer.removeClass(document.getElementById(id), 'active'));
      if (this.isActive) {
        this.docClickListener =
          this.docClickListener === undefined
            ? this.renderer.listen(document, 'click', (event) => {
                this.clickout(event);
              })
            : this.docClickListener;
      } else {
        if (this.docClickListener !== undefined) {
          this.docClickListener();
          this.docClickListener = undefined;
        }
      }
    }
    e.stopPropagation();
  }
}
