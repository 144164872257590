import { Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { IStatusPanelAngularComp } from 'ag-grid-angular';
import { IStatusPanelParams } from 'ag-grid-enterprise';

@Component({
  selector: 'iu-status-bar-count',
  templateUrl: './status-bar-count.component.html',
  styleUrls: ['./status-bar-count.component.scss'],
})
export class StatusBarCountComponent implements IStatusPanelAngularComp, OnDestroy {
  @ViewChild('statusBarCount') statusBarCountEl: ElementRef<HTMLDivElement>;
  public columns: any[];
  public isPanelDisplayed = false;
  public selectedLines: any[];
  public params: ApizStatusPanelParams;

  private globalClickListener: () => void = () => {};

  public agInit(params: ApizStatusPanelParams): void {
    this.params = params;
  }

  public openSelectedLinesPanel(event: Event): void {
    event?.stopPropagation();
    this.globalClickListener();
    this.globalClickListener = this.globalListen();
    this.columns = this.params.columnApi.getColumns();
    this.selectedLines = this.params.api.getSelectedRows();
    this.isPanelDisplayed = true;
  }

  public removeLine(event: Event, line: any): void {
    event?.stopPropagation();
    const node = this.params.api.getSelectedNodes().find((n) => n.data === line);
    if (node) {
      node.setSelected(false);
    }
    this.selectedLines = this.selectedLines.filter((l) => l !== line);
    if (this.selectedLines.length === 0) {
      this.closeSelectedLinesPanel(event);
    }
  }

  public closeSelectedLinesPanel(event: Event): void {
    event?.stopPropagation();
    this.globalClickListener();
    this.isPanelDisplayed = false;
  }

  private globalListen(): () => void {
    const listener = (event: MouseEvent) => {
      if (!(event.composedPath() as HTMLElement[]).find((e) => e === this.statusBarCountEl.nativeElement)) {
        this.closeSelectedLinesPanel(event);
      }
    };
    document.documentElement.addEventListener('click', listener);
    return () => {
      document.documentElement.removeEventListener('click', listener);
    };
  }

  public ngOnDestroy(): void {
    this.globalClickListener();
  }
}

interface ApizStatusPanelParams extends IStatusPanelParams {
  gridTab: any;
}
