<div style="height: 2.5em">
  <p-autoComplete
    placeholder="{{ 'usearch.welcome' | translate }}"
    styleClass="wd-menu-top-u-search"
    inputStyleClass="wd-menu-top-u-search"
    [(ngModel)]="inputValue"
    [suggestions]="results"
    (completeMethod)="search($event)"
    (onKeyUp)="$event.keyCode === 13 ? onkeyup($event) : ''"></p-autoComplete>
</div>
