<span
  class="iu-checkbox"
  [ngClass]="{
    'iu-checkbox-disabled': disabled,
    'iu-checkbox-tri': tristate
  }"
  (click)="onClick($event)">
  <ng-container *ngIf="tristate; else bistateTpl">
    <ng-container *ngTemplateOutlet="tristateTpl"></ng-container>
  </ng-container>
</span>

<ng-template #tristateTpl>
  <i
    tabindex="0"
    [ngClass]="{
      'icon-checkbox-checked': checked === true,
      'icon-checkbox-tri-false': checked === false,
      'icon-checkbox-unchecked': checked === null,
    }"></i>
</ng-template>
<ng-template #bistateTpl>
  <i
    tabindex="0"
    [ngClass]="{
      'icon-checkbox-checked': checked,
      'icon-checkbox-unchecked': !checked,
    }"></i>
</ng-template>
