import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, ElementRef, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { OverridedCSS } from '@iupics-manager/models/overrided-css';
import { Fieldset } from 'primeng/fieldset';

@Component({
  selector: 'iu-prime-fieldset',
  templateUrl: './prime-fieldset.component.html',
  styleUrls: ['./prime-fieldset.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('fieldsetContent', [
      state(
        'hidden',
        style({
          height: '0px',
          opacity: 0,
          overflow: 'hidden',
        })
      ),
      state(
        'visible',
        style({
          height: '*',
          opacity: 1,
        })
      ),
      transition('visible => hidden', animate('{{transitionParams}}')),
      transition('hidden => visible', animate('{{transitionParams}}')),
    ]),
  ],
})
export class PrimeFieldsetComponent extends Fieldset {
  isLegendVisible = true;
  @Input()
  tableName: string;
  @Input()
  isSingleRow = false;
  @Input()
  isAdditionalInfo = false;
  @Output()
  openEmitter: EventEmitter<any> = new EventEmitter();
  @Input()
  overridedCSS: OverridedCSS;
  @Input()
  isReadOnly = false;

  constructor(el: ElementRef) {
    super(el);
  }

  handleLegendEvent(event: Event) {
    if (this.isSingleRow && !this.isReadOnly) {
      this.openEmitter.emit();
      event.preventDefault();
      event.stopPropagation();
    } else if (this.toggleable) {
      this.toggle(event);
    }
  }
}
