import { Injectable } from '@angular/core';
import { CompiereNotification } from '@compiere-ws/models/compiere-notification-json';
import { Permission } from '@compiere-ws/models/permission.enum';
import { PushNotificationOptions } from '@compiere-ws/models/push-notification';
import { Observable } from 'rxjs';

@Injectable()
export class PushNotificationsService {
  public permission: Permission;

  constructor() {
    this.permission = this.isSupported() ? Permission.DEFAULT : Permission.DENIED;
  }

  public isSupported(): boolean {
    return 'Notification' in window;
  }

  /**
   * Demande l'autorisation d'afficher les notifications dans le navigateur
   */
  requestPermission(): void {
    const self = this;
    if ('Notification' in window) {
      Notification.requestPermission((status) => {
        switch (status) {
          case Permission.DEFAULT.valueOf():
            return (self.permission = Permission.DEFAULT);
          case Permission.GRANTED.valueOf():
            return (self.permission = Permission.GRANTED);
          case Permission.DENIED.valueOf():
            return (self.permission = Permission.DENIED);
          default:
            return null;
        }
      });
    }
  }

  /**
   * Fait apparaitre la notification générée
   * @param {string} title
   * @param {PushNotificationOptions} options
   * @returns {Observable<any>}
   */
  popNotification(title: string, options?: PushNotificationOptions): Observable<any> {
    const self = this;
    return new Observable((obs) => {
      if (!('Notification' in window)) {
        console.log('Notifications are not available in this environment');
        obs.complete();
      }
      if (self.permission !== 'granted') {
        console.log("The user hasn't granted you permission to send push notifications");
        obs.complete();
      }
      const notify = new Notification(title, options);
      /* les events sont onshow, onclick, onerror, onclose */
      notify.onclick = (e) => {
        return obs.next({
          notification: notify,
          event: e,
        });
      };
      notify.onerror = (e) => {
        return obs.error({
          notification: notify,
          event: e,
        });
      };
      notify.onclose = () => {
        return obs.complete();
      };
    });
  }

  /**
   * Génère une notification du navigateur
   * @param {CompiereNotification[]} source
   */
  generateNotification(source: CompiereNotification[]): void {
    source.forEach((item) => {
      const options: PushNotificationOptions = {
        body: item.summary,
        icon: 'assets/iupics/img/logo-apiz.svg',
      };
      this.popNotification(item.title, options).subscribe((res: { notification: Notification; event: any }) => {
        console.log(res.event);
      });
    });
  }
}
