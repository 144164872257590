<div
  #editViewElement
  [ngClass]="cssClass"
  [style.height.%]="100"
  (swipeleft)="startAnimation($event, 'slideOutLeft')"
  (swiperight)="startAnimation($event, 'slideOutRight')"
  [attr.data-cy-editview-tablename]="this.editTabs[0]?.data.tableName"
  [attr.data-cy-editview-tablevel]="this.editTabs[0]?.data.tabLevel">
  <div
    class="iupics-blade-content-sidebar"
    *ngIf="isZoomTarget"
    [ngClass]="[GridViewVisibility === 'hidden' ? 'invisible' : 'visible']"
    [ngStyle]="{ visibility: GridViewVisibility }">
    <div class="iupics-blade-content-sidebar-content visible">
      <div class="p-toolbar ui-widget-header ui-corner-all ui-helper-clearfix">
        <div class="ui-right" (click)="GridViewVisibility = 'hidden'">
          <button
            pButton
            type="button"
            icon="icon-close"
            class="p-button p-menu-bar-button p-button-icon-only"></button>
        </div>
      </div>
      <iu-grid-view-ui
        #gridViewUi
        [gridTabFilter]="filterZoomTarget"
        [container]="container"
        [DOMParentComponent]="container"
        [data]="zoomTargetData[0].gridView.data"
        [tabId]="zoomTargetData[0].tabId"
        (gridViewCellClicked)="gridViewCellClicked($event)"
        [isMenuDisplay]="true"
        [windowType]="undefined"
        [hasUniversalFilter]="true"
        [cssClass]="'iupics-blade-content-first'"
        [isZoomTargetGrid]="true"></iu-grid-view-ui>
    </div>
  </div>
  <!-- #region audit panel -->
  <div class="audits-panel" *ngIf="showAuditsPanel" (click)="showAuditsPanel = false">
    <div class="audits-panel-content" (click)="$event.preventDefault(); $event.stopPropagation()">
      <ng-template #vcrAudit> </ng-template>
    </div>
  </div>
  <!-- #endregion -->
  <iu-menu-bar-detail-ui
    [linkedComponents]="linkedComponents"
    [nbUploadedFiles]="nbUploadedFiles"
    (changeGridElementEvent)="changeGridElement($event)"
    (saveEvent)="beforeSave($event)"
    (deleteEvent)="deleteData($event)"
    (closeEvent)="checkBeforeClose($event)"
    (refreshEvent)="refreshData(true, $event)"
    (printEvent)="printReport($event)"
    (newEvent)="openNew($event)"
    (joinFilesEvent)="updateModalDisplay({ key: 'displayJoinFilesPanel', value: true })"
    (changeGridViewVisibility)="changeGridViewVisibility($event)"
    (emailEvent)="updateModalDisplay({ key: 'displayEmailEditor', value: true })"
    (noteEvent)="showNotes()"
    (exportDataEvent)="updateModalDisplay({ key: 'displayExportDataModal', value: true })"
    [noData]="noData"
    [viewRecordChangeLogLabel]="editTabs[0] ? editTabs[0]?.data.label : ('tabUi.loading' | translate)"
    [status]="editTabs[0] && editTabs[0].dataStored ? editTabs[0].dataStored.status : DatastoreStatus.SYNC"
    [processed]="
      editTabs[0] && editTabs[0].dataStored && editTabs[0].dataStored.data ? editTabs[0].dataStored.data.Processed : 'N'
    "
    [isZoomEditView]="isZoomEditView"
    [isZoomTarget]="zoomTarget ? true : false"
    (viewRecordChangeLog)="viewRecordChangeLog($event)"
    (copyEvent)="copyData($event)"
    [breadcrumb]="container.breadcrumbComponent"
    [tabId]="tabId"
    [isSplitView]="isSplitView"
    [activeTabID]="container?.activeTab?.id"
    [isReadOnly]="isReadOnly"
    [isDeleteable]="isDeleteable"
    [isInsertRecord]="isInsertRecord"
    [arrowsDisplayed]="
      linkedComponents && linkedComponents.length > 0 ? linkedComponents[0].children.length === 0 : false
    "
    [changingMenuBar]="changingMenuBar"></iu-menu-bar-detail-ui>
  <p-progressBar
    *ngIf="isLoading"
    [mode]="'indeterminate'"
    [value]="0"
    [showValue]="false"
    [styleClass]="'loading-progressbar'"></p-progressBar>
  <div class="iu-editview-info">
    <div class="tabManage">
      <ul class="tabNamesManager">
        <li>
          <a href="#" class="open-icon-title" (click)="$event.preventDefault()">
            <span>{{ 'editView.sections' | translate }}</span>
            <i class="icon-next-ancre" aria-hidden="true"></i>
          </a>
          <ul class="tab-list">
            <ng-container *ngFor="let tab of editTabs; let i = index">
              <li *ngIf="tab.isDisplay !== 'none'">
                <a href="#" class="open-icon" (click)="$event.preventDefault(); goToAnchor($event, tab)">
                  <span>{{ tab.label }}</span>
                  <i class="icon-next-ancre" aria-hidden="true"></i>
                </a>
              </li>
            </ng-container>
          </ul>
        </li>
      </ul>
    </div>
    <iu-menu-smartbuttons-ui
      class="iu-editview-smartbuttons"
      (smartButtonClick)="onSmartButtonClick($event)"></iu-menu-smartbuttons-ui>
  </div>

  <div class="compContainer">
    <div class="contentInline" [style.width]="cssWidth" style="font-size: 16px; word-break: break-all">
      <div
        #stepperDiv
        *ngIf="isStepperVisible && this.editTabs[0] && this.editTabs[0].data && this.editTabs[0].data.isDocumentTab"
        class="p-col-12 ui-stepper">
        <iu-stepper-ui
          (refreshEdit)="refreshEditViews(false)"
          [data]="this.editTabs[0].data.stepperData"
          [changingStepper]="changingStepper"
          [isReadOnly]="false"
          [isIndexNumber]="false"
          [preventPrevious]="true"
          [editTab]="editTabs[0]"
          [parent]="this"></iu-stepper-ui>
      </div>
      <div
        *ngIf="
          isStepperVisible &&
          isStepperEmpty &&
          !(this.editTabs[0] && this.editTabs[0].data && this.editTabs[0].data.isDocumentTab)
        "
        class="p-col-12 ui-stepper">
        <iu-stepper-ui [isReadOnly]="true"></iu-stepper-ui>
      </div>
      <iu-banner *ngIf="conflictsResult.refreshAuto" type="success">
        {{ 'editView.refreshAutoMsg' | translate }}</iu-banner
      >
      <iu-banner
        *ngIf="conflictsResult.mustRefresh && !conflictsResult.refreshAuto"
        type="warning"
        icon="icon-refresh3x">
        {{ 'editView.mustRefreshMsg' | translate }}</iu-banner
      >
      <iu-banner *ngIf="conflictsResult.hasConflicts" type="error"
        >{{ 'editView.hasConflictsMsg' | translate }}
        <a style="text-decoration: underline; cursor: pointer" (click)="wantToMergeConflicts = true">{{
          'editView.verify' | translate
        }}</a>
        {{ 'editView.andSave' | translate }} <br />{{ nbConflicts }} {{ 'editView.remainingConflict' | translate }}
      </iu-banner>

      <p-scrollPanel
        [style]="{
          width: '100%',
          height: isStepperVisible ? 'calc(100% - ' + stepperElt?.nativeElement?.offsetHeight + 'px)' : '100%'
        }">
        <div
          #gridUi
          (swipeleft)="startAnimation($event, 'slideOutLeft')"
          (swiperight)="startAnimation($event, 'slideOutRight')"
          id="{{ scrollPanelid }}"
          class="p-col contentInlineScrollPanel">
          <ng-template iuDynamicContainer></ng-template>
        </div>
      </p-scrollPanel>
    </div>
    <div
      class="contentInline2"
      (swipeleft)="startAnimation($event, 'slideOutLeft')"
      (swiperight)="startAnimation($event, 'slideOutRight')">
      <article
        *ngIf="additionalInfoWidthExpanded"
        #article
        class="accordion"
        [style.height.%]="100"
        style="position: relative">
        <section
          id="acc1"
          [ngClass]="[!isAdditionalOpened ? 'additionalHidden' : '']"
          [style.height.%]="100"
          [style.width]="additionalInfoWidth">
          <h2>
            <a #openButton (click)="isAdditionalOpened ? minimize() : resize()">
              <i class="icon-up" aria-hidden="true"></i>
              <span>{{ 'editView.additionalInformation' | translate }}</span>
            </a>
          </h2>
          <p-scrollPanel [style]="{ width: '100%', height: '100%' }">
            <span><ng-container #additionalInfoVcr></ng-container></span>
          </p-scrollPanel>
        </section>
      </article>
    </div>
    <div class="notes-detail-wrapper" [attr.data-expanded]="notesDetail !== null">
      <ng-container #notesDetailVcr></ng-container>
    </div>
  </div>
</div>

<div *ngIf="wantToMergeConflicts" class="modal-background">
  <iu-mergetool
    [conflictsResult]="conflictsResult"
    [parentEditView]="this"
    (mergeEmitter)="onMerge($event)"></iu-mergetool>
</div>

<p-sidebar
  [(visible)]="isSidebarOpen"
  dismissible="false"
  [baseZIndex]="6000"
  position="right"
  styleClass="p-sidebar-md ">
  <iu-notes-panel-ui
    *ngIf="isSidebarOpen"
    [table_id]="this.table_id"
    [currentDataStoreKey]="this.currentDataStoreKey"
    [parentEdit]="this"></iu-notes-panel-ui>
</p-sidebar>

<ng-container *ngIf="displayJoinFilesPanel">
  <iu-modal-ui
    [title]="'joinFiles.title'"
    [hasCloseBtn]="true"
    [contentType]="ModalContentType.JOINFILE"
    (closeModalEmitter)="updateModalDisplay({ key: 'displayJoinFilesPanel', value: false })">
    <iu-join-file-ui
      [dsKey]="currentDataStoreKey"
      [adTable_ID]="table_id"
      [adTab_ID]="tabId"
      [(nbUploadedFiles)]="nbUploadedFiles"
      [data]="data"></iu-join-file-ui>
  </iu-modal-ui>
</ng-container>

<ng-container *ngIf="displayEmailEditor">
  <iu-modal-ui
    [title]="'email.title'"
    (closeModalEmitter)="updateModalDisplay({ key: 'displayEmailEditor', value: false })">
    <iu-email-editor-ui
      [parentEditView]="this"
      (cancelEmailEmitter)="updateModalDisplay({ key: 'displayEmailEditor', value: false })"></iu-email-editor-ui>
  </iu-modal-ui>
</ng-container>

<ng-container *ngIf="displayExportDataModal">
  <iu-modal-ui
    #exportDataModal
    [title]="'exportData.title'"
    (closeModalEmitter)="updateModalDisplay({ key: 'displayExportDataModal', value: false })"
    [angularStyle]="{ 'iu-modal-body': { overflow: 'unset' } }">
    <iu-export-data-ui
      [tableId]="this.editTabs[0].data.AD_Table_ID"
      [tabId]="this.tabId"
      [dsKey]="currentDataStoreKey"
      (cancelExportDataEmitter)="updateModalDisplay({ key: 'displayExportDataModal', value: false })"
      [sourceModal]="exportDataModal"
      (afterExecute)="updateNbFileAttached()"></iu-export-data-ui>
  </iu-modal-ui>
</ng-container>

<ng-container *ngIf="displayProcessUI">
  <iu-modal-ui
    (closeModalEmitter)="updateModalDisplay({ key: 'displayProcessUI', value: false })"
    [hasCloseBtn]="true"
    [angularStyle]="{ 'iu-modal-body': { padding: 0 } }"
    [contentType]="ModalContentType.PROCESS">
    <iu-process-ui
      (closeModalEmitter)="updateModalDisplay({ key: 'displayProcessUI', value: false })"
      [windowId]="processId"
      [parentComponent]="this"
      [sourceComponentData]="displayProcessUI_sourceComponentData"
      [isModal]="true"></iu-process-ui>
  </iu-modal-ui>
</ng-container>

<div [ngClass]="[!displayFormUI ? 'hidden' : '', 'specificModal']">
  <iu-modal-ui
    #specificModal
    [title]="specificWindowTitle"
    [isModalDisplay]="displayFormUI"
    [angularStyle]="customFormModalBodyCss"
    [modalClass]="modalClass"
    [contentType]="ModalContentType.FORM"
    (closeModalEmitter)="updateModalDisplay({ key: 'displayFormUI', value: false })">
    <ng-template #vcrSpecific></ng-template>
  </iu-modal-ui>
</div>

<ng-container *ngIf="displaySearch">
  <iu-modal-ui
    [angularStyle]="{ 'iu-modal': { 'min-height': '500px' } }"
    data-cy="search-panel"
    [attr.data-cy-columnName]="this.searchLinkedComponent.autoComplete.data.columnName"
    [hidden]="displayFormUI || !displaySearch"
    [title]="this.searchLinkedComponent.autoComplete.label"
    [hasCloseBtn]="true"
    (closeModalEmitter)="this.displaySearch = false">
    <div class="autocomplete-universal-filter">
      <iu-universal-filter-ui
        [tabId]="-1"
        [columns]="searchLinkedComponent.autoComplete.data.searchColumns"
        [gridTab]="gridTab"></iu-universal-filter-ui>
    </div>
    <iu-grid-tab-infinity-scroll-ui
      #gridTab
      (searchEmitter)="
        this.searchLinkedComponent.setSearchSelectItem($event); displaySearch = false; searchPanelValidation = undefined
      "
      [data]="this.searchLinkedComponent.autoComplete.data"
      [isSearch]="true"
      [dataStored]="this.searchLinkedComponent.autoComplete.dataStored"
      [container]="this"
      [parentComponent]="this"
      [windowType]="windowType"
      rowSelection="single"
      [validation]="searchPanelValidation"></iu-grid-tab-infinity-scroll-ui>
  </iu-modal-ui>
</ng-container>

<ng-container *ngIf="specificDisplaySearch">
  <iu-modal-ui
    [title]="this.specificSearchLinkedComponent.label"
    [hasCloseBtn]="true"
    (closeModalEmitter)="specificDisplaySearch = false">
    <div class="autocomplete-universal-filter">
      <iu-universal-filter-ui
        [gridTab]="gridTabSpecific"
        [columns]="this.specificSearchLinkedComponent.data.searchColumns"
        [tabId]="-1">
      </iu-universal-filter-ui>
    </div>
    <iu-grid-tab-infinity-scroll-ui
      #gridTabSpecific
      [data]="this.specificSearchLinkedComponent.data"
      [isSearch]="true"
      [dataStored]="this.specificSearchLinkedComponent.dataStored"
      [container]="this"
      [parentComponent]="this"
      [windowType]="windowType"
      [validation]="searchPanelValidation"
      (searchEmitter)="
        this.specificSearchLinkedComponent.setSearchSelectItem($event);
        specificDisplaySearch = false;
        searchPanelValidation = undefined
      ">
    </iu-grid-tab-infinity-scroll-ui>
  </iu-modal-ui>
</ng-container>
