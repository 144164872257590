<div class="address-container">
  <h4 class="address-title">{{ displayData?.name }}</h4>
  <button
    *ngIf="displayData?.address && displayData?.city && displayData?.country"
    pButton
    class="p-button p-button-icon-only address-gmap-btn"
    type="button"
    (click)="openMap()">
    <i class="icon-pointer-2" aria-hidden="true"></i>
  </button>
  <div class="address-details">
    <span>{{ displayData?.address }}</span>
    <span>{{ displayData?.city }} {{ displayData?.postalCode }}</span>
    <span>{{ displayData?.country }}</span>
  </div>
  <button pButton class="p-button p-button-icon-only address-copy-btn" type="button" (click)="copy()">
    <i class="icon-copy" aria-hidden="true"></i>
  </button>
  <div class="address-tags">
    <div *ngIf="displayData?.isShipTo" class="address-tag" data-type="ship">
      <span>{{ 'cardsUi.addressCard.shipTo' | translate }}</span>
    </div>
    <div *ngIf="displayData?.isBillTo" class="address-tag" data-type="bill">
      <span>{{ 'cardsUi.addressCard.billTo' | translate }}</span>
    </div>
    <div *ngIf="displayData?.isRemitTo" class="address-tag" data-type="remit">
      <span>{{ 'cardsUi.addressCard.remitTo' | translate }}</span>
    </div>
    <div *ngIf="displayData?.isPayFrom" class="address-tag" data-type="pay">
      <span>{{ 'cardsUi.addressCard.payFrom' | translate }}</span>
    </div>
  </div>
</div>
