import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { CompiereMenu } from '@compiere-ws/models/compiere-menu-json';
import { KeyCode } from '@iupics-config/keycode.enum';
import { MenuFavoritesCategoryUI, MenuItemUI } from '@web-desktop/models/menu-item-ui';
import { Observable } from 'rxjs';
import { DragDropGroup } from '../drag-drop-group';

@Component({
  selector: 'iu-widget-group-ui',
  templateUrl: './widget-group-ui.component.html',
  styleUrls: ['./widget-group-ui.component.scss'],
})
export class WidgetGroupUiComponent extends DragDropGroup implements OnInit, OnDestroy {
  protected newItemLabel = 'dashboard.newTab.widgets';
  widgets$: Observable<MenuItemUI[]>;

  searchWidgetValue: string;
  arrowFocusedWidget: MenuItemUI;
  widgetCssClass = 'p-col-12 p-md-6 ui-g';

  @Output() public itemClicked: EventEmitter<MenuItemUI> = new EventEmitter<MenuItemUI>();

  ngOnInit() {
    this.createEmptyDiv('p-col-12 p-md-6 p-lg-4');
    // récupère la liste des widgets favoris
    this.getItems();
  }

  openList(event: Event) {
    event.preventDefault();
    if (this.searchInputEl && this.searchInputEl.nativeElement) {
      setTimeout(() => {
        this.searchInputEl.nativeElement.focus();
      }, 100);
    }
    this.searchWidgetValue = '';
    this.arrowFocusedWidget = undefined;
    this.widgets$ = this.uiCreatorService.getIupicsWidgets();
  }

  protected save(arrayToSave: CompiereMenu[]): Observable<CompiereMenu[]> {
    return this.uiCreatorService.setIupicsWidgetFavorites(arrayToSave);
  }

  protected delete(parentId: number, menuId: number, isSummary: boolean): Observable<boolean> {
    return this.uiCreatorService.deleteIupicsWidgetFavorites(parentId, menuId, isSummary);
  }

  protected handleInputKeyup(event: KeyboardEvent, groups: MenuItemUI[]) {
    // eslint-disable-next-line deprecation/deprecation
    switch (event.keyCode) {
      case KeyCode.LEFT_ARROW:
        this.getNextItem(-1, groups);
        this.scrollToListItem(this.arrowFocusedWidget);
        break;
      case KeyCode.UP_ARROW:
        this.getNextItem(-1, groups);
        this.scrollToListItem(this.arrowFocusedWidget);
        break;
      case KeyCode.RIGHT_ARROW:
        this.getNextItem(1, groups);
        this.scrollToListItem(this.arrowFocusedWidget);
        break;
      case KeyCode.DOWN_ARROW:
        this.getNextItem(1, groups);
        this.scrollToListItem(this.arrowFocusedWidget);
        break;
      case KeyCode.ENTER:
        this.selectItem();
        break;
      default:
        this.arrowFocusedWidget = undefined;
        break;
    }
  }

  protected selectItem() {
    if (this.arrowFocusedWidget !== undefined) {
      this.addItem(this.arrowFocusedWidget);
    }
  }

  protected getNextItem(offset: number, groups: MenuItemUI[]) {
    this.arrowFocusedWidget = this.dashboardManager.getNextWidget(
      offset,
      groups,
      this.searchWidgetValue,
      this.arrowFocusedWidget
    );
  }

  private scrollToListItem(selectedWidget: MenuItemUI) {
    this.dashboardManager.scrollToItem('iu_widget_menu_' + selectedWidget.menuId, 'iu_widget_container');
  }

  protected getGroups(): MenuFavoritesCategoryUI[] {
    return this.uiCreatorService.getIupicsWidgetFavoritesCategories();
  }

  protected getAll(forceRefresh: boolean): Observable<MenuItemUI[]> {
    return this.uiCreatorService.getIupicsWidgetFavorites(forceRefresh);
  }
}
