<span
  #container
  [ngClass]="[
    'p-calendar',
    showIcon ? 'p-calendar-w-btn' : '',
    timeOnly ? 'p-calendar-timeonly' : '',
    styleClass || ''
  ]"
  [ngStyle]="style">
  <ng-template [ngIf]="!inline">
    <label
      for="float-input"
      *ngIf="isLabelDisplay && label !== undefined && label !== null && label.trim().length > 0"
      [ngClass]="{ 'iu-field-only-label': isFieldOnly }"
      >{{ label | textLimit : this.el.nativeElement.firstElementChild.firstElementChild.clientWidth / 7 }}</label
    >
    <div class="calendar-input-container">
      <input
        #inputfield
        type="text"
        [ngClass]="['p-inputtext ui-corner-all', mandatoryCss || '', inputStyleClass || '']"
        [style.width]="'calc(100% - ' + (hasTodayBtn && !timeOnly ? 26 : 0) + 'px - ' + (showIcon ? 26 : 0) + 'px)'"
        [attr.id]="inputId"
        [attr.name]="name"
        [attr.required]="required"
        [attr.value]="inputFieldValue"
        (mousedown)="cancelFocus($event)"
        (focus)="!readonlyInput ? onInputFocus($event) : null"
        [tabindex]="readonlyInput ? '-1' : null"
        (keydown)="onInputKeydown($event)"
        (click)="onInputClick()"
        (change)="onInputBlur($event)"
        (blur)="onInputBlur($event)"
        [readonly]="readonlyInput"
        [disabled]="readonlyInput"
        (input)="onUserInput($any($event))"
        [ngStyle]="inputStyle"
        [placeholder]="placeholder || ''"
        [attr.tabindex]="tabindex"
        autocomplete="no"
        data-cy="input-text"
        [attr.data-cy-columnName]="columnName"
        tabindex="-1" />
      <i
        type="button"
        *ngIf="hasTodayBtn && !timeOnly"
        (click)="!readonlyInput && onTodayModeChange(!calendarConfig.todayMode)"
        [ngClass]="[
          disabled ? 'p-disabled' : '',
          calendarConfig.todayMode ? 'icon-today-full' : 'icon-today',
          'iconeCalendar'
        ]"
        tabindex="-1"
        disabled="readonlyInput"
        aria-hidden="true"
        data-cy="today-mode"
        [attr.data-cy-today-mode]="calendarConfig.todayMode ? 'on' : 'off'"></i>
      <i
        type="button"
        *ngIf="showIcon"
        (click)="!readonlyInput && onButtonClick($event, inputfield)"
        [ngClass]="[
          timeOnly ? 'icon-hours' : 'icon-calendar',
          disabled || calendarConfig.todayMode ? 'p-disabled' : '',
          'iconeCalendar p-datepicker-trigger'
        ]"
        tabindex="-1"
        disabled="readonlyInput"
        aria-hidden="true"></i>
    </div>
  </ng-template>

  <div
    *ngIf="(inline || overlayVisible) && calendarConfig.todayMode"
    [ngStyle]="panelStyle"
    style="top: 48px"
    [ngClass]="[
      'p-datepicker ui-corner-all',
      inline ? 'p-datepicker-inline' : 'ui-shadow',
      disabled ? 'p-disabled' : '',
      timeOnly ? 'p-datepicker-timeonly' : '',
      this.numberOfMonths > 1 ? 'p-datepicker-multiple-month' : '',
      view === 'month' ? 'p-datepicker-monthpicker' : '',
      touchUI ? 'p-datepicker-touch-ui' : '',
      panelStyleClass || ''
    ]"
    [@overlayAnimationCalendar]="
      touchUI
        ? {
            value: 'visibleTouchUI',
            params: { showTransitionParams: showTransitionOptions, hideTransitionParams: hideTransitionOptions }
          }
        : {
            value: 'visible',
            params: { showTransitionParams: showTransitionOptions, hideTransitionParams: hideTransitionOptions }
          }
    "
    [@.disabled]="inline === true"
    (@overlayAnimationCalendar.start)="onOverlayAnimationStart($event)"
    (@overlayAnimationCalendar.done)="onOverlayAnimationDone($event)">
    <ng-container
      *ngTemplateOutlet="todayModeSuggestionsTpl; context: { $implicit: todayModeSuggestions }"></ng-container>
  </div>
  <div
    *ngIf="(inline || overlayVisible) && !calendarConfig.todayMode"
    [ngStyle]="panelStyle"
    style="top: 48px"
    [ngClass]="[
      'p-datepicker ui-corner-all',
      inline ? 'p-datepicker-inline' : 'ui-shadow',
      disabled ? 'p-disabled' : '',
      timeOnly ? 'p-datepicker-timeonly' : '',
      this.numberOfMonths > 1 ? 'p-datepicker-multiple-month' : '',
      view === 'month' ? 'p-datepicker-monthpicker' : '',
      touchUI ? 'p-datepicker-touch-ui' : '',
      panelStyleClass || ''
    ]"
    [@overlayAnimationCalendar]="
      touchUI
        ? {
            value: 'visibleTouchUI',
            params: { showTransitionParams: showTransitionOptions, hideTransitionParams: hideTransitionOptions }
          }
        : {
            value: 'visible',
            params: { showTransitionParams: showTransitionOptions, hideTransitionParams: hideTransitionOptions }
          }
    "
    [@.disabled]="inline === true"
    (@overlayAnimationCalendar.start)="onOverlayAnimationStart($event)"
    (@overlayAnimationCalendar.done)="onOverlayAnimationDone($event)">
    <ng-container *ngIf="!timeOnly">
      <div class="p-datepicker-group" *ngFor="let month of months; let i = index">
        <div class="p-datepicker-header ui-corner-all">
          <ng-content select="p-header"></ng-content>
          <a
            class="p-datepicker-prev ui-corner-all"
            href="#"
            (click)="$event.preventDefault(); navBackward($event)"
            *ngIf="i === 0"
            tabindex="-1">
            <span class="pi pi-chevron-left"></span>
          </a>
          <a
            class="p-datepicker-next ui-corner-all"
            href="#"
            (click)="$event.preventDefault(); navForward($event)"
            *ngIf="numberOfMonths === 1 ? true : i === numberOfMonths - 1"
            tabindex="-1">
            <span class="pi pi-chevron-right"></span>
          </a>
          <div class="p-datepicker-title">
            <span class="p-datepicker-month" *ngIf="!monthNavigator && view !== 'month'">{{
              locale.monthNames[month.month]
            }}</span>
            <select
              tabindex="-1"
              class="p-datepicker-month"
              *ngIf="monthNavigator && view !== 'month' && numberOfMonths === 1"
              (change)="onMonthDropdownChange($any($event).target.value)">
              <option
                [value]="i"
                *ngFor="let monthName of locale.monthNames; let i = index"
                [selected]="i === month.month">
                {{ monthName }}
              </option>
            </select>
            <select
              tabindex="-1"
              class="p-datepicker-year"
              *ngIf="yearNavigator && numberOfMonths === 1"
              (change)="onYearDropdownChange($any($event).target.value)">
              <option [value]="year" *ngFor="let year of yearOptions" [selected]="year === currentYear">
                {{ year }}
              </option>
            </select>
            <span class="p-datepicker-year" *ngIf="!yearNavigator">{{
              view === 'month' ? currentYear : month.year
            }}</span>
          </div>
        </div>
        <div class="p-datepicker-calendar-container" *ngIf="view === 'date'">
          <table class="p-datepicker-calendar" aria-label="datepicker calendar table">
            <thead>
              <tr>
                <th scope="col" *ngFor="let weekDay of weekDays; let begin = first; let end = last">
                  <span>{{ weekDay }}</span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let week of month.dates">
                <td
                  *ngFor="let date of week"
                  [ngClass]="[
                    date.otherMonth ? 'p-datepicker-other-month' : '',
                    isSelected(date) ? 'p-datepicker-current-day' : '',
                    date.today ? 'p-datepicker-today' : ''
                  ]">
                  <ng-container *ngIf="date.otherMonth ? showOtherMonths : true">
                    <a
                      *ngIf="date.selectable"
                      [ngClass]="[isSelected(date) ? 'p-highlight' : '', date.today ? 'p-highlight' : '']"
                      (click)="onDateSelect($event, date)"
                      draggable="false"
                      tabindex="-1">
                      <ng-container *ngIf="!dateTemplate">{{ date.day }}</ng-container>
                      <ng-container *ngTemplateOutlet="dateTemplate; context: { $implicit: date }"></ng-container>
                    </a>
                    <span class="p-disabled" *ngIf="!date.selectable"> {{ date.day }} </span>
                  </ng-container>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="p-monthpicker" *ngIf="view === 'month'">
        <a
          href="#"
          *ngFor="let m of monthPickerValues(); let i = index"
          (click)="onMonthSelect($event, i)"
          [ngClass]="[isMonthSelected(i) ? 'p-highlight' : '', 'p-monthpicker-month']"
          tabindex="-1">
          {{ m }}
        </a>
      </div>
    </ng-container>
    <div class="p-timepicker ui-corner-all" *ngIf="showTime || timeOnly">
      <div class="p-hour-picker">
        <a
          (mousedown)="onTimePickerElementMouseDown($event, 0, 1)"
          (mouseup)="onTimePickerElementMouseUp($event)"
          tabindex="-1">
          <span class="pi pi-chevron-up"></span>
        </a>
        <span [ngStyle]="{ display: currentHour < 10 ? 'inline' : 'none' }">0</span><span>{{ currentHour }}</span>
        <a
          tabindex="-1"
          (mousedown)="onTimePickerElementMouseDown($event, 0, -1)"
          (mouseup)="onTimePickerElementMouseUp($event)">
          <span class="pi pi-chevron-down"></span>
        </a>
      </div>
      <div class="p-separator">
        <a tabindex="-1">
          <span class="pi pi-chevron-up"></span>
        </a>
        <span>{{ timeSeparator }}</span>
        <a tabindex="-1">
          <span class="pi pi-chevron-down"></span>
        </a>
      </div>
      <div class="p-minute-picker">
        <a
          tabindex="-1"
          (mousedown)="onTimePickerElementMouseDown($event, 1, 1)"
          (mouseup)="onTimePickerElementMouseUp($event)">
          <span class="pi pi-chevron-up"></span>
        </a>
        <span [ngStyle]="{ display: currentMinute < 10 ? 'inline' : 'none' }">0</span><span>{{ currentMinute }}</span>
        <a
          tabindex="-1"
          (mousedown)="onTimePickerElementMouseDown($event, 1, -1)"
          (mouseup)="onTimePickerElementMouseUp($event)">
          <span class="pi pi-chevron-down"></span>
        </a>
      </div>
      <div class="p-separator" *ngIf="showSeconds">
        <a tabindex="-1">
          <span class="pi pi-chevron-up"></span>
        </a>
        <span>{{ timeSeparator }}</span>
        <a tabindex="-1">
          <span class="pi pi-chevron-down"></span>
        </a>
      </div>
      <div class="p-second-picker" *ngIf="showSeconds">
        <a
          tabindex="-1"
          (mousedown)="onTimePickerElementMouseDown($event, 2, 1)"
          (mouseup)="onTimePickerElementMouseUp($event)">
          <span class="pi pi-chevron-up"></span>
        </a>
        <span [ngStyle]="{ display: currentSecond < 10 ? 'inline' : 'none' }">0</span><span>{{ currentSecond }}</span>
        <a
          tabindex="-1"
          (mousedown)="onTimePickerElementMouseDown($event, 2, -1)"
          (mouseup)="onTimePickerElementMouseUp($event)">
          <span class="pi pi-chevron-down"></span>
        </a>
      </div>
      <div class="p-ampm-picker" *ngIf="hourFormat === '12'">
        <a tabindex="-1" (click)="toggleAMPM($event)">
          <span class="pi pi-chevron-up"></span>
        </a>
        <span>{{ pm ? 'PM' : 'AM' }}</span>
        <a tabindex="-1" (click)="toggleAMPM($event)">
          <span class="pi pi-chevron-down"></span>
        </a>
      </div>
    </div>
    <div class="p-datepicker-buttonbar" *ngIf="showButtonBar">
      <div class="p-grid">
        <div class="p-col-6">
          <button
            tabindex="-1"
            type="button"
            [label]="_locale.today"
            (click)="onTodayButtonClick($event)"
            style="width: 100%"
            pButton
            [ngClass]="[todayButtonStyleClass || '', 'p-button-primary']"></button>
        </div>
        <div class="p-col-6">
          <button
            tabindex="-1"
            type="button"
            [label]="_locale.clear"
            (click)="onClearButtonClick($event)"
            style="width: 100%"
            pButton
            [ngClass]="[clearButtonStyleClass || '', 'p-button-alert']"></button>
        </div>
        <div class="p-col-6" *ngIf="showTime || timeOnly">
          <button
            tabindex="-1"
            type="button"
            [label]="'OK'"
            (click)="onOKButtonClick($event)"
            style="width: 100%"
            pButton
            [ngClass]="[todayButtonStyleClass || '', 'p-button-info']"></button>
        </div>
      </div>
    </div>
    <ng-content select="p-footer"></ng-content>
  </div>
</span>

<ng-template #todayModeSuggestionsTpl let-suggestions>
  <div class="p-calendar-today-suggestions" *ngIf="suggestions.length > 0">
    <a
      *ngFor="let suggestion of suggestions"
      tabindex="-1"
      class="p-calendar-today-suggestions-item"
      (click)="onSuggestionClick($event, suggestion)">
      {{ suggestion }}
    </a>
  </div>
</ng-template>
