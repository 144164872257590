<span
  [style.backgroundColor]="backgroundColorCss"
  [style.display]="displayCss"
  [style.width]="overridedCSS?.width"
  class="iu-input-text-ui"
  [life]="3000"
  [hideDelay]="250"
  pTooltip="{{ tooltip }}"
  [escape]="false"
  tooltipPosition="top"
  [showDelay]="500"
  tooltipZIndex="2100"
  [style.margin-top.px]="data && data.marginTop > 0 ? data.marginTop : null">
  <label
    *ngIf="this.isLabelDisplay && label !== undefined && label !== null && label.trim().length > 0"
    [style.font-size]="overridedCSS?.labelFontSize"
    [ngClass]="{ 'iu-field-only-label': data?.isFieldOnly }"
    >{{ label | textLimit : this.elementRef.nativeElement.firstElementChild.firstElementChild.clientWidth / 7 }}</label
  >
  <input
    #input
    (change)="dataChange(input.value); fieldValueChange.emit(input.value)"
    (contextmenu)="onContextMenu($event)"
    (press)="onContextMenu($any($event))"
    [(ngModel)]="fieldValue"
    [readonly]="isReadOnly"
    [tabindex]="isReadOnly ? '-1' : null"
    [ngClass]="[hasConflict ? 'iu-field-conflict-text' : '', mandatoryCss, 'ui-corner-all']"
    [type]="data ? data.fieldType : 'text'"
    autocomplete="new-password"
    pInputText
    placeholder="{{ placeHolder }}"
    [attr.value]="fieldValue"
    (click)="resetPassWord()"
    (blur)="changePassWord()"
    (keydown.enter)="enterKey.emit($event)"
    [style.font-size]="overridedCSS?.inputFontSize"
    [style.height]="overridedCSS?.inputHeight"
    data-cy="input-text"
    [attr.data-cy-columnName]="data ? data.columnName : columnName"
    (input)="onUserInput($event)"
    (keyup)="isKeyUpTriggerable && onKeyUp($event)" />
  <i
    *ngIf="hasConflict && dataStored"
    #spanInput
    class="rtconflict fas fa-exclamation-triangle iconClass"
    (click)="showConflictPanel($event)"
    aria-hidden="true"></i>
  <iu-prime-overlay #opValuePref *ngIf="showValuePrefPanel" [dismissable]="false" appendTo="body">
    <iu-value-preference-panel
      [overlayPanel]="opValuePref"
      [fieldValue]="fieldValue"
      [dataStored]="this.dataStored"
      [sourceComponent]="this"
      [data]="this.data"
      (closeEmitter)="toggleValuePreferencePanel($event)"></iu-value-preference-panel>
  </iu-prime-overlay>
</span>

<iu-prime-overlay #opConflict>
  <a class="data-conflict" (click)="chooseData($event, (dataStored?.data)[data.columnName], true); opConflict.hide()">{{
    (dataStored?.data)[data.columnName]
  }}</a>
  |
  <a class="data-conflict" (click)="chooseData($event, conflictedData, false); opConflict.hide()">{{
    conflictedData
  }}</a>
</iu-prime-overlay>
