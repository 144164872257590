import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { CompiereMenu } from '@compiere-ws/models/compiere-menu-json';
import { KeyCode } from '@iupics-config/keycode.enum';
import { MenuFavoritesCategoryUI, MenuItemUI } from '@web-desktop/models/menu-item-ui';
import { Observable } from 'rxjs';
import { DragDropGroup } from '../drag-drop-group';

@Component({
  selector: 'iu-favorite-group-ui',
  templateUrl: './favorite-group-ui.component.html',
  styleUrls: ['./favorite-group-ui.component.scss'],
})
export class FavoriteGroupUiComponent extends DragDropGroup implements OnInit, OnDestroy {
  protected newItemLabel = 'dashboard.newTab.favorites';
  menus$: Observable<{ category: string; items: MenuItemUI[] }[]>;

  searchFavoriteValue: string;
  selectedCategory: string;
  arrowFocusedCategory: string;
  arrowFocusedMenu: MenuItemUI;

  @Output() public itemClicked: EventEmitter<MenuItemUI> = new EventEmitter<MenuItemUI>();

  ngOnInit() {
    this.createEmptyDiv('p-col-12 p-md-6 p-lg-3');
    // récupère la liste des menus favoris et les tries par catégories
    this.getItems();
  }

  openList(event: Event) {
    event.preventDefault();
    if (this.searchInputEl && this.searchInputEl.nativeElement) {
      setTimeout(() => {
        this.searchInputEl.nativeElement.focus();
      }, 100);
    }
    this.searchFavoriteValue = '';
    this.selectedCategory = undefined;
    this.arrowFocusedCategory = undefined;
    this.arrowFocusedMenu = undefined;
    // récupère la liste des menus et les tries par catégories
    this.menus$ = this.dashboardManager.getDashboardIupicsMenu();
  }

  //#region menu-favorite specific
  onSelectCategory(event: Event, category: string) {
    event.stopPropagation();
    this.selectCategory(category);
  }

  private selectCategory(category: string) {
    this.selectedCategory = category;
    this.arrowFocusedCategory = undefined;
    this.arrowFocusedMenu = undefined;
  }
  //#endregion

  protected save(arrayToSave: CompiereMenu[]): Observable<CompiereMenu[]> {
    return this.uiCreatorService.setIupicsFavorites(arrayToSave);
  }

  protected delete(parentId: number, menuId: number, isSummary: boolean): Observable<boolean> {
    return this.uiCreatorService.deleteIupicsFavorites(parentId, menuId, isSummary);
  }

  handleInputKeyup(event: KeyboardEvent, groups: { category: string; items: MenuItemUI[] }[]) {
    // eslint-disable-next-line deprecation/deprecation
    switch (event.keyCode) {
      case KeyCode.LEFT_ARROW:
        this.getNextItem(-1, groups);
        this.scrollToListItem(this.arrowFocusedCategory, this.arrowFocusedMenu);
        break;
      case KeyCode.UP_ARROW:
        this.getNextItem(-1, groups);
        this.scrollToListItem(this.arrowFocusedCategory, this.arrowFocusedMenu);
        break;
      case KeyCode.RIGHT_ARROW:
        this.getNextItem(1, groups);
        this.scrollToListItem(this.arrowFocusedCategory, this.arrowFocusedMenu);
        break;
      case KeyCode.DOWN_ARROW:
        this.getNextItem(1, groups);
        this.scrollToListItem(this.arrowFocusedCategory, this.arrowFocusedMenu);
        break;
      case KeyCode.ENTER:
        this.selectItem();
        break;
      default:
        this.selectedCategory = undefined;
        this.arrowFocusedMenu = undefined;
        this.arrowFocusedCategory = undefined;
        break;
    }
  }

  protected selectItem() {
    if (
      this.selectedCategory === undefined &&
      (this.searchFavoriteValue === undefined ||
        this.searchFavoriteValue === '' ||
        this.searchFavoriteValue === '!' ||
        this.searchFavoriteValue === '#' ||
        this.searchFavoriteValue === '!#')
    ) {
      if (this.arrowFocusedCategory !== undefined) {
        this.selectCategory(this.arrowFocusedCategory);
      }
    } else {
      if (this.arrowFocusedMenu !== undefined) {
        this.addItem(this.arrowFocusedMenu);
      }
    }
  }

  protected getNextItem(offset: number, groups: { category: string; items: MenuItemUI[] }[]) {
    const response = this.dashboardManager.getNextCategoryOrMenu(
      offset,
      groups,
      this.selectedCategory,
      this.searchFavoriteValue,
      this.arrowFocusedCategory,
      this.arrowFocusedMenu
    );
    if (response !== undefined && typeof response === 'string') {
      this.arrowFocusedCategory = response;
    } else if (response !== undefined) {
      this.arrowFocusedMenu = <MenuItemUI>response;
    }
  }

  private scrollToListItem(selectedCategory: string, selectedMenu: MenuItemUI) {
    if (selectedCategory !== undefined) {
      this.dashboardManager.scrollToItem('iu_favorite_category_' + selectedCategory, 'iu_favorite_container');
    } else if (selectedMenu !== undefined) {
      this.dashboardManager.scrollToItem('iu_favorite_menu_' + selectedMenu.menuId, 'iu_favorite_container');
    }
  }

  protected getGroups(): MenuFavoritesCategoryUI[] {
    return this.uiCreatorService.getIupicsMenuFavoritesCategories();
  }

  protected getAll(forceRefresh: boolean): Observable<MenuItemUI[]> {
    return this.uiCreatorService.getIupicsMenuFavorites(forceRefresh);
  }
}
