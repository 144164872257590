<div
  class="quick-action-container"
  [attr.aria-expanded]="isExpanded"
  [attr.aria-readonly]="data?.readonly ?? false"
  #qaElement>
  <div
    class="quick-action-label"
    (click)="applyFunction(data?.action ?? null)"
    iuLoading
    [dataToWatch]="data"
    role="button">
    <span>{{ data.label }}</span>
    <i *ngIf="data?.type" [ngClass]="[data.type]" aria-hidden="true"></i>
  </div>
  <div *ngIf="data.subActions?.length > 0" class="quick-action-subactions">
    <div class="quick-action-subactions-wrapper" role="list">
      <span
        class="quick-action-subactions-label"
        *ngFor="let sa of data.subActions"
        (click)="applyFunction(sa)"
        role="listitem"
        title="{{ sa.displayValue }}">
        {{ sa.displayValue }}
      </span>
    </div>
  </div>
</div>
