<div class="chartView">
  <div class="chartViewRender">
    <div class="filters">
      <div class="switchView">
        <button
          pButton
          [life]="3000"
          [hideDelay]="250"
          pTooltip="{{ tooltipLabels.pieChart }}"
          tooltipPosition="top"
          type="button"
          icon="fas fa-chart-pie"
          (click)="swicthToPieChart()"
          class="p-menu-bar-button"></button>
        <button
          pButton
          [life]="3000"
          [hideDelay]="250"
          pTooltip="{{ tooltipLabels.doughnutChart }}"
          tooltipPosition="top"
          type="button"
          icon="fas fa-dot-circle"
          (click)="swicthToDoughnutChart()"
          class="p-menu-bar-button"></button>
        <button
          pButton
          [life]="3000"
          [hideDelay]="250"
          pTooltip="{{ tooltipLabels.polarChart }}"
          tooltipPosition="top"
          type="button"
          icon="fas fa-bullseye"
          (click)="swicthToPolarChart()"
          class="p-menu-bar-button"></button>
        <button
          pButton
          [life]="3000"
          [hideDelay]="250"
          pTooltip="{{ tooltipLabels.lineChart }}"
          tooltipPosition="top"
          type="button"
          icon="fas fa-chart-line"
          (click)="swicthToLineChart()"
          class="p-menu-bar-button"></button>
        <button
          pButton
          [life]="3000"
          [hideDelay]="250"
          pTooltip="{{ tooltipLabels.areaChart }}"
          tooltipPosition="top"
          type="button"
          icon="fas fa-chart-area"
          (click)="swicthToAreaChart()"
          class="p-menu-bar-button"></button>
        <button
          pButton
          [life]="3000"
          [hideDelay]="250"
          pTooltip="{{ tooltipLabels.barChart }}"
          tooltipPosition="top"
          type="button"
          icon="far fa-chart-bar fa-rotate-90"
          (click)="swicthToBarChart()"
          class="p-menu-bar-button"></button>
        <button
          pButton
          [life]="3000"
          [hideDelay]="250"
          pTooltip="{{ tooltipLabels.columnChart }}"
          tooltipPosition="top"
          type="button"
          icon="fas fa-chart-bar"
          (click)="swicthToColumnChart()"
          class="p-menu-bar-button"></button>
        <button
          pButton
          [life]="3000"
          [hideDelay]="250"
          pTooltip="{{ tooltipLabels.radarChart }}"
          tooltipPosition="top"
          type="button"
          icon="far fa-snowflake"
          (click)="swicthToRadarChart()"
          class="p-menu-bar-button"></button>
      </div>
      <div class="operation">
        <iu-autocomplete-ui
          #operationField
          [label]="'chartview.aggregate' | translate"
          [(fieldValue)]="operationFieldValue"
          (fieldValueModified)="changeOperationToCompute($event)"
          [isStandalone]="true"
          [data]="{
            items: [
              { id: 'sum', displayValue: 'gridTools.sum' | translate },
              { id: 'count', displayValue: 'gridTools.count' | translate }
            ]
          }"></iu-autocomplete-ui>
      </div>
      <div class="column">
        <iu-autocomplete-ui
          [(fieldValue)]="defaultColumn"
          [label]="'chartview.dataAggregate' | translate"
          (fieldValueModified)="changeColumnToCompute($event)"
          [isStandalone]="true"
          [data]="{
            items: columns
          }"></iu-autocomplete-ui>
      </div>
    </div>

    <p-chart
      #chart
      [type]="chartType === 'area' ? 'line' : chartType === 'horizontalBar' ? 'bar' : chartType"
      [data]="dataChart"
      [options]="options"
      [height]="'70vh'"></p-chart>
  </div>
</div>
