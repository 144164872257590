import {animate, style, transition, trigger} from '@angular/animations';
import {
    AfterViewInit,
    Component,
    ComponentRef,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
    ViewContainerRef,
    inject,
} from '@angular/core';
import {WidgetDataCompiereJson} from '@compiere-ws/models/widget-center-json';
import {WidgetCenterService} from '@compiere-ws/services/widget-center/widget-center.service';
import {ChartType, ViewType} from '@iupics-components/models/view-type.enum';
import {CacheManagerService} from '@iupics-manager/managers/cache-manager/cache-manager.service';
import {Global} from '@iupics-manager/models/global-var';
import {ThemeService} from '@web-desktop/controllers/theme.service';
import {IupicsWidget} from '@web-desktop/models/iupics-widget';
import {MenuItemUI} from '@web-desktop/models/menu-item-ui';
import {Observable, map} from 'rxjs';

@Component({
    selector: 'iu-widget-ui',
    templateUrl: './widget-ui.component.html',
    animations: [
        trigger('enterAnimation', [
            transition(':enter', [
                // :enter is alias to 'void => *'
                style({opacity: 0}),
                animate(500, style({opacity: 1})),
            ]),
            transition(':leave', [
                // :leave is alias to '* => void'
                animate(500, style({opacity: 0})),
            ]),
        ]),
    ],
    styleUrls: ['./widget-ui.component.scss'],
})
export class WidgetUiComponent implements OnInit, AfterViewInit, OnDestroy {
    // @HostBinding('class')
    // class = 'p-col-12 p-md-6 ui-g';

    class = 'p-col-12 p-md-6';


    @ViewChild('widgetVCR', {read: ViewContainerRef, static: true})
    vcr: ViewContainerRef;
    isLoaded = false;
    @Input()
    widget: MenuItemUI;
    iupicsWidget$: Observable<IupicsWidget>;

    @Input()
    index: number;

    @Output()
    deleteWidget = new EventEmitter<number>();

    @Output()
    openTab: EventEmitter<any> = new EventEmitter();
    @Output()
    changeWidgetColor: EventEmitter<any> = new EventEmitter<any>();

    showColor = false;
    showMoreColor = false;
    oneDefaultColorIsSelected = false;
    colorpicked = '';
    defaultColor: string[];

    screenHeight: number;
    screenWidth: number;

    showMenu = false;

    isMobile = Global.isMobile();

    //#region injection
    private widgetCenterService = inject(WidgetCenterService);
    //#endregion

    ViewType = ViewType;

    // readonly ghostSVG$: Observable<SafeHtml>;
    private readonly ghostEltNames: string[] = ['horizontal-chart', 'list-chart', 'pie-chart', 'vertical-chart'];
    readonly ghostElt: string;

    private compoRef: ComponentRef<any>;

    constructor(private themeService: ThemeService, public ref: ElementRef) {
        this.defaultColor = this.themeService.getThemeProperty('colorsPalette');
        const ghostIndex = Global.getRandomNumber(this.ghostEltNames.length);
        this.ghostElt = `url(assets/ghosts/${this.ghostEltNames[ghostIndex]}.svg)`;
    }

    ngOnInit() {
        this.class = this.class + ' p-lg-' + (this.widget.width > 3 ? 4 : this.widget.width * 4);
        this.colorpicked = this.widget.color;
        if (this.defaultColor.indexOf(this.colorpicked) !== -1) {
            this.oneDefaultColorIsSelected = true;
        }
    }

    ngAfterViewInit() {
        this.isLoaded = true;
        if (this.widget.angularClass) {
            const compoRef = this.vcr.createComponent(CacheManagerService.iupics_widgets.get(this.widget.angularClass));
            (compoRef.instance).openTabEmitter.subscribe((item) => this.onOpenTab(item));
            (compoRef.instance).widget = this.widget;
            this.compoRef = compoRef;
        } else {
            this.iupicsWidget$ = this.widgetCenterService
                .getWidgetData(this.widget.menuId)
                .pipe(map((widgetData) => this.handleWidgetData(widgetData)));
        }
    }

    onDelete(index) {
        this.deleteWidget.emit(index);
    }

    changeDefaultColor(color: string) {
        this.colorpicked = color;
        this.onChangeColor(document.createEvent('Event'));
        this.showMenu = false;
    }

    onChangeColor(event: Event) {
        event.stopPropagation();
        this.widget.color = this.colorpicked;
        this.changeWidgetColor.emit({color: this.colorpicked});
        this.showColor = false;
        this.showMoreColor = false;
    }

    onColorPicker($event: { originalEvent: MouseEvent; value: string }) {
        $event.originalEvent.stopPropagation();
        this.colorpicked = $event.value;
    }

    cancelColorChange() {
        this.showColor = false;
        this.showMoreColor = false;
        this.colorpicked = this.widget.color;
    }

    onOpenTab(item: any) {
        this.openTab.emit(item);
    }

    isSelectedColor(color: string) {
        return color === this.colorpicked;
    }

    private handleWidgetData(widgetData: WidgetDataCompiereJson): IupicsWidget {
        let type: ChartType;
        switch (widgetData.dashboardFormatVO.dashboardType) {
            case 'BUC':
                type = ChartType.BUBBLE;
                break;
            case 'CLC':
                type = ChartType.COLUMN;
                break;
            case 'ARC':
                type = ChartType.AREA;
                break;
            case 'SCC':
                type = ChartType.SCATTER;
                break;
            case 'BRC':
                type = ChartType.BAR;
                break;
            case 'LIC':
                type = ChartType.LINE;
                break;
            case 'PIC':
                type = ChartType.PIE;
                break;
            case 'GEN':
                type = ChartType.GEN;
                return new IupicsWidget(
                    this.widget.name,
                    this.widget.description,
                    ViewType.LIST,
                    type,
                    null,
                    this.widget ? (this.widget.color || '#27586B') : '#27586B',
                    widgetData.dashboardFormatVO.width,
                    this.widget.angularClass
                );
        }

        if (type) {
            if (
                type === ChartType.SCATTER ||
                type === ChartType.BUBBLE ||
                type === ChartType.COLUMN ||
                type === ChartType.AREA ||
                type === ChartType.BAR ||
                type === ChartType.LINE
            ) {
                const labelChart = [];
                if (widgetData.dataTable.columns.length > 1) {
                    labelChart.push(widgetData.dataTable.columns[1].label);
                }
                const datasets = [];
                const datasetsXY = [];
                for (let i = 0; i < widgetData.labels.length; i++) {
                    const dataSet = {
                        dataXY: null,
                        data: widgetData.dataSet[i],
                        label: widgetData.labels[i],
                        backgroundColor: [],
                        borderColor: null,
                        pointBorderColor: null,
                        pointBackgroundColor: null,
                        hoverBackgroundColor: [],
                    };
                    if (!isNaN(parseFloat(dataSet.data[0])) && !isNaN(parseFloat(dataSet.data[1]))) {
                        const dataSetXY = {
                            data: [
                                {
                                    // X Value
                                    x: parseFloat(dataSet.data[0]),
                                    // Y Value
                                    y: parseFloat(dataSet.data[1]),
                                    // Bubble radius in pixels (not scaled).
                                    r: Math.sqrt(parseFloat(dataSet.data[2])) * 0.5,
                                    // r: parseFloat(dataSet.data[2])
                                },
                            ],
                            label: widgetData.labels[i],
                            backgroundColor: [],
                            borderColor: null,
                            pointBorderColor: null,
                            pointBackgroundColor: null,
                            hoverBackgroundColor: [],
                        };
                        datasetsXY.push(dataSetXY);
                    }
                    datasets.push(dataSet);
                }
                return new IupicsWidget(
                    this.widget.name,
                    this.widget.description,
                    ViewType.CHART,
                    type,
                    {
                        labels: labelChart,
                        datasets: datasets,
                    },
                    this.widget ? (this.widget.color || '#27586B') : '#27586B',
                    widgetData.dashboardFormatVO.width >3 ?widgetData.dashboardFormatVO.width : 1,
                    undefined,
                    {
                        labels: labelChart,
                        datasets: datasetsXY,
                    }
                );
            } else {
                return new IupicsWidget(
                    this.widget.name,
                    this.widget.description,
                    ViewType.CHART,
                    type,
                    {
                        labels: widgetData.labels,
                        datasets: [
                            {
                                data: widgetData.dataSet,
                                backgroundColor: [],
                                borderColor: null,
                                pointBorderColor: null,
                                pointBackgroundColor: null,
                                hoverBackgroundColor: [],
                            },
                        ],
                    },
                    this.widget ? (this.widget.color || '#27586B') : '#27586B',
                    widgetData.dashboardFormatVO.width >3 ?widgetData.dashboardFormatVO.width : 1
                );
            }
        }
    }

    ngOnDestroy(): void {
        this.compoRef?.destroy();
    }
}
