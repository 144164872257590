import { AfterViewInit, Component, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { PoService } from '@compiere-ws/services/po/po.service';
import { EditTabUiComponent } from '@iupics-components/standard/layouts/edit-tab-ui/edit-tab-ui.component';
import { CacheManagerService } from '@iupics-manager/managers/cache-manager/cache-manager.service';
import { DataStoreService } from '@iupics-manager/managers/data-store/data-store.service';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { UICreatorService } from '@iupics-manager/managers/ui-creator/ui-creator.service';
import { AbstractDataContainer } from '@iupics-manager/models/abstract-datacontainer';
import { IupicsEvent } from '@iupics-manager/models/iupics-event';
import { ContextMenuService } from '@web-desktop/components/workspace/controllers/context-menu/context-menu.service';

@Component({
  selector: 'iu-text-field-ui',
  templateUrl: './text-field-ui.component.html',
  styleUrls: ['./text-field-ui.component.scss'],
})
export class TextFieldUiComponent extends AbstractDataContainer implements OnInit, AfterViewInit {
  @Input() parentTab: EditTabUiComponent;

  @Input() loadingAnimationTimeout: number = null;

  @Input() withColorIndicator = false;
  @Input() colorIndicatorCondition: boolean = null;

  @Input() data: any;
  @Input() value: any;

  adLanguage: string;
  @Input() formatter: Intl.NumberFormat = undefined;

  constructor(
    public elementRef: ElementRef,
    public store: DataStoreService,
    protected connectorService: SecurityManagerService,
    public uiCreatorService: UICreatorService,
    public cmService: ContextMenuService,
    renderer: Renderer2,
    protected po: PoService,
    protected cacheService: CacheManagerService
  ) {
    super(elementRef, connectorService, cmService, store, uiCreatorService, renderer, po, cacheService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    if (this.container?.DOMChildrenComponent) {
      this.container.DOMChildrenComponent.push(this);
    }

    if (this.data?.data?.label) {
      this.label = this.data?.data?.label;
    }

    this.adLanguage = (this.data?.data?.locale ?? this.connectorService.getIupicsUserContext()['#AD_Language']).slice(
      0,
      2
    );
    this.setValue();
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
  }

  onSiblingUpdate(event: IupicsEvent): void {
    this.setValue();
  }

  setValue() {
    const valueNotFormatted = this.parentTab?.dataStored.data[this.data.data?.columnName] ?? this.data?.value ?? null;
    if (typeof valueNotFormatted === 'number') {
      if (this.formatter === undefined) {
        this.formatter = Intl.NumberFormat(this.adLanguage);
      }

      if (this.formatter) {
        this.value = this.formatter?.format(valueNotFormatted ?? 0);
        return;
      }
    }

    this.value = valueNotFormatted;
  }
}
