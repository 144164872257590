import { Directive, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Directive({
  selector: '[iuToggleButton]',
})
export class ToggleButtonDirective {
  constructor(private el: ElementRef<HTMLElement>) {}

  @Input() iuToggleButtonVar: boolean;
  @Input() iuToggleButtonSwitch = false;

  @Output() iuToggleButtonVarChange = new EventEmitter<boolean>();

  @HostListener('document:click', ['$event'])
  clickout(event: MouseEvent) {
    if (!event.composedPath().find((e) => e === this.el.nativeElement.parentElement) && this.iuToggleButtonVar) {
      this.iuToggleButtonVar = false;
      this.iuToggleButtonVarChange.emit(this.iuToggleButtonVar);
    }
  }

  @HostListener('click', ['$event'])
  onClick(event: MouseEvent) {
    this.iuToggleButtonVar = this.iuToggleButtonSwitch === false ? true : !this.iuToggleButtonVar;
    this.iuToggleButtonVarChange.emit(this.iuToggleButtonVar);
  }
}
