import { ComponentRef, Injectable } from '@angular/core';
import { CompiereDataFieldType } from '@compiere-ws/models/compiere-data-json';
import { CompiereWorkflowService } from '@compiere-ws/services/compiere-workflow/compiere-workflow.service';
import { SpecificWindowUiComponent } from '@iupics-components/specific/window/specific-window-ui/specific-window-ui.component';
import { AdditionalInfoUiComponent } from '@iupics-components/standard/layouts/additional-info-ui/additional-info-ui.component';
import { EditTabUiComponent } from '@iupics-components/standard/layouts/edit-tab-ui/edit-tab-ui.component';
import { EditViewUiComponent } from '@iupics-components/standard/layouts/edit-view-ui/edit-view-ui.component';
import { NotesPanelUiComponent } from '@iupics-components/standard/notes/notes-panel-ui/notes-panel-ui.component';
import { AppConfig } from '@iupics-config/app.config';
import { CacheManagerService } from '@iupics-manager/managers/cache-manager/cache-manager.service';
import { UICreatorService } from '@iupics-manager/managers/ui-creator/ui-creator.service';
import { AbstractDataContainer } from '@iupics-manager/models/abstract-datacontainer';
import { AbstractDynamicComponent } from '@iupics-manager/models/abstract-dynamic-component';
import { DynamicComponent } from '@iupics-manager/models/dynamic-component';
import { Global } from '@iupics-manager/models/global-var';
import { IupicsField, IupicsTab } from '@iupics-manager/models/iupics-data';
import { IupicsEvent, IupicsTypeEvent } from '@iupics-manager/models/iupics-event';
import { IupicsMenuType } from '@web-desktop/models/menu-item-ui';
import { cloneDeep } from 'lodash';

@Injectable()
export class WindowFactoryService {
  private editTabSize = 40;
  private max: number;
  private currentTab: EditTabUiComponent;
  private currentProcess: any;

  constructor(
    private uiCreatorService: UICreatorService,
    private workflowService: CompiereWorkflowService,
    private config: AppConfig
  ) {}

  newEventHandler(event: IupicsEvent): void {
    switch (event.type) {
      case IupicsTypeEvent.showGridView:
        this.uiCreatorService.getActualTab(event.item.windowId, event.item.tabId).subscribe((tabUI) => {
          event.item.gridTabFilter = tabUI.firstTab.gridView.gridTabFilter;
          event.item.gridTabValidator = tabUI.firstTab.gridView.gridTabValidator;
          event.item.component = tabUI.firstTab.gridView.component;
          event.item.data = tabUI.firstTab.gridView.data;
          this.addContainerComponent(event.item, event.item.isCssOnComponent);
          event.item.DOMParentComponent.onChildUpdate(event);
        });
        break;

      case IupicsTypeEvent.showEditView:
      case IupicsTypeEvent.showAdditionalInfo:
        this.uiCreatorService.getTab(event.item.windowId, event.item.tabId).subscribe((tabUI) => {
          let viewSize = tabUI.bladeSize;
          let tabsTransformed = [];
          let tabsTransformedAdditional = [];
          if (event.type === IupicsTypeEvent.showEditView) {
            tabsTransformed = tabUI.editTabs;
            tabsTransformedAdditional = tabUI.additionalTabs;
            if (tabsTransformed.length === 0) {
              tabsTransformed = tabsTransformedAdditional;
              tabsTransformedAdditional = [];
            }
          } else {
            viewSize = tabUI.bladeSizeAdditional;
            tabsTransformed = tabUI.additionalTabs;
          }
          event.item.children = [
            {
              container: event.item.container,
              component: 'GridUiComponent',
              cssClass: 'p-col-12 p-col-nopad',
              children: tabsTransformed,
            },
          ];

          const compRef = this.addContainerComponent(event.item, event.item.isCssOnComponent);
          if (compRef) {
            if (compRef.instance instanceof EditViewUiComponent) {
              let viewSizeAd = null;
              if (tabsTransformedAdditional.length > 0) {
                viewSizeAd = tabUI.bladeSizeAdditional;
              }

              const editViewInstance = <EditViewUiComponent>compRef.instance;
              editViewInstance.additionalInfoWidthExpanded = viewSizeAd;
              editViewInstance.linkedComponents = event.item.linkedComponents;
              editViewInstance.isReadOnly = tabUI.firstTab ? tabUI.firstTab.editView.data.isReadOnly : false;
              editViewInstance.isDeleteable = tabUI.firstTab ? tabUI.firstTab.editView.data.isDeleteable : false;
              editViewInstance.isInsertRecord = tabUI.firstTab ? tabUI.firstTab.editView.data.isInsertRecord : false;
              editViewInstance.table_id = tabUI.firstTab ? tabUI.firstTab.editView.data.AD_Table_ID : 0;
              editViewInstance.data = tabUI.firstTab ? tabUI.firstTab.editView.data : null;
            }

            // avoid resize of grid on additionnalInfoShow
            if (event.type !== IupicsTypeEvent.showAdditionalInfo) {
              (<AbstractDynamicComponent>compRef.instance).notifierLinkedComponent.next({
                type: IupicsTypeEvent.collapseEvent,
                item: tabUI.firstTab,
              });
            }
          }

          event.item.DOMParentComponent.onChildUpdate(event);
          if (compRef.instance instanceof EditViewUiComponent) {
            event.item.container.updateContainerZone(viewSize);
          }
          event.item.DOMParentComponent.onChildUpdate({
            type: IupicsTypeEvent.addBreadcrumbItem,
            item: {
              container: null,
              tabId: tabUI.firstTab.tabId,
              data: {
                label: tabUI.firstTab.editView.data.label,
              },
            },
          });
        });
        break;

      case IupicsTypeEvent.showNotesDetail:
        const editView = <EditViewUiComponent>event.item.DOMParentComponent;
        if ((<EditViewUiComponent>event.item.DOMParentComponent).notesDetailVcr.length > 0) {
          const index = editView.DOMChildrenComponent.indexOf(editView.notesDetail);
          if (index !== -1) {
            editView.DOMChildrenComponent.splice(index, 1);
          }
          editView.notesDetailVcr.clear();
          editView.notesDetail = null;

          return undefined;
        }

        const compRef = this.addContainerComponent(event.item, event.item.isCssOnComponent);
        (<NotesPanelUiComponent>compRef.instance).parentTab = editView.editTabs[0];
        event.item.DOMParentComponent.onChildUpdate(event);

        break;

      case IupicsTypeEvent.removeChildEvent:
        this.removeComponent(event.item);
        event.item.DOMParentComponent.onChildUpdate(event);
        event.item.container.updateContainerZone(0);
        break;

      case IupicsTypeEvent.showProductAttributeView:
        this.transformADFieldtoIupics(
          cloneDeep(CacheManagerService.productAttributes_caching.get(event.item.productAttributeId)),
          1
        );
        break;

      case IupicsTypeEvent.showProcess:
        let fieldsTransformed: IupicsField[] = CacheManagerService.process_Params_caching.get(event.item.tabId).fields;
        fieldsTransformed = cloneDeep(fieldsTransformed);
        event.item.children = fieldsTransformed;
        this.addContainerComponent(event.item, event.item.isCssOnComponent);
        break;
      case IupicsTypeEvent.showSpecificWindow:
        this.uiCreatorService.getSpecificWindow(event.item.tabId).subscribe((specificWindow) => {
          (<SpecificWindowUiComponent>event.item.container).buildWindow(specificWindow);
        });
        break;

      default:
        break;
    }
  }

  private addContainerComponent(item: DynamicComponent, isCssOnComponent: boolean = true): ComponentRef<any> {
    if (item.DOMParentComponent.vcr) {
      Global.startPerf(item.DOMParentComponent, 'addContainerComponent.' + item.component);
      let componentRef;
      const componentName = CacheManagerService.iupics_components.get(<string>item.component);
      if (
        item.component === 'AdditionalInfoUiComponent' &&
        (<EditViewUiComponent>item.DOMParentComponent).additionalInfoVcr
      ) {
        componentRef = (<EditViewUiComponent>item.DOMParentComponent).additionalInfoVcr.createComponent(componentName);
        (<EditViewUiComponent>item.DOMParentComponent).additionalInfoComponent = <AdditionalInfoUiComponent>(
          componentRef.instance
        );
      } else if (
        item.component === 'NotesPanelUiComponent' &&
        (<EditViewUiComponent>item.DOMParentComponent).notesDetailVcr
      ) {
        componentRef = (<EditViewUiComponent>item.DOMParentComponent).notesDetailVcr.createComponent(componentName);
        (<EditViewUiComponent>item.DOMParentComponent).notesDetail = <NotesPanelUiComponent>componentRef.instance;
      } else {
        componentRef = item.DOMParentComponent.vcr.createComponent(componentName);
      }

      if (item.component === 'GridViewUiComponent' || item.component === 'EditTabUiComponent') {
        (<AbstractDynamicComponent>componentRef.instance).isReadOnly = item.data.isReadOnly;
        (<AbstractDynamicComponent>componentRef.instance).isInsertRecord = item.data.isInsertRecord;
      }

      if (!item.container) {
        item.container = item.DOMParentComponent.container;
      }

      switch ((<AbstractDynamicComponent>item.container).windowType) {
        case IupicsMenuType.WINDOW:
          (<AbstractDataContainer>componentRef.instance).fieldType = CompiereDataFieldType.FIELD;
          break;
        case IupicsMenuType.PROCESS:
          (<AbstractDataContainer>componentRef.instance).fieldType = CompiereDataFieldType.PROCESS_PARA;
          break;
        case IupicsMenuType.FORM:
          (<AbstractDataContainer>componentRef.instance).fieldType = CompiereDataFieldType.FORM_ITEM;
          break;
      }

      (<AbstractDynamicComponent>componentRef.instance).container = item.container;
      (<AbstractDynamicComponent>componentRef.instance).DOMParentComponent =
        item.DOMParentComponent instanceof AdditionalInfoUiComponent
          ? item.DOMParentComponent.DOMParentComponent
          : item.DOMParentComponent;
      (<AbstractDynamicComponent>componentRef.instance).DOMComponent = componentRef;
      (<AbstractDynamicComponent>componentRef.instance).data = item.data;
      (<AbstractDynamicComponent>componentRef.instance).gridPaginator = item.gridPaginator;
      if (item.tabId) {
        (<AbstractDynamicComponent>componentRef.instance).tabId = item.tabId;
      }

      if (item.gridTabFilter) {
        (<AbstractDynamicComponent>componentRef.instance).gridTabFilter = item.gridTabFilter;
      }
      if (item.initRequest) {
        (<AbstractDynamicComponent>componentRef.instance).initRequest = item.initRequest;
      }
      if (item.gridTabValidator) {
        (<AbstractDynamicComponent>componentRef.instance).gridTabValidator = item.gridTabValidator;
      }

      if (item.zoomInfo) {
        (<AbstractDynamicComponent>componentRef.instance).zoomInfo = item.zoomInfo;
      }
      if (item.parentStore) {
        (<AbstractDynamicComponent>componentRef.instance).parentStore = item.parentStore;
      }
      if (item.parentTab || (item.DOMParentComponent && item.DOMParentComponent.parentTab)) {
        (<AbstractDynamicComponent>componentRef.instance).parentTab = item.parentTab
          ? item.parentTab
          : item.DOMParentComponent.parentTab;
      }
      if (item.zoomTarget) {
        (<AbstractDynamicComponent>componentRef.instance).zoomTarget = item.zoomTarget;
      }
      if (item.parentProcess || (item.DOMParentComponent && item.DOMParentComponent.parentProcess)) {
        (<AbstractDynamicComponent>componentRef.instance).parentProcess = item.parentProcess
          ? item.parentProcess
          : item.DOMParentComponent.parentProcess;
        (<AbstractDataContainer>componentRef.instance).fieldType = CompiereDataFieldType.PROCESS_PARA;
      }
      if (item.zoomTargetData) {
        (<AbstractDynamicComponent>componentRef.instance).zoomTargetData = item.zoomTargetData;
      }
      if (item.linkedComponents) {
        item.linkedComponents.forEach((element) => {
          (<AbstractDynamicComponent>componentRef.instance).addSubscribeOnLinkedComponent(element, componentRef);
        });
      }
      if (isCssOnComponent) {
        componentRef.location.nativeElement.className = item.cssClass;
      } else {
        (<AbstractDynamicComponent>componentRef.instance).cssClass = item.cssClass;
      }
      (<AbstractDynamicComponent>componentRef.instance).componentEmitter.subscribe((event) => {
        this.newEventHandler(event);
      });

      /*additional info */
      item.DOMParentComponent.DOMChildrenComponent.push(<AbstractDynamicComponent>componentRef.instance);
      if (item.children) {
        (<AbstractDynamicComponent>componentRef.instance).children = item.children.filter(
          (child) => child !== undefined
        );
        (<AbstractDynamicComponent>componentRef.instance).children.forEach(
          (child) => (child.DOMParentComponent = <AbstractDynamicComponent>componentRef.instance)
        );
      }
      Global.endPerf(item.DOMParentComponent, 'addContainerComponent.' + item.component);
      return componentRef;
    } else {
      return undefined;
    }
  }

  private removeComponent(item: DynamicComponent): void {
    const componentToRemoved = <AbstractDynamicComponent>item.component;

    const vcrIndex: number = item.DOMParentComponent.vcr.indexOf(componentToRemoved.DOMComponent.hostView);
    const compIndex = item.DOMParentComponent.DOMChildrenComponent.indexOf(componentToRemoved);

    if (vcrIndex === -1) {
      return;
    }

    let length = item.DOMParentComponent.DOMChildrenComponent.length;
    for (let i = compIndex; i < length; i++) {
      item.DOMParentComponent.DOMChildrenComponent[compIndex].onRemoveComponent({
        type: IupicsTypeEvent.removeChildEvent,
        item: {
          container: null,
          tabId: item.DOMParentComponent.DOMChildrenComponent[compIndex].tabId,
          data: {
            label: '',
          },
        },
      });
      item.DOMParentComponent.DOMChildrenComponent.splice(compIndex, 1);
      /*Mise à jour de l'url en cas de suppression d'un élément*/
      if (item.container) {
        item.container.notifyUrlChange();
      }
    }

    length = item.DOMParentComponent.vcr.length;
    for (let i = length - 1; i >= vcrIndex; i--) {
      item.DOMParentComponent.vcr.remove(i);
    }
  }

  private editSize(children: IupicsTab[], isFirst: boolean): number {
    let numbersCol: number[] = [];
    let maxPos = 1;
    const childrenCloned = cloneDeep(children);
    let firstChild = null;
    let maxCols;
    let currentPos;
    //TODO CHECK LOGIC
    if (isFirst) {
      firstChild = childrenCloned.splice(0, 1)[0];
      currentPos = firstChild.editView.data.positionEditTab;
      maxPos = maxPos > firstChild.editView.data.positionEditTab ? maxPos : currentPos;
    }
    childrenCloned.forEach((element) => {
      currentPos = element.gridView.data.positionEditTab;
      maxPos = maxPos > element.gridView.data.positionEditTab ? maxPos : currentPos;
    });
    let colspan;
    numbersCol = firstChild === null ? [] : [firstChild.editView.data.colspan];
    childrenCloned.forEach((element) => {
      colspan = element.gridView.data.colspan;
      numbersCol.push(colspan);
    });
    maxCols = Math.max(...numbersCol);
    return maxCols;
  }

  private transformADFieldtoIupics(children: IupicsField[], nbCol: number): any[] {
    let currentRow = 0;
    let newRow = true;
    const fields = [];
    let fieldsCol = [];
    for (let i = 0; i < nbCol; i++) {
      fieldsCol.push(0);
    }
    let className: string;
    for (let i = 0; i < children.length; i++) {
      if (children[i].component === 'ProgressBarUiComponent' || children[i].component === 'GridViewUiComponent') {
        return children;
      } else {
        if (children[i].component === 'AccordionUiComponent') {
          children[i].children = this.transformADFieldtoIupics(children[i].children, nbCol);
        }

        const currentPos = children[i].data.nbCol;
        const currentColspan = children[i].data.colspan;
        if (!newRow) {
          for (let j = currentPos - 1; j < fieldsCol.length; j++) {
            if (fieldsCol[j]) {
              newRow = true;
            }
          }
        }

        if (newRow) {
          fieldsCol = [];
          for (let m = 0; m < nbCol; m++) {
            fieldsCol.push(0);
          }
          let countOffest = 0;
          let finish = false;
          let k = currentPos - 2;
          while (k > -1 && !finish) {
            if (!fieldsCol[k--]) {
              countOffest++;
            } else {
              finish = true;
            }
          }
          currentRow++;
          className = 'p-col-' + (12 / nbCol) * currentColspan + ' p-offset-' + countOffest * (12 / nbCol);
          if (children[i] && children[i].isRange) {
            className += ' rangeFrom';
          }
          children[i].cssClass = className;
          fields[currentRow] = {
            cssClass: '',
            component: 'RowUiComponent',
            children: [children[i]],
          };
          newRow = false;
        } else {
          let countOffest = 0;
          let finish = false;
          let k = currentPos - 2;
          while (k > -1 && !finish) {
            if (!fieldsCol[k--]) {
              countOffest++;
            } else {
              finish = true;
            }
          }
          children[i].cssClass = 'p-col-' + (12 / nbCol) * currentColspan + ' p-offset-' + countOffest * (12 / nbCol);
          if (children[i].data && children[i].data.fromRange) {
            children[i].cssClass += ' rangeTo';
          }
          fields[currentRow].children.push(children[i]);
        }
        for (let n = currentPos - 1; n < currentPos - 1 + currentColspan; n++) {
          fieldsCol[n] = 1;
        }
      }
    }
    return fields;
  }
}
