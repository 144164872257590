import {
  AfterViewInit,
  Component,
  ComponentRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation,
} from '@angular/core';
import { CompiereDataGridFilterModel, CompiereDataGridFilterType } from '@compiere-ws/models/compiere-data-json';
import { CompiereProcessService } from '@compiere-ws/services/compiere-process/compiere-process.service';
import { OperatorFilterType } from '@iupics-components/models/universal-filter';
import { ViewType } from '@iupics-components/models/view-type.enum';
import { InfoDialogType } from '@iupics-components/specific/window/info-dialog/info-dialog.component';
import { InputSwitchUiComponent } from '@iupics-components/standard/fields/input-switch-ui/input-switch-ui.component';
import { EditViewUtils } from '@iupics-components/standard/layouts/edit-view-ui/utils/edit-view.utils';
import { AppConfig } from '@iupics-config/app.config';
import { ActiveTabManagerService } from '@iupics-manager/managers/active-tab-manager/active-tab-manager.service';
import { CacheManagerService } from '@iupics-manager/managers/cache-manager/cache-manager.service';
import { DataStoreService } from '@iupics-manager/managers/data-store/data-store.service';
import { KeybindStateManagerService } from '@iupics-manager/managers/keybind-state-manager/keybind-state-manager.service';
import { MessageManagerService } from '@iupics-manager/managers/message/message-manager.service';
import { UICreatorService } from '@iupics-manager/managers/ui-creator/ui-creator.service';
import { AbstractDynamicComponent } from '@iupics-manager/models/abstract-dynamic-component';
import { Global } from '@iupics-manager/models/global-var';
import { IupicsSpecificWindow } from '@iupics-manager/models/iupics-data';
import { IupicsTypeEvent } from '@iupics-manager/models/iupics-event';
import { RoleUI } from '@iupics-manager/models/user-account';
import { Utils } from '@iupics-util/tools/util';
import { TranslateService } from '@ngx-translate/core';
import { MenuTopComponent } from '@web-desktop/components/menu-top/components/menu-top-ui/menu-top-ui.component';
import { TabGroupUiComponent } from '@web-desktop/components/menu-top/components/tab-group-ui/tab-group-ui.component';
import { TabUiComponent } from '@web-desktop/components/menu-top/components/tab-ui/tab-ui.component';
import { ContextMenuService } from '@web-desktop/components/workspace/controllers/context-menu/context-menu.service';
import { WebDesktopService } from '@web-desktop/controllers/web-desktop.service';
import { URLParams } from '@web-desktop/models/url-params';
import { Dialog } from 'primeng/dialog';
import { TabSplitViewUiComponent } from '../../tab-split-view-ui/tab-split-view-ui.component';
import { DashboardComponent } from '../dashboard/dashboard.component';

@Component({
  selector: 'iu-workspace-ui',
  templateUrl: './workspace-ui.component.html',
  styleUrls: ['./workspace-ui.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class WorkspaceComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('vcrTabSplitView', { read: ViewContainerRef, static: true })
  vcrTabSplitView: ViewContainerRef;
  @ViewChild('dialogTheme', { static: true })
  dialogTheme: Dialog;
  @ViewChild('inputSwitch', { static: true })
  inputSwitch: InputSwitchUiComponent;
  @ViewChild('menuTop', { static: true })
  menuTop: MenuTopComponent;
  @Input()
  urlParams: URLParams;
  tabSplitViews: ComponentRef<TabSplitViewUiComponent | DashboardComponent>[] = [];
  mapViewByRole: Map<RoleUI, any> = new Map();

  isMenuTopDisplay: boolean;
  monochromelabel = 'Image';
  activeTab: TabGroupUiComponent | TabUiComponent = null;
  isTab: boolean = true;
  dashboardIsActive: boolean = true;
  displayContextPanel: boolean = false;
  isCopiedToClipBoard: boolean = false;
  contextPanelInfo: { title: string; userContext: any[]; windowContexts: any[] };
  void: any;

  isDialogDisplay = false;
  isBackgroundImage: boolean;
  isActualBackgroundImage: boolean;

  actualTheme: { name?: string; path: string };
  theme: { name?: string; path: string };
  themes: { name?: string; path: string }[];

  actualBackground: { source?: string; alt: string };
  background: { source?: string; alt: string };
  backgrounds: { source?: string; alt: string }[] = [];

  actualColor: string;
  color: string;
  subscriptions: any[] = [];

  @Output()
  validateLogoutEmitter: EventEmitter<any> = new EventEmitter();

  isViewAllComponent: boolean;

  dialogInfo = InfoDialogType.INFO;
  dialogError = InfoDialogType.ERROR;
  dialogConfirm = InfoDialogType.CONFIRM;
  dialogConfirmYN = InfoDialogType.CONFIRM_YESNO;

  bannerCloseDelay = 5000; // 5sec default

  errorMessages: any[] = [];
  /*permet de garder la référence du composant contenu par le tabuicomponent */
  linkedComponentToTabMap: Map<string, AbstractDynamicComponent> = new Map<string, AbstractDynamicComponent>();
  constructor(
    private webDesktopService: WebDesktopService,
    private dataStore: DataStoreService,
    private uiCreatorService: UICreatorService,
    public cmService: ContextMenuService,
    public processService: CompiereProcessService,
    private activeTabManager: ActiveTabManagerService,
    private keybindStateManager: KeybindStateManagerService,
    private translateService: TranslateService,
    private config: AppConfig,
    private renderer2: Renderer2,
    private cacheService: CacheManagerService
  ) {}

  ngOnInit() {
    this.subscriptions.push(
      MessageManagerService.messageSource.subscribe((messages) => {
        this.errorMessages.push(messages);
        if (this.errorMessages.length > 15) {
          this.errorMessages.shift();
        }
      })
    );
    this.bannerCloseDelay = this.config.getConstant('BannerComponent#closeDelay');
  }

  ngAfterViewInit() {
    Global.workspace = this;
    this.restoreEnvironment();
    this.handleUrlParams();
  }
  handleUrlParams() {
    if (this.urlParams.id) {
      this.subscriptions.push(
        this.uiCreatorService.getIupicsMenuFromWindow(this.urlParams.id, this.urlParams.type).subscribe((menu) => {
          if (menu) {
            let dataGridRequest = this.urlParams.dataGridRequest;
            if (this.urlParams.recordId) {
              const splittedRecord = this.urlParams.recordId.split(',');
              if (splittedRecord.length > 1) {
                const filter: CompiereDataGridFilterModel = {
                  filterType: CompiereDataGridFilterType.NUMBER,
                  operators: [OperatorFilterType.EQUALS],
                  values: [[splittedRecord[1]]],
                };
                if (!dataGridRequest) {
                  dataGridRequest = { filterModel: {} };
                }
                if (
                  dataGridRequest.filterModel[splittedRecord[0]] &&
                  dataGridRequest.filterModel[splittedRecord[0]].values[0]
                ) {
                  if (!dataGridRequest.filterModel[splittedRecord[0]].values[0].includes(splittedRecord[1])) {
                    dataGridRequest.filterModel[splittedRecord[0]].values[0].push(splittedRecord[1]);
                  }
                } else {
                  dataGridRequest.filterModel[splittedRecord[0]] = filter;
                }
              }
            }
            this.menuTop.createTab({
              actionID: menu.actionID,
              menuType: menu.menuType,
              name: menu.name,
              iconClass: menu ? menu.iconClass : '',
              openedRecordId: this.urlParams.recordId,
              othersRecordId: this.urlParams.othersRecordId,
              viewType: this.urlParams.viewType,
              dataGridRequest: dataGridRequest,
              processParamsMap: this.urlParams.processParamsMap,
              ctx: this.urlParams.ctx,
              zoomInfo: this.urlParams.zoomInfo,
            });
            this.urlParams = {
              id: null,
              type: null,
              viewType: null,
              recordId: null,
              viewData: null,
              processParamsMap: null,
              othersRecordId: null,
              dataGridRequest: null,
              ctx: null,
            };
          } else {
            throw new Error(this.translateService.instant('workspace.accessDenied'));
          }
        })
      );
    }
  }

  openTargetSearch(target) {
    // check si ce n'est pas un point de menu
    if (target.cat.id === -1) {
      this.urlParams.id = target.source.id;
      this.urlParams.type = target.source.menuType ? target.source.menuType : 'Window';
      if (target.source.menuType === 'Window') {
        this.urlParams.viewType = ViewType.GRID;
      }
    } else {
      this.urlParams.id = target.cat.id;
      this.urlParams.recordId = target.source.id;
      this.urlParams.type = target.source.menuType ? target.source.menuType : 'Window';
      this.urlParams.viewType = ViewType.GRID;
      this.urlParams.zoomInfo = target.zoomInfo;
    }

    if (target?.ctx) {
      this.urlParams.ctx = target.ctx;
    }

    this.handleUrlParams();
  }

  restoreEnvironment(role?: RoleUI) {
    if (role) {
      this.uiCreatorService.resetCachingData();
      this.cacheService.clearCache();
    }
    this.restoreWorkspace();
    this.dataStore.cleanDataStore();
    this.subscriptions.push(
      this.uiCreatorService.getIupicsMenus().subscribe(),
      this.uiCreatorService.getIupicsWidgets().subscribe()
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub?.unsubscribe());
    this.tabSplitViews.forEach((cr) => cr?.destroy());
  }

  restoreWorkspace() {
    this.activeTab = null;
    this.isTab = true;
    // this.detachAll(role);
    this.tabSplitViews.forEach((cr) => cr?.destroy());
    this.tabSplitViews = [];
    this.menuTop.unactiveAll();
    this.menuTop.restoreTabsBar();
    this.createDashBoard();
  }

  subscription() {
    this.subscriptions.push(
      this.webDesktopService.emitter.subscribe((event) => {
        switch (event.type) {
          case IupicsTypeEvent.UNIVERSAL_SEARCH:
            this.openWindow(event);
            break;
        }
      })
    );
  }

  updateWorkspace(isMenuTopDisplay: boolean): void {
    this.isMenuTopDisplay = isMenuTopDisplay;
  }

  generateWindow(tab: TabUiComponent) {
    if (Global.isMobile()) {
      this.closeTab();
    }
    if (tab.menuType && tab.menuType === 'Window') {
      const observ = this.uiCreatorService.getWindow(tab.actionID);
      this.subscriptions.push(
        observ.subscribe((tabs) => {
          tab.isLoading = false;
          this.setActiveTab(tab);
        })
      );
    } else if (tab.menuType && tab.menuType === 'Process') {
      this.setActiveTab(tab);
    } else if (tab.menuType && tab.menuType === 'Form') {
      this.subscriptions.push(
        this.uiCreatorService.getSpecificWindow(tab.actionID).subscribe((response) => {
          tab.isLoading = false;
          this.setActiveTab(tab, response);
        })
      );
    } else if (tab.menuType && tab.menuType === 'Report') {
      this.setActiveTab(tab);
    }
  }

  openGroupTab(tabGroupRef) {
    const tabs: TabUiComponent[] = [];
    tabGroupRef.components.forEach((element) => {
      tabs.push(element.instance);
    });
    (this.tabSplitViews[1].instance as TabSplitViewUiComponent).detachAll();
    (this.tabSplitViews[1].instance as TabSplitViewUiComponent).attach({
      activeTab: this.activeTab,
      tabs: tabs,
      isTab: this.isTab,
    });
  }

  openActiveWindow() {
    this.clearModalsAndOverlays();
    if (this.tabSplitViews.length === 1) {
      this.vcrTabSplitView.detach();
      this.createTabSplitView();
    } else {
      if (this.dashboardIsActive) {
        this.swapToSplitView();
      }
      if (this.activeTab !== null) {
        const tabs: TabUiComponent[] = [];
        if (this.activeTab instanceof TabGroupUiComponent) {
          this.activeTab.components.forEach((element) => {
            tabs.push(element.instance);
          });
        } else {
          tabs.push(this.activeTab);
        }
        if (this.tabSplitViews[1]) {
          (this.tabSplitViews[1].instance as TabSplitViewUiComponent).detachAll();
          (this.tabSplitViews[1].instance as TabSplitViewUiComponent).attach({
            activeTab: this.activeTab,
            tabs: tabs,
            isTab: this.isTab,
          });
        }
      } else {
        (this.tabSplitViews[1].instance as TabSplitViewUiComponent).detachAll();
      }
    }
    this.keybindStateManager.setFocus(this.activeTab.id);
  }

  createTabSplitView() {
    const componentRef = this.vcrTabSplitView.createComponent(TabSplitViewUiComponent);
    const tabs = [];
    if (this.activeTab instanceof TabGroupUiComponent) {
      this.activeTab.components.forEach((element) => {
        tabs.push(element.instance);
      });
    } else {
      tabs.push(this.activeTab);
    }
    (<TabSplitViewUiComponent>componentRef.instance).activeTab = this.activeTab;
    (<TabSplitViewUiComponent>componentRef.instance).tabs = tabs;
    (<TabSplitViewUiComponent>componentRef.instance).isTab = this.isTab;
    (<TabSplitViewUiComponent>componentRef.instance).destroyEmitter.subscribe(() => {
      this.destroyView();
    });
    (<TabSplitViewUiComponent>componentRef.instance).swapToDashBoardEmitter.subscribe(() => {
      this.swapToDashBoard();
    });
    this.tabSplitViews.push(componentRef);

    this.dashboardIsActive = false;
  }

  createDashBoard() {
    this.vcrTabSplitView.detach();
    this.tabSplitViews.forEach((cr) => cr?.destroy());
    this.tabSplitViews = [];
    const componentRef = this.vcrTabSplitView.createComponent(DashboardComponent);
    (<DashboardComponent>componentRef.instance).openTabEmitter.subscribe((response: any) => {
      this.urlParams.id = response.actionID;
      this.urlParams.viewType = ViewType.GRID;
      this.urlParams.type = response.menuType;
      this.urlParams.recordId = response.openedRecordId;
      this.urlParams.othersRecordId = response.othersRecordId;
      this.urlParams.dataGridRequest = response.dataGridRequest;
      this.handleUrlParams();
    });
    this.dashboardIsActive = true;
    this.tabSplitViews.push(componentRef);
  }

  clearModalsAndOverlays() {
    Global.activeModals
      .filter((m) => m.isModalDisplay && m.containerViewChild)
      .forEach((modal) => {
        this.renderer2.addClass(modal.containerViewChild.nativeElement, 'hidden');
        modal.close(null, true);
      });
    Global.activeOverlays
      .filter((o) => o.overlayVisible && o.container)
      .forEach((overlay) => {
        this.renderer2.addClass(overlay.container, 'hidden');
        overlay.hide();
      });
  }
  closeTab() {
    if (this.activeTab) {
      if (this.tabSplitViews[1]) {
        (this.tabSplitViews[1].instance as TabSplitViewUiComponent).removeSplitViewRef({
          tabId: this.activeTab.id,
          tabUI: this.activeTab,
        });
      }
    }
  }
  swapToDashBoard() {
    if (Global.isMobile()) {
      this.closeTab();
    }
    this.clearModalsAndOverlays();
    this.keybindStateManager.setFocus('');
    this.vcrTabSplitView.detach();
    this.vcrTabSplitView.insert(
      this.tabSplitViews.find((view) => view.instance instanceof DashboardComponent).hostView
    );
    (<DashboardComponent>(
      this.tabSplitViews.find((view) => view.instance instanceof DashboardComponent).instance
    )).refreshDashboard();
    this.menuTop.unactiveAll();
    this.dashboardIsActive = true;
    this.activeTabManager.setActiveTab();
    Utils.onTabChange();
  }

  swapToSplitView() {
    this.vcrTabSplitView.detach();
    this.vcrTabSplitView.insert(
      this.tabSplitViews.find((view) => view.instance instanceof TabSplitViewUiComponent).hostView
    );
    this.dashboardIsActive = false;
  }
  destroyView() {
    this.tabSplitViews = this.tabSplitViews.filter((view) => view.instance instanceof DashboardComponent);
    this.swapToDashBoard();
  }
  detachAll(role: RoleUI) {
    if (this.mapViewByRole.get(role)) {
      this.tabSplitViews = this.mapViewByRole.get(role);
      this.swapToDashBoard();
    } else {
      this.createDashBoard();
    }
  }

  openWindow(event) {
    if (event.menuType === 'Window') {
      if (this.tabSplitViews.length === 0) {
        this.createTabSplitView();
      } else {
        (this.tabSplitViews[1].instance as TabSplitViewUiComponent).detachAll();
        (this.tabSplitViews[1].instance as TabSplitViewUiComponent).attach({
          activeTab: this.activeTab,
          tabs: [this.activeTab],
          isTab: this.isTab,
        });
      }

      document.body.style.cursor = '';
    }
  }

  closeWindow(data: { tabId: string; tabUI: any }) {
    if (this.tabSplitViews[1]) {
      (this.tabSplitViews[1].instance as TabSplitViewUiComponent).removeSplitViewRef(data);
    }
  }

  handleEvent(event: { originalEvent: MouseEvent; item: any }) {
    if (event.item.id === 'change-theme') {
    }
    event.originalEvent.preventDefault();
  }

  viewAllComponent() {
    this.isViewAllComponent = !this.isViewAllComponent;
  }

  setActiveTab(component: any, specificWindow?: IupicsSpecificWindow) {
    this.activeTab = component;
    if (this.activeTab) {
      this.isTab = component instanceof TabUiComponent || component === null;
      this.menuTop.unactiveAll();
      this.activeTab.isActive = true;
      if (specificWindow) {
        this.activeTab.angularClass = specificWindow.angularClass;
      }
    }
    this.activeTabManager.setActiveTab(this.activeTab);
    this.openActiveWindow();
  }

  closeErrorMessage(index: number) {
    this.errorMessages.splice(index, 1);
  }

  showContextPanel(context: any = {}) {
    /*Création du contexte de la fenêtre*/
    const windowContextArray: any[] = [];
    if (this.activeTab && !this.dashboardIsActive) {
      if (this.activeTab['components']) {
        (<TabGroupUiComponent>this.activeTab).components.forEach((tab) => {
          this.addTabContextToArray(windowContextArray, tab.instance);
        });
      } else {
        this.addTabContextToArray(windowContextArray, <TabUiComponent>this.activeTab);
      }
    }
    /*Création du contexte de l'utilisateur*/
    const userContextFormated = [];
    Object.keys(context)
      .sort((i1, i2) => (i1 < i2 ? -1 : i1 > i2 ? 1 : 0))
      .forEach((key) => userContextFormated.push({ key: key, value: context[key] }));
    this.contextPanelInfo = {
      title: 'workspace.contextPanel.title',
      userContext: userContextFormated,
      windowContexts: windowContextArray,
    };
    this.displayContextPanel = true;
  }
  addTabContextToArray(windowContextArray: any[], tab: TabUiComponent) {
    const windowContext = this.linkedComponentToTabMap[tab.id].getWindowContext();
    const windowContextFormated = [];
    Object.keys(windowContext)
      .sort((i1, i2) => (i1 < i2 ? -1 : i1 > i2 ? 1 : 0))
      .forEach((key) => {
        if (windowContext[key] !== undefined && windowContext[key] !== null) {
          windowContextFormated.push({
            key: key,
            value: windowContext[key]
              ? windowContext[key].id !== undefined && windowContext[key].id !== null
                ? windowContext[key].id
                : windowContext[key] instanceof Object
                ? JSON.stringify(windowContext[key])
                : windowContext[key]
              : windowContext[key],
          });
        }
      });
    windowContextArray.push({ windowName: tab.name, values: windowContextFormated });
  }
  closeContextPanel() {
    this.displayContextPanel = false;
  }
  copyToClipBoard(event, target?: string) {
    event.stopPropagation();
    let fullArray = '';
    fullArray = this.generateContextString(target);
    EditViewUtils.copyToClipboard(fullArray);
    EditViewUtils.copyToClipboard(fullArray);
    if (this.hasOwnProperty('isCopiedToClipBoard')) {
      this.isCopiedToClipBoard = true;
      setTimeout(() => {
        this.isCopiedToClipBoard = false;
      }, 3000);
    }
  }
  generateContextString(target?: string) {
    let fullArray =
      '(' +
      this.translateService.instant('workspace.contextPanel.UserContextTitle') +
      ')' +
      '\n' +
      this.contextPanelInfo.userContext.map((kValue) => kValue.key + ': ' + kValue.value).join('\n');
    this.contextPanelInfo.windowContexts
      .filter((windowContext) => target === 'user' || windowContext.windowName === target)
      .forEach((windowContext) => {
        const windowNameString = windowContext.windowName;
        const windowContextString = windowContext.values.map((kValue) => kValue.key + ': ' + kValue.value).join('\n');
        fullArray +=
          '\n\n\n' +
          '(' +
          this.translateService.instant('workspace.contextPanel.WindowContextTitle') +
          ': ' +
          windowNameString +
          ')' +
          '\n' +
          windowContextString;
      });
    return fullArray;
  }
}
