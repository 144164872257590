import { Pipe, PipeTransform } from '@angular/core';
import { CompiereDataGridFilterType } from '@compiere-ws/models/compiere-data-json';
import { OperatorFilterAutocomplete } from '@iupics-components/models/autocomplete-interfaces';

@Pipe({
  name: 'operatorFilter',
})
export class OperatorFilterPipe implements PipeTransform {
  transform(
    operators: { items: OperatorFilterAutocomplete[] },
    filterType: CompiereDataGridFilterType
  ): { items: OperatorFilterAutocomplete[] } {
    if (!filterType) {
      return operators;
    }
    return { items: operators.items.filter((operator) => operator.operator.filterType === filterType) };
  }
}
