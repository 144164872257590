import {
  AfterViewInit,
  Component,
  ComponentRef,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { ValuePreferencesService } from '@compiere-ws/services/value-preference/value-preference.service';
import { ViewType } from '@iupics-components/models/view-type.enum';
import { SpecificWindowUiComponent } from '@iupics-components/specific/window/specific-window-ui/specific-window-ui.component';
import { GridViewUiComponent } from '@iupics-components/standard/grid/grid-view-ui/grid-view-ui.component';
import { BladeUiComponent } from '@iupics-components/standard/layouts/blade-ui/blade-ui.component';
import { EditViewUiComponent } from '@iupics-components/standard/layouts/edit-view-ui/edit-view-ui.component';
import { KeybindStateManagerService } from '@iupics-manager/managers/keybind-state-manager/keybind-state-manager.service';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { AbstractDynamicComponent } from '@iupics-manager/models/abstract-dynamic-component';
import { Global } from '@iupics-manager/models/global-var';
import { IupicsTypeEvent } from '@iupics-manager/models/iupics-event';
import { TabGroupUiComponent } from '@web-desktop/components/menu-top/components/tab-group-ui/tab-group-ui.component';
import { TabUiComponent } from '@web-desktop/components/menu-top/components/tab-ui/tab-ui.component';
import { SplitManagerService } from '@web-desktop/controllers/split-manager/split-manager.service';
import { SplitComponent } from 'angular-split';
import { TabSplitViewContentUiComponent } from '../components/tab-split-view-content-ui/tab-split-view-content-ui.component';

@Component({
  selector: 'iu-tab-split-view-ui',
  templateUrl: './tab-split-view-ui.component.html',
  styleUrls: ['./tab-split-view-ui.component.scss'],
})
export class TabSplitViewUiComponent implements OnInit, AfterViewInit, OnDestroy {
  static cpt = 0;
  id: string;
  draggedSplitArea: ViewContainerRef;
  elementSwapped: any;
  hasState: Boolean = false;
  @Input()
  isTab: Boolean;
  @Input()
  tabs: TabUiComponent[];
  @Input()
  activeTab: any;
  splitViews: any[] = [];
  isSplitAreaTop1Visible: Boolean = true;
  isSplitAreaTop2Visible: Boolean = false;
  isSplitAreaBot1Visible: Boolean = false;
  isSplitAreaBot2Visible: Boolean = false;

  @ViewChild('splitAreaTop1', { read: ElementRef })
  splitAreaTop1: ElementRef;
  @ViewChild('splitAreaTop2', { read: ElementRef })
  splitAreaTop2: ElementRef;
  @ViewChild('splitAreaBot1', { read: ElementRef })
  splitAreaBot1: ElementRef;
  @ViewChild('splitAreaBot2', { read: ElementRef })
  splitAreaBot2: ElementRef;
  @ViewChild('splitViewContentTop1', { read: ViewContainerRef })
  vcrSplitViewContentTop1: ViewContainerRef;
  @ViewChild('splitViewContentTop2', { read: ViewContainerRef })
  vcrSplitViewContentTop2: ViewContainerRef;
  @ViewChild('splitViewContentBot1', { read: ViewContainerRef })
  vcrSplitViewContentBot1: ViewContainerRef;
  @ViewChild('splitViewContentBot2', { read: ViewContainerRef })
  vcrSplitViewContentBot2: ViewContainerRef;
  @ViewChild('mainSplit', { read: SplitComponent })
  mainSplit: SplitComponent;

  @Output()
  destroyEmitter: EventEmitter<any> = new EventEmitter();
  @Output()
  swapToDashBoardEmitter: EventEmitter<any> = new EventEmitter();

  private componentRefs: ComponentRef<any>[] = [];

  constructor(
    private keybindStateManager: KeybindStateManagerService,
    private splitManager: SplitManagerService,
    private renderer: Renderer2,
    private valuePref: ValuePreferencesService,
    private securityManager: SecurityManagerService
  ) {}

  ngOnInit() {
    Global.startPerf(this);
    this.id = 'WindowView' + TabSplitViewUiComponent.cpt;
    TabSplitViewUiComponent.cpt++;
  }
  ngAfterViewInit() {
    this.init();
    Global.endPerf(this);
  }
  removeSplitViewRef(data: { tabId: string; tabUI: any }) {
    const splitToRemove = this.splitViews.find((splitView) => splitView.instance.tab.id === data.tabId);
    if (splitToRemove) {
      const editView: EditViewUiComponent = splitToRemove.instance.contentRef.instance.DOMChildrenComponent.find(
        (child) => child instanceof EditViewUiComponent
      );
      if (editView) {
        editView.checkBeforeClose(document.createEvent('Event'), false, () => {
          splitToRemove.destroy();
          this.splitViews = this.splitViews.filter((splitView) => splitView.instance.tab.id !== data.tabId);
          if (this.splitViews.length === 0) {
            this.destroyEmitter.emit();
          }
          data.tabUI.destroyTab();
        });
      } else {
        splitToRemove.destroy();
        this.splitViews = this.splitViews.filter((splitView) => splitView.instance.tab.id !== data.tabId);
        if (this.splitViews.length === 0) {
          this.destroyEmitter.emit();
        }
        data.tabUI.destroyTab();
      }
    }
  }
  displayActiveArea() {
    this.isSplitAreaTop1Visible =
      !this.vcrSplitViewContentTop1 || this.vcrSplitViewContentTop1.length === 0 ? false : true;
    this.isSplitAreaTop2Visible =
      !this.vcrSplitViewContentTop2 || this.vcrSplitViewContentTop2.length === 0 ? false : true;
    if (this.tabs.length > 2) {
      this.isSplitAreaBot1Visible =
        !this.vcrSplitViewContentBot1 || this.vcrSplitViewContentBot1.length === 0 ? false : true;
      this.isSplitAreaBot2Visible =
        !this.vcrSplitViewContentBot2 || this.vcrSplitViewContentBot2.length === 0 ? false : true;
    }
  }
  displayAllArea() {
    this.isSplitAreaTop1Visible = true;
    this.isSplitAreaTop2Visible = this.tabs.length > 1;
    this.isSplitAreaBot1Visible = this.tabs.length > 2;
    this.isSplitAreaBot2Visible = this.tabs.length === 4;

    if (this.tabs.length === 2) {
      const ctx = this.securityManager.getIupicsUserContext();
      const key = 'P|APIZ_SPLIT';
      let value: number | string = isNaN(parseInt(ctx[key], 10)) ? 1 : parseInt(ctx[key], 10);
      value = String(value !== null && value !== undefined ? value + 1 : 1);
      const ad_Client_ID = isNaN(parseInt(ctx['#AD_Client_ID'], 10)) ? 0 : parseInt(ctx['#AD_Client_ID'], 10);
      const sub = this.valuePref
        .saveValuePreference(
          {
            attribute: 'APIZ_SPLIT',
            value,
            ad_Client_ID,
            ad_Org_ID: 0,
            ad_User_ID: 0,
            ad_Window_ID: 0,
            ad_Process_ID: 0,
            ad_Form_ID: 0,
          },
          false
        )
        .subscribe((res) => {
          if (res) {
            ctx[key] = value;
            this.securityManager.setIupicsUserContext(ctx);
          }
          sub.unsubscribe();
        });
    }
  }
  init() {
    this.displayAllArea();
    setTimeout(() => {
      this.attachCalendar();
      if (this.tabs.length > 0) {
        if (this.tabs.length === 1 && this.tabs[0].splitViewRef === null) {
          const componentRef = this.vcrSplitViewContentTop1.createComponent(TabSplitViewContentUiComponent);
          (<TabSplitViewContentUiComponent>componentRef.instance).tab = this.tabs[0];
          componentRef.instance.activeTab = this.activeTab;
          componentRef.instance.draggedSplitArea = this.draggedSplitArea;
          componentRef.instance.elementSwapped = this.elementSwapped;
          componentRef.instance.dragEndEmitter.subscribe((data) => {
            this.dragEnd(data);
          });
          this.splitViews.push(componentRef);
          this.tabs[0].splitViewRef = this.vcrSplitViewContentTop1.get(
            this.vcrSplitViewContentTop1.indexOf(componentRef.hostView)
          );
          this.displayActiveArea();
          componentRef.changeDetectorRef.detectChanges();
          this.componentRefs.push(componentRef);
        } else {
          let i = 0;
          let order = 0;
          while (this.tabs[i]) {
            order = i !== 1 ? order + 2 : 0;
            this.insertToVcr(i);
            const splitViewContentRef = this.splitViews.find(
              (svcRef) => (<TabUiComponent>svcRef.instance.tab).domWinId === this.tabs[i].domWinId
            );
            if (splitViewContentRef) {
              const splitViewContent = splitViewContentRef.instance;
              if (splitViewContent.contentRef.instance instanceof BladeUiComponent) {
                // Update grid size if not loaded completly before detaching it from dom
                splitViewContent.contentRef.instance.DOMChildrenComponent.forEach((child) =>
                  (<AbstractDynamicComponent>child).onChildUpdate({
                    type: IupicsTypeEvent.showGridView,
                    item: null,
                  })
                );
              } else if (splitViewContent.contentRef.instance instanceof SpecificWindowUiComponent) {
                (<SpecificWindowUiComponent>splitViewContent.contentRef.instance).gridViews.forEach((gridView) => {
                  gridView.GridTabInfinityScrollUiComponent.allColumns(true);
                });
              }
            }
            this.displayActiveArea();
            i++;
          }
        }
      }
    }, 10);
  }

  keepScrollPosition(index: number) {
    const splitViewContentRef = this.splitViews.find(
      (splitView) =>
        (<TabSplitViewContentUiComponent>splitView.instance).tab.splitViewRef === this.tabs[index].splitViewRef
    );
    if (splitViewContentRef) {
      const splitViewContent = splitViewContentRef.instance;
      (<TabSplitViewContentUiComponent>splitViewContent).updateBlade();
    }
  }

  notifySplitViewContentUrlChange(index: number) {
    const splitViewContentRef = this.splitViews.find(
      (splitView) =>
        (<TabSplitViewContentUiComponent>splitView.instance).tab.splitViewRef === this.tabs[index].splitViewRef
    );
    if (splitViewContentRef) {
      const splitViewContent = splitViewContentRef.instance;
      (<TabSplitViewContentUiComponent>splitViewContent).notifyUrlChange();
    }
  }

  insertToVcr(index: number) {
    switch (index) {
      case 0:
        this.keepScrollPosition(index);
        this.notifySplitViewContentUrlChange(index);
        if (!this.tabs[index].splitViewRef.destroyed) {
          this.vcrSplitViewContentTop1.insert(this.tabs[index].splitViewRef);
        }
        break;
      case 1:
        this.keepScrollPosition(index);
        if (!this.tabs[index].splitViewRef.destroyed) {
          this.vcrSplitViewContentTop2.insert(this.tabs[index].splitViewRef);
        }
        break;
      case 2:
        this.keepScrollPosition(index);
        if (!this.tabs[index].splitViewRef.destroyed) {
          this.vcrSplitViewContentBot1.insert(this.tabs[index].splitViewRef);
        }
        break;
      case 3:
        this.keepScrollPosition(index);
        if (!this.tabs[index].splitViewRef.destroyed) {
          this.vcrSplitViewContentBot2.insert(this.tabs[index].splitViewRef);
        }
        break;
    }
  }
  detachAll() {
    this.keybindStateManager.setFocus('');
    if (this.vcrSplitViewContentTop1) {
      while (this.vcrSplitViewContentTop1.length > 0) {
        this.vcrSplitViewContentTop1.detach();
      }
    }
    if (this.vcrSplitViewContentTop2) {
      while (this.vcrSplitViewContentTop2.length > 0) {
        this.vcrSplitViewContentTop2.detach();
      }
    }
    if (this.vcrSplitViewContentBot1) {
      while (this.vcrSplitViewContentBot1.length > 0) {
        this.vcrSplitViewContentBot1.detach();
      }
    }
    if (this.vcrSplitViewContentBot2) {
      while (this.vcrSplitViewContentBot2.length > 0) {
        this.vcrSplitViewContentBot2.detach();
      }
    }
    this.detachCalendar();
  }

  /**
   * Sauvegarde l'état des calendrier detaché
   */
  detachCalendar() {
    this.splitViews.forEach((tabSplitViewContent) => {
      if (
        tabSplitViewContent.instance.contentRef &&
        tabSplitViewContent.instance.contentRef.instance instanceof BladeUiComponent
      ) {
        const blade: BladeUiComponent = tabSplitViewContent.instance.contentRef.instance;
        const gridView: GridViewUiComponent = <GridViewUiComponent>blade.DOMChildrenComponent[0];

        if (gridView && gridView.viewType === ViewType.CALENDAR) {
          gridView.calendarViewUiComponent.handleDetach();
        }
      }
    });
  }

  /**
   * Reprend l'état du calendrier affiché, s'il est affiché
   */
  attachCalendar() {
    this.tabs.forEach((tabui: TabUiComponent) => {
      const tabSplitViewContent = this.splitViews.find((compoRef) => compoRef.instance.tab.id === tabui.id);
      if (tabSplitViewContent && tabSplitViewContent.instance.contentRef.instance instanceof BladeUiComponent) {
        const blade: BladeUiComponent = tabSplitViewContent.instance.contentRef.instance;
        const gridView: GridViewUiComponent = <GridViewUiComponent>blade.DOMChildrenComponent[0];
        if (gridView && gridView.viewType === ViewType.CALENDAR) {
          gridView.calendarViewUiComponent.handleInsert();
        }
      }
    });
  }

  attach(data) {
    this.activeTab = data.activeTab;
    this.tabs = data.tabs;
    this.isTab = data.isTab;
    this.init();
  }

  dragStart(ev) {
    ev.originalEvent.dataTransfer.setData('fireEvent', 'draggend');
    const toElement = ev.originalEvent.toElement ? ev.originalEvent.toElement : ev.originalEvent.originalTarget;
    if (toElement && toElement.className !== 'p-treenode-content') {
      this.draggedSplitArea = ev.vcr;
      ev.originalEvent.target.classList.add('dragged');
    }
  }

  dragEnd(ev) {
    ev.originalEvent.target.classList.remove('dragged');
    this.draggedSplitArea = null;
    this.elementSwapped = null;
    if (this.splitAreaTop1) {
      this.renderer.setAttribute(this.splitAreaTop1.nativeElement, 'draggable', 'false');
    }
    if (this.splitAreaTop2) {
      this.renderer.setAttribute(this.splitAreaTop2.nativeElement, 'draggable', 'false');
    }
    if (this.splitAreaBot1) {
      this.renderer.setAttribute(this.splitAreaBot1.nativeElement, 'draggable', 'false');
    }
    if (this.splitAreaBot2) {
      this.renderer.setAttribute(this.splitAreaBot2.nativeElement, 'draggable', 'false');
    }
  }

  /*swapping different split-area element */
  swapView(ev) {
    if (ev.originalEvent.preventDefault) {
      ev.originalEvent.preventDefault();
    } else {
      ev.originalEvent.stop();
    }
    if (this.draggedSplitArea && this.draggedSplitArea !== ev.vcr) {
      if (this.elementSwapped && this.elementSwapped.droppedInTab !== this.draggedSplitArea) {
        this.reverseSwapView();
        this.elementSwapped = null;
      }
      if (ev.vcr !== this.draggedSplitArea) {
        const draggedVcr = this.draggedSplitArea;
        const tmpViewRef = draggedVcr.detach();
        const tmpViewRef2 = ev.vcr.detach();
        ev.vcr.insert(tmpViewRef);
        draggedVcr.insert(tmpViewRef2);
        this.elementSwapped = { draggedVcr: ev.vcr, droppedInVcr: draggedVcr };
        this.draggedSplitArea = ev.vcr;
      }
      ev.originalEvent.returnValue = false;
      ev.originalEvent.stopPropagation();
    }
  }

  /*reverse last swap  */
  reverseSwapView() {
    const tmpViewRef = this.elementSwapped.draggedVcr.detach();
    const tmpViewRef2 = this.elementSwapped.droppedInVcr.detach();
    this.elementSwapped.droppedInVcr.insert(tmpViewRef);
    this.elementSwapped.draggedVcr.insert(tmpViewRef2);
    this.draggedSplitArea = this.elementSwapped.droppedInVcr;
  }

  drop(ev) {
    if (ev.originalEvent.preventDefault) {
      ev.originalEvent.preventDefault();
    } else {
      ev.originalEvent.stop();
    }
  }

  dropInView(ev) {
    if (ev.originalEvent.preventDefault) {
      ev.originalEvent.preventDefault();
    } else {
      ev.originalEvent.stop();
    }
    if (Global.draggedComponent instanceof TabGroupUiComponent) {
      return false;
    }
    if (Global.draggedComponent && this.activeTab.id !== Global.draggedComponent.id) {
      if (!this.activeTab.id.startsWith('tabContainer')) {
        if (!this.activeTab.parentId.startsWith('tabContainer')) {
          this.activeTab.createTabContainerEmitter.emit({
            draggedTab: Global.draggedComponent,
            droppedInTab: this.activeTab,
          });
        }
      } else {
        const tabFound = this.activeTab.components.find((tab) => tab.instance.id === Global.draggedComponent.id);
        // check if container has reached its limit or already contains dragged tab
        if (this.activeTab.components.length < 4 && !tabFound) {
          this.activeTab.createTab(Global.draggedComponent);
          this.activeTab.setActive(this.activeTab);
          this.activeTab.destroyTabEmitter.emit(Global.draggedComponent);
        }
      }
      Global.draggedComponent = null;
    }
    ev.originalEvent.returnValue = false;
    ev.originalEvent.stopPropagation();
  }

  allowDropInView(ev: any) {
    if (ev.originalEvent.preventDefault) {
      ev.originalEvent.preventDefault();
    } else {
      ev.originalEvent.stop();
    }
    /*check position of drop */
    ev.originalEvent.returnValue = false;
    ev.originalEvent.stopPropagation();
  }

  leaveView(ev: any) {
    ev.originalEvent.returnValue = false;
    ev.originalEvent.stopPropagation();
  }

  handleGutterDragEnd() {
    this.splitManager.dragGutterEmitter.emit();
  }

  setDraggable(splitAreaTop1) {
    this.renderer.setAttribute(splitAreaTop1, 'draggable', 'true');
  }

  ngOnDestroy(): void {
    this.componentRefs.forEach((cr) => cr?.destroy());
  }
}
