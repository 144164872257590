<ul [ngClass]="{ 'p-submenu-list': !root }">
  <ng-template ngFor let-child let-index="index" [ngForOf]="$any(root ? item : item.items)">
    <li
      #menuitem
      *ngIf="child.separator"
      class="p-menu-separator"
      [ngClass]="{ 'p-hidden': child.visible === false }"
      role="separator"></li>
    <li
      *ngIf="!child.separator"
      #menuitem
      [ngClass]="{
        'p-menuitem': true,
        'p-menuitem-contextmenu': true,
        'p-hidden': child.visible === false,
        'ui-widget': true
      }"
      (mouseenter)="onItemMouseEnter($event, child, getKey(index))"
      (mouseleave)="onItemMouseLeave($event, child)"
      role="none"
      (click)="onItemClick($event, child, menuitem, getKey(index))"
      [attr.data-ik]="getKey(index)"
      style="cursor: pointer">
      <a
        *ngIf="!child.routerLink"
        [attr.href]="child.url ? child.url : null"
        [attr.target]="child.target"
        [attr.title]="child.title"
        [attr.id]="child.id"
        [attr.tabindex]="child.disabled ? null : '0'"
        [ngClass]="{ 'p-menuitem-link': true, 'p-disabled': child.disabled }"
        [ngStyle]="child.style"
        [class]="child.styleClass"
        pRipple
        [attr.aria-haspopup]="item.items !== null">
        <span class="p-menuitem-icon" *ngIf="child.icon" [ngClass]="child.icon"></span>
        <span class="p-menuitem-text" *ngIf="child.escape !== false; else htmlLabel">{{ child.label }}</span>
        <ng-template #htmlLabel><span class="p-menuitem-text" [innerHTML]="child.label"></span></ng-template>
        <span class="p-submenu-icon pi pi-angle-right" *ngIf="child.items"></span>
      </a>
      <a
        *ngIf="child.routerLink"
        [routerLink]="child.routerLink"
        [queryParams]="child.queryParams"
        [routerLinkActive]="'p-menuitem-link-active'"
        role="menuitem"
        [routerLinkActiveOptions]="child.routerLinkActiveOptions || { exact: false }"
        [attr.target]="child.target"
        [attr.title]="child.title"
        [attr.id]="child.id"
        [attr.tabindex]="child.disabled ? null : '0'"
        (click)="onItemClick($event, child, menuitem, getKey(index))"
        [ngClass]="{ 'p-menuitem-link': true, 'p-disabled': child.disabled }"
        [ngStyle]="child.style"
        [class]="child.styleClass"
        pRipple
        [fragment]="child.fragment"
        [queryParamsHandling]="child.queryParamsHandling"
        [preserveFragment]="child.preserveFragment"
        [skipLocationChange]="child.skipLocationChange"
        [replaceUrl]="child.replaceUrl"
        [state]="child.state">
        <span class="p-menuitem-icon" *ngIf="child.icon" [ngClass]="child.icon"></span>
        <span class="p-menuitem-text" *ngIf="child.escape !== false; else htmlRouteLabel">{{ child.label }}</span>
        <ng-template #htmlRouteLabel><span class="p-menuitem-text" [innerHTML]="child.label"></span></ng-template>
        <span class="p-submenu-icon pi pi-angle-right" *ngIf="child.items"></span>
      </a>
      <iu-prime-context-menu-sub
        [parentItemKey]="getKey(index)"
        [item]="child"
        *ngIf="child.items"></iu-prime-context-menu-sub>
    </li>
  </ng-template>
</ul>
