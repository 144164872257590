import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'iu-checkbox-renderer',
  template: `
    <iu-input-switch-ui
      [isStandalone]="true"
      [fieldValue]="this.fieldValue"
      [data]="this.templates"
      (fieldValueModified)="onFieldModified($event)">
    </iu-input-switch-ui>
  `,
  styles: [],
})
export class CheckboxRendererComponent implements ICellRendererAngularComp {
  params;
  label: string;
  templates: any;
  fieldValue = null;

  agInit(params: ICellRendererParams) {
    this.params = params;
    this.label = this.params.label || null;
    this.templates = this.params.templates;
    if (params.value !== undefined) {
      this.fieldValue = params.value;
    }
  }

  refresh(params?: ICellRendererParams): boolean {
    return true;
  }

  onFieldModified(value: any) {
    const oldValue = this.params.node.data[this.templates.columnName];
    this.params.node.data[this.templates.columnName] = value;

    if (!this.params.node.isSelected()) {
      this.params.node.setSelected(true); // select row
    }

    if (this.params.onClick instanceof Function) {
      // put anything into params u want pass into parents component
      const params = {
        event: value,
        rowData: this.params.node.data,
        columnName: this.params.colDef.field,
        oldValue,
        newValue: value,
        // ...something
      };
      this.params.onClick({ ...this.params, ...params });
    }
  }
}
