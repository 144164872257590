import { Component, OnInit } from '@angular/core';
import { CompiereDataGridFilterType, CompiereDataGridType } from '@compiere-ws/models/compiere-data-json';
import { OperatorFilterType } from '@iupics-components/models/universal-filter';
import { CardContent } from '@iupics-components/specific/window/customer-360/components/cards/cards-ui/card-content/card-type';
import { DataStoreService } from '@iupics-manager/managers/data-store/data-store.service';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';

@Component({
  selector: 'iu-contact-card',
  templateUrl: './contact-card.component.html',
  styleUrls: ['./contact-card.component.scss'],
})
export class ContactCardComponent extends CardContent implements OnInit {
  displayData: any = {};

  language: string;

  constructor(private store: DataStoreService, private connectorService: SecurityManagerService) {
    super();
  }

  ngOnInit(): void {
    this.displayData = this.content;
    this.language = this.connectorService.getIupicsDefaultLanguage().iso_code;
    this.getGreetingData();
    this.getJobData();
  }

  async getGreetingData() {
    if (!this.content?.C_Greeting_ID) return;

    this.dataGridRequest('C_Greeting', {
      next: (res) => {
        if (!res || res?.error) return;

        const [data] = res.data;

        this.displayData['greetingData'] = {
          ...data,
        };
      },
    });
  }

  async getJobData() {
    if (!this.content?.C_Job_ID) return;

    this.dataGridRequest('C_Job', {
      next: (res) => {
        if (!res || res?.error) return;

        const [data] = res.data;

        this.displayData['jobData'] = {
          ...data,
        };
      },
    });
  }

  async dataGridRequest(tableName: string, callback: any) {
    const idColName = `${tableName}_ID`;
    const request = {
      windowId: -1,
      compiereRequest: {
        windowType: CompiereDataGridType.TABLE,
        tableName: tableName,
        ad_language: this.language,
        filterModel: {
          [idColName]: {
            filterType: CompiereDataGridFilterType.SET,
            operators: [OperatorFilterType.EQUALS],
            values: [this.content[idColName]?.id ?? this.content[idColName] ?? null],
          },
        },
      },
    };

    this.store.getDataGrid(request).subscribe(callback);
  }
}
