import { Pipe, PipeTransform } from '@angular/core';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { TranslateService } from '@ngx-translate/core';
@Pipe({
  name: 'docServerFormatValue',
})
export class DocServerFormatValuePipe implements PipeTransform {
  constructor(private translateService: TranslateService, private connectorService: SecurityManagerService) {}

  transform(value: any, referenceId: number): Promise<any> {
    return this.getFormatFromReference(value, referenceId);
  }
  private getFormatFromReference(value: any, referenceId: number) {
    if (value !== null && value !== undefined) {
      return referenceId === 20
        ? value.toLowerCase() === 'y'
          ? this.translateService.instant('docServerFormatValue.yes')
          : this.translateService.instant('docServerFormatValue.no')
        : referenceId === 15 || referenceId === 16 || referenceId === 24
        ? this.parseDate(value, referenceId)
        : referenceId === 12 || referenceId === 37 || referenceId === 22 || referenceId === 29 || referenceId === 11
        ? this.parseNumber(value, referenceId)
        : value;
    } else {
      return value;
    }
  }
  private parseNumber(value: any, referenceId: number) {
    let floatValue = value && value.replace ? parseFloat(value.replace(',', '.')) : value;
    if (floatValue === null || floatValue === undefined || isNaN(parseFloat(floatValue))) {
      return value;
    } else {
      try {
        const defaultLanguage = this.connectorService.getIupicsDefaultLanguage();
        floatValue = parseFloat(floatValue.toFixed(2)).toLocaleString(defaultLanguage.iso_code.replace('_', '-'));
      } catch (e) {
        floatValue = 0;
      }

      return floatValue;
    }
  }
  private parseDate(value: any, referenceId: number) {
    let date;
    try {
      if (referenceId === 15) {
        /* Display Type 15	Date	*/
        date = new Date(value).toLocaleDateString();
      } else if (referenceId === 16) {
        /* Display Type 16	DateTime	*/
        date = new Date(value).toLocaleString();
      } else if (referenceId === 24) {
        /* Display Type 24	Time	*/
        date = new Date(value).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
      }
    } catch (error) {
      date = value;
    }
    return date;
  }
}
