<span
  #container
  [life]="3000"
  [hideDelay]="250"
  pTooltip="{{ description }}"
  tooltipPosition="top"
  tooltipZIndex="2100"
  [escape]="false"
  [showDelay]="500"
  [ngClass]="[
    'p-autocomplete ui-widget',
    dropdown ? 'p-autocomplete-dd' : '',
    multiple ? 'p-autocomplete-multiple' : '',
    styleClass || ''
  ]"
  [ngStyle]="style">
  <label
    *ngIf="isLabelDisplay && label !== undefined && label !== null && label.trim().length > 0"
    [style.font-size]="overridedCSS?.labelFontSize"
    [ngClass]="{ 'iu-field-only-label': data?.isFieldOnly }"
    >{{ label | textLimit : this.el.nativeElement.firstElementChild.firstElementChild.clientWidth / 7 }}</label
  >
  <div class="autocomplete-input-container">
    <input
      *ngIf="!multiple"
      [ngClass]="[
        'p-inputtext ui-widget ui-state-default ui-corner-all p-autocomplete-input',
        mandatoryCss || '',
        inputStyleClass || ''
      ]"
      #in
      [attr.type]="type"
      [attr.id]="inputId"
      [ngStyle]="inputStyle"
      [attr.required]="required"
      [attr.value]="inputFieldValue === undefined ? '' : inputFieldValue"
      (click)="onInputClick($event)"
      (input)="onInput($event)"
      (keydown)="onKeydown($event)"
      (keyup)="onKeyup($event)"
      (focus)="!readonly ? handleDropdownClick($event) : null"
      [tabindex]="readonly ? '-1' : null"
      (blur)="onInputBlur($event)"
      (change)="onInputChange($event)"
      [attr.placeholder]="placeholder"
      [attr.size]="size"
      [attr.maxlength]="maxlength"
      [attr.tabindex]="tabindex"
      [readonly]="readonly"
      autocomplete="no"
      [style.font-size]="overridedCSS?.inputFontSize"
      data-cy="input-autocomplete"
      [style.height]="overridedCSS?.inputHeight"
      [attr.data-cy-columnName]="data?.columnName ?? columnName"
      [attr.data-cy-isstandalone]="isStandalone" />

    <ul
      *ngIf="multiple"
      #multiContainer
      [ngClass]="[
        disabled ? 'p-disabled' : '',
        focus ? 'ui-state-focus' : '',
        'p-autocomplete-multiple-container ui-widget p-inputtext ui-state-default ui-corner-all p-autocomplete-input'
      ]"
      (click)="multiIn.focus()"
      [ngStyle]="{ display: 'flex', float: 'left', overflow: 'hidden', 'margin-right': '-1px' }"
      data-cy="overlay-multiple-autocomplete">
      <div #wrapper (wheel)="handleWheel($event)" (scroll)="$event.stopPropagation()">
        <ng-container *ngIf="value?.length">
          <li #token *ngFor="let val of value" class="p-autocomplete-token p-highlight ui-corner-all">
            <ng-container *ngTemplateOutlet="selectedItemTemplate; context: { $implicit: val }"></ng-container>
            <span *ngIf="!selectedItemTemplate" class="p-autocomplete-token-label">{{ val.displayValue }}</span>
            <span
              class="p-autocomplete-token-icon fa fa-fw fa-close"
              (click)="removeItem(token)"
              *ngIf="!disabled"></span>
          </li>
        </ng-container>
      </div>
      <li class="p-autocomplete-input-token">
        <input
          #multiIn
          [attr.type]="type"
          [attr.id]="inputId"
          [attr.placeholder]="value && value.length ? null : placeholder"
          [attr.tabindex]="tabindex"
          (input)="onInput($event)"
          (click)="onInputClick($event)"
          (keydown)="onKeydown($event)"
          [readonly]="readonly"
          (keyup)="onKeyup($event)"
          (focus)="!readonly ? onInputFocus($event) : null"
          [tabindex]="readonly ? '-1' : null"
          (blur)="onInputBlur($event)"
          (change)="onInputChange($event)"
          autocomplete="off"
          [ngStyle]="inputStyle"
          class="{{ inputStyleClass }}"
          data-cy="input-autocomplete"
          [attr.data-cy-columnName]="data?.columnName ?? columnName"
          [attr.data-cy-isstandalone]="isStandalone" />
      </li>
    </ul>
    <i *ngIf="loading" class="p-autocomplete-loader icon-line-loader" aria-hidden="true"></i>
    <i
      [style.height]="overridedCSS?.inputHeight"
      [ngClass]="['icon-search-bolder', iconeMandatoryCss || '']"
      aria-hidden="true"
      #ddBtn
      type="button"
      data-cy="input-autocomplete-lookup"
      [attr.data-cy-lookup-columnName]="data?.columnName ?? columnName"
      (click)="
        checkSpecificView()
          ? !readonly && !disabled
            ? showFormPanel()
            : null
          : !readonly && !disabled
          ? data?.urlSearch
            ? !isRendererView
              ? openSearchPanel()
              : handleDropdownClick($event)
            : handleDropdownClick($event)
          : null
      "
      *ngIf="dropdown"></i>
  </div>

  <div
    #panel
    *ngIf="overlayVisible"
    [style]="'overflow: auto; background: var(--prime-autocomplete-bg-color-panel);z-index: 9999;'"
    [ngClass]="['p-autocomplete-panel p-component ui-widget-content ui-corner-all ui-shadow']"
    [style.max-height]="scrollHeight"
    [ngStyle]="panelStyle"
    [class]="panelStyleClass"
    [@overlayAnimation]="{
      value: 'visible',
      params: { showTransitionParams: showTransitionOptions, hideTransitionParams: hideTransitionOptions }
    }"
    (@overlayAnimation.start)="onOverlayAnimationStart($event)">
    <ul
      class="p-autocomplete-items p-autocomplete-list ui-widget-content ui-widget ui-corner-all ui-helper-reset"
      data-cy="overlay-autocomplete">
      <li
        *ngFor="let option of suggestions | autocompleteSuggestionsFilter : value; let idx = index"
        [ngClass]="[
          'p-autocomplete-list-item ui-corner-all',
          option.id === -1 ? 'moreResults' : '',
          highlightOption === option ? 'p-highlight' : ''
        ]"
        (mouseenter)="highlightOption = option"
        (mouseleave)="highlightOption = null"
        (click)="selectItem(option); $event.stopPropagation()"
        [attr.data-cy-value]="field ? ObjectUtils.resolveFieldData(option, field) : option.displayValue">
        <span [style.font-size]="overridedCSS?.inputFontSize" *ngIf="!itemTemplate && option.id !== -1">{{
          field ? ObjectUtils.resolveFieldData(option, field) : option.displayValue
        }}</span>
        <span
          [style.font-size]="overridedCSS?.inputFontSize"
          class="moreResultsSpan"
          *ngIf="!itemTemplate && option.id === -1"
          >{{ field ? ObjectUtils.resolveFieldData(option, field) : option.displayValue }}</span
        >
        <ng-container *ngTemplateOutlet="itemTemplate; context: { $implicit: option, index: idx }"></ng-container>
      </li>
      <li *ngIf="noResults && emptyMessage" class="p-autocomplete-list-item ui-corner-all">
        {{ emptyMessage }}
      </li>
    </ul>
  </div>
</span>
