<!-- #region favorite -->
<div class="apiz-p-grid" style="position: relative">
  <div
    [ngClass]="[
      isMobileWidth() ? 'menu-mobile' : '',
      isMobileWidth() && showList ? 'menu-mobile-show' : '',
      'p-col-11 p-md-11 p-lg-11 menu-closed'
    ]">
    <iu-dashboard-group-ui
      data-cy="menu-group-favorites"
      (changeMenuItemList)="onChangeCategory($event)"
      (addGroupItem)="onAddGroupItem($event)"
      (renameGroupItem)="onRenameGroupItem($event)"
      (removeGroupItem)="onRemoveGroupItem($event)"
      [groups]="groups"
      (dragEndEmitter)="onSwitchMenuGroup($event)"
      keybinding="dashboard.favorite-group"></iu-dashboard-group-ui>
  </div>
  <h2 class="p-col-10 mobileOnly">
    <div class="dashboard-section-header">
      <button
        data-cy="mobile_menu_favorite_toggle"
        class="mobile_menu_bar_toggle"
        iuToggleButton
        [iuToggleButtonSwitch]="true"
        [(iuToggleButtonVar)]="showList">
        <i
          [ngClass]="[showList ? 'icon-menu-bars-toggle-opened' : 'icon-menu-bars-toggle-closed']"
          aria-hidden="true"></i>
      </button>
      <div class="menu_name">{{ groups | dashboardGroupName : currentGroupID }}</div>
    </div>
  </h2>
  <div class="p-col-1 p-md-1 p-lg-1 ui-sd-2 shortManage">
    <ul
      id="shortcutsManager-container"
      class="shortcutsManager"
      iuAddActive
      [iuAddActive-idToCheck]="'shortcutsManager-add'"
      [iuAddActive-otherToClose]="['widgetManager-container']"
      iuKeybinding
      [iuKeybinding-active]="true"
      iuKeybinding-code="dashboard.favorite-menu">
      <li>
        <a
          id="shortcutsManager-add"
          data-cy="menu-add-favorite"
          href="#"
          tooltipPosition="left"
          [showDelay]="250"
          [life]="3000"
          [hideDelay]="250"
          pTooltip="{{ 'dashboard.addShortcut' | translate }}"
          class="add"
          (click)="openList($event)">
          <i class="icon-add" aria-hidden="true"></i>
        </a>
        <ul>
          <ng-container *ngIf="menus$ | async as menus">
            <li class="title">{{ 'dashboard.availableShortcuts' | translate }}</li>
            <li class="searchSC">
              <input
                #searchInput
                data-cy="menu-search-favorite"
                class="searchInput p-inputtext"
                (keydown)="handleInputKeydown($event)"
                (keyup)="handleInputKeyup($event, menus)"
                [(ngModel)]="searchFavoriteValue"
                type="text"
                iuAutofocusDirective />
              <i class="iconeSearch icon-search-bolder" aria-hidden="true" #ddBtn type="button"></i>
            </li>
            <span
              id="iu_favorite_container"
              *ngIf="
                !(
                  searchFavoriteValue &&
                  searchFavoriteValue !== '' &&
                  searchFavoriteValue !== '!' &&
                  searchFavoriteValue !== '#' &&
                  searchFavoriteValue !== '!#'
                );
                else filterSearch
              "
              class="listNiv-1">
              <ng-container *ngFor="let menuCategory of menus; let i = index">
                <span *ngIf="selectedCategory === undefined">
                  <li>
                    <a
                      id="iu_favorite_category_{{ menuCategory.category }}"
                      data-cy="menu-category-favorite"
                      href="#"
                      [ngClass]="[
                        arrowFocusedCategory === menuCategory.category ? 'item-arrow-focused' : '',
                        'open-icon'
                      ]"
                      (click)="$event.preventDefault(); onSelectCategory($event, menuCategory.category)">
                      <span>{{ menuCategory.category }}</span>
                      <i class="icon-open" aria-hidden="true"></i>
                    </a>
                  </li>
                </span>
                <li *ngIf="selectedCategory === menuCategory.category" class="opened">
                  <a
                    href="#"
                    class="back-icon"
                    (click)="$event.preventDefault(); onSelectCategory($event, undefined)"
                    data-cy="menu-category-favorite">
                    <i class="icon-back" aria-hidden="true"></i>
                    <span>{{ menuCategory.category }}</span>
                  </a>
                  <ul>
                    <li *ngFor="let menu of menuCategory.items">
                      <a
                        id="iu_favorite_menu_{{ menu.menuId }}"
                        data-cy="menu-favorite"
                        href="#"
                        [life]="3000"
                        [hideDelay]="250"
                        pTooltip="{{
                          menu.menuCategory ? menu.menuCategory.name + '/' : ('dashboard.menu.others' | translate) + '/'
                        }}"
                        tooltipPosition="bottom"
                        [ngClass]="[arrowFocusedMenu?.menuId === menu.menuId ? 'item-arrow-focused' : '', 'add-icon']"
                        (click)="$event.preventDefault(); addItem(menu)">
                        <span class="favorite-icon {{ menu.iconClass }}" aria-hidden="true"></span>
                        <span>{{ menu.name }}</span>
                        <i class="icon-add" aria-hidden="true"></i>
                      </a>
                    </li>
                  </ul>
                </li>
              </ng-container>
            </span>
            <ng-template #filterSearch>
              <span id="iu_favorite_container" class="filtredList">
                <li *ngFor="let menuCategory of menus | favoriteFilter : searchFavoriteValue">
                  <a
                    id="iu_favorite_menu_{{ menuCategory.menuId }}"
                    data-cy="menu-favorite"
                    href="#"
                    [life]="3000"
                    [hideDelay]="250"
                    pTooltip="{{
                      menuCategory.menuCategory
                        ? menuCategory.menuCategory.name + '/'
                        : ('dashboard.menu.others' | translate) + '/'
                    }}"
                    tooltipPosition="bottom"
                    [ngClass]="[
                      arrowFocusedMenu?.menuId === menuCategory.menuId ? 'item-arrow-focused' : '',
                      'add-icon'
                    ]"
                    (click)="$event.preventDefault(); addItem(menuCategory)">
                    <span class="favorite-icon {{ menuCategory.icon }}"></span> {{ menuCategory.name }}</a
                  >
                </li>
              </span>
            </ng-template>
          </ng-container>
        </ul>
      </li>
    </ul>
  </div>
</div>
<div class="apiz-p-grid shortcutsList" data-cy="menu-favorites">
  <ng-container *ngIf="currentItemList$ | async as currentItemList; else favoriteLoading">
    <ng-container *ngFor="let favorite of currentItemList">
      <div
        #dragElem
        class="drag-item p-col-12 p-md-6 p-lg-3"
        (dragstart)="handleDragStart($event, dragElem, favorite)"
        (mouseover)="isDragMode && dragElem !== activeDiv ? handleMouseOver($event, dragElem) : null">
        <iu-favorites-ui
          [favorite]="favorite"
          (deleteFavorite)="onDeleteItem($event)"
          (openTab)="onOpenTab($event)"
          (updateFavorite)="onUpdateItem($event)"></iu-favorites-ui>
      </div>
    </ng-container>
  </ng-container>
</div>
<!-- #endregion -->

<ng-template #favoriteLoading> <!-- on peut mettre le loader ici --> </ng-template>
