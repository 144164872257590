import { animate, state, style, transition, trigger } from '@angular/animations';
import { HttpResponse } from '@angular/common/http';
import { InfoDialogComponent } from '@iupics-components/specific/window/info-dialog/info-dialog.component';
import { ModalUiComponent } from '@iupics-components/standard/layouts/modal-ui/modal-ui.component';
import { NotificationCenterUiComponent } from '@web-desktop/components/menu-top/components/notification-center-ui/notification-center-ui.component';
import { WorkspaceComponent } from '@web-desktop/components/workspace/components/workspace-ui/workspace-ui.component';
import { OverlayPanel } from 'primeng/overlaypanel';

export enum DeviceEnum {
  ANDROID = 'android',
  IPAD = 'ipad',
  WORKSTATION = 'workstation',
}

export enum IupicsGenericComponent {}

export enum NavigatorEnum {
  MOZILLA,
  CHROME,
}

export class Global {
  static perfLogActive = false;
  /**
   * 0 : montre tout,
   * 1 : montre que les logs sans label,
   * 2 : montre que les logs avec label
   */
  static perfLogLevel = 2;
  static minIdleTime = 200;
  static taskNameFilter = '';
  static perfStartTimes: Map<string, number> = new Map();
  static perfTotalTimes: Map<string, number> = new Map();
  static perfTimeout: Map<string, NodeJS.Timeout> = new Map();
  static totalPerfTime: number = 0;
  static lastPerfEndTime: number = 0;
  static totalPerfTimeout: NodeJS.Timeout;
  static lastPerfTimeout: NodeJS.Timeout;
  static perfColors = {
    d: 'color:#FFFFFF',
    s: 'color:#22E08F',
    mc: 'color:#E0AC22',
    e: 'color:#E04822',
    sc: 'color:#22A1E0',
    w: 'color:#E02222',
  };
  static device = navigator.userAgent.toUpperCase().includes('ANDROID')
    ? DeviceEnum.ANDROID
    : navigator.userAgent.toUpperCase().includes('IPAD')
    ? DeviceEnum.IPAD
    : DeviceEnum.WORKSTATION;

  static timestackDebug;

  static activeXHRRequest = 0;

  static draggedComponent: any = null;
  static isLoginPageShow = false;
  static defaultTitle = 'Apiz';

  static workspace: WorkspaceComponent;
  static activeModals: ModalUiComponent[] = [];
  static activeOverlays: OverlayPanel[] = [];
  static notificationCenter: NotificationCenterUiComponent;

  static infoDialog: InfoDialogComponent;

  static isLoginAsModalVisible = false;

  static lastActivity: Date;
  static mergeLevel: number;

  static dateFormat = 'YYYY-MM-DDTHH:mm:ss.SSS';

  static overlayAnimationAutocomplete: any[] = [
    trigger('overlayAnimationAutocomplete', [
      state(
        'void',
        style({
          transform: 'translateY(5%)',
          opacity: 0,
        })
      ),
      state(
        'visible',
        style({
          transform: 'translateY(0)',
          opacity: 1,
        })
      ),
      transition('void => visible', animate('225ms ease-out')),
      transition('visible => void', animate('195ms ease-in')),
    ]),
  ];

  static overlayAnimationCalendar: any[] = [
    trigger('overlayAnimationCalendar', [
      state(
        'visible',
        style({
          transform: 'translateY(0)',
          opacity: 1,
        })
      ),
      state(
        'visibleTouchUI',
        style({
          transform: 'translate(-50%,-50%)',
          opacity: 1,
        })
      ),
      transition('void => visible', [
        style({ transform: 'translateY(5%)', opacity: 0 }),
        animate('{{showTransitionParams}}'),
      ]),
      transition('visible => void', [
        animate(
          '{{hideTransitionParams}}',
          style({
            opacity: 0,
            transform: 'translateY(5%)',
          })
        ),
      ]),
      transition('void => visibleTouchUI', [
        style({ opacity: 0, transform: 'translate3d(-50%, -40%, 0) scale(0.9)' }),
        animate('{{showTransitionParams}}'),
      ]),
      transition('visibleTouchUI => void', [
        animate(
          '{{hideTransitionParams}}',
          style({
            opacity: 0,
            transform: 'translate3d(-50%, -40%, 0) scale(0.9)',
          })
        ),
      ]),
    ]),
  ];

  static isLandscape() {
    const mql = window.matchMedia('(orientation: portrait)');
    return !mql.matches;
  }
  static getDeviceWidth() {
    const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window['MSStream'];
    return iOS ? screen.width : window.innerWidth;
  }
  static getDeviceHeight() {
    const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window['MSStream'];
    return iOS ? screen.height : window.innerHeight;
  }
  static isMobile() {
    // (typeof window.orientation !== 'undefined') || (navigator.userAgent.indexOf('IEMobile') !== -1);
    // eslint-disable-next-line deprecation/deprecation
    if (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1) {
      return true;
    }
    if (navigator.userAgent.match(/(iPhone|iPod|iPad|Android|webOS|BlackBerry|IEMobile|Opera Mini)/i)) {
      return true;
    } else {
      let check = false;
      (function (a) {
        // http://detectmobilebrowsers.com/
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
            a
          ) ||
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
            a.substring(0, 4)
          )
        ) {
          check = true;
        }
      })(navigator.userAgent || navigator.vendor);
      return check;
    }
  }

  static isMobileWidth() {
    return document.documentElement.clientWidth <= 640;
  }

  static downloadFile(response: HttpResponse<any>, defaultFilename: string = 'iupics-downloaded-file') {
    let filename: string;
    // todo: comprendre pourquoi response.headers.get('content-length') renvoi null, alors que dans l'onglet network il est là
    if (response.headers.get('Content-Disposition')) {
      filename = response.headers.get('Content-Disposition').match('filename="(.*)"(;|$)')[1];
    } else if (response.headers.get('content-disposition')) {
      filename = response.headers.get('content-disposition').match('filename="(.*)"(;|$)')[1];
    } else {
      // todo Change this name
      filename = defaultFilename;
    }
    const element = document.createElement('a');
    element.href = window.URL.createObjectURL(response.body);
    element.download = filename;
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
    // }
  }

  static isActiveRequest(): boolean {
    return Global.activeXHRRequest > 0;
  }

  /**
   * Permet de récupérer la valeur d'un attribut grâce au chemin spécifié dans un tableau de string
   * @param parent
   * @param paths
   */
  static getValueByPath(parent: any, paths: string[]): any {
    let response = Object.assign({}, parent);
    paths.forEach((path) => (response = response[path]));
    return response;
  }

  /**
   * Permet de démarrer un timer qui sera log aprés un appel à "endPerf()"
   * @param source composant courant ou parent
   * @param label nom de la méthode ou nom d'une logique à tester
   */
  static startPerf(source: any, label?: string) {
    if (Global.perfLogActive) {
      if (this.perfStartTimes.size === 0) {
        console.clear();
        document.body.classList.add('click-event-disabled');
      }
      const perfTime = performance.now();
      const key = this.getKey(source, label);
      let taskName = this.getTaskName(source, label, key);

      this.checkIdleTime(perfTime);
      this.perfStartTimes.set(key, perfTime);
      if (
        (!this.taskNameFilter || taskName.includes(this.taskNameFilter)) &&
        (Global.perfLogLevel == 0 ||
          (Global.perfLogLevel == 1 && (label == null || label == undefined || label.trim() === '')) ||
          (Global.perfLogLevel == 2 && label != null && label != undefined && label.trim() !== ''))
      ) {
        let extraData = '';
        if (source && source?.data?.label) {
          extraData = source.data.label;
        }
        console.log(
          `${new Date().toLocaleTimeString()} | %cSTART%c | %c${taskName} | ${extraData}`,
          this.perfColors.s,
          this.perfColors.d,
          label ? this.perfColors.sc : this.perfColors.mc
        );
      }
    }
  }
  private static getKey(source: any, label: string): string {
    let key: string = null;
    if (source && source.uuid) {
      key = source.uuid;
    }
    if (label != null && label != undefined && label.trim() !== '') {
      if (key != null) {
        key += '.' + label;
      } else {
        key = label;
      }
    }
    if (!key && source && Object.getPrototypeOf(source)) {
      key = Object.getPrototypeOf(source).constructor.name;
    }
    return key;
  }
  private static getTaskName(source: any, label?: string, key?: string): string {
    let taskName: string;
    if (source && Object.getPrototypeOf(source)) {
      taskName = Object.getPrototypeOf(source).constructor.name;
    }
    if (label != null && label != undefined && label.trim() !== '') {
      if (taskName) {
        taskName += '.' + label;
      } else {
        taskName = label;
      }
    }

    if (!taskName) {
      taskName = key;
    }
    return taskName;
  }
  /**
   * Permet de mettre fin à un timer enclenché par un startPerf()
   * @param source composant courant ou parent
   * @param label nom de la méthode ou nom d'une logique à tester (doit être identique au label utilisé dans le "startPref()").
   * @param group nom du groupe de log auquel on veut ajouter le temps de cette performance.
   */
  static endPerf(source: any, label?: string, group?: string) {
    if (Global.perfLogActive) {
      const key = this.getKey(source, label);
      let taskName = this.getTaskName(source, label, key);
      if (this.perfStartTimes.has(key)) {
        const perf = performance.now() - this.perfStartTimes.get(key);
        this.perfStartTimes.delete(key);
        if (
          (!this.taskNameFilter || taskName.includes(this.taskNameFilter)) &&
          (Global.perfLogLevel == 0 ||
            (Global.perfLogLevel == 1 && (label == null || label == undefined || label.trim() === '')) ||
            (Global.perfLogLevel == 2 && label != null && label != undefined && label.trim() !== ''))
        ) {
          let extraData = '';
          if (source && source?.data?.label) {
            extraData = source.data.label;
          }
          const color = label ? this.perfColors.sc : this.perfColors.mc;
          console.log(
            `${new Date().toLocaleTimeString()} |  %cEND%c  |%c ${taskName} | %c${perf} MS%c | ${extraData}`,
            this.perfColors.e,
            this.perfColors.d,
            color,
            perf > this.minIdleTime ? this.perfColors.w : color,
            color
          );
        }
        this.perfIdleinitTime();
        if (!group) {
          if (source && Object.getPrototypeOf(source)) {
            group = Object.getPrototypeOf(source).constructor.name;
          }
        }
        this.perfGroupLog(perf, group);
        this.totalPerfTime += perf;
        if (this.totalPerfTimeout) {
          clearTimeout(this.totalPerfTimeout);
        }
        this.totalPerfTimeout = setTimeout(() => {
          console.log(
            `${new Date().toLocaleTimeString()} |RESULTS| %cTOTAL | ${this.totalPerfTime} MS`,
            this.perfColors.s
          );
          this.totalPerfTime = 0;
          this.perfStartTimes.clear();
          this.perfTotalTimes.clear();
          document.body.classList.remove('click-event-disabled');
        }, 2000);
        this.lastPerfEndTime = performance.now();
      } else {
        // throw new Error('key not found for ' + taskName + '(' + key + ')');
      }
    }
  }
  private static checkIdleTime(perfTime: number) {
    if (this.lastPerfEndTime > 0) {
      const idleTime = perfTime - this.lastPerfEndTime;
      if (idleTime > this.minIdleTime) {
        console.log(`${new Date().toLocaleTimeString()} | INFO  | %cIDLE | ${idleTime} MS`, this.perfColors.w);
        this.lastPerfEndTime = 0;
      }
    }
  }
  private static perfIdleinitTime() {
    if (this.lastPerfTimeout) {
      clearTimeout(this.lastPerfTimeout);
    }
    this.lastPerfTimeout = setTimeout(() => {
      this.lastPerfEndTime = 0;
    }, 2000);
  }
  private static perfGroupLog(perf: number, group: string) {
    const groupKey = group.toUpperCase();
    if (!this.perfTotalTimes.has(groupKey)) {
      this.perfTotalTimes.set(groupKey, perf);
    } else {
      const totalPerf = this.perfTotalTimes.get(groupKey) + perf;
      this.perfTotalTimes.set(groupKey, totalPerf);
    }

    if (this.perfTimeout.has(groupKey)) {
      clearTimeout(this.perfTimeout.get(groupKey));
    }
    const timeout = setTimeout(() => {
      console.log(
        `${new Date().toLocaleTimeString()} |RESULTS|%c ${group} | ${this.perfTotalTimes.get(groupKey)} MS`,
        this.perfColors.s
      );
      this.perfTotalTimes.delete(groupKey);
    }, 2000);
    this.perfTimeout.set(groupKey, timeout);
  }

  /**
   *
   * @param max exclusive number
   * @param min inclusive number
   * @returns a random number between min and max
   */
  static getRandomNumber(max: number, min: number = 0): number {
    min = Math.ceil(min);
    max = Math.floor(max);
    const buf = crypto.getRandomValues(new Uint8Array(1)); // max value is 255 and min 0
    const rand = (buf[0] || 0) / 255; // divide by max value to get a percent value
    return Math.floor(rand * (max - min) + min);
  }

  static Time = {
    MILLISECOND: 1,
    SECOND: 1000,
    MINUTE: 60000,
    HOUR: 3600000,
    DAY: 86400000,
  };
}
