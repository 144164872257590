<ng-container *ngIf="source$ | async as source; else loading">
  <ng-container
    *ngTemplateOutlet="
      favoriteFilter;
      context: {
        columnFilters: source.columnFilters,
        operatorFilters: source.operatorFilters,
        $implicit: selected
      }
    "></ng-container>
</ng-container>
<ng-template #loading>
  <ng-container
    *ngTemplateOutlet="
      favoriteFilter;
      context: { columnFilters: { items: [] }, operatorFilters: { items: [] }, data: selected }
    "></ng-container>
</ng-template>

<ng-template #favoriteFilter let-columnFilters="columnFilters" let-operatorFilters="operatorFilters" let-selected>
  <div class="search" [attr.data-cy-ufilter-tablename]="tableName">
    <iu-prime-chips
      [(ngModel)]="filterChips"
      [buildItemOnSave]="buildChipsOnSave"
      [conditionToAdd]="conditionToAdd"
      (onAdd)="addFilterByChips($event.originalEvent, $event.value)"
      (onRemove)="removeFilterByChips($event.originalEvent, $event.value)"
      (addError)="onAddError()"
      [shouldBeSmall]="gridView?.isGridCollapsed && gridView?.isTabTopLevel"
      [widthChipsContainer]="gridView?.isTabTopLevel ? undefined : 270"
      data-cy="universal-filter-chips"
      class="prime-chips-toolbar">
      <ng-template let-item pTemplate="item">
        <span
          *ngIf="item"
          class="{{ item?.icon }}"
          [attr.data-cy-chips-columnName]="item?.columnName"
          style="margin-right: 3px"></span
        >{{ item?.displayValue }}
      </ng-template>
    </iu-prime-chips>
    <span data-uf="favorites" [ngClass]="['favorite']">
      <button
        #buttonFavoriteFilter
        tooltipPosition="top"
        [showDelay]="250"
        [life]="3000"
        [hideDelay]="250"
        pTooltip="{{ 'universalFilter.favorite' | translate }}"
        pButton
        type="button"
        icon="icon-favorite"
        class="p-menu-bar-button favorite"
        (click)="
          updatePanelFilter($event, 'FavoriteFilter', 'favorite-filter-panel');
        "
        data-cy="universal-filter-favorite"></button>
      <iu-prime-overlay #overlayFavourite [appendTo]="'body'" [dismissable]="false" (onHide)="(onHide)">
        <div
          *ngIf="isDisplayFavoriteFilter"
          data-cy="universal-filter-favorite-panel"
          id="favorite-filter-panel"
          [ngStyle]="{ right: gridView?.isZoomTargetGrid ? 'unset' : null }"
          class="favorite-options ui-overlaypanel">
          <div class="save-favorite">
            <input
              #filterFavorite
              [(ngModel)]="selectedLabel"
              data-cy="universal-filter-favorite-input"
              type="text"
              class="name"
              class="p-inputtext ui-corner-all" />
            <button
              tooltipPosition="top"
              [showDelay]="250"
              [life]="3000"
              [hideDelay]="250"
              pTooltip="{{ 'generic.save' | translate }}"
              pButton
              type="button"
              icon="icon-save3x"
              class="save"
              data-cy="universal-filter-favorite-save-button"
              (click)="saveFilter($event, filterFavorite.value)"></button>
          </div>
          <div *ngIf="dataToDisplay.length > 0" data-cy="universal-filter-favorite-list" class="saved-list">
            <ul>
              <ng-container *ngFor="let item of dataToDisplay">
                <li
                  [ngClass]="[selectedLabel === item.favorite ? 'selected' : '']"
                  data-cy="universal-filter-favorite-item"
                  [attr.data-cy-favorite-item]="item.favorite"
                  (click)="selectAndApply($event, item)">
                  <span class="label">{{ item.favorite }}</span>
                  <button
                    tooltipPosition="top"
                    [showDelay]="250"
                    [life]="3000"
                    [hideDelay]="250"
                    pTooltip="{{ 'universalFilter.byDefault' | translate }}"
                    pButton
                    type="button"
                    icon="icon-bookmark"
                    class="p-button-default"
                    [ngClass]="{ bookmarked: item.isDefault }"
                    (click)="updateDefault($event, item.favorite, !item.isDefault)"></button>
                  <button
                    tooltipPosition="top"
                    [showDelay]="250"
                    [life]="3000"
                    [hideDelay]="250"
                    pTooltip="{{ 'generic.edit' | translate }}"
                    pButton
                    type="button"
                    class="p-button-default"
                    icon="icon-edit-pencil"
                    (click)="selectFilter($event, item)"></button>
                  <button
                    tooltipPosition="top"
                    [showDelay]="250"
                    [life]="3000"
                    [hideDelay]="250"
                    pTooltip="{{ 'generic.delete' | translate }}"
                    pButton
                    type="button"
                    class="p-button-default"
                    icon="icon-delete"
                    (click)="deleteFilter($event, item)"></button>
                </li>
              </ng-container>
            </ul>
          </div>
        </div>
      </iu-prime-overlay>
    </span>

    <span [ngClass]="[isDisplayGroupFilter ? 'opened' : '', 'group']">
      <button
        #buttonGroupFilter
        tooltipPosition="top"
        [showDelay]="250"
        [life]="3000"
        [hideDelay]="250"
        pTooltip="{{ 'universalFilter.filter' | translate }}"
        pButton
        type="button"
        icon="icon-advanced-search-2"
        (click)="
          updatePanelFilter($event, 'GroupFilter', 'group-filter-panel');
        "
        class="p-menu-bar-button group"
        data-cy="universal-filter-setting"></button>
      <iu-prime-overlay #overlayFilter [appendTo]="'body'" [dismissable]="false" >
        <div
          *ngIf="isDisplayGroupFilter"
          id="group-filter-panel"
          class="group-list ui-overlaypanel {{ gridView?.isTree ? 'hasTreeView' : '' }} {{ isMobile ? 'mobile' : '' }}">
          <p *ngIf="selected.filters.length > 0" [ngStyle]="{ order: gridView?.viewType === 'kanban' ? 1 : 0 }">
            {{ 'universalFilter.filterTitle' | translate }}
          </p>
          <ul
            #filtersContainer
            *ngIf="selected.filters.length > 0"
            [ngStyle]="{ order: gridView?.viewType === 'kanban' ? 1 : 0 }"
            cdkDropList
            (cdkDropListDropped)="moveItem($event, 'filters')"
            data-uf="filters">
            <div
              *ngFor="let filter of selected.filters; first as isFirst; last as isLast; index as i"
              cdkDrag
              data-cy="universal-filter-line"
              [attr.data-cy-index]="i">
              <div class="ui-row-drag-preview" *cdkDragPreview>
                <!-- Preview du drag -->{{ 'universalFilter.filterPreview' | translate
                }}{{ filter.column.displayValue || '[ColumnName]' }}
                {{ filter.operator.displayValue || '[Operator]' }}
                {{ filter.filter || '[Filter]' }}
                {{
                  filter.filterTo !== undefined && filter.filterTo !== null && filter.filterTo !== ''
                    ? ' - ' + filter.filterTo
                    : ''
                }}
              </div>
              <div class="ui-row-drag-placeholder" *cdkDragPlaceholder><!-- Placeholder du drag --></div>
              <li class="ui-row" [attr.data-cy-row]="filter.column.id">
                <span cdkDragHandle class="icon-grab" style="font-size: 17px; cursor: move"></span>
                <div class="filtres">
                  <div
                    class="lev-1 filter-field {{ filter?.column?.id === -1 ? 'valueEmpty' : '' }}"
                    data-cy="universal-filter-column-autocomplete">
                    <iu-autocomplete-ui
                      [fieldValue]="filter.column"
                      (fieldValueModified)="setColumnFilter($any($event), i)"
                      [isStandalone]="true"
                      [data]="
                        columnFilters
                          | universalFilterColFilterType
                            : 0
                            : i
                            : (gridView?.viewType === ViewType.CALENDAR
                                ? CompiereDataGridFilterType.DATE
                                : CompiereDataGridFilterType.NONE)
                          | autocompleteSuggestionsFilter: usedFilterColumnNames : filter.column.id
                      "
                      [isInUniversalFilter]="true"
                      [isInsideOverflow]="true"></iu-autocomplete-ui>
                  </div>

                  <div
                    class="lev-2 filter-field {{ filter?.column?.id === -1 ? 'valueEmpty' : '' }}"
                    data-cy="universal-filter-operator-autocomplete">
                    <iu-autocomplete-ui
                      [fieldValue]="filter.operator"
                      (fieldValueModified)="setOperatorFilter($any($event), i)"
                      [isStandalone]="true"
                      [data]="operatorFilters | operatorFilter: filter?.column?.columnInfo?.filterType"
                      [isInUniversalFilter]="true"
                      [isInsideOverflow]="true"></iu-autocomplete-ui>
                  </div>

                  <div
                    class="lev-3 filter-field {{ filter?.operator?.operator?.isRange ? 'isRange' : '' }}"
                    data-cy="universal-filter-value">
                    <ng-template #vcrFilter></ng-template>
                  </div>
                  <div
                    class="lev-4 filter-field {{ filter?.operator?.operator?.isRange ? 'isRange' : '' }}"
                    data-cy="universal-filter-value-to">
                    <!-- <ng-template #vcrFilterTo></ng-template> -->
                    <iu-input-number-ui
                      *ngIf="filter?.operator?.operator?.isRange && filter?.column?.columnInfo?.filterType === 'number'"
                      [isStandalone]="true"
                      [fieldValue]="filter.filterTo"
                      (fieldValueModified)="setFilterTo($event, i)"></iu-input-number-ui>
                    <iu-calendar-ui
                      *ngIf="filter?.operator?.operator?.isRange && filter?.column?.columnInfo?.filterType === 'date'"
                      [hasTodayBtn]="true"
                      [isStandalone]="true"
                      [data]="{
                        needTime: filter.column.columnInfo.fieldEntity.field.AD_Reference_ID === 16
                      }"
                      [fieldValue]="
                        filter.filterTo | toDate: filter.column.columnInfo.fieldEntity.field.AD_Reference_ID === 16
                      "
                      [calendarConfig]="filter.configsTo?.calendarConfig || { todayMode: false }"
                      (calendarConfigChange)="onCalendarConfigChange($event, i, true)"
                      (fieldValueModified)="setFilterTo($event, i)"></iu-calendar-ui>
                    <iu-input-time-ui
                      *ngIf="filter?.operator?.operator?.isRange && filter?.column?.columnInfo?.filterType === 'time'"
                      [isStandalone]="true"
                      [fieldValue]="filter.filterTo | toDate: true"
                      [calendarConfig]="filter.configsTo?.calendarConfig || { todayMode: false }"
                      (calendarConfigChange)="onCalendarConfigChange($event, i, true)"
                      (fieldValueModified)="setFilterTo($event, i)" />
                  </div>
                </div>
                <div class="delete" data-cy="universal-filter-remove-filter">
                  <button
                    tooltipPosition="top"
                    [showDelay]="250"
                    [life]="3000"
                    [hideDelay]="250"
                    pTooltip="{{ 'generic.delete' | translate }}"
                    pButton
                    icon="icon-delete"
                    class="hover"
                    (click)="removeFilter(i, $event)"></button>
                </div>
              </li>
            </div>
          </ul>
          <ul
            *ngIf="selected.filters.length > 0"
            class="buttonAdd"
            [ngStyle]="{ order: gridView?.viewType === 'kanban' ? 1 : 0 }">
            <li class="ui-row">
              <div class="p-col-12">
                <!-- au clic créaction d'un nouvel Li au-dessus de celui-ci et contenant un pemier auto-complete-->
                <button
                  pButton
                  id="addrow"
                  *ngIf="canFilter"
                  class="button p-button-secondary"
                  style="cursor: pointer"
                  (click)="addFilter($event)"
                  label="{{ 'universalFilter.addFilter' | translate }}"
                  icon="icon-add"
                  data-cy="universal-filter-add-filter-one"></button>
              </div>
            </li>
          </ul>
          <p
            *ngIf="selected.groups.length > 0"
            [ngStyle]="{ order: gridView?.viewType === 'kanban' ? 0 : 1 }"
            style="padding-top: 5px">
            {{ 'universalFilter.groupTitle' | translate }}
          </p>
          <ul
            #groupsContainer
            *ngIf="selected.groups.length > 0"
            [ngStyle]="{ order: gridView?.viewType === 'kanban' ? 0 : 1 }"
            cdkDropList
            (cdkDropListDropped)="moveItem($event, 'groups')"
            data-uf="groups">
            <div
              *ngFor="let group of selected.groups; first as isFirst; last as isLast; index as i"
              data-cy="universal-filter-group"
              [attr.data-cy-index]="i"
              cdkDrag>
              <div class="ui-row-drag-preview" *cdkDragPreview>
                <!-- Preview du drag -->{{ 'universalFilter.groupPreview' | translate
                }}{{ group.displayValue || '[ColumnName]' }}
              </div>
              <div class="ui-row-drag-placeholder" *cdkDragPlaceholder><!-- Placeholder du drag --></div>
              <li class="ui-row">
                <div class="filtres">
                  <span cdkDragHandle class="icon-grab" style="font-size: 17px; cursor: move"></span>
                  <div class="lev-1 filter-field" data-cy="universal-filter-group-autocomplete">
                    <iu-autocomplete-ui
                      [fieldValue]="group"
                      (fieldValueModified)="setGroup($any($event), i)"
                      [isStandalone]="true"
                      [data]="columnFilters | columnGroupFilter: isFromWindow"
                      [isInUniversalFilter]="true"
                      [isInsideOverflow]="true"></iu-autocomplete-ui>
                  </div>
                  <div class="delete" data-cy="universal-filter-remove-group">
                    <button
                      tooltipPosition="top"
                      [showDelay]="250"
                      [life]="3000"
                      [hideDelay]="250"
                      pTooltip="{{ 'generic.delete' | translate }}"
                      pButton
                      icon="icon-delete"
                      class="hover"
                      (click)="removeGroup(i, $event)"></button>
                  </div>
                </div>
              </li>
            </div>
          </ul>
          <ul
            *ngIf="selected.groups.length > 0"
            class="buttonAdd"
            [ngStyle]="{ order: gridView?.viewType === 'kanban' ? 0 : 1 }"
            style="padding-top: 5px">
            <li class="ui-row">
              <div class="p-col-12">
                <!-- au clic créaction d'un nouvel Li au-dessus de celui-ci et contenant un pemier auto-complete-->
                <button
                  pButton
                  *ngIf="canGroup"
                  class="button p-button-secondary"
                  style="cursor: pointer"
                  (click)="addGroup($event)"
                  label="{{ 'universalFilter.addGroup' | translate }}"
                  icon="icon-add"
                  data-cy="universal-filter-add-group-one"></button>
              </div>
            </li>
          </ul>
          <p *ngIf="selected.sortings.length > 0" [ngStyle]="{ order: 2 }" style="padding-top: 5px">
            {{ 'universalFilter.sortingTitle' | translate }}
          </p>
          <ul
            #sortsContainer
            *ngIf="selected.sortings.length > 0"
            [ngStyle]="{ order: 2 }"
            cdkDropList
            (cdkDropListDropped)="moveItem($event, 'sortings')"
            data-uf="sortings">
            <div
              *ngFor="let sorting of selected.sortings; first as isFirst; last as isLast; index as i"
              data-cy="universal-filter-sort"
              [attr.data-cy-index]="i"
              cdkDrag>
              <div class="ui-row-drag-preview" *cdkDragPreview>
                <!-- Preview du drag -->{{ 'universalFilter.sortingPreview' | translate
                }}{{ sorting.column.displayValue || '[ColumnName]' }} {{ sorting.sortingType }}
              </div>
              <div class="ui-row-drag-placeholder" *cdkDragPlaceholder><!-- Placeholder du drag --></div>
              <li class="ui-row">
                <div class="filtres">
                  <span cdkDragHandle class="icon-grab" style="font-size: 17px; cursor: move"></span>
                  <div class="lev-1 filter-field" data-cy="universal-filter-sort-autocomplete">
                    <iu-autocomplete-ui
                      [fieldValue]="sorting.column"
                      (fieldValueModified)="setSorting($any($event), i)"
                      [disable]="true"
                      [isStandalone]="true"
                      [data]="columnFilters"
                      [isInUniversalFilter]="true"
                      [isInsideOverflow]="true"></iu-autocomplete-ui>
                  </div>
                  <li class="asc-desc filter-field">
                    <div class="p-col-2 asc">
                      <input
                        type="radio"
                        name="sorting_{{ i }}"
                        value="asc"
                        checked="{{ sorting.sortingType === 'asc' ? 'checked' : null }}"
                        [(ngModel)]="sorting.sortingType"
                        label="asc"
                        data-cy="universal-filter-sort-type-asc" /><label id="asc">asc</label>
                    </div>
                    <div class="p-col-2 desc">
                      <input
                        type="radio"
                        name="sorting_{{ i }}"
                        value="desc"
                        checked="{{ sorting.sortingType === 'desc' ? 'checked' : null }}"
                        [(ngModel)]="sorting.sortingType"
                        label="desc"
                        data-cy="universal-filter-sort-type-desc" /><label id="desc">desc</label>
                    </div>
                  </li>
                  <div class="delete" data-cy="universal-filter-remove-sort">
                    <button
                      tooltipPosition="top"
                      [showDelay]="250"
                      [life]="3000"
                      [hideDelay]="250"
                      pTooltip="{{ 'generic.delete' | translate }}"
                      pButton
                      icon="icon-delete"
                      class="hover"
                      (click)="removeSorting(i, $event)"></button>
                  </div>
                </div>
              </li>
            </div>
          </ul>
          <ul *ngIf="selected.sortings.length > 0" class="buttonAdd" [ngStyle]="{ order: 2 }">
            <li class="ui-row">
              <div class="p-col-12">
                <!-- au clic créaction d'un nouvel Li au-dessus de celui-ci et contenant un pemier auto-complete-->
                <button
                  pButton
                  *ngIf="canSort"
                  class="button p-button-secondary"
                  style="cursor: pointer"
                  (click)="addSorting($event)"
                  label="{{ 'universalFilter.addSorting' | translate }}"
                  icon="icon-add"
                  data-cy="universal-filter-add-sort-one"></button>
              </div>
            </li>
          </ul>
          <div class="actions ui-row">
            <div class="action">
              <button
                *ngIf="canFilter && selected.filters.length <= 0"
                class="button p-button-secondary p-button action-btn"
                style="cursor: pointer"
                (click)="addFilter($event)"
                data-cy="universal-filter-add-filter-none">
                <span class="icon-add"></span>{{ 'universalFilter.addFilter' | translate }}
              </button>
              <button
                *ngIf="canGroup && selected.groups.length <= 0"
                class="button p-button-secondary p-button action-btn"
                style="cursor: pointer"
                (click)="addGroup($event)"
                data-cy="universal-filter-add-group-none">
                <span class="icon-add"></span>{{ 'universalFilter.addGroup' | translate }}
              </button>
              <button
                *ngIf="canSort && selected.sortings.length <= 0"
                class="button p-button-secondary p-button action-btn"
                style="cursor: pointer"
                (click)="addSorting($event)"
                data-cy="universal-filter-add-sort-none">
                <span class="icon-add"></span>{{ 'universalFilter.addSorting' | translate }}
              </button>
            </div>
            <div class="action right">
              <button
                type="button"
                pButton
                label="{{ 'generic.apply' | translate }}"
                class="p-button p-button-primary"
                (click)="applyFilter($event)"
                data-cy="apply-filter-btn"></button>
              <button
                type="button"
                pButton
                label="{{ 'universalFilter.resetFilters' | translate }}"
                class="p-button p-button-primary"
                (click)="resetFilters($event)"
                data-cy="reset-filter-btn"></button>
            </div>
          </div></div
      ></iu-prime-overlay>
    </span>
  </div>
</ng-template>

<!-- <li *ngIf="!isFirst" class="ui-row">
  <div class=" p-col-2 and">
    <input
      type="radio"
      name="link_{{ i }}"
      checked="{{ filter.link === 'and' ? 'checked' : null }}"
      [(ngModel)]="filter.link"
      value="and"
      label="Et"
    /><label id="Et">Et</label>
  </div>
  <div class=" p-col-2 or">
    <input
      type="radio"
      name="link_{{ i }}"
      checked="{{ filter.link === 'or' ? 'checked' : null }}"
      [(ngModel)]="filter.link"
      value="or"
      label="Ou"
    /><label id="Ou">Ou</label>
  </div>
</li> -->
