import { Component, HostListener, Injector, OnDestroy, OnInit } from '@angular/core';
import { AccountEditorWindowUiComponent } from '@iupics-components/specific/window/account-editor-window-ui/account-editor-window-ui.component';
import { AccountingViewerUiComponent } from '@iupics-components/specific/window/accounting-viewer-ui/accounting-viewer-ui.component';
import { AccountingViewerWindowUiComponent } from '@iupics-components/specific/window/accounting-viewer-window-ui/accounting-viewer-window-ui.component';
import { BpartnerBalanceComponent } from '@iupics-components/specific/window/bpartner-balance/bpartner-balance.component';
import { ChangelogWindowUIComponent } from '@iupics-components/specific/window/changelog-window-ui/changelog-window-ui.component';
import { CreateFromInvoiceWindowUiComponent } from '@iupics-components/specific/window/create-from-invoice-window-ui/create-from-invoice-window-ui.component';
import { CreateFromOpenInvoiceComponent } from '@iupics-components/specific/window/create-from-open-invoice/create-from-open-invoice.component';
import { CreateFromShipmentWindowUiComponent } from '@iupics-components/specific/window/create-from-shipment-window-ui/create-from-shipment-window-ui.component';
import { CreateFromStatementWindowUiComponent } from '@iupics-components/specific/window/create-from-statement-window-ui/create-from-statement-window-ui.component';
import { CruiseSituationUiComponent } from '@iupics-components/specific/window/cruise-situation-ui/cruise-situation-ui.component';
import { CardsUiComponent } from '@iupics-components/specific/window/customer-360/components/cards/cards-ui/cards-ui.component';
import { Customer360Component } from '@iupics-components/specific/window/customer-360/customer-360.component';
import { DirectPrintingDocumentsComponent } from '@iupics-components/specific/window/direct-printing-documents/direct-printing-documents.component';
import { FastCreateInvoiceVendorComponent } from '@iupics-components/specific/window/fast-create-invoice-vendor/fast-create-invoice-vendor.component';
import { FastCreateOrderComponent } from '@iupics-components/specific/window/fast-create-order/fast-create-order.component';
import { ImportFileLoaderWindowUiComponent } from '@iupics-components/specific/window/import-file-loader-window-ui/import-file-loader-window-ui.component';
import { InvoiceMatchingWindowImplComponent } from '@iupics-components/specific/window/invoice-matching-window-impl/invoice-matching-window-impl.component';
import { MatchingAcctViewerWindowUiComponent } from '@iupics-components/specific/window/matching-acct-viewer-window-ui/matching-acct-viewer-window-ui.component';

import { createCustomElement } from '@angular/elements';
import { LocationPanelComponent } from '@iupics-components/specific/window/location-panel/location-panel.component';
import { LoginasFormComponent } from '@iupics-components/specific/window/loginas-form/loginas-form.component';
import { MaterialTransactionComponent } from '@iupics-components/specific/window/material-transaction/material-transaction.component';
import { OperationManagementUIComponent } from '@iupics-components/specific/window/operation-management-ui/operation-management-ui.component';
import { PartnerViewComponent } from '@iupics-components/specific/window/partner-view/partner-view.component';
import { PaymentAllocationWindowUiComponent } from '@iupics-components/specific/window/payment-allocation-window-ui/payment-allocation-window-ui.component';
import { PaymentSelectionWindowUiComponent } from '@iupics-components/specific/window/payment-selection-window-ui/payment-selection-window-ui.component';
import { PlanningWindowUiComponent } from '@iupics-components/specific/window/planning-window-ui/planning-window-ui.component';
import { PosUiComponent } from '@iupics-components/specific/window/pos-ui/pos-ui.component';
import { PriceHistoryComponent } from '@iupics-components/specific/window/price-history/price-history.component';
import { ProcessInProgressWindowUiComponent } from '@iupics-components/specific/window/process-in-progress-window-ui/process-in-progress-window-ui.component';
import { ProductAttributeComponent } from '@iupics-components/specific/window/product-attribute/product-attribute.component';
import { ProductViewComponent } from '@iupics-components/specific/window/product-view/product-view.component';
import { SelectOrderComponent } from '@iupics-components/specific/window/select-order/select-order.component';
import { SpecificWindowUiComponent } from '@iupics-components/specific/window/specific-window-ui/specific-window-ui.component';
import { TreeMaintenanceUIComponent } from '@iupics-components/specific/window/tree-maintenance-ui/tree-maintenance-ui.component';
import { AutocompleteUiComponent } from '@iupics-components/standard/fields/autocomplete-ui/autocomplete-ui.component';
import { ButtonUiComponent } from '@iupics-components/standard/fields/button-ui/button-ui.component';
import { CalendarUiComponent } from '@iupics-components/standard/fields/calendar-ui/calendar-ui.component';
import { CommentUiComponent } from '@iupics-components/standard/fields/comment-ui/comment-ui.component';
import { InputFileUiComponent } from '@iupics-components/standard/fields/input-file-ui/input-file-ui.component';
import { InputImageUiComponent } from '@iupics-components/standard/fields/input-image-ui/input-image-ui.component';
import { InputLocationUiComponent } from '@iupics-components/standard/fields/input-location-ui/input-location-ui.component';
import { TagContentUiComponent } from '@iupics-components/standard/fields/input-mention-ui/components/tag-content-ui/tag-content-ui.component';
import { TagListItemUiComponent } from '@iupics-components/standard/fields/input-mention-ui/components/tag-list-item-ui/tag-list-item-ui.component';
import { InputNumberUiComponent } from '@iupics-components/standard/fields/input-number-ui/input-number-ui.component';
import { InputProductAttributesUiComponent } from '@iupics-components/standard/fields/input-product-attributes-ui/input-product-attributes-ui.component';
import { InputSwitchUiComponent } from '@iupics-components/standard/fields/input-switch-ui/input-switch-ui.component';
import { InputTextHtmlUiComponent } from '@iupics-components/standard/fields/input-text-html-ui/input-text-html-ui.component';
import { InputTextUiComponent } from '@iupics-components/standard/fields/input-text-ui/input-text-ui.component';
import { InputTextareaUiComponent } from '@iupics-components/standard/fields/input-textarea-ui/input-textarea-ui.component';
import { InputTimeUiComponent } from '@iupics-components/standard/fields/input-time-ui/input-time-ui.component';
import { StepperUiComponent } from '@iupics-components/standard/fields/stepper-ui/stepper-ui.component';
import { GridViewUiComponent } from '@iupics-components/standard/grid/grid-view-ui/grid-view-ui.component';
import { AccordionUiComponent } from '@iupics-components/standard/layouts/accordion-ui/accordion-ui.component';
import { AdditionalInfoUiComponent } from '@iupics-components/standard/layouts/additional-info-ui/additional-info-ui.component';
import { EditTabUiComponent } from '@iupics-components/standard/layouts/edit-tab-ui/edit-tab-ui.component';
import { EditViewUiComponent } from '@iupics-components/standard/layouts/edit-view-ui/edit-view-ui.component';
import { GridUiComponent } from '@iupics-components/standard/layouts/grid-ui/grid-ui.component';
import { RowUiComponent } from '@iupics-components/standard/layouts/row-ui/row-ui.component';
import { MenuBarDetailUiComponent } from '@iupics-components/standard/menu/menu-bar-detail-ui/menu-bar-detail-ui.component';
import { NotesPanelUiComponent } from '@iupics-components/standard/notes/notes-panel-ui/notes-panel-ui.component';
import { ValuePreferencePanelComponent } from '@iupics-components/standard/value-preference-panel/value-preference-panel.component';
import { CacheManagerService } from '@iupics-manager/managers/cache-manager/cache-manager.service';
import { IupicsCookieService } from '@iupics-manager/managers/security-manager/cookies/iupics-cookie.service';
import { Global } from '@iupics-manager/models/global-var';
import { IupicsMessage } from '@iupics-manager/models/iupics-message';
import { TranslateService } from '@ngx-translate/core';
import { DomHandler } from 'primeng/dom';
import { RecentItemWidgetUiComponent } from './modules/iupics-widgets/components/recent-item-widget-ui/recent-item-widget-ui.component';

@Component({
  selector: 'iu-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  private subscriptions = [];
  globalEnv = Global;
  successMessage: IupicsMessage;
  clearMessageDelay: NodeJS.Timeout;

  constructor(
    private cookieService: IupicsCookieService,
    private translateService: TranslateService,
    private injector: Injector
  ) {
    this.createCustomElements();

    if (document.querySelector('.indexLoaderMsg')) {
      (document.querySelector('.indexLoaderMsg') as HTMLElement).style.display = 'none';
    }
    if (document.querySelector('.indexLoader')) {
      (document.querySelector('.indexLoader') as HTMLElement).style.display = 'none';
    }
    this.overridePrimengDomHandler();
  }

  private createCustomElements() {
    [
      {
        name: 'iu-tag-list-item-element',
        component: TagListItemUiComponent,
      },
      {
        name: 'iu-tag-content-element',
        component: TagContentUiComponent,
      },
    ].forEach(({ name, component }) => {
      const element = createCustomElement(component, { injector: this.injector });
      if (!customElements.get(name)) {
        // Register the custom element with the browser
        customElements.define(name, element);
      }
    });
  }

  private overridePrimengDomHandler() {
    DomHandler.addClass = (element: any, className: string): void => {
      if (className.trim()) {
        if (element.classList) {
          element.classList.add(className);
        } else {
          element.className += ' ' + className;
        }
      }
    };

    DomHandler.addMultipleClasses = (element: any, className: string): void => {
      if (element.classList) {
        const styles: string[] = className
          .trim()
          .split(' ')
          .filter((cn) => cn.trim());
        for (let i = 0; i < styles.length; i++) {
          element.classList.add(styles[i]);
        }
      } else {
        const styles: string[] = className
          .trim()
          .split(' ')
          .filter((cn) => cn.trim());
        for (let i = 0; i < styles.length; i++) {
          element.className += ' ' + styles[i];
        }
      }
    };
  }

  ngOnInit() {
    CacheManagerService.iupics_components.set('AccordionUiComponent', AccordionUiComponent);
    CacheManagerService.iupics_components.set('AdditionalInfoUiComponent', AdditionalInfoUiComponent);
    CacheManagerService.iupics_components.set('AutocompleteUiComponent', AutocompleteUiComponent);
    CacheManagerService.iupics_components.set('ButtonUiComponent', ButtonUiComponent);
    CacheManagerService.iupics_components.set('CalendarUiComponent', CalendarUiComponent);
    CacheManagerService.iupics_components.set('CommentUiComponent', CommentUiComponent);
    CacheManagerService.iupics_components.set('EditTabUiComponent', EditTabUiComponent);
    CacheManagerService.iupics_components.set('EditViewUiComponent', EditViewUiComponent);
    CacheManagerService.iupics_components.set('GridUiComponent', GridUiComponent);
    CacheManagerService.iupics_components.set('GridViewUiComponent', GridViewUiComponent);
    CacheManagerService.iupics_components.set('InputFileUiComponent', InputFileUiComponent);
    CacheManagerService.iupics_components.set('InputImageUiComponent', InputImageUiComponent);
    CacheManagerService.iupics_components.set('InputLocationUiComponent', InputLocationUiComponent);
    CacheManagerService.iupics_components.set('InputNumberUiComponent', InputNumberUiComponent);
    CacheManagerService.iupics_components.set('InputProductAttributesUiComponent', InputProductAttributesUiComponent);
    CacheManagerService.iupics_components.set('InputSwitchUiComponent', InputSwitchUiComponent);
    CacheManagerService.iupics_components.set('InputTextareaUiComponent', InputTextareaUiComponent);
    CacheManagerService.iupics_components.set('InputTextUiComponent', InputTextUiComponent);
    CacheManagerService.iupics_components.set('InputTimeUiComponent', InputTimeUiComponent);
    CacheManagerService.iupics_components.set('MenuBarDetailUiComponent', MenuBarDetailUiComponent);
    CacheManagerService.iupics_components.set('RowUiComponent', RowUiComponent);
    CacheManagerService.iupics_components.set('SelectOrderComponent', SelectOrderComponent);
    CacheManagerService.iupics_components.set('StepperUiComponent', StepperUiComponent);
    CacheManagerService.iupics_components.set('ValuePreferencePanelComponent', ValuePreferencePanelComponent);
    CacheManagerService.iupics_components.set('InputTextHtmlUiComponent', InputTextHtmlUiComponent);
    CacheManagerService.iupics_components.set('CardsUiComponent', CardsUiComponent);
    CacheManagerService.iupics_components.set('NotesPanelUiComponent', NotesPanelUiComponent);

    CacheManagerService.iupics_specific_window.set('default', SpecificWindowUiComponent);
    CacheManagerService.iupics_specific_window.set('AccountEditorWindowUiComponent', AccountEditorWindowUiComponent);
    CacheManagerService.iupics_specific_window.set('AccountingViewerUiComponent', AccountingViewerUiComponent);
    CacheManagerService.iupics_specific_window.set(
      'AccountingViewerWindowUiComponent',
      AccountingViewerWindowUiComponent
    );
    CacheManagerService.iupics_specific_window.set('BpartnerBalanceComponent', BpartnerBalanceComponent);
    CacheManagerService.iupics_specific_window.set(
      'CreateFromInvoiceWindowUiComponent',
      CreateFromInvoiceWindowUiComponent
    );
    CacheManagerService.iupics_specific_window.set('CreateFromOpenInvoiceComponent', CreateFromOpenInvoiceComponent);
    CacheManagerService.iupics_specific_window.set(
      'CreateFromShipmentWindowUiComponent',
      CreateFromShipmentWindowUiComponent
    );
    CacheManagerService.iupics_specific_window.set(
      'CreateFromStatementWindowUiComponent',
      CreateFromStatementWindowUiComponent
    );
    CacheManagerService.iupics_specific_window.set('CruiseSituationUiComponent', CruiseSituationUiComponent);
    CacheManagerService.iupics_specific_window.set('FastCreateOrderComponent', FastCreateOrderComponent);
    CacheManagerService.iupics_specific_window.set(
      'DirectPrintingDocumentsComponent',
      DirectPrintingDocumentsComponent
    );
    CacheManagerService.iupics_specific_window.set(
      'ImportFileLoaderWindowUiComponent',
      ImportFileLoaderWindowUiComponent
    );
    CacheManagerService.iupics_specific_window.set(
      'InvoiceMatchingWindowImplComponent',
      InvoiceMatchingWindowImplComponent
    );
    CacheManagerService.iupics_specific_window.set(
      'MatchingAcctViewerWindowUiComponent',
      MatchingAcctViewerWindowUiComponent
    );
    CacheManagerService.iupics_specific_window.set(
      'PaymentAllocationWindowUiComponent',
      PaymentAllocationWindowUiComponent
    );
    CacheManagerService.iupics_specific_window.set(
      'PaymentSelectionWindowUiComponent',
      PaymentSelectionWindowUiComponent
    );
    CacheManagerService.iupics_specific_window.set('PosUiComponent', PosUiComponent);
    CacheManagerService.iupics_specific_window.set('PriceHistoryComponent', PriceHistoryComponent);
    CacheManagerService.iupics_specific_window.set(
      'ProcessInProgressWindowUiComponent',
      ProcessInProgressWindowUiComponent
    );
    CacheManagerService.iupics_specific_window.set('ProductAttributeComponent', ProductAttributeComponent);
    CacheManagerService.iupics_specific_window.set('SpecificWindowUiComponent', SpecificWindowUiComponent);
    CacheManagerService.iupics_specific_window.set('OperationManagementUIComponent', OperationManagementUIComponent);
    CacheManagerService.iupics_specific_window.set('ProductViewComponent', ProductViewComponent);
    CacheManagerService.iupics_specific_window.set('ChangelogWindowUIComponent', ChangelogWindowUIComponent);
    CacheManagerService.iupics_specific_window.set('LocationPanelComponent', LocationPanelComponent);
    CacheManagerService.iupics_specific_window.set('TreeMaintenanceUIComponent', TreeMaintenanceUIComponent);
    CacheManagerService.iupics_specific_window.set('LoginasFormComponent', LoginasFormComponent);
    CacheManagerService.iupics_specific_window.set('PlanningWindowUiComponent', PlanningWindowUiComponent);
    CacheManagerService.iupics_specific_window.set(
      'FastCreateInvoiceVendorComponent',
      FastCreateInvoiceVendorComponent
    );
    CacheManagerService.iupics_widgets.set('RecentItemWidgetUiComponent', RecentItemWidgetUiComponent);
    CacheManagerService.iupics_specific_window.set('PartnerViewComponent', PartnerViewComponent);
    CacheManagerService.iupics_specific_window.set('MaterialTransactionComponent', MaterialTransactionComponent);
    CacheManagerService.iupics_specific_window.set('Customer360Component', Customer360Component);
    // CacheManagerService.iupics_specific_window.set('TextFieldUiComponent', TextFieldUiComponent); // ? pourquoi on a mis un input dans la map des fenêtres spécifiques ?
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => {
      sub.unsubscribe();
    });
  }

  @HostListener('document:keydown.shift.alt.delete', ['$event'])
  handleKeyPressed(event: KeyboardEvent) {
    if (event.shiftKey && event.altKey && event.key === 'Delete') {
      this.cookieService.clearLsCookies();
      this.successMessage = new IupicsMessage(
        null,
        this.translateService.instant('homepage.clearLocalStorageCookies'),
        'success'
      );
      this.clearMessageDelay = setTimeout(() => {
        this.closeSuccessMessage();
      }, 1500);
      event.preventDefault();
    }
  }

  closeSuccessMessage() {
    if (this.clearMessageDelay) {
      clearTimeout(this.clearMessageDelay);
    }

    location.reload(); // charger du server et non pas du cache
    this.successMessage = null;
  }
}
