import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { RecentItem } from '@compiere-ws/models/compiere-recent-item';
import { RecentItemsManagerService } from '@iupics-manager/managers/recent-items-manager/recent-items-manager.service';
import { MenuItemUI } from '@web-desktop/models/menu-item-ui';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'iu-recent-item-widget-ui',
  templateUrl: './recent-item-widget-ui.component.html',
  styleUrls: ['./recent-item-widget-ui.component.scss'],
})
export class RecentItemWidgetUiComponent implements OnInit, AfterViewInit, OnDestroy {
  recentItems$: Observable<RecentItem[]>;
  refresherSub: Subscription;

  @Input() widget: MenuItemUI;

  @Output() openTabEmitter = new EventEmitter<any>();

  constructor(private riManager: RecentItemsManagerService) {}

  ngOnInit() {
    this.recentItems$ = this.riManager.getRecentItems();
  }

  ngAfterViewInit() {
    // this.refresherSub = this.widget.refresher.subscribe(() => (this.recentItems$ = this.riManager.getRecentItems()));
  }

  openTab(event: Event, item: RecentItem) {
    event.stopPropagation();
    this.openTabEmitter.emit({
      actionID: item.entityID,
      menuType: 'Window',
      name: item.name.split(':')[0],
      iconClass: 'fa fa-windows',
      openedRecordId: item.entityKey,
    });
  }

  ngOnDestroy() {
    // this.refresherSub.unsubscribe();
  }
}
