import { Pipe, PipeTransform } from '@angular/core';
import { ColumnFilterAutocomplete } from '@iupics-components/models/autocomplete-interfaces';
import { cloneDeep } from 'lodash';

@Pipe({
  name: 'autocompleteSuggestionsFilter',
})
export class AutocompleteSuggestionsFilterPipe implements PipeTransform {
  transform(value: any, exclude?: any, current?: string): any {
    if (value && 'items' in value) {
      if (current) {
        exclude = exclude.filter((c: string) => c !== current);
      }
      if (exclude) {
        const newValue: { items: ColumnFilterAutocomplete[] } = { items: cloneDeep(value.items) };
        newValue.items = newValue.items.filter((item) => !exclude.find((columnName: string) => columnName === item.id));
        return newValue;
      }
    }

    if (Array.isArray(value)) {
      if (Array.isArray(exclude)) {
        if (exclude && exclude.length > 0) {
          return value.filter(({ id: id1 }) => !exclude.find(({ id: id2 }) => id1 === id2));
        }
      } else if (exclude && exclude.id) {
        return value.filter(({ id: id1 }) => exclude.id !== id1);
      }
    }

    return value;
  }
}
