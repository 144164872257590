<div
  #widgetElement
  class="box firstbox iupics-hover-item"
  [ngStyle]="{
    'border-top-color': !widget.color ? 'var(--widget-bd-color-changeColor)' : widget.color,
    height: '100%'
  }"
  draggable="true"
  (drag)="$event.preventDefault()"
  (dragstart)="$event.preventDefault(); showMenu = false; showColor = false"
  (dragend)="$event.preventDefault()"
  (dragenter)="$event.preventDefault()"
  (dragleave)="$event.preventDefault()"
  (dragover)="$event.preventDefault()"
  (drop)="$event.preventDefault()"
  [attr.data-cy-widget-name-favorite]="widget.name">
  <div class="box p-col-12 apiz-p-grid">
    <div class="box p-col-11">
      <h4 data-cy="widget-label-favorite">{{ widget.name }}</h4>
    </div>
    <div draggable="true" (dragstart)="$event.stopPropagation(); $event.preventDefault()" class="box p-col-1 edit-menu">
      <ul [ngClass]="['edit-item', showMenu || isMobile ? 'blocked' : '']">
        <li [ngClass]="[showMenu ? 'opened' : '']">
          <a
            href="#"
            class="icon-menu-bullets"
            data-cy="widget-open-edit-favorite"
            (click)="$event.preventDefault(); showColor = false"
            iuToggleButton
            [iuToggleButtonSwitch]="true"
            [(iuToggleButtonVar)]="showMenu"
            ><span> </span
          ></a>
          <ul data-cy="widget-edit-favorite">
            <ng-container *ngIf="showMenu">
              <ng-container *ngIf="!showColor">
                <li>
                  <a data-cy="widget-change-color" href="#" (click)="$event.preventDefault(); showColor = true">{{
                    'widgetUi.changeColor' | translate
                  }}</a>
                </li>
                <li>
                  <a data-cy="widget-delete-favorite" href="#" (click)="$event.preventDefault(); onDelete(index)">{{
                    'widgetUi.remove' | translate
                  }}</a>
                </li>
              </ng-container>
              <li *ngIf="showColor" class="new-menu">
                <div style="text-align: center" class="widgetColor">
                  <div class="color-container">
                    <div class="dot" *ngFor="let color of defaultColor">
                      <span
                        [ngClass]="['dot-color', isSelectedColor(color) ? 'selected' : '']"
                        [ngStyle]="{ 'background-color': color }"
                        (click)="changeDefaultColor(color)"></span>
                    </div>
                  </div>
                  <div *ngIf="!showMoreColor" (click)="showMoreColor = true">
                    <img
                      width="25"
                      height="25"
                      src="https://raw.githubusercontent.com/SahAssar/bolt-bootstrap-colorpicker/master/web/colorpicker-icon.png"
                      alt=""
                      style="cursor: pointer" />
                  </div>
                  <div
                    class="colorpicker"
                    *ngIf="showMoreColor || (!oneDefaultColorIsSelected && widget.color !== undefined)">
                    <p-colorPicker
                      [inline]="true"
                      [(ngModel)]="colorpicked"
                      (onChange)="onColorPicker($event)"></p-colorPicker>
                  </div>
                  <button
                    pButton
                    class="p-button p-button-secondary margin changeColor"
                    (click)="cancelColorChange()"
                    label="{{ 'widgetUi.cancel' | translate }}"></button>
                  <button
                    pButton
                    class="p-button p-button-secondary margin changeColor"
                    (click)="onChangeColor($event)"
                    label="{{ 'widgetUi.editColor' | translate }}"></button>
                </div>
              </li>
            </ng-container>
          </ul>
        </li>
      </ul>
    </div>
  </div>
  <div class="box p-col-12 apiz-p-grid content">
    <div [ngClass]="{ 'box p-col-12': true, centeredContainer: widget.angularClass }">
      <ng-container *ngIf="!widget.angularClass">
        <ng-container *ngIf="iupicsWidget$ | async as iupicsWidget; else ghostWidget">
          <iu-chart-widget
            *ngIf="iupicsWidget.viewType === ViewType.CHART && !widget.angularClass && isLoaded"
            [@enterAnimation]
            [widget]="iupicsWidget"></iu-chart-widget>
          <iu-list-widget
            *ngIf="iupicsWidget.viewType === ViewType.LIST && !widget.angularClass && isLoaded"
            [@enterAnimation]
            [widget]="iupicsWidget"></iu-list-widget>
          <iu-grid-widget
            *ngIf="iupicsWidget.viewType === ViewType.GRID && !widget.angularClass && isLoaded"
            [@enterAnimation]
            [widget]="iupicsWidget"
            (openTabEmitter)="onOpenTab($event)"></iu-grid-widget>
        </ng-container>
        <ng-template #ghostWidget>
          <div [ngStyle]="{ '--svg-ghost-url': ghostElt }" data-loading="true" data-loading-svg="true"></div>
        </ng-template>
      </ng-container>
      <ng-container #widgetVCR></ng-container>
    </div>
  </div>
</div>
