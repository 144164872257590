<!-- #region header -->
<div class="iu-email-editor-header">
  <iu-input-chips-ui
    [cssClass]="'p-col-12 iu-email-editor-header-field'"
    [label]="'email.destination' | translate"
    [addOnSpace]="true"
    [addOnBlur]="true"
    [editOnBackspace]="true"
    [(fieldValue)]="to"
    [matchPattern]="regexMail"
    [columnName]="'email-destination'"
    [inputStyle]="{ minWidth: '200px', background: 'var(--email-editor-bg-color-header-field)' }"
    [inContainer]="true"></iu-input-chips-ui>
  <div *ngIf="!displayCC || !displayCCI" class="iu-email-editor-header-display-cc-cci">
    <a *ngIf="!displayCC" href="#" (click)="$event.preventDefault(); displayCC = true" data-cy="email-add-cc">
      {{ 'email.addCC' | translate }}
    </a>
    <a *ngIf="!displayCCI" href="#" (click)="$event.preventDefault(); displayCCI = true" data-cy="email-add-cci">
      {{ 'email.addCCI' | translate }}
    </a>
  </div>
  <iu-input-chips-ui
    *ngIf="displayCC"
    [cssClass]="'p-col-12 iu-email-editor-header-field'"
    [label]="'CC'"
    [addOnSpace]="true"
    [addOnBlur]="true"
    [editOnBackspace]="true"
    [(fieldValue)]="cc"
    [matchPattern]="regexMail"
    [columnName]="'email-cc'"
    [inputStyle]="{ minWidth: '200px', background: 'var(--email-editor-bg-color-header-field)' }"
    [inContainer]="true"></iu-input-chips-ui>
  <iu-input-chips-ui
    *ngIf="displayCCI"
    [cssClass]="'p-col-12 iu-email-editor-header-field'"
    [label]="'CCI'"
    [addOnSpace]="true"
    [addOnBlur]="true"
    [editOnBackspace]="true"
    [(fieldValue)]="cci"
    [matchPattern]="regexMail"
    [columnName]="'email-cci'"
    [inputStyle]="{ minWidth: '200px', background: 'var(--email-editor-bg-color-header-field)' }"
    [inContainer]="true"></iu-input-chips-ui>
  <iu-input-text-ui
    [cssClass]="'p-col-12 iu-email-editor-header-field'"
    [label]="'email.subject' | translate"
    [(fieldValue)]="subject"
    [columnName]="'email-subject'"></iu-input-text-ui>
</div>
<!-- #endregion -->
<!-- #region body -->
<div class="iu-email-editor-body">
  <div #quillHeader>
    <!-- desktop -->
    <div [ngStyle]="{ display: isMobile ? 'none' : 'flex' }" class="iu-email-editor-body-header">
      <select class="ql-header">
        <option value="1">Heading</option>
        <option value="2">Subheading</option>
        <option selected>Normal</option>
      </select>
      <select class="ql-font">
        <option selected>Sans Serif</option>
        <option value="serif">Serif</option>
        <option value="monospace">Monospace</option>
      </select>
      <button class="ql-bold" aria-label="Bold">Bold</button>
      <button class="ql-italic" aria-label="Italic">Italic</button>
      <button class="ql-underline" aria-label="Underline">Underline</button>
      <select class="ql-color"></select>
      <select class="ql-background"></select>
      <button class="ql-list" value="ordered" aria-label="Ordered List"></button>
      <button class="ql-list" value="bullet" aria-label="Unordered List"></button>
      <select class="ql-align">
        <option selected></option>
        <option value="center"></option>
        <option value="right"></option>
        <option value="justify"></option>
      </select>
      <button class="ql-link" aria-label="Insert Link"></button>
      <button class="ql-image" aria-label="Insert Image"></button>
      <button class="ql-code-block" aria-label="Insert Code Block"></button>
      <button class="ql-clean" aria-label="Remove Styles"></button>
    </div>
    <!-- mobile -->
    <div [ngStyle]="{ display: isMobile ? 'flex' : 'none' }" class="iu-email-editor-mobile">
      <div class="iu-email-editor-mobile-menu-display" (click)="displayMobileMenu = !displayMobileMenu">
        Menu<span
          [ngClass]="['fa', displayMobileMenu ? 'fa-chevron-down' : 'fa-chevron-right']"
          style="margin-left: 5px"></span>
      </div>
      <div [ngStyle]="{ display: displayMobileMenu ? 'flex' : 'none' }" class="iu-email-editor-mobile-menu">
        <div class="iu-email-editor-mobile-menu-fieldset">
          <select class="ql-header">
            <option value="1">Heading</option>
            <option value="2">Subheading</option>
            <option selected>Normal</option>
          </select>
          <select class="ql-font">
            <option selected>Sans Serif</option>
            <option value="serif">Serif</option>
            <option value="monospace">Monospace</option>
          </select>
        </div>
        <div class="iu-email-editor-mobile-menu-fieldset">
          <button class="ql-bold" aria-label="Bold"></button> <button class="ql-italic" aria-label="Italic"></button>
          <button class="ql-underline" aria-label="Underline"></button>
          <select class="ql-color"></select>
          <select class="ql-background"></select>
          <button class="ql-list" value="ordered" aria-label="Ordered List"></button>
          <button class="ql-list" value="bullet" aria-label="Unordered List"></button>
          <select class="ql-align">
            <option selected></option>
            <option value="center"></option>
            <option value="right"></option>
            <option value="justify"></option>
          </select>
          <button class="ql-link" aria-label="Insert Link"></button>
          <button class="ql-image" aria-label="Insert Image"></button>
          <button class="ql-code-block" aria-label="Insert Code Block"></button>
          <button class="ql-clean" aria-label="Remove Styles"></button>
        </div>
      </div>
    </div>
  </div>
  <div #quillEditor [ngStyle]="style" data-cy="email-body"></div>
</div>
<!-- #endregion -->
<!-- #region footer -->
<div class="iu-email-editor-footer">
  <div class="iu-email-editor-footer-attachement">
    <iu-input-file-ui
      [data]="{ isParam: false, columnName: 'email-fileupload' }"
      [styleClass]="'iu-email-editor-fileupload'"
      [multiple]="true"
      [displayButtons]="false"
      [displayFileUploaded]="true"
      [auto]="true"
      [type]="'EMAIL_ATTACHEMENT'"
      [mustGet]="false"
      [isLoaderActive]="isLoaderActive"
      [uploadedFiles]="uploadedFiles"
      [uploadedFileList_title]="'email.attachedFilesTitle'"
      [uploadedFileList_noFiles]="'email.noAttachedFiles'"
      [uploadingFileList_title]="'email.attachFiles'">
    </iu-input-file-ui>
  </div>
  <div class="iu-email-editor-footer-template">
    <ng-container *ngIf="templates$ | async as templates">
      <iu-autocomplete-ui
        #emailModelAutocomplete
        [cssClass]="'p-col-12 iu-email-editor-header-field'"
        tooltip="{{ 'email.emailModels' | translate }}"
        label="{{ 'email.emailModels' | translate }}"
        limitSuggestions="3"
        (autoCompleteEmitter)="selectTemplate($event.model)"
        [data]="templates"
        [columnName]="'email-templates'"
        [(fieldValue)]="defaultEmailModel"></iu-autocomplete-ui>
    </ng-container>
  </div>
  <div class="iu-email-editor-footer-buttons">
    <button class="p-button p-button-secondary" (click)="cancelEmailEmitter.emit()">
      {{ 'email.cancel' | translate }}
    </button>
    <button
      class="p-button p-button-primary"
      [disabled]="isLoaderActive"
      (click)="sendEmail($event)"
      data-cy="email-send-button">
      {{ 'email.sendEmail' | translate }}
    </button>
  </div>
</div>
<!-- #endregion -->
