<div class="iupics-calendar-view-box">
  <iu-calendar-toolbar-ui
    (next)="showNextPeriod()"
    (prev)="showPrevPeriod()"
    (today)="showToday()"
    (changeView)="changeView($event)"
    [title]="title">
  </iu-calendar-toolbar-ui>
  <div #fc class="iupics-calendar-view-content"></div>
</div>
