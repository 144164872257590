import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { Global } from '@iupics-manager/models/global-var';
import { MenuFavoritesCategoryUI } from '@web-desktop/models/menu-item-ui';

@Component({
  selector: 'iu-dashboard-group-tab-ui',
  templateUrl: './dashboard-group-tab-ui.component.html',
  styleUrls: ['./dashboard-group-tab-ui.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DashboardGroupTabUiComponent implements OnInit {
  @ViewChild('inputText') tref: ElementRef;

  @Input() group: MenuFavoritesCategoryUI;

  @Output() renameEvent = new EventEmitter<any>();
  @Output() selectItemEvent = new EventEmitter<number>();
  @Output() deleteEvent = new EventEmitter<any>();

  current_menu_name: string;
  showRename = false;
  showMenu = false;

  isMobile = Global.isMobile();

  constructor() {}

  ngOnInit(): void {}

  showRenameCadre(menu: string) {
    this.current_menu_name = menu;
    this.showRename = true;
    this.showMenu = false;
    setTimeout(() => this.tref.nativeElement.focus(), 1);
  }

  onRename(event: Event) {
    event.stopPropagation();
    this.group.name = this.current_menu_name;
    this.renameEvent.emit(this.current_menu_name);
    this.showRename = false;
  }
}
