<div class="wd-notification-center">
  <h2 class="wd-notification-center-title">
    <span class="wd-notification-center-title-text">{{ 'notificationCenterUi.notificationCenter' | translate }}</span>
    <i
      class="icon-next wd-notification-center-title-btn"
      aria-hidden="true"
      role="button"
      tabindex="0"
      (click)="toggleNotifCenter.emit($event)"
      data-cy="notification-center-close-button"></i>
  </h2>
  <div class="wd-notification-center-tabs">
    <a
      [ngClass]="{
        'wd-notification-center-tab': true,
        active: notificationType === 'N'
      }"
      (click)="setActiveTab($event, 'N')"
      data-cy="notification-center-tab-notif">
      {{ 'notificationCenterUi.tab-notification' | translate }}
      <span class="number">{{ nbNotification }}</span>
    </a>
    <!-- <a
      [ngClass]="{
        'wd-notification-center-tab': true,
        active: notificationType === 'A'
      }"
      (click)="setActiveTab($event, 'C')"
    >
      {{ 'notificationCenterUi.tab-alert' | translate }}
      <span class="number">{{ nbAlerts }}</span>
    </a> -->
    <a
      [ngClass]="{
        'wd-notification-center-tab': true,
        active: notificationType === 'C'
      }"
      (click)="setActiveTab($event, 'C')"
      data-cy="notification-center-tab-chat">
      {{ 'notificationCenterUi.tab-chat' | translate }}
      <span class="number">{{ nbChats }}</span>
    </a>
  </div>
  <div class="wd-notification-center-toolbar">
    <button
      pButton
      tooltipPosition="top"
      [showDelay]="250"
      [life]="3000"
      [hideDelay]="250"
      pTooltip="{{ 'notificationCenterUi.deleteAll' | translate }}"
      type="button"
      icon="icon-delete"
      (click)="setMessageType(showMessageType === 'confirmDelete' ? null : 'confirmDelete')"
      data-cy="notification-center-deleteAll-btn"></button>
    <button
      pButton
      tooltipPosition="top"
      [showDelay]="250"
      [life]="3000"
      [hideDelay]="250"
      pTooltip="{{ 'notificationCenterUi.viewAll' | translate }}"
      icon="far fa-eye"
      type="button"
      (click)="viewAllNotifications($event)"
      data-cy="notification-center-viewAll-btn"></button>
    <button
      pButton
      tooltipPosition="top"
      [showDelay]="250"
      [life]="3000"
      [hideDelay]="250"
      pTooltip="{{ 'notificationCenterUi.refresh' | translate }}"
      icon="icon-refresh3x"
      type="button"
      (click)="refresh($event)"></button>
  </div>
  <div
    [ngClass]="[
      'wd-notification-center-message',
      showMessageType && showMessageType !== null ? 'expanded' : '',
      showMessageType === 'confirmDelete' ? 'message-delete' : 'message-view'
    ]">
    <ng-container *ngIf="showMessageType === 'confirmDelete'">
      <span>{{ 'notificationCenterUi.confirmDelete' | translate }}</span>
      <button
        pButton
        icon="fa fa-check"
        type="button"
        (click)="deleteAllNotifications($event)"
        [title]="'generic.confirm' | translate"
        data-cy="notification-center-deleteAll-btn-confirm"></button>
      <button
        pButton
        icon="fa fa-ban"
        type="button"
        (click)="setMessageType()"
        [title]="'generic.cancel' | translate"></button>
    </ng-container>
  </div>
  <div class="wd-notification-center-content">
    <iu-notification
      *ngFor="let item of datas$ | async | filterList : filterListOptions"
      [item]="item"
      [areButtonsDisabled]="areButtonsDisabled"
      (notificationEmitter)="deleteNotification($event)"
      (dlFileEmitter)="handleDlFile($event, item)">
    </iu-notification>
  </div>
</div>
