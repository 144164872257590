import { AfterViewInit, Component, OnInit, ViewChild, ViewContainerRef, ViewRef } from '@angular/core';
import { DataStore, DataStoreRequest } from '@compiere-ws/models/compiere-data-json';
import { CompiereProcessService } from '@compiere-ws/services/compiere-process/compiere-process.service';
import { ProcessInProgressService } from '@compiere-ws/services/process-in-progress/process-in-progress.service';
import { SocketService } from '@compiere-ws/services/socket/socket.service';
import { CustomDesignItemType } from '@iupics-components/models/custom-design';
import { AutocompleteUiComponent } from '@iupics-components/standard/fields/autocomplete-ui/autocomplete-ui.component';
import { GridViewUiComponent } from '@iupics-components/standard/grid/grid-view-ui/grid-view-ui.component';
import { DataStoreService } from '@iupics-manager/managers/data-store/data-store.service';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { UICreatorService } from '@iupics-manager/managers/ui-creator/ui-creator.service';
import { WindowFactoryService } from '@iupics-manager/managers/ui-creator/window-factory/window-factory.service';
import { DynamicComponent } from '@iupics-manager/models/dynamic-component';
import { IupicsData } from '@iupics-manager/models/iupics-data';
import { IupicsTypeEvent } from '@iupics-manager/models/iupics-event';
import { TranslateService } from '@ngx-translate/core';
import { debounce } from 'lodash';
import { zip } from 'rxjs';
import { SpecificGridQuery, SpecificWindowUiComponent } from '../specific-window-ui/specific-window-ui.component';

@Component({
  selector: 'iu-invoice-matching-window-impl',
  templateUrl: './invoice-matching-window-impl.component.html',
  styleUrls: ['./invoice-matching-window-impl.component.scss'],
})
export class InvoiceMatchingWindowImplComponent extends SpecificWindowUiComponent implements OnInit, AfterViewInit {
  @ViewChild('top', { read: ViewContainerRef, static: true })
  vcrTop: ViewContainerRef;
  @ViewChild('bot', { read: ViewContainerRef, static: true })
  vcrBot: ViewContainerRef;
  @ViewChild('matchTo', { read: ViewContainerRef, static: true })
  vcrMatchTo: ViewContainerRef;
  @ViewChild('matchTest', { read: ViewContainerRef, static: true })
  vcrMatchTest: ViewContainerRef;
  @ViewChild('matchFrom', { read: ViewContainerRef, static: true })
  vcrMatchFrom: ViewContainerRef;
  // container temporaire pour les elements à détacher
  @ViewChild('arrayTmp', { read: ViewContainerRef, static: true })
  vcrArrayTmp: ViewContainerRef;
  vcrArrayTmpViewRefs: Map<string, ViewRef> = new Map<string, ViewRef>();
  matchFrom: any;
  matchTo: any;
  matchMode: any;
  isReturnTrx: any;
  isConsigned: any;
  sameBPartner: any;
  sameProduct: any;
  sameQty: any;
  samePriceList: any;
  matchFromSuggestions: string[];
  matchToSuggestions: string[];
  currentMatchFromTableName: string;
  currentMatchToTableName: string;
  selectedRows: any[] = [];
  toReconcile = 0;
  lettering = 0;
  difference = 0;
  toReconcileLabel = 'specificWindow.invoiceMatching.toReconcile';
  letteringLabel = 'specificWindow.invoiceMatching.lettering';
  differenceLabel = 'specificWindow.invoiceMatching.difference';
  constructor(
    windowFactory: WindowFactoryService,
    uiCreator: UICreatorService,
    store: DataStoreService,
    processService: CompiereProcessService,
    protected translateService: TranslateService,
    socketService: SocketService,
    connectorService: SecurityManagerService,
    progressService: ProcessInProgressService
  ) {
    super(
      windowFactory,
      uiCreator,
      store,
      processService,
      socketService,
      connectorService,
      progressService,
      translateService
    );
    this.isModal = false;
    this.customDesignArray.push(
      {
        vcr: 'vcrTop',
        type: CustomDesignItemType.FIELD,
        columnName: 'MatchFrom',
        cssClass: 'p-col-12 p-md-3 p-lg-1',
      },
      {
        vcr: 'vcrTop',
        type: CustomDesignItemType.FIELD,
        columnName: 'MatchTo',
        cssClass: 'p-col-12 p-md-3 p-lg-1',
      },
      {
        vcr: 'vcrTop',
        type: CustomDesignItemType.FIELD,
        columnName: 'MatchMode',
        cssClass: 'p-col-12 p-md-3 p-lg-1',
      },
      {
        vcr: 'vcrTop',
        type: CustomDesignItemType.FIELD,
        columnName: 'C_BPartner_ID',
        cssClass: 'p-col-12 p-md-3 p-lg-1',
      },
      {
        vcr: 'vcrTop',
        type: CustomDesignItemType.FIELD,
        columnName: 'DateFrom',
        cssClass: 'p-col-12 p-md-3 p-lg-1',
      },
      {
        vcr: 'vcrTop',
        type: CustomDesignItemType.FIELD,
        columnName: 'DateTo',
        cssClass: 'p-col-12 p-md-3 p-lg-1',
      },
      {
        vcr: 'vcrTop',
        type: CustomDesignItemType.FIELD,
        columnName: 'M_Product_ID',
        cssClass: 'p-col-12 p-md-3 p-lg-1',
      },
      {
        vcr: 'vcrTop',
        type: CustomDesignItemType.FIELD,
        columnName: 'IsReturnTrx',
        cssClass: 'p-col-12 p-md-3 p-lg-1 ng-star-inserted',
      },
      {
        vcr: 'vcrTop',
        type: CustomDesignItemType.FIELD,
        columnName: 'IsConsigned',
        cssClass: 'p-col-12 p-md-3 p-lg-1 ng-star-inserted',
      },
      {
        vcr: 'vcrTop',
        type: CustomDesignItemType.FIELD,
        columnName: 'Search',
        cssClass: 'p-col-12',
      },
      {
        vcr: 'vcrArrayTmp',
        type: CustomDesignItemType.GRID,
        tableName: 'Matched Invoices Info window Table (receipt)',
        cssClass: 'p-col-12',
      },
      {
        vcr: 'vcrArrayTmp',
        type: CustomDesignItemType.GRID,
        tableName: 'Matched Invoices Info window Table (order)',
        cssClass: 'p-col-12',
      },
      {
        vcr: 'vcrArrayTmp',
        type: CustomDesignItemType.GRID,
        tableName: 'Matched Invoices Info window Table (consignedTransfer)',
        cssClass: 'p-col-12',
      },
      {
        vcr: 'vcrArrayTmp',
        type: CustomDesignItemType.GRID,
        tableName: 'Matched Invoices Info window Table (invoice)',
        cssClass: 'p-col-12',
      },
      {
        vcr: 'vcrMatchTest',
        type: CustomDesignItemType.FIELD,
        columnName: 'SameBPartner',
        cssClass: 'p-col-12 p-md-3 p-lg-1',
      },
      {
        vcr: 'vcrMatchTest',
        type: CustomDesignItemType.FIELD,
        columnName: 'SameProduct',
        cssClass: 'p-col-12 p-md-3 p-lg-1',
      },
      {
        vcr: 'vcrMatchTest',
        type: CustomDesignItemType.FIELD,
        columnName: 'SameQty',
        cssClass: 'p-col-12 p-md-3 p-lg-1',
      },
      {
        vcr: 'vcrMatchTest',
        type: CustomDesignItemType.FIELD,
        columnName: 'SamePriceList',
        cssClass: 'p-col-12 p-md-3 p-lg-1',
      },
      {
        vcr: 'vcrButtons',
        type: CustomDesignItemType.FIELD,
        columnName: 'Processing',
        cssClass: 'p-col-12',
        btnType: 'primary',
      }
    );
    this.updateWindow = debounce(this.updateWindow, 1000);
  }
  // override
  setDataContainersValueWithChangedStore() {}
  ngOnInit() {
    super.ngOnInit();
    this.activeGrids = [];
    const item: DynamicComponent = {
      container: this,
      DOMParentComponent: this,
      component: 'SpecificWindowUiComponent',
      cssClass: 'p-col-12',
      isCssOnComponent: false,
      tabId: this.formId,
      gridPaginator: false,
    };
    this.windowFactory.newEventHandler({
      type: IupicsTypeEvent.showSpecificWindow,
      item: item,
    });
  }
  notifyFromDataChange(item: any) {
    this.updateWindow(item);
  }
  notifySelect(gridView: GridViewUiComponent) {
    const activeGridFound = this.activeGrids.find((activeGrid) => activeGrid.tableName === gridView.data['tableName']);
    if (activeGridFound.vcrName === 'vcrMatchFrom') {
      // permet de notifier le changement sur le deuxieme tableau
      this.refreshGrids(this.dataStore, false, { columnName: 'SameBPartner' });
    }
  }
  ngAfterViewInit() {
    super.ngAfterViewInit();
    if (this.vcrArrayTmp) {
      while (this.vcrArrayTmp.length > 0) {
        this.vcrArrayTmp.detach();
      }
    }
    this.subscriptions.push(
      this.initFieldsState().subscribe((array) => {
        this.matchFrom = array[0];
        this.matchTo = array[1];
        this.isReturnTrx = 'N';
        this.isConsigned = 'N';
        this.sameBPartner = 'Y';
        this.sameProduct = 'Y';
        this.sameQty = 'N';
        this.samePriceList = 'N';
        this.setDatacontainerValue('MatchFrom', array[0]);
        this.setDatacontainerValue('MatchTo', array[1]);
        this.setDatacontainerValue('MatchMode', array[2]);
        this.updateSuggestions(this.matchFrom.id, this.isConsigned);
        this.displayRightGrid(this.matchFrom.id, 'vcrMatchFrom');
        this.displayRightGrid(this.matchTo.id, 'vcrMatchTo');
      })
    );
  }

  detachAllGrids() {
    this.activeGrids = [];
    if (this.vcrMatchFrom) {
      while (this.vcrMatchFrom.length > 0) {
        this.vcrMatchFrom.detach();
      }
    }
    if (this.vcrMatchTo) {
      while (this.vcrMatchTo.length > 0) {
        this.vcrMatchTo.detach();
      }
    }
  }

  initFieldsState() {
    const matchFromField = this.dataContainers.find((datacontainer) => datacontainer.data.columnName === 'MatchFrom');
    const matchToField = this.dataContainers.find((datacontainer) => datacontainer.data.columnName === 'MatchTo');
    const matchModeField = this.dataContainers.find((datacontainer) => datacontainer.data.columnName === 'MatchMode');
    const zippingCall = zip(
      (<AutocompleteUiComponent>matchFromField).getSearchItem(MatchOptions.INVOICE),
      (<AutocompleteUiComponent>matchToField).getSearchItem(MatchOptions.RECEIPT),
      (<AutocompleteUiComponent>matchModeField).getSearchItem(MatchModes.NOTMATCHED)
    );
    return zippingCall;
  }
  /**
   * Set les valeurs venant du datastore courant
   */
  setFieldsState() {
    this.matchFrom = this.dataStore.data['MatchFrom'];
    this.matchTo = this.dataStore.data['MatchTo'];
    this.matchMode = this.dataStore.data['MatchMode'];
    this.isReturnTrx = this.dataStore.data['IsReturnTrx'];
    this.sameBPartner = this.dataStore.data['SameBPartner'];
    this.sameProduct = this.dataStore.data['SameProduct'];
    this.sameQty = this.dataStore.data['SameQty'];
    this.samePriceList = this.dataStore.data['SamePriceList'];
  }

  /**
   * Permet de changer les suggestions des autocompletes MatchTo etMatchFrom
   * @param matchFrom le champs qui a changé
   * @param isConsignedData valeur de la case "est consigné"
   */
  updateSuggestions(matchFrom: MatchOptions, isConsignedData: 'N' | 'Y') {
    if (matchFrom === MatchOptions.RECEIPT) {
      this.matchFromSuggestions = [MatchOptions.INVOICE, MatchOptions.ORDER, MatchOptions.RECEIPT];
      this.matchToSuggestions = [MatchOptions.INVOICE, MatchOptions.ORDER];
    } else if (matchFrom === MatchOptions.CONSIGNED) {
      this.matchFromSuggestions = [MatchOptions.INVOICE, MatchOptions.CONSIGNED];
      this.matchToSuggestions = [MatchOptions.INVOICE];
    } else if (isConsignedData === 'Y') {
      this.matchFromSuggestions = [MatchOptions.INVOICE, MatchOptions.CONSIGNED];
      this.matchToSuggestions = [MatchOptions.CONSIGNED];
    } else {
      this.matchFromSuggestions = [MatchOptions.INVOICE, MatchOptions.ORDER, MatchOptions.RECEIPT];
      this.matchToSuggestions = [MatchOptions.RECEIPT];
    }
  }
  /**
   * mets à jour la fenêtre en fonction de la valeur des champs
   */
  updateWindow(item: any) {
    // init Champs
    const matchFromField = this.dataContainers.find((datacontainer) => datacontainer.data.columnName === 'MatchFrom');
    const matchToField = this.dataContainers.find((datacontainer) => datacontainer.data.columnName === 'MatchTo');
    const matchModeField = this.dataContainers.find((datacontainer) => datacontainer.data.columnName === 'MatchMode');
    const IsReturnTrxField = this.dataContainers.find(
      (datacontainer) => datacontainer.data.columnName === 'IsReturnTrx'
    );
    const SameBPartnerField = this.dataContainers.find(
      (datacontainer) => datacontainer.data.columnName === 'SameBPartner'
    );
    const sameProductField = this.dataContainers.find(
      (datacontainer) => datacontainer.data.columnName === 'SameProduct'
    );
    const sameQtyField = this.dataContainers.find((datacontainer) => datacontainer.data.columnName === 'SameQty');
    const samePriceListField = this.dataContainers.find(
      (datacontainer) => datacontainer.data.columnName === 'SamePriceList'
    );
    // init Id
    const matchFromIdTmp = this.matchFrom ? this.matchFrom.id : null;
    const matchFromDataIdTmp = this.dataStore.data['MatchFrom'] ? this.dataStore.data['MatchFrom'].id : null;
    const matchToIdTmp = this.matchTo ? this.matchTo.id : null;
    const matchToDataIdTmp = this.dataStore.data['MatchTo'] ? this.dataStore.data['MatchTo'].id : null;
    const isConsignedData = this.dataStore.data['IsConsigned'];
    // comportement d'un changement de champ
    let shouldReset = false;

    this.setFieldsState();
    if (matchFromIdTmp !== matchFromDataIdTmp) {
      this.displayRightGrid(matchFromDataIdTmp, 'vcrMatchFrom');
      shouldReset = true;
    } else if (matchToIdTmp !== matchToDataIdTmp) {
      this.displayRightGrid(matchToDataIdTmp, 'vcrMatchTo');
      shouldReset = true;
    } else if (this.isConsigned !== this.dataStore.data['IsConsigned']) {
      shouldReset = true;
      if (item.data.columnName === 'IsConsigned') {
        if (isConsignedData === 'Y') {
          this.isConsigned = 'Y';
          const zippingCall = zip(
            (<AutocompleteUiComponent>matchFromField).getSearchItem(MatchOptions.CONSIGNED),
            (<AutocompleteUiComponent>matchToField).getSearchItem(MatchOptions.INVOICE)
          );
          this.subscriptions.push(
            zippingCall.subscribe((array) => {
              matchFromField.dataChange(array[0]);
              matchToField.dataChange(array[1]);
            })
          );
          this.matchFromSuggestions = [MatchOptions.INVOICE, MatchOptions.CONSIGNED];
          this.matchToSuggestions = [MatchOptions.INVOICE];
        } else {
          this.isConsigned = 'N';
          const zippingCall = zip(
            (<AutocompleteUiComponent>matchFromField).getSearchItem(MatchOptions.INVOICE),
            (<AutocompleteUiComponent>matchToField).getSearchItem(MatchOptions.RECEIPT)
          );
          this.subscriptions.push(
            zippingCall.subscribe((array) => {
              matchFromField.dataChange(array[0]);
              matchToField.dataChange(array[1]);
            })
          );
          this.matchFromSuggestions = [MatchOptions.INVOICE, MatchOptions.ORDER, MatchOptions.RECEIPT];
          this.matchToSuggestions = [MatchOptions.RECEIPT];
        }
      }
    }
    // changement de matchTo en CONSIGNED
    if (matchFromIdTmp === MatchOptions.CONSIGNED && matchFromDataIdTmp !== MatchOptions.CONSIGNED) {
      if (matchToDataIdTmp !== MatchOptions.CONSIGNED && this.isConsigned === 'Y') {
        this.subscriptions.push(
          (<AutocompleteUiComponent>matchToField).getSearchItem(MatchOptions.CONSIGNED).subscribe((dataWs) => {
            matchToField.dataChange(dataWs);
          })
        );
      }
      this.updateSuggestions(matchFromDataIdTmp, isConsignedData);
    }
    // changement de matchTo en INVOICE
    if (matchFromIdTmp !== MatchOptions.CONSIGNED && matchFromDataIdTmp === MatchOptions.CONSIGNED) {
      if (matchToDataIdTmp !== MatchOptions.INVOICE && this.isConsigned === 'Y') {
        this.subscriptions.push(
          (<AutocompleteUiComponent>matchToField)
            .getSearchItem(MatchOptions.INVOICE)
            .subscribe((dataWs) => matchToField.dataChange(dataWs))
        );
      }
      this.updateSuggestions(matchFromDataIdTmp, isConsignedData);
    }
    // changement de matchTo en RECEIPT
    if (matchFromIdTmp === MatchOptions.RECEIPT && matchFromDataIdTmp !== MatchOptions.RECEIPT) {
      if (matchToDataIdTmp !== MatchOptions.RECEIPT && this.isConsigned === 'N') {
        this.subscriptions.push(
          (<AutocompleteUiComponent>matchToField)
            .getSearchItem(MatchOptions.RECEIPT)
            .subscribe((dataWs) => matchToField.dataChange(dataWs))
        );
      }
      this.updateSuggestions(matchFromDataIdTmp, isConsignedData);
    }
    // changement de matchTo en INVOICE
    if (matchFromIdTmp !== MatchOptions.RECEIPT && matchFromDataIdTmp === MatchOptions.RECEIPT) {
      if (matchToDataIdTmp !== MatchOptions.INVOICE && this.isConsigned === 'N') {
        this.subscriptions.push(
          (<AutocompleteUiComponent>matchToField)
            .getSearchItem(MatchOptions.INVOICE)
            .subscribe((dataWs) => matchToField.dataChange(dataWs))
        );
      }
      this.updateSuggestions(matchFromDataIdTmp, isConsignedData);
    }
    if (shouldReset) {
      this.subscriptions.push(
        (<AutocompleteUiComponent>matchModeField).getSearchItem(MatchModes.NOTMATCHED).subscribe((dataWs) => {
          this.matchMode = dataWs.id;
          this.isReturnTrx = 'N';
          this.sameBPartner = 'Y';
          this.sameProduct = 'Y';
          this.sameQty = 'N';
          this.samePriceList = 'N';
          this.dataStore.data['MatchMode'] = dataWs;
          this.dataStore.data['IsReturnTrx'] = 'N';
          this.dataStore.data['SameBPartner'] = 'Y';
          this.dataStore.data['SameProduct'] = 'Y';
          this.dataStore.data['SameQty'] = 'N';
          this.dataStore.data['SamePriceList'] = 'N';
          IsReturnTrxField.fieldValue = 'N';
          SameBPartnerField.fieldValue = 'Y';
          sameProductField.fieldValue = 'Y';
          sameQtyField.fieldValue = 'N';
          samePriceListField.fieldValue = 'N';
        })
      );
    }
    this.refreshGrids(this.dataStore, false, { columnName: item ? item.data.columnName : '' });
  }
  /**
   * permet d'afficher la grille en fonction des choix de l'utilisateur
   * @param value valeur qui détermine quelle grid afficher
   * @param vcrName vcr qui est impacté
   */
  displayRightGrid(value, vcrName): ViewRef {
    const oppositeVcr = this[vcrName === 'vcrMatchTo' ? 'vcrMatchFrom' : 'vcrMatchTo'];
    this.activeGrids = this.activeGrids.filter((activeGrid) => activeGrid.vcrName !== vcrName);
    let oppositeViewRef = null;
    if (oppositeVcr) {
      oppositeViewRef = oppositeVcr.detach();
    }
    this[vcrName].detach();
    let rightGrid: ViewRef = null;
    let tableName = null;
    switch (value) {
      case MatchOptions.INVOICE:
        rightGrid = this.vcrArrayTmpViewRefs.get(TableName.INVOICE);
        tableName = TableName.INVOICE;
        break;
      case MatchOptions.RECEIPT:
        rightGrid = this.vcrArrayTmpViewRefs.get(TableName.RECEIPT);
        tableName = TableName.RECEIPT;
        break;
      case MatchOptions.ORDER:
        rightGrid = this.vcrArrayTmpViewRefs.get(TableName.ORDER);
        tableName = TableName.ORDER;
        break;
      case MatchOptions.CONSIGNED:
        rightGrid = this.vcrArrayTmpViewRefs.get(TableName.CONSIGNED);
        tableName = TableName.CONSIGNED;
        break;
      default:
        break;
    }
    if (rightGrid) {
      if (vcrName === 'vcrMatchFrom') {
        this.currentMatchFromTableName = tableName;
      } else {
        this.currentMatchToTableName = tableName;
      }
      this[vcrName].insert(rightGrid);
      this.activeGrids.push({
        vcrName: vcrName,
        tableName: tableName,
      });
      if (oppositeViewRef) {
        const oppositeActiveGrid = this.activeGrids.find(
          (activeGrid) => activeGrid.vcrName === (vcrName === 'vcrMatchTo' ? 'vcrMatchFrom' : 'vcrMatchTo')
        );
        this.activeGrids = this.activeGrids.filter(
          (activeGrid) => activeGrid.vcrName !== (vcrName === 'vcrMatchTo' ? 'vcrMatchFrom' : 'vcrMatchTo')
        );
        if (!oppositeActiveGrid || oppositeActiveGrid.tableName !== tableName) {
          oppositeVcr.insert(oppositeViewRef);
          if (vcrName === 'vcrMatchFrom') {
            this.activeGrids.push({
              vcrName: 'vcrMatchTo',
              tableName: this.currentMatchToTableName,
            });
          } else {
            this.activeGrids.push({
              vcrName: 'vcrMatchFrom',
              tableName: this.currentMatchFromTableName,
            });
          }
        }
      }
    }
    this.setRowSingleSelection();
    return rightGrid;
  }

  /**
   * change le type de selection des grids actives
   */
  setRowSingleSelection() {
    const matchFromGrid = this.gridViews.find(
      (grid) => (<GridViewUiComponent>grid).data['tableName'] === this.currentMatchFromTableName
    );
    if (matchFromGrid) {
      matchFromGrid.GridTabInfinityScrollUiComponent.rowSelection = 'single';
    }
    const matchToGrid = this.gridViews.find(
      (grid) => (<GridViewUiComponent>grid).data['tableName'] === this.currentMatchToTableName
    );
    if (matchToGrid) {
      matchToGrid.GridTabInfinityScrollUiComponent.rowSelection = 'multiple';
    }
  }

  /**
   * Filtre à appliquer dans les autoCompletes
   * @param suggestion la suggestion de l'autoComplete
   * @param columnName le nom de la colonne lié à cette suggestion
   */
  filterSuggestions(suggestion: any, columnName: string) {
    let acceptedArray = [];
    switch (columnName) {
      case 'MatchFrom':
        acceptedArray = this.matchFromSuggestions;
        break;
      case 'MatchTo':
        acceptedArray = this.matchToSuggestions;
        break;
      default:
        return suggestion;
    }
    if (acceptedArray.includes(suggestion.id)) {
      return suggestion;
    }
  }
  /**
   * OVERRIDE
   * @param datastore contexte
   * @param isStart true si premiere fois
   */
  protected refreshGrids(datastore?: DataStore, isStart?: boolean, data?: IupicsData, tabId?: number) {
    const query = { filters: {}, validations: {}, fields: {} };
    // let mandatoryField = 0;
    this.fields
      .filter((field) => {
        return field.data.isFilterSearch;
      })
      .forEach((field) => {
        query.fields[field.data.columnName] = field;
        if (field.data.isRange) {
          // dont put it in filter if the range not completed
          const valueTmp = this.dataStore.data[field.data.columnName];
          const valueTmp1 = this.dataStore.data[field.data.columnName + '_To'];

          if (valueTmp1 && valueTmp) {
            query.validations[field.data.columnName] = valueTmp;
            query.validations[field.data.columnName + '_To'] = valueTmp1;
          }
        } else {
          // dont put it in filter if value is null
          const dataContainer = this.dataContainers.find(
            (datacontainer) => datacontainer.data.columnName === field.data.columnName
          );
          if (dataContainer && dataContainer.fieldValue !== null) {
            const valueTmp = this.dataStore.data[field.data.columnName];
            if (valueTmp && (!(valueTmp instanceof Array) || valueTmp.length > 0)) {
              if (!dataContainer.data.hasExtendedLogic) {
                query.filters[field.data.columnName] = valueTmp;
              } else {
                query.validations[field.data.columnName] = valueTmp;
              }
            }
          }
        }
      });
    this.gridViews
      .filter((grid) => tabId === undefined || grid.tabId === tabId)
      .forEach((gridView) => {
        gridView.updateDisplayLogic();
        if ((!isStart || gridView.isLaunchSearchGrid) && this.getMissingMantoryField().length === 0) {
          // check si la grid doit être rafrachie
          const activeGridFound = this.activeGrids.find(
            (activeGrid) => activeGrid.tableName === (<GridViewUiComponent>gridView).data['tableName']
          );
          if (gridView.isDisplayed && (!this.activeGrids || (gridView.data && activeGridFound))) {
            const currentQuery: SpecificGridQuery = { ...query };
            // supprime les filters indiquant une colonne non existante
            if (currentQuery.filters) {
              // à remplir pour prendre en compte les noms de colonnes qui ne sont pas contenu dans la table
              const keys = ['DateFrom', 'DateTo', 'IsReturnTrx', 'IsConsigned', 'MatchMode'];
              gridView.GridTabInfinityScrollUiComponent.columns.forEach((column) => keys.push(column.field));
              Object.keys(currentQuery.filters).forEach((key) => {
                if (!keys.includes(key)) {
                  delete currentQuery.filters[key];
                }
              });
            }
            // check si la grid est celui qui doit être filtré sur bpartner mproduct etc...
            const matchToParams = ['SameBPartner', 'SameProduct', 'SameQty', 'SamePriceList'];
            if (
              activeGridFound.vcrName !== 'vcrMatchTo' &&
              (data === null || data === undefined || !matchToParams.includes(data.columnName))
            ) {
              currentQuery.specificGridOptions = this.getSpecificGridOption(
                (<GridViewUiComponent>gridView).data['tableName']
              );
              gridView.refreshGrid(currentQuery);
            }
            if (
              activeGridFound.vcrName === 'vcrMatchFrom' &&
              data !== null &&
              data !== undefined &&
              matchToParams.includes(data.columnName)
            ) {
              const matchToGrid = this.gridViews.find(
                (grid) => (<GridViewUiComponent>grid).data['tableName'] === this.currentMatchToTableName
              );
              if (matchToGrid) {
                const currentQuery2: SpecificGridQuery = {
                  validations: { ...{}, ...currentQuery.validations },
                  filters: { ...{}, ...currentQuery.filters },
                  fields: { ...{}, ...currentQuery.fields },
                  specificGridOptions: { shouldResetRequest: true },
                };
                this.refreshMatchTo(currentQuery2, matchToGrid);
              }
            }
          }
        } else {
          this.reInitGridData(gridView.data.columnName);
        }
      });
  }

  /**
   * récupère le nom de colonne lié au nom de table spécifié
   * @param gridName nom de la table
   */
  getQtyColumn(gridName: String) {
    switch (gridName) {
      case TableName.INVOICE:
        return 'QtyInvoiced';
      case TableName.ORDER:
        return 'QtyOrdered';
      case TableName.RECEIPT:
        return 'MovementQty';
      case TableName.CONSIGNED:
        return 'TransferQty';
      default:
        return null;
    }
  }
  /**
   *ajoute les options suplémentaires liés à la grid MatchTo
   * @param currentFilters query sur la grid
   * @param gridView grid matchTo
   */
  refreshMatchTo(currentFilters, gridView) {
    this.clearGridSelection(gridView.data.columnName);
    if (this.currentMatchFromTableName) {
      const matchFromGrid = this.gridViews.find(
        (grid) => (<GridViewUiComponent>grid).data['tableName'] === this.currentMatchFromTableName
      );
      if (matchFromGrid) {
        const row = this.dataStore.data['selections'].find(
          (grid) => grid['AD_FormDetail_ID'] === (<GridViewUiComponent>matchFromGrid).data['AD_FormDetail_ID']
        );
        if (row && row.selection.length > 0) {
          const cbPartnerData = row.selection[0]['C_BPartner_ID'];
          const qtyData = row.selection[0][this.getQtyColumn(this.currentMatchFromTableName)];
          const productData = row.selection[0]['M_Product_ID'];
          // const priceListData = row.selection[0].['M_Product_ID'];
          if (this.sameBPartner === 'Y' && cbPartnerData !== undefined && cbPartnerData !== null) {
            currentFilters.filters['C_BPartner_ID'] = cbPartnerData;
          }
          if (this.sameProduct === 'Y' && productData !== undefined && productData !== null) {
            currentFilters.filters['M_Product_ID'] = productData;
          }
          if (this.sameQty === 'Y' && qtyData !== null && qtyData !== undefined) {
            currentFilters.filters[this.getQtyColumn(gridView.data['tableName'])] = qtyData;
          }
          // if (this.sameQty === 'Y' && priceListData !== null && priceListData !== undefined) {
          //   currentFilters['M_Product_ID'] = priceListData;
          // }
        }
      }
      this.calculateQty();
    }
    gridView.refreshGrid(currentFilters, this.parentFormID);
  }
  notifyFromRowSelected(rowSelected: any) {
    this.calculateQty();
  }
  calculateQty() {
    this.difference = 0;
    this.toReconcile = 0;
    this.lettering = 0;
    const matchFromGrid = this.gridViews.find(
      (grid) => (<GridViewUiComponent>grid).data['tableName'] === this.currentMatchFromTableName
    );
    if (matchFromGrid) {
      const row = this.dataStore.data['selections'].find(
        (grid) => grid['AD_FormDetail_ID'] === (<GridViewUiComponent>matchFromGrid).data['AD_FormDetail_ID']
      );
      row.selection.forEach((node) => {
        if (node[this.getQtyColumn(matchFromGrid.data['tableName'])]) {
          this.toReconcile += node[this.getQtyColumn(matchFromGrid.data['tableName'])] - node['QtyMatched'];
        }
      });
    }
    const matchToGrid = this.gridViews.find(
      (grid) => (<GridViewUiComponent>grid).data['tableName'] === this.currentMatchToTableName
    );
    if (matchToGrid) {
      const row = this.dataStore.data['selections'].find(
        (grid) => grid['AD_FormDetail_ID'] === (<GridViewUiComponent>matchToGrid).data['AD_FormDetail_ID']
      );
      row.selection.forEach((node) => {
        if (node[this.getQtyColumn(matchToGrid.data['tableName'])]) {
          this.lettering += node[this.getQtyColumn(matchToGrid.data['tableName'])] - node['QtyMatched'];
        }
      });
    }
    this.difference = this.toReconcile - this.lettering;
  }
  notifyFromGridRefresh(gridView: GridViewUiComponent, dataStoreRequest: DataStoreRequest) {
    const activeGridFound = this.activeGrids.find((activeGrid) => activeGrid.tableName === gridView.data['tableName']);
    if (activeGridFound && activeGridFound.vcrName === 'vcrMatchFrom') {
      // permet de notifier le changement sur le deuxieme tableau
      this.selectLinesByDataUUID(gridView.data.columnName, { shouldDeselectAll: true, simulateClick: true });
    }
  }
}

export enum MatchOptions {
  INVOICE = 'INV',
  RECEIPT = 'REC',
  ORDER = 'ORD',
  CONSIGNED = 'CON',
}
export enum MatchModes {
  MATCHED = 'M',
  NOTMATCHED = 'NM',
}
export enum TableName {
  INVOICE = 'Matched Invoices Info window Table (invoice)',
  ORDER = 'Matched Invoices Info window Table (order)',
  RECEIPT = 'Matched Invoices Info window Table (receipt)',
  CONSIGNED = 'Matched Invoices Info window Table (consignedTransfer)',
}
