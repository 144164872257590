import { AfterViewInit, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ProcessUiComponent } from '@iupics-components/specific/window/process-ui/process-ui.component';
import { WindowFactoryUtils } from '@iupics-manager/managers/ui-creator/window-factory/window-factory-utils';
import { AbstractDynamicComponent } from '@iupics-manager/models/abstract-dynamic-component';
import { Global } from '@iupics-manager/models/global-var';
import { IupicsEvent } from '@iupics-manager/models/iupics-event';
import { timer } from 'rxjs';

@Component({
  selector: 'iu-grid-ui',
  templateUrl: './grid-ui.component.html',
  styleUrls: ['./grid-ui.component.css'],
})
export class GridUiComponent extends AbstractDynamicComponent implements OnInit, AfterViewInit {
  constructor(private cd: ChangeDetectorRef) {
    super();
  }

  ngOnInit() {
    if (!this.cssClass || Global.isMobileWidth()) {
      this.cssClass = 'p-col-12 p-col-nopad';
    }
    Global.startPerf(this);
  }
  ngAfterViewInit() {
    if (
      this.DOMParentComponent &&
      this.DOMParentComponent['componentRef'] &&
      this.DOMParentComponent['componentRef'].componentType.name === 'ProcessUiComponent'
    ) {
      const currentProcess = this.DOMParentComponent;
      this.parentProcess = <ProcessUiComponent>currentProcess;
    }
    this.buildChildren();
  }
  buildChildren() {
    const child = this.children[0];
    if (child && child.data?.tabLevel === 0 && child.component === 'EditTabUiComponent') {
      WindowFactoryUtils.addContainerComponent(this, child, child.container ? child.isCssOnComponent : false);
      this.cd.detectChanges();
      this.subscriptions.push(
        timer(0).subscribe((x) => {
          for (let i = 1; i < this.children.length; i++) {
            const child = this.children[i];
            WindowFactoryUtils.addContainerComponent(this, child, child.container ? child.isCssOnComponent : false);
          }
        })
      );
    } else {
      for (let i = 0; i < this.children.length; i++) {
        const child = this.children[i];
        WindowFactoryUtils.addContainerComponent(this, child, child.container ? child.isCssOnComponent : false);
      }
    }
  }
  onChildUpdate(event): void {}

  onSiblingUpdate(event: IupicsEvent) {}

  onRemoveComponent(event: IupicsEvent) {}
}
