<div *ngIf="opened && currentUserAccount" #userProfile class="user-profile-wrapper" data-cy="profile-panel">
  <div class="user-profile-header">
    <iu-avatar-ui [avatarInfos]="currentUserAccount.avatarInfos"></iu-avatar-ui>
    <div class="account">
      <span
        (mouseover)="setTokenExpirationInterval($event)"
        class="account-name"
        [life]="3000"
        [hideDelay]="250"
        pTooltip="{{ 'profil.sessionExpiration' | translate }} {{ tokenExpiration }}"
        tooltipPosition="right"
        >{{ currentUserAccount.fullname }}</span
      >
      <span class="account-email">{{ currentUserAccount.email }}</span>
    </div>
  </div>

  <div class="user-profile-content">
    <ng-container
      *ngTemplateOutlet="
        dropdownList;
        context: {
          $implicit: 'role',
          isDisplay: roleIsDisplay,
          selected: selectedRole,
          list: roles$ | async,
          searchKey: 'searchRole',
          updateFn: 'changeRole'
        }
      "></ng-container>

    <ng-container *ngIf="currentUserAccount.current_role.role_id > 0">
      <ng-container
        *ngTemplateOutlet="
          dropdownList;
          context: {
            $implicit: 'organisation',
            isDisplay: organisationIsDisplay,
            selected: selectedOrganisation,
            list: organisations$ | async,
            searchKey: 'searchOrganisation',
            updateFn: 'updateOrganisation'
          }
        "></ng-container>
    </ng-container>

    <ng-container *ngIf="currentUserAccount.current_role.role_id > 0">
      <ng-container
        *ngTemplateOutlet="
          dropdownList;
          context: {
            $implicit: 'warehouse',
            isDisplay: warehouseIsDisplay,
            selected: selectedWarehouse,
            list: warehouses$ | async,
            searchKey: 'searchWarehouse',
            updateFn: 'updateWarehouse'
          }
        "></ng-container>
    </ng-container>
  </div>

  <div class="user-profile-footer">
    <button
      pButton
      class="p-col-12 p-button p-button-secondary"
      (click)="showUserContext($event)"
      data-cy="user-context-btn"
      [label]="'profil.showUserContext' | translate"></button>
    <button
      pButton
      class="p-col-12 p-button p-button-secondary"
      [label]="'profil.update' | translate"
      (click)="openUpdateProfil($event)"
      data-cy="modify-profile"></button>
    <button
      pButton
      class="p-col-12 p-button p-button-secondary"
      [label]="'profil.keycloakManagementBtn' | translate"
      (click)="goToKeycloakManageProfile()"></button>
    <button
      pButton
      *ngIf="!isUpdateProfil"
      class="p-col-12 p-button p-button-alert user-profile-logoff-btn"
      (click)="logout($event)"
      data-cy="logout-btn"
      [label]="'profil.logoff' | translate"></button>
    <div class="split-button" *ngIf="selectedRole.roleUI.role_id === 0 && !isUpdateProfil">
      <div class="split-button-toggle-button" [ngClass]="{ 'split-button-open': isSplitButtonOpen }">
        <button
          pButton
          data-cy="sys-admin-reload-cache-server"
          class="p-col-10 p-button p-button-warning"
          (click)="reloadConfiguration($event, false)"
          [label]="'profil.reload-server' | translate"></button>
        <button
          pButton
          data-cy="sys-admin-split-button-open"
          class="p-col-2 p-button p-button-warning"
          (click)="isSplitButtonOpen = !isSplitButtonOpen"
          [icon]="isSplitButtonOpen ? 'icon-open' : 'icon-next'"></button>
      </div>
      <div *ngIf="isSplitButtonOpen" class="split-button-list">
        <button
          pButton
          data-cy="sys-admin-reload-cache-client"
          class="p-col-12 p-button p-button-warning"
          (click)="reloadConfiguration($event, true)"
          [label]="'profil.reload-client' | translate"></button>
      </div>
    </div>
    <iu-app-version-panel></iu-app-version-panel>
  </div>
</div>

<p-sidebar
  [(visible)]="isUpdateProfil"
  appendTo="body"
  position="right"
  (contextmenu)="$event.preventDefault()"
  [styleClass]="(isMobile || isMobileWidth ? 'iu-fullscreen-sidebar' : 'p-sidebar-md') + ' user-profile-sidebar'"
  [dismissible]="canHideProfileEdition"
  [closeOnEscape]="canHideProfileEdition">
  <div *ngIf="isUpdateProfil" class="user-profile-edition-zone" data-cy="update-panel">
    <div class="user-profile-edition-zone-content">
      <iu-prime-fieldset [toggleable]="false" [collapsed]="false" [legend]="'profil.my-profile' | translate">
        <div class="user-profile-fieldset">
          <div class="apiz-p-grid">
            <iu-autocomplete-ui
              #languagesSelector
              data-cy="language-selector"
              [label]="'profil.language' | translate"
              [isStandalone]="true"
              columnName="Profile-AD_Language"
              [data]="languages"
              cssGrid="p-col-12"
              cssClass="p-col-12"></iu-autocomplete-ui>
          </div>
          <div class="apiz-p-grid">
            <iu-autocomplete-ui
              #printerNameField
              [label]="'profil.printerName' | translate"
              [isStandalone]="true"
              columnName="Profile-printerName"
              [fieldValue]="this.printerName"
              [data]="printerNames"
              cssGrid="p-col-12"
              cssClass="p-col-12"></iu-autocomplete-ui>
          </div>
        </div>
      </iu-prime-fieldset>
      <iu-prime-fieldset [toggleable]="false" [collapsed]="false" [legend]="'profil.my-settings' | translate">
        <div class="apiz-p-grid">
          <iu-calendar-ui
            class="defaultDateCalendar p-col-12"
            label="{{ 'profil.date' | translate }}"
            [fieldValue]="currentDefaultDate"
            [isStandalone]="true"
            [locale]="currentUserAccount.default_language.iso_code"
            cssGrid="p-col-12"
            (fieldValueModified)="changeDefaultDate($any($event))"></iu-calendar-ui>
          <iu-input-switch-ui
            #autoCommitField
            label="{{ 'profil.autoCommitPref' | translate }}"
            [fieldValue]="this.isAutoCommit"
            cssGrid="p-col-12"
            style="text-align: left">
          </iu-input-switch-ui>
          <iu-input-switch-ui
            #advancedShowField
            label="{{ 'profil.oneuserpref' | translate }}"
            [fieldValue]="this.isShowAdvanced"
            cssGrid="p-col-12"
            style="text-align: left">
          </iu-input-switch-ui>
          <iu-input-switch-ui
            #acctShowField
            label="{{ 'profil.twouserpref' | translate }}"
            [fieldValue]="this.isShowAcct"
            cssGrid="p-col-12"
            style="text-align: left"
            columnName="Profile-AcctNote">
          </iu-input-switch-ui>
          <iu-input-switch-ui
            #trlShowField
            label="{{ 'profil.threeuserpref' | translate }}"
            [fieldValue]="this.isShowTrl"
            cssGrid="p-col-12"
            style="text-align: left">
          </iu-input-switch-ui>
          <iu-input-switch-ui
            #previewField
            label="{{ 'profil.fouruserpref' | translate }}"
            [fieldValue]="this.isPrintPreview"
            cssGrid="p-col-12"
            style="text-align: left"></iu-input-switch-ui>
          <iu-autocomplete-ui
            #notificationReminderTimeoutField
            data-cy="autocomplete-notifTimeout"
            label="{{ 'profil.fiveuserpref' | translate }}"
            [isStandalone]="true"
            columnName="Profile-NotificationTimeout"
            [data]="NotificationDelay"
            [fieldValue]="this.notificationReminderTimeout"
            cssGrid="p-col-12"
            cssClass="p-col-12"></iu-autocomplete-ui>
        </div>
      </iu-prime-fieldset>
      <iu-prime-fieldset [toggleable]="false" [collapsed]="false" [legend]="'profil.appearence' | translate">
        <div class="apiz-p-grid">
          <iu-autocomplete-ui
            #themeSelector
            data-cy="autocomplete-themes"
            label="{{ 'profil.theme' | translate }}"
            [isStandalone]="true"
            columnName="Profile-UIThemes"
            [data]="UIThemes"
            [fieldValue]="this.currentUITheme"
            (fieldValueModified)="changeTheme($event)"
            cssGrid="p-col-12"
            cssClass="p-col-12"></iu-autocomplete-ui>
          <!-- {AD_Role_ID: 100, AD_User_ID:100} -->
        </div>
      </iu-prime-fieldset>
      <div class="user-profile-edition-zone-buttons">
        <button
          [disabled]="!canHideProfileEdition"
          class="p-button p-button-secondary"
          data-cy="cancel-update-profile"
          (click)="isUpdateProfil = false; $event.stopPropagation()">
          {{ 'generic.cancel' | translate }}
        </button>
        <button
          [disabled]="!canHideProfileEdition"
          class="p-button p-button-primary"
          data-cy="update-profile"
          (click)="validateProfile(); $event.stopPropagation()">
          {{ 'profil.valid' | translate }}
        </button>
        <button class="p-button p-button-alert" (click)="resetDashboard($event)" [disabled]="!canHideProfileEdition">
          {{ 'profil.reset-dashboard' | translate }}
        </button>
      </div>
    </div>
  </div>
</p-sidebar>

<ng-template
  #dropdownList
  let-key
  let-isDisplay="isDisplay"
  let-selected="selected"
  let-list="list"
  let-searchKey="searchKey"
  let-updateFn="updateFn">
  <div [ngClass]="['user-profile-dropdown', isDisplay ? 'opened' : 'closed']">
    <div
      class="user-profile-dropdown-header"
      [attr.data-cy]="'display-' + key"
      (click)="toggleDropdown(key)"
      role="button">
      <div class="user-profile-dropdown-header-label">
        <span class="user-profile-dropdown-header-label-text">{{ 'profil.' + key | translate }} :</span>
        <span class="user-profile-dropdown-header-label-role">{{ selected?.displayValue }}</span>
      </div>
      <i
        [ngClass]="['user-profile-dropdown-header-label-icon', isDisplay ? 'icon-up' : 'icon-open']"
        aria-hidden="true"></i>
    </div>

    <div class="user-profile-dropdown-content">
      <iu-input-text-ui
        class="user-profile-dropdown-search"
        *ngIf="list?.length > 10"
        [isStandalone]="true"
        [isKeyUpTriggerable]="true"
        [placeHolder]="'generic.search' | translate"
        [(fieldValue)]="this[searchKey]"></iu-input-text-ui>

      <ul class="user-profile-dropdown-list" [attr.data-cy]="key + '-list'">
        <li
          *ngFor="let item of list | objectArrayFilter : [this[searchKey], 'displayValue']"
          [ngClass]="[
            'user-profile-dropdown-item',
            item.id === selected?.id ? 'user-profile-dropdown-item-active' : ''
          ]"
          (click)="this[updateFn](item)">
          <i *ngIf="item.id === selected?.id" class="icon-next" aria-hidden="true"></i>
          <span>{{ item.displayValue }}</span>
        </li>
      </ul>
    </div>
  </div>
</ng-template>
