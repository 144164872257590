import { Component, Input, OnInit } from '@angular/core';
import { AbstractDataContainer } from '@iupics-manager/models/abstract-datacontainer';

@Component({
  selector: 'iu-input-stars-ui',
  templateUrl: './input-stars-ui.component.html',
  styleUrls: ['./input-stars-ui.component.scss'],
})
export class InputStarsUiComponent extends AbstractDataContainer implements OnInit {
  @Input() data: any;

  animatedValue: number = 0;

  ngOnInit(): void {
    super.ngOnInit();
  }

  bindValue(number: number) {
    this.animatedValue = number;
  }
}
