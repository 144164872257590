<div [ngClass]="[locationPanelPosition, 'locationPanel p-col-12']">
  <div class="locationPanel-content">
    <div class="locationPanel-field-fullwidth" [hidden]="data.nbrAddressSlot <= 0">
      <ng-template #vcrAddress1> </ng-template>
    </div>
    <div class="locationPanel-field-fullwidth" [hidden]="data.nbrAddressSlot <= 1">
      <ng-template #vcrAddress2> </ng-template>
    </div>
    <div class="locationPanel-field-fullwidth" [hidden]="data.nbrAddressSlot <= 2">
      <ng-template #vcrAddress3> </ng-template>
    </div>
    <div class="locationPanel-field-fullwidth" [hidden]="data.nbrAddressSlot <= 3">
      <ng-template #vcrAddress4> </ng-template>
    </div>
    <div class="locationPanel-field-fullwidth" [hidden]="data.nbrAddressSlot >= 4">
      <a
        data-cy="add-address-line"
        class="button p-button-secondary"
        style="cursor: pointer"
        (click)="data.nbrAddressSlot = data.nbrAddressSlot + 1"
        ><span class="icon-add"></span> {{ 'locationpanel.addressline' | translate }}
      </a>
    </div>
    <div class="locationPanel-field-semiwidth">
      <ng-template #vcrPostal> </ng-template>
    </div>
    <div [hidden]="!hasLocation" class="locationPanel-field-semiwidth">
      <ng-template #vcrC_City_ID> </ng-template>
    </div>
    <div [hidden]="hasLocation" class="locationPanel-field-semiwidth">
      <ng-template #vcrCity> </ng-template>
    </div>
  </div>
  <div
    [hidden]="!this.dataStore || (!this.dataStore.data && !this.dataStore.data['C_Country_ID'])"
    class="locationPanel-field-semiwidth">
    <ng-template #vcrC_Region_ID> </ng-template>
  </div>
  <div class="locationPanel-field-semiwidth">
    <ng-template #vcrC_Country_ID> </ng-template>
  </div>
  <div class="actions">
    <button
      pButton
      class="p-button-secondary"
      style="cursor: pointer; width: 50%"
      (click)="openMap()"
      label="{{ 'locationpanel.map' | translate }}"
      icon="fas fa-map-marked-alt"
      iconPos="left"></button>
    <button
      pButton
      style="width: 50%"
      type="button"
      class="p-button p-button-primary"
      label="{{ 'generic.save' | translate }}"
      (click)="saveLocation($event)"
      data-cy="save-location"></button>
  </div>
  <div>
    <button
      pButton
      class="p-button-secondary"
      style="width: 50%"
      type="button"
      label="{{ 'generic.cancel' | translate }}"
      (click)="cancel($event)"></button>
    <button
      pButton
      class="p-button-secondary"
      style="width: 50%"
      type="button"
      label="{{ 'generic.clear' | translate }}"
      (click)="clearLocation($event)"></button>
  </div>
</div>
<ng-container *ngIf="displaySearch">
  <iu-modal-ui
    data-cy="search-panel"
    [attr.data-cy-columnName]="this.searchLinkedComponent.autoComplete.data.columnName"
    [title]="this.searchLinkedComponent.autoComplete.label"
    [hasCloseBtn]="true"
    (closeModalEmitter)="closeModalSearch()"
    [appendTo]="'body'">
    <div class="autocomplete-universal-filter">
      <iu-universal-filter-ui
        [tabId]="-1"
        [columns]="searchLinkedComponent.autoComplete.data.searchColumns"
        [gridTab]="gridTab"></iu-universal-filter-ui>
    </div>
    <iu-grid-tab-infinity-scroll-ui
      #gridTab
      (searchEmitter)="
        this.searchLinkedComponent.setSearchSelectItem($event); displaySearch = false; searchPanelValidation = undefined
      "
      [data]="this.searchLinkedComponent.autoComplete.data"
      [container]="this"
      [isSearch]="true"
      [dataStored]="this.searchLinkedComponent.autoComplete.dataStored"
      [validation]="searchPanelValidation"></iu-grid-tab-infinity-scroll-ui>
  </iu-modal-ui>
</ng-container>
<div [ngClass]="[!displayFormUI ? 'hidden' : '', 'specificModal']">
  <iu-modal-ui
    [title]="specificWindowTitle"
    [isModalDisplay]="displayFormUI"
    (closeModalEmitter)="updateModalDisplay({ key: 'displayFormUI', value: false, sourceComponent: this })"
    [angularStyle]="customFormModalBodyCss"
    [appendTo]="'body'">
    <ng-template #vcrSpecific></ng-template>
  </iu-modal-ui>
</div>
