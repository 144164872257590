import { Component, OnInit, ViewChild } from '@angular/core';
import { CompiereProcessService } from '@compiere-ws/services/compiere-process/compiere-process.service';
import { ProcessInProgressService } from '@compiere-ws/services/process-in-progress/process-in-progress.service';
import { SocketService } from '@compiere-ws/services/socket/socket.service';
import { InfoDialogComponent } from '@iupics-components/specific/window/info-dialog/info-dialog.component';
import { GridViewUiComponent } from '@iupics-components/standard/grid/grid-view-ui/grid-view-ui.component';
import { DataStoreService } from '@iupics-manager/managers/data-store/data-store.service';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { UICreatorService } from '@iupics-manager/managers/ui-creator/ui-creator.service';
import { WindowFactoryService } from '@iupics-manager/managers/ui-creator/window-factory/window-factory.service';
import { Global } from '@iupics-manager/models/global-var';
import { TranslateService } from '@ngx-translate/core';
import { InfoDialogType } from '../info-dialog/info-dialog.component';
import { SpecificWindowUiComponent } from '../specific-window-ui/specific-window-ui.component';

@Component({
  selector: 'iu-matching-acct-viewer-window-ui',
  templateUrl: './matching-acct-viewer-window-ui.component.html',
  styleUrls: ['./matching-acct-viewer-window-ui.component.scss'],
})
export class MatchingAcctViewerWindowUiComponent extends SpecificWindowUiComponent implements OnInit {
  totals = 0;
  @ViewChild(InfoDialogComponent, { static: true })
  infoDialog: InfoDialogComponent;
  LengthOfRowSelected = 0;
  LetteringVerification_Process_ID = 0;
  checkLettrageProcess_ID = 0;
  checkAndDelete_Process_ID = 0;
  constructor(
    windowFactory: WindowFactoryService,
    uiCreator: UICreatorService,
    store: DataStoreService,
    processService: CompiereProcessService,
    protected translateService: TranslateService,
    socketService: SocketService,
    connectorService: SecurityManagerService,
    progressService: ProcessInProgressService,
    private uiCreatorService: UICreatorService
  ) {
    super(
      windowFactory,
      uiCreator,
      store,
      processService,
      socketService,
      connectorService,
      progressService,
      translateService
    );
    this.isModal = false;
  }

  processLettering(item) {
    const button = this.getDatacontainer('unLettering');
    if (button) {
      button.isReadOnly = true;
    }
    const buttoVerif = this.getDatacontainer('LetteringVerification');
    if (buttoVerif) {
      buttoVerif.isReadOnly = true;
    }

    this.executeProcess(item.processId);
  }

  notifyFromDataChange(item: any) {
    this.gridViews[0].GridTabInfinityScrollUiComponent.agGrid.columnApi.setColumnsVisible(['Qty'], false);
    this.gridViews[0].GridTabInfinityScrollUiComponent.agGrid.columnApi.setColumnsVisible(['C_UOM_ID'], false);
    this.gridViews[0].GridTabInfinityScrollUiComponent.agGrid.columnApi.setColumnsVisible(['AD_Table_ID'], false);
    this.gridViews[0].GridTabInfinityScrollUiComponent.agGrid.columnApi.setColumnsVisible(['Record_ID'], false);
    this.gridViews[0].GridTabInfinityScrollUiComponent.agGrid.columnApi.setColumnsVisible(['C_Currency_ID'], false);
    this.gridViews[0].GridTabInfinityScrollUiComponent.agGrid.columnApi.setColumnsVisible(['AmtSourceDr'], false);
    this.gridViews[0].GridTabInfinityScrollUiComponent.agGrid.columnApi.setColumnsVisible(['AmtSourceCr'], false);
    this.gridViews[0].GridTabInfinityScrollUiComponent.agGrid.columnApi.setColumnsVisible(['Rate'], false);

    if (item.data.columnName === 'IsShowQuantity' && this.dataStore.data['IsShowQuantity'] == 'Y') {
      this.gridViews[0].GridTabInfinityScrollUiComponent.agGrid.columnApi.setColumnsVisible(['Qty'], true);
      this.gridViews[0].GridTabInfinityScrollUiComponent.agGrid.columnApi.setColumnsVisible(['C_UOM_ID'], true);
    } else if (item.data.columnName === 'SelectDocument' && this.dataStore.data['SelectDocument'] == 'Y') {
      this.gridViews[0].GridTabInfinityScrollUiComponent.agGrid.columnApi.setColumnsVisible(['AD_Table_ID'], true);
      this.gridViews[0].GridTabInfinityScrollUiComponent.agGrid.columnApi.setColumnsVisible(['Record_ID'], true);
    } else if (item.data.columnName === 'IsShowSourceInfo' && this.dataStore.data['IsShowSourceInfo'] == 'Y') {
      this.gridViews[0].GridTabInfinityScrollUiComponent.agGrid.columnApi.setColumnsVisible(['C_Currency_ID'], true);
      this.gridViews[0].GridTabInfinityScrollUiComponent.agGrid.columnApi.setColumnsVisible(['AmtSourceDr'], true);
      this.gridViews[0].GridTabInfinityScrollUiComponent.agGrid.columnApi.setColumnsVisible(['AmtSourceCr'], true);
      this.gridViews[0].GridTabInfinityScrollUiComponent.agGrid.columnApi.setColumnsVisible(['Rate'], true);
    }
    super.notifyFromDataChange(item);
  }

  clearSelections() {
    this.dataStore.data['selections'].forEach((grid) => {
      grid['selection'] = [];
    });
    this.gridViews.forEach((grid) => {
      grid.GridTabInfinityScrollUiComponent.agGrid.api.deselectAll();
    });
  }

  notifyFromRowSelected(gridView: GridViewUiComponent) {
    this.LengthOfRowSelected = this.gridViews[0].GridTabInfinityScrollUiComponent.agGrid.api.getSelectedRows().length;
    this.totals = 0;
    if (this.dataStore.data.selections[0].selection.length > 0) {
      for (let i = 0; i < this.dataStore.data.selections[0].selection.length; i++) {
        this.totals +=
          Math.round(this.dataStore.data.selections[0].selection[i].AmtAcctDr) -
          Math.round(this.dataStore.data.selections[0].selection[i].AmtAcctCr);
      }
      this.dataStore.data.Total = this.totals;
    } else {
      this.dataStore.data.Total = 0;
    }
    const buttonLettrage = this.getDatacontainer('Lettering');
    const buttonDelettrage = this.getDatacontainer('unlettering');

    if (this.totals === 0 && this.LengthOfRowSelected !== 0) {
      // disable lettrage
      buttonLettrage.isReadOnly = false;
    } else {
      buttonLettrage.isReadOnly = true;
    }

    if (
      this.dataStore.data['IsShowLettrage'] === 'Y' &&
      this.gridViews[0].GridTabInfinityScrollUiComponent.agGrid.api.getInfiniteRowCount() > 1 &&
      this.dataStore.data['MatchingNo'] !== null &&
      this.dataStore.data['MatchingNo'].length > 0
    ) {
      buttonDelettrage.isReadOnly = false;
    } else {
      buttonDelettrage.isReadOnly = true;
    }

    this.setDataContainersValueWithChangedStore();
  }
  checkBeforeProcessing() {
    if (this.checkAndDelete_Process_ID === 0) {
      this.fields.forEach((item) => {
        if (item.data.columnName === 'CheckAndDeleteDirtyMatched') {
          this.checkAndDelete_Process_ID = item.processId;
        } else if (item.data.columnName === 'LetteringVerification') {
          this.LetteringVerification_Process_ID = item.processId;
          this.checkLettrageProcess_ID = item.processId;
        }
      });
    }
    return true;
  }
  processLetteringVerification(item) {
    let button = this.getDatacontainer('LetteringVerification');
    if (button) {
      button.isReadOnly = true;
    }
    Global.infoDialog.message = {
      summary: this.translateService.instant('infodialog.dialogs.DeleteDirtyMatched.title'),
      detail: this.translateService.instant('infodialog.dialogs.DeleteDirtyMatched.message'),
    };

    Global.infoDialog.dialogType = InfoDialogType.CONFIRM_YESNO;
    Global.infoDialog.showInfoDialog();
    const confirm = Global.infoDialog.confirm.subscribe((e: any) => {
      const button = this.getDatacontainer('LetteringVerification');
      this.executeProcess(this.checkAndDelete_Process_ID);
      if (button) {
        button.isReadOnly = true;
      }
      if (confirm !== undefined) {
        confirm.unsubscribe();
      }
      if (cancel !== undefined) {
        cancel.unsubscribe();
      }
    });

    const cancel = Global.infoDialog.cancel.subscribe((e) => {
      if (button) {
        button.isReadOnly = false;
      }
      this.executeProcess(this.checkLettrageProcess_ID);

      if (confirm !== undefined) {
        confirm.unsubscribe();
      }
      if (cancel !== undefined) {
        cancel.unsubscribe();
      }
    });
  }

  onEndProcess() {
    const button = this.getDatacontainer('unLettering');
    if (button) {
      button.isReadOnly = false;
    }
    const buttoVerif = this.getDatacontainer('LetteringVerification');
    if (buttoVerif) {
      buttoVerif.isReadOnly = false;
    }
    this.refreshGrids(this.dataStore, false);
    this.clearAllGridsSelection();
  }
}
