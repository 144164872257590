import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'iu-checkbox-ui',
  templateUrl: './checkbox-ui.component.html',
  styleUrls: ['./checkbox-ui.component.scss'],
})
export class CheckboxUiComponent {
  @Input() tristate: boolean = false;
  @Input() disabled: boolean = false;
  @Input() checked: any;
  @Output() checkedChange = new EventEmitter<any>();

  public onClick(event: MouseEvent) {
    if (!this.disabled) {
      if (this.tristate) this.updateTristateValue();
      else this.updateBistateValue();
    }
  }

  private updateTristateValue() {
    switch (this.checked) {
      case true:
        this.checked = false;
        break;
      case false:
        this.checked = null;
        break;
      default:
        this.checked = true;
        break;
    }
    this.checkedChange.emit(this.checked);
  }

  private updateBistateValue() {
    this.checked = !this.checked;
    this.checkedChange.emit(this.checked);
  }
}
