import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dashboardGroupName',
})
export class DashboardGroupNamePipe implements PipeTransform {
  transform(groups: any[], currentID?: any): any {
    if (groups !== undefined && groups !== null && groups.length > 0 && currentID !== undefined && currentID !== null) {
      const grIDX = groups.findIndex((item) => item.id === currentID);
      if (grIDX !== -1) {
        return groups[grIDX].name;
      }
    }
    return '';
  }
}
