import { ComponentRef } from '@angular/core';
import { CompiereDataFieldType } from '@compiere-ws/models/compiere-data-json';
import { CacheManagerService } from '@iupics-manager/managers/cache-manager/cache-manager.service';
import { AbstractDataContainer } from '@iupics-manager/models/abstract-datacontainer';
import { AbstractDynamicComponent } from '@iupics-manager/models/abstract-dynamic-component';
import { DynamicComponent } from '@iupics-manager/models/dynamic-component';
import { Global } from '@iupics-manager/models/global-var';
import { IupicsMenuType } from '@web-desktop/models/menu-item-ui';
export class WindowFactoryUtils {
  public static addContainerComponent(
    parent: AbstractDynamicComponent,
    item: DynamicComponent,
    isCssOnComponent: boolean = true
  ): ComponentRef<any> {
    Global.startPerf(parent, 'addContainerComponent.' + item.component);
    let componentRef: ComponentRef<any>;
    componentRef = parent.vcr.createComponent(CacheManagerService.iupics_components.get(<string>item.component));
    // ajout du composant créer dasn les enfants du parent
    parent.DOMComponent.componentType.name === 'AdditionalInfoUiComponent'
      ? parent.DOMParentComponent.DOMChildrenComponent.push(<AbstractDynamicComponent>componentRef.instance)
      : parent.DOMChildrenComponent.push(<AbstractDynamicComponent>componentRef.instance);
    (<AbstractDynamicComponent>componentRef.instance).DOMParentComponent = parent;

    if (!item.container) {
      item.container = item.DOMParentComponent.container;
    }
    if ((<AbstractDynamicComponent>item.container).windowType === IupicsMenuType.WINDOW) {
      (<AbstractDataContainer>componentRef.instance).fieldType = CompiereDataFieldType.FIELD;
    } else if ((<AbstractDynamicComponent>item.container).windowType === IupicsMenuType.PROCESS) {
      (<AbstractDataContainer>componentRef.instance).fieldType = CompiereDataFieldType.PROCESS_PARA;
    } else if ((<AbstractDynamicComponent>item.container).windowType === IupicsMenuType.FORM) {
      (<AbstractDataContainer>componentRef.instance).fieldType = CompiereDataFieldType.FORM_ITEM;
    }
    (<AbstractDynamicComponent>componentRef.instance).container = item.container;
    (<AbstractDynamicComponent>componentRef.instance).DOMComponent = componentRef;
    (<AbstractDynamicComponent>componentRef.instance).data = item.data;
    if (item.component === 'GridViewUiComponent' || item.component === 'EditTabUiComponent') {
      (<AbstractDynamicComponent>componentRef.instance).isReadOnly = item.data.isReadOnly;
      (<AbstractDynamicComponent>componentRef.instance).isInsertRecord = item.data.isInsertRecord;
    }
    // if (item.component === 'CardsUiComponent') {
    //   (<CardsUiComponent>componentRef.instance).type = item.data.tabType;
    // }
    (<AbstractDynamicComponent>componentRef.instance).gridPaginator = item.gridPaginator;
    if (item.tabId) {
      (<AbstractDynamicComponent>componentRef.instance).tabId = item.tabId;
    }

    if (item.gridTabFilter) {
      (<AbstractDynamicComponent>componentRef.instance).gridTabFilter = item.gridTabFilter;
    }
    if (item.gridTabValidator) {
      (<AbstractDynamicComponent>componentRef.instance).gridTabValidator = item.gridTabValidator;
    }
    if (item.initRequest) {
      (<AbstractDynamicComponent>componentRef.instance).initRequest = item.initRequest;
    }
    if (item.zoomInfo) {
      (<AbstractDynamicComponent>componentRef.instance).zoomInfo = item.zoomInfo;
    }

    if (item.parentStore) {
      (<AbstractDynamicComponent>componentRef.instance).parentStore = item.parentStore;
    }

    if (item.zoomTarget) {
      (<AbstractDynamicComponent>componentRef.instance).zoomTarget = item.zoomTarget;
    }
    if (item.parentTab || (item.DOMParentComponent && item.DOMParentComponent.parentTab)) {
      (<AbstractDynamicComponent>componentRef.instance).parentTab = item.parentTab
        ? item.parentTab
        : item.DOMParentComponent.parentTab;
    }
    if (item.parentProcess || (item.DOMParentComponent && item.DOMParentComponent.parentProcess)) {
      (<AbstractDynamicComponent>componentRef.instance).parentProcess = item.parentProcess
        ? item.parentProcess
        : item.DOMParentComponent.parentProcess;
      (<AbstractDataContainer>componentRef.instance).fieldType = CompiereDataFieldType.PROCESS_PARA;
    }
    if (item.zoomTargetData) {
      (<AbstractDynamicComponent>componentRef.instance).zoomTargetData = item.zoomTargetData;
    }
    if (item.linkedComponents) {
      item.linkedComponents.forEach((element) => {
        (<AbstractDynamicComponent>componentRef.instance).addSubscribeOnLinkedComponent(element, componentRef);
      });
    }
    if (isCssOnComponent) {
      componentRef.location.nativeElement.className = item.cssClass;
    } else {
      (<AbstractDynamicComponent>componentRef.instance).cssClass = item.cssClass;
    }
    (<AbstractDynamicComponent>componentRef.instance).componentEmitter.subscribe((event) => {
      parent.container.windowFactory.newEventHandler(event);
    });
    if (item.children) {
      (<AbstractDynamicComponent>componentRef.instance).children = item.children.filter((child) => child !== undefined);
      item.children.forEach((child) => {
        child.DOMParentComponent = componentRef.instance;
      });
    }
    Global.endPerf(parent, 'addContainerComponent.' + item.component);
    return componentRef;
  }
}
