<div class="cards-body">
  <div class="cards-container" [attr.data-cards-container]="uuid">
    <button
      *ngIf="cardsDisplayed.length > 0"
      class="cards-button"
      data-position="left"
      type="button"
      [disabled]="cardsElement[0] === cardsIntoView[0]"
      (click)="prev()"
      data-small>
      <i class="icon-back" aria-hidden="true"></i>
    </button>
    <div class="cards-wrapper" data-cards-wrapper>
      <div
        *ngFor="let card of cardsDisplayed; let i = index"
        class="card-container"
        [attr.data-card]="i"
        (click)="clickCard(card)">
        <iu-address-card *ngIf="type === 'A'" [content]="card"></iu-address-card>
        <iu-contact-card *ngIf="type === 'C'" [content]="card"></iu-contact-card>
      </div>
    </div>
    <button
      *ngIf="cardsDisplayed.length > 0"
      class="cards-button"
      data-position="right"
      type="button"
      [disabled]="cardsElement[cardsElement?.length - 1] === cardsIntoView[cardsIntoView?.length - 1]"
      (click)="next()"
      data-small>
      <i class="icon-next" aria-hidden="true"></i>
    </button>
  </div>
  <button
    *ngIf="cardsDisplayed?.length !== cards?.length"
    class="cards-button"
    type="button"
    data-large
    (click)="addMoreCards()">
    {{ 'cardsUi.cardsPlusBtn' | translate }}
  </button>
</div>
