<div [style.width]="this.isTabTopLevel ? this.tableWidth : '100%'" [style.height]="this.tableHeight">
  <ag-grid-angular
    #agGrid
    style="width: 100%; height: 100%"
    id="myGrid"
    class="ag-theme-balham"
    (cellClicked)="onCellClicked($event)"
    (rowSelected)="onRowSelected($event)"
    (toolPanelVisibleChanged)="toolPanelVisibleChanged()"
    (rowGroupOpened)="onRowGroupOpened($event)"
    (cellEditingStopped)="onCellEditingStopped($event)"
    [animateRows]="false"
    [cacheBlockSize]="cacheBlockSize"
    [columnDefs]="columnsTableHeader"
    [components]="this.data.frameworkComponents"
    [getContextMenuItems]="getContextMenuItems"
    [localeText]="localeText"
    [multiSortKey]="'ctrl'"
    [pagination]="true"
    [paginationAutoPageSize]="!isMobile && (!isInSpecificOrNotLvl0 || forcePaginationAutoPageSize)"
    [paginationPageSize]="paginationPageSize"
    (paginationChanged)="onPageChange()"
    [rowModelType]="'serverSide'"
    [getRowId]="getRowId"
    [groupDisplayType]="'multipleColumns'"
    [rowMultiSelectWithClick]="rowMultiSelectWithCLick"
    [rowSelection]="rowSelection"
    [sideBar]="sideBar"
    [suppressColumnVirtualisation]="true"
    [suppressRowClickSelection]="suppressRowClickSelection"
    [statusBar]="statusBar"
    [rowClassRules]="rowClassRules"
    [enableBrowserTooltips]="true"
    (gridReady)="onGridReady($event)"
    [defaultColDef]="defaultColDef"></ag-grid-angular>
</div>
