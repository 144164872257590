import { Injectable } from '@angular/core';
import { Global } from '@iupics-manager/models/global-var';
import { ApizConfig, environment } from 'environments/environment';
import { merge } from 'lodash';

@Injectable()
export class AppConfig {
  private static envVariable: {};
  private static apizConfig: ApizConfig;

  constructor() {}

  /**
   * Used in APP_INITIALIZER to load Environment variables
   * @returns
   */
  public loadEnvVariable() {
    const configFile = `assets/env.properties`;
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open('GET', configFile);

      xhr.addEventListener('readystatechange', () => {
        if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
          const response = xhr.responseText;
          const parsedResponse = this.parsePropertyFileToJSON(response);
          AppConfig.envVariable = parsedResponse['IUPICS'];
          this.mergeEnvVariables();
          resolve(true);
        }
      });
      xhr.send(null);
    });
  }

  /**
   * Used in APP_INITIALIZER to load the config from the config-server
   * @param access_token
   * @returns
   */
  public loadConfig(access_token: string) {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open('GET', `${environment.configUrl}?${new Date().getTime()}`);
      xhr.setRequestHeader('Authorization', 'Bearer ' + access_token);
      xhr.addEventListener('readystatechange', () => {
        if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
          const result = JSON.parse(xhr.responseText);
          AppConfig.apizConfig = result;
          this.mergeConfig();
          this.mergeEnvVariables();
          Global.perfLogActive = environment.config.perfLogActive;
          // this.logConfig();
          resolve(true);
        } else if (xhr.readyState === XMLHttpRequest.DONE) {
          reject();
        }
      });
      xhr.send(null);
    });
  }

  private mergeEnvVariables() {
    if (AppConfig.envVariable) {
      environment.config = merge(environment.config, AppConfig.envVariable['config']);
      environment.constant = merge(environment.constant, AppConfig.envVariable['constant']);
      environment.themes = merge(environment.themes, AppConfig.envVariable['themes']);
      const keys = Object.keys(AppConfig.envVariable).filter((key) => !['config', 'constant', 'themes'].includes(key));
      keys.forEach((envKey) => {
        environment[envKey] = AppConfig.envVariable[envKey];
      });
    }
  }

  private mergeConfig() {
    environment.config = merge(environment.config, AppConfig.apizConfig['config']);
    environment.constant = merge(environment.constant, AppConfig.apizConfig['constant']);
    environment.themes = merge(environment.themes, AppConfig.apizConfig['themes']);
    const keys = Object.keys(AppConfig.apizConfig).filter((key) => !['config', 'constant', 'themes'].includes(key));
    keys.forEach((envKey) => {
      environment[envKey] = AppConfig.apizConfig[envKey];
    });
  }

  public getBackendResource(key?: string): string {
    return environment.config.backend.ws.url + (key ? environment.config.backend.ws.paths[key] : '');
  }

  getConstant(key: string): any {
    return environment.constant[key];
  }

  private parseYMLtoJSON(ymlObject: any): ApizConfig {
    const config = {};
    Object.keys(ymlObject).forEach((key) => {
      let node = config;
      const keys = key.split('.');
      for (let i = 0; i < keys.length; i++) {
        if (!node[keys[i]]) {
          if (i === keys.length - 1) {
            if (keys[i].endsWith(']')) {
              const keyArray = keys[i].substring(0, keys[i].lastIndexOf('['));
              if (node[keyArray]) {
                node[keyArray].push(ymlObject[key]);
              } else {
                node[keyArray] = [];
                node[keyArray].push(ymlObject[key]);
              }
            } else {
              node[keys[i]] = ymlObject[key];
            }
          } else {
            node[keys[i]] = {};
          }
        }
        node = node[keys[i]];
      }
    });
    return <ApizConfig>config;
  }

  private parsePropertyFileToJSON(str) {
    const result = {};
    str
      .split('\n')
      .filter((l: string) => l?.trim())
      .forEach((line: string) => {
        if (!line.startsWith('#')) {
          const datas = line.split('=');
          const keys = datas[0].split('_');
          let obj = result;
          for (let i = 0; i < keys.length; i++) {
            if (obj instanceof Object) {
              if (!obj[keys[i].trim()] && obj) {
                obj[keys[i].trim()] = {};
              }
              if (i + 1 === keys.length) {
                try {
                  obj[keys[i].trim()] = JSON.parse(datas[1]);
                } catch (e) {
                  obj[keys[i].trim()] = datas[1];
                }
              }
              obj = obj[keys[i].trim()];
            }
          }
        }
      });
    return result;
  }

  private logConfig() {
    console.group('config-server.yml');
    console.groupCollapsed('AppConfig.config');
    console.log(AppConfig.apizConfig);
    console.groupEnd();
    console.groupCollapsed('AppConfig.envVariable');
    console.log(AppConfig.envVariable);
    console.groupEnd();
    console.groupCollapsed('environment');
    console.log(environment);
    console.groupEnd();
    console.groupEnd();
  }
}
