import { Pipe, PipeTransform, inject } from '@angular/core';
import { MenuCategoryUI, MenuItemUI } from '@web-desktop/models/menu-item-ui';
import { Observable } from 'rxjs';
import { MenuUiManagerService } from '../../controllers/menu-ui-manager/menu-ui-manager.service';
import { MenuUiComponent } from './menu-ui.component';

@Pipe({
  name: 'filterMenus',
})
export class FilterMenusPipe implements PipeTransform {
  private menuUiManager = inject(MenuUiManagerService);

  transform(
    menus$: Observable<MenuItemUI[]>,
    searchValue: string,
    selectedCategory: MenuCategoryUI,
    context: MenuUiComponent
  ): Observable<MenuItemUI[]> {
    if (!searchValue && selectedCategory?.id === 0) {
      context.selectedCategory = undefined;
      selectedCategory = undefined;
    }
    context.selectedMenu = undefined;
    return searchValue ? this.menuUiManager.search(searchValue) : menus$;
  }
}
