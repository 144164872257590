import { Injectable } from '@angular/core';
import { CompiereMenu } from '@compiere-ws/models/compiere-menu-json';
import { AppConfig } from '@iupics-config/app.config';
import { Observable } from 'rxjs';
import { ApiService } from '../api/api.service';

@Injectable()
export class CompiereMenuFavoritesService {
  private readonly urlMenu: string;
  private readonly urlMenuEdit: string;
  private readonly urlMenuDelete: string;

  private readonly urlWidget: string;
  private readonly urlWidgeEdit: string;
  private readonly urlWidgeDelete: string;

  constructor(private http: ApiService, private config: AppConfig) {
    this.urlMenu = this.config.getBackendResource('menuFavorites');
    this.urlMenuEdit = this.config.getBackendResource('menuFavorites');
    this.urlMenuDelete = this.config.getBackendResource('menuFavorites');

    this.urlWidget = this.config.getBackendResource('widgetFavorites');
    this.urlWidgeEdit = this.config.getBackendResource('widgetFavorites');
    this.urlWidgeDelete = this.config.getBackendResource('widgetFavorites');
  }

  getMenuFavorites(): Observable<CompiereMenu[]> {
    return this.http.get<CompiereMenu[]>(this.urlMenu);
  }

  getWidgetFavorites(): Observable<CompiereMenu[]> {
    return this.http.get<CompiereMenu[]>(this.urlWidget);
  }

  setWidgetFavorites(compiereMenu: CompiereMenu[]): Observable<CompiereMenu[]> {
    const isSummary = compiereMenu[0].isSummary;
    return this.http.post<CompiereMenu[]>(this.urlWidgeEdit + '?isCategory=' + isSummary, compiereMenu);
  }

  deleteWidgetFavorites(parentId: number, menuId: number, isSummary: boolean): Observable<boolean> {
    return this.http.delete<boolean>(
      this.urlWidgeDelete + '?isCategory=' + isSummary + '&menuID=' + menuId + '&parentId=' + parentId
    );
  }

  setMenuFavorites(compiereMenu: CompiereMenu[]): Observable<CompiereMenu[]> {
    const isSummary = compiereMenu[0].isSummary;
    return this.http.post<CompiereMenu[]>(this.urlMenuEdit + '?isCategory=' + isSummary, compiereMenu);
  }

  deleteMenuFavorites(parentId: number, menuId: number, isSummary: boolean): Observable<boolean> {
    return this.http.delete<boolean>(
      this.urlMenuDelete + '?isCategory=' + isSummary + '&menuID=' + menuId + '&parentId=' + parentId
    );
  }
}
