<div
  #specificContainer
  class="iu-specific-window iu-specific-window-container"
  [attr.data-cy-specific-window-name]="name">
  <p-progressBar
    *ngIf="!isModal && isLoading"
    [mode]="'indeterminate'"
    [value]="0"
    [showValue]="false"
    [styleClass]="'loading-progressbar'"></p-progressBar>
  <div class="fields p-grid" style="margin-bottom: 10px">
    <ng-template iuDynamicContainer></ng-template>
  </div>
  <p-tabView (onChange)="onResize($event)">
    <p-tabPanel header="{{ 'productPrice.priceHistory' | translate }}">
      <div class="top-container-PriceHistory">
        <div class="p-col-12">
          <ng-template #vcrHistoriqueDesPrix></ng-template>
        </div>
      </div>
    </p-tabPanel>
    <p-tabPanel header="{{ 'productPrice.qtyReserved' | translate }}">
      <div class="top-container-PriceHistory">
        <div class="p-col-12">
          <ng-template #vcrQtyReserved></ng-template>
        </div>
      </div>
    </p-tabPanel>
    <p-tabPanel header="{{ 'productPrice.qtyOrdered' | translate }}">
      <div class="top-container-PriceHistory">
        <div class="p-col-12">
          <ng-template #vcrOrdered></ng-template>
        </div>
      </div>
    </p-tabPanel>
    <p-tabPanel header="{{ 'productPrice.unconQty' | translate }}">
      <div class="top-container-PriceHistory">
        <div class="p-col-12">
          <ng-template #vcrUnconfirmed></ng-template>
        </div>
      </div>
    </p-tabPanel>
    <p-tabPanel
      [headerStyle]="{ display: !isProductView ? 'none' : 'block' }"
      header="{{ 'productPrice.avalaibaleSales' | translate }}">
      <div class="top-container-PriceHistory">
        <div class="p-col-12">
          <ng-template #vcrATP></ng-template>
        </div>
      </div>
    </p-tabPanel>
    <p-tabPanel header="{{ 'productPrice.salesContract' | translate }}">
      <div class="top-container-PriceHistory">
        <div class="p-col-12">
          <ng-template #vcrBlanketSO></ng-template>
        </div>
      </div>
    </p-tabPanel>
    <p-tabPanel header="{{ 'productPrice.purchaseContract' | translate }}">
      <div class="top-container-PriceHistory">
        <div class="p-col-12">
          <ng-template #vcrBlanketPO></ng-template>
        </div>
      </div>
    </p-tabPanel>
  </p-tabView>
</div>
<div [ngClass]="[!displayFormUI ? 'hidden' : '', 'specificModal']">
  <iu-modal-ui
    [title]="specificWindowTitle"
    [isModalDisplay]="displayFormUI"
    (closeModalEmitter)="updateModalDisplay({ key: 'displayFormUI', value: false })">
    <ng-template #vcrSpecific></ng-template>
  </iu-modal-ui>
</div>
