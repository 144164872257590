import { Pipe, PipeTransform } from '@angular/core';
import { CompiereDataGridFilterType } from '@compiere-ws/models/compiere-data-json';
import { ColumnFilterAutocomplete } from '@iupics-components/models/autocomplete-interfaces';

@Pipe({
  name: 'universalFilterColFilterType',
})
export class UniversalFilterColFilterTypePipe implements PipeTransform {
  transform(
    value: { items: ColumnFilterAutocomplete[] },
    indexWanted: number,
    indexInArray: number,
    wantedType: CompiereDataGridFilterType
  ): any {
    if (wantedType !== CompiereDataGridFilterType.NONE) {
      if (indexWanted === indexInArray) {
        const newValue: { items: ColumnFilterAutocomplete[] } = { items: [] };
        newValue.items = value.items.filter((cfa) => cfa.columnInfo.filterType === wantedType);
        return newValue;
      }
    }
    return value;
  }
}
