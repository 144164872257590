// Angular Modules
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CompiereWsModule } from '@compiere-ws/compiere-ws.module';
import { DataStore } from '@compiere-ws/models/compiere-data-json';
import { PrimeAutocompleteComponent } from '@iupics-components/overrided/prime-autocomplete/prime-autocomplete.component';
import { PrimeBreadcrumbComponent } from '@iupics-components/overrided/prime-breadcrumb/prime-breadcrumb.component';
import { AgGridModule } from 'ag-grid-angular';
import { LicenseManager } from 'ag-grid-enterprise';
// Project Imports
import { PrimeCalendarComponent } from '@iupics-components/overrided/prime-calendar/prime-calendar.component';
import { PrimeFileuploadComponent } from '@iupics-components/overrided/prime-fileupload/prime-fileupload.component';
import { PrimengModule } from '@iupics-components/primeng.module';
import { BpartnerBalanceComponent } from '@iupics-components/specific/window/bpartner-balance/bpartner-balance.component';
import { CreateFromStatementWindowUiComponent } from '@iupics-components/specific/window/create-from-statement-window-ui/create-from-statement-window-ui.component';
import { InfoDialogComponent } from '@iupics-components/specific/window/info-dialog/info-dialog.component';
import { PriceHistoryComponent } from '@iupics-components/specific/window/price-history/price-history.component';
import { ProcessUiComponent } from '@iupics-components/specific/window/process-ui/process-ui.component';
import { SelectOrderComponent } from '@iupics-components/specific/window/select-order/select-order.component';
import { SpecificWindowUiComponent } from '@iupics-components/specific/window/specific-window-ui/specific-window-ui.component';
import { AutocompleteUiComponent } from '@iupics-components/standard/fields/autocomplete-ui/autocomplete-ui.component';
import { ButtonUiComponent } from '@iupics-components/standard/fields/button-ui/button-ui.component';
import { CalendarUiComponent } from '@iupics-components/standard/fields/calendar-ui/calendar-ui.component';
import { InputFileUiComponent } from '@iupics-components/standard/fields/input-file-ui/input-file-ui.component';
import { InputImageUiComponent } from '@iupics-components/standard/fields/input-image-ui/input-image-ui.component';
import { InputLocationUiComponent } from '@iupics-components/standard/fields/input-location-ui/input-location-ui.component';
import { InputNumberUiComponent } from '@iupics-components/standard/fields/input-number-ui/input-number-ui.component';
import { InputProductAttributesUiComponent } from '@iupics-components/standard/fields/input-product-attributes-ui/input-product-attributes-ui.component';
import { InputSwitchUiComponent } from '@iupics-components/standard/fields/input-switch-ui/input-switch-ui.component';
import { InputTextUiComponent } from '@iupics-components/standard/fields/input-text-ui/input-text-ui.component';
import { InputTextareaUiComponent } from '@iupics-components/standard/fields/input-textarea-ui/input-textarea-ui.component';
import { InputTimeUiComponent } from '@iupics-components/standard/fields/input-time-ui/input-time-ui.component';
import { GridTabInfinityScrollUiComponent } from '@iupics-components/standard/grid/grid-tab-infinity-scroll-ui/grid-tab-infinity-scroll-ui.component';
import { GridViewUiComponent } from '@iupics-components/standard/grid/grid-view-ui/grid-view-ui.component';
import { TreeUiComponent } from '@iupics-components/standard/grid/tree-ui/tree-ui.component';
import { AccordionUiComponent } from '@iupics-components/standard/layouts/accordion-ui/accordion-ui.component';
import { BladeUiComponent } from '@iupics-components/standard/layouts/blade-ui/blade-ui.component';
import { EditTabUiComponent } from '@iupics-components/standard/layouts/edit-tab-ui/edit-tab-ui.component';
import { EditViewUiComponent } from '@iupics-components/standard/layouts/edit-view-ui/edit-view-ui.component';
import { GridUiComponent } from '@iupics-components/standard/layouts/grid-ui/grid-ui.component';
import { RowUiComponent } from '@iupics-components/standard/layouts/row-ui/row-ui.component';
import { MenuBarDetailUiComponent } from '@iupics-components/standard/menu/menu-bar-detail-ui/menu-bar-detail-ui.component';
import { MenuBarUiComponent } from '@iupics-components/standard/menu/menu-bar-ui/menu-bar-ui.component';
import { IupicsManagerModule } from '@iupics-manager/iupics-manager.module';
import { IupicsCookieService } from '@iupics-manager/managers/security-manager/cookies/iupics-cookie.service';
import { WindowFactoryService } from '@iupics-manager/managers/ui-creator/window-factory/window-factory.service';
import { IupicsUtilModule } from '@iupics-util/iupics-util.module';
import { TranslateModule } from '@ngx-translate/core';
import { ContextMenuService } from '@web-desktop/components/workspace/controllers/context-menu/context-menu.service';
import { AngularSplitModule } from 'angular-split';
// Misc Imports
import { DocIconPipe } from '@iupics-util/pipes/doc-icon/doc-icon.pipe';
import { CookieService } from 'ngx-cookie-service';
import { TagifyModule } from 'ngx-tagify';
import { ObjectUtils } from 'primeng/utils';
import { KeybindingDirective } from './directives/keybinding.directive';
import { LoadingDirective } from './directives/loading.directive';
import { NumberAnimationDirective } from './directives/number-animation.directive';
import { MaterialModule } from './material.module';
import { BannerComponent } from './message/banner/banner.component';
import { NotificationComponent } from './message/notification/notification.component';
import { ProcessLogsUiComponent } from './message/process-logs-ui/process-logs-ui.component';
import { PrimeChipsComponent } from './overrided/prime-chips/prime-chips.component';
import {
  PrimeContextMenuComponent,
  PrimeContextMenuSubComponent,
} from './overrided/prime-contextmenu/prime-contextmenu.component';
import { PrimeFieldsetComponent } from './overrided/prime-fieldset/prime-fieldset.component';
import { GetPercentInPxCssPipe } from './overrided/prime-fileupload/get-percent-in-px-css.pipe';
import { PrimeOverlayComponent } from './overrided/prime-overlay/prime-overlay.component';
import { PrimeStepsComponent } from './overrided/prime-steps/prime-steps.component';
import { AutocompleteSuggestionsFilterPipe } from './pipes/autocomplete-suggestions-filter/autocomplete-suggestions-filter.pipe';
import { ColumnGroupFilterPipe } from './pipes/column-group-filter/column-group-filter.pipe';
import { FilterListPipe } from './pipes/filter-list/filter-list.pipe';
import { OperatorFilterPipe } from './pipes/operator-filter/operator-filter.pipe';
import { ParseInstanceParamsPipe } from './pipes/parse-instance-params/parse-instance-params.pipe';
import { SafeHtmlPipe } from './pipes/safeHtml/safeHtml.pipe';
import { SpreadOperatorPipe } from './pipes/spread-operator/spread-operator.pipe';
import { TimeFormatPipe } from './pipes/time-format/time-format.pipe';
import { ToDatePipe } from './pipes/to-date/to-date.pipe';
import { UniversalFilterColFilterTypePipe } from './pipes/universal-filter-col-filter-type/universal-filter-col-filter-type.pipe';
import { AccountEditorWindowUiComponent } from './specific/window/account-editor-window-ui/account-editor-window-ui.component';
import { AccountingViewerUiComponent } from './specific/window/accounting-viewer-ui/accounting-viewer-ui.component';
import { AccountingViewerWindowUiComponent } from './specific/window/accounting-viewer-window-ui/accounting-viewer-window-ui.component';
import { NumberEditorComponent } from './specific/window/cell-editor/number-editor.component';
import { ChangelogWindowUIComponent } from './specific/window/changelog-window-ui/changelog-window-ui.component';
import { CreateFromInvoiceWindowUiComponent } from './specific/window/create-from-invoice-window-ui/create-from-invoice-window-ui.component';
import { CreateFromOpenInvoiceComponent } from './specific/window/create-from-open-invoice/create-from-open-invoice.component';
import { CreateFromShipmentWindowUiComponent } from './specific/window/create-from-shipment-window-ui/create-from-shipment-window-ui.component';
import { CruiseSituationUiComponent } from './specific/window/cruise-situation-ui/cruise-situation-ui.component';
import { BadgeButtonComponent } from './specific/window/customer-360/components/badge-button/badge-button.component';
import { AddressCardComponent } from './specific/window/customer-360/components/cards/cards-ui/card-content/address-card/address-card.component';
import { ContactCardComponent } from './specific/window/customer-360/components/cards/cards-ui/card-content/contact-card/contact-card.component';
import { CardsUiComponent } from './specific/window/customer-360/components/cards/cards-ui/cards-ui.component';
import { CustomerProfileComponent } from './specific/window/customer-360/components/customer-profile/customer-profile.component';
import { QuickActionComponent } from './specific/window/customer-360/components/quick-action/quick-action.component';
import { StatisticsCardComponent } from './specific/window/customer-360/components/statistics-card/statistics-card.component';
import { Customer360Component } from './specific/window/customer-360/customer-360.component';
import { DirectPrintingDocumentsComponent } from './specific/window/direct-printing-documents/direct-printing-documents.component';
import { EmailEditorUiComponent } from './specific/window/email-editor-ui/email-editor-ui.component';
import { ExportDataUiComponent } from './specific/window/export-data-ui/export-data-ui.component';
import { FastCreateInvoiceVendorComponent } from './specific/window/fast-create-invoice-vendor/fast-create-invoice-vendor.component';
import { FastCreateOrderComponent } from './specific/window/fast-create-order/fast-create-order.component';
import { ImportFileLoaderWindowUiComponent } from './specific/window/import-file-loader-window-ui/import-file-loader-window-ui.component';
import { InvoiceMatchingWindowImplComponent } from './specific/window/invoice-matching-window-impl/invoice-matching-window-impl.component';
import { JoinFileUiComponent } from './specific/window/join-file-ui/join-file-ui.component';
import { LocationPanelComponent } from './specific/window/location-panel/location-panel.component';
import { LoginasFormComponent } from './specific/window/loginas-form/loginas-form.component';
import { LoginasUiComponent } from './specific/window/loginas-ui/loginas-ui.component';
import { MatchingAcctViewerWindowUiComponent } from './specific/window/matching-acct-viewer-window-ui/matching-acct-viewer-window-ui.component';
import { MaterialTransactionComponent } from './specific/window/material-transaction/material-transaction.component';
import { MergetoolComponent } from './specific/window/mergetool/mergetool.component';
import { OperationManagementUIComponent } from './specific/window/operation-management-ui/operation-management-ui.component';
import { PartnerViewComponent } from './specific/window/partner-view/partner-view.component';
import { PaymentAllocationWindowUiComponent } from './specific/window/payment-allocation-window-ui/payment-allocation-window-ui.component';
import { PaymentSelectionWindowUiComponent } from './specific/window/payment-selection-window-ui/payment-selection-window-ui.component';
import { PlanningWindowUiComponent } from './specific/window/planning-window-ui/planning-window-ui.component';
import { PosUiComponent } from './specific/window/pos-ui/pos-ui.component';
import { ProcessInProgressUiComponent } from './specific/window/process-in-progress-ui/process-in-progress-ui.component';
import { ProcessInProgressWindowUiComponent } from './specific/window/process-in-progress-window-ui/process-in-progress-window-ui.component';
import { ProductAttributeComponent } from './specific/window/product-attribute/product-attribute.component';
import { ProductViewComponent } from './specific/window/product-view/product-view.component';
import { AutocompleteRendererComponent } from './specific/window/renderer/autocomplete-renderer.component';
import { ButtonRendererComponent } from './specific/window/renderer/button-renderer.component';
import { CalendarRendererComponent } from './specific/window/renderer/calendar-renderer.component';
import { CheckboxRendererComponent } from './specific/window/renderer/checkbox-renderer.component';
import { NumberRendererComponent } from './specific/window/renderer/number-renderer.component';
import { TextRendererComponent } from './specific/window/renderer/text-renderer.component';
import { TreeMaintenanceUIComponent } from './specific/window/tree-maintenance-ui/tree-maintenance-ui.component';
import { CommentUiComponent } from './standard/fields/comment-ui/comment-ui.component';
import { InputChipsUiComponent } from './standard/fields/input-chips-ui/input-chips-ui.component';
import { TagContentUiComponent } from './standard/fields/input-mention-ui/components/tag-content-ui/tag-content-ui.component';
import { TagListItemUiComponent } from './standard/fields/input-mention-ui/components/tag-list-item-ui/tag-list-item-ui.component';
import { InputMentionUiComponent } from './standard/fields/input-mention-ui/input-mention-ui.component';
import { InputStarsUiComponent } from './standard/fields/input-stars-ui/input-stars-ui.component';
import { InputTextHtmlUiComponent } from './standard/fields/input-text-html-ui/input-text-html-ui.component';
import { SmartButtonUiComponent } from './standard/fields/smart-button-ui/smart-button-ui.component';
import { StepperUiComponent } from './standard/fields/stepper-ui/stepper-ui.component';
import { TextFieldUiComponent } from './standard/fields/text-field-ui/text-field-ui.component';
import { CalendarToolbarUiComponent } from './standard/grid/calendar/calendar-toolbar-ui/calendar-toolbar-ui.component';
import { CalendarViewUiComponent } from './standard/grid/calendar/calendar-view-ui/calendar-view-ui.component';
import { ChartUiComponent } from './standard/grid/chart-ui/chart-ui.component';
import { GanttComponent } from './standard/grid/gantt/gantt.component';
import { GridTabRowdataComponent } from './standard/grid/grid-tab-rowdata/grid-tab-rowdata.component';
import { KanbanBoardUiComponent } from './standard/grid/kanban/kanban-board-ui/kanban-board-ui.component';
import { KanbanCardUiComponent } from './standard/grid/kanban/kanban-card-ui/kanban-card-ui.component';
import { KanbanUiComponent } from './standard/grid/kanban/kanban-ui/kanban-ui.component';
import { SelectAllHeaderComponent } from './standard/grid/select-all-header/select-all-header.component';
import { StatusBarCountComponent } from './standard/grid/status-bar-count/status-bar-count.component';
import { AdditionalInfoUiComponent } from './standard/layouts/additional-info-ui/additional-info-ui.component';
import { ModalUiComponent } from './standard/layouts/modal-ui/modal-ui.component';
import { MenuSmartbuttonsUiComponent } from './standard/menu/menu-smartbuttons-ui/menu-smartbuttons-ui.component';
import { UniversalFilterUiComponent } from './standard/menu/universal-filter-ui/universal-filter-ui.component';
import { NoteUiComponent } from './standard/notes/note-ui/note-ui.component';
import { NotesPanelUiComponent } from './standard/notes/notes-panel-ui/notes-panel-ui.component';
import { PreviewDocComponent } from './standard/preview-doc/preview-doc.component';
import { AvatarService } from './standard/user/avatar-ui/avatar-service/avatar.service';
import { AvatarUiComponent } from './standard/user/avatar-ui/avatar-ui.component';
import { ValuePreferencePanelComponent } from './standard/value-preference-panel/value-preference-panel.component';
import { CheckboxUiComponent } from './standard/fields/checkbox-ui/checkbox-ui.component';

LicenseManager.setLicenseKey(
  `Using_this_AG_Grid_Enterprise_key_( AG-041745 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( AUDAXIS S.A. )_is_granted_a_( Single Application )_Developer_License_for_the_application_( Audaxis )_only_for_( 4 )_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_working_on_( Audaxis )_need_to_be_licensed___( Audaxis )_has_been_granted_a_Deployment_License_Add-on_for_( 3 )_Production_Environments___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 27 November 2024 )____[v2]_MTczMjY2NTYwMDAwMA==2edabad85bd3159c4a9895d498ea1171`
);

@NgModule({
  imports: [
    CompiereWsModule,
    CommonModule,
    PrimengModule,
    BrowserAnimationsModule,
    BrowserModule,
    AgGridModule,
    TranslateModule,
    IupicsUtilModule,
    IupicsManagerModule,
    MaterialModule,
    AngularSplitModule,
    TagifyModule.forRoot(),
  ],
  exports: [
    PrimengModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    HttpClientModule,
    AutocompleteUiComponent,
    InputNumberUiComponent,
    InputTextUiComponent,
    InputSwitchUiComponent,
    TranslateModule,
    InfoDialogComponent,
    InputTimeUiComponent,
    InputImageUiComponent,
    InputFileUiComponent,
    InputLocationUiComponent,
    InputProductAttributesUiComponent,
    SpecificWindowUiComponent,
    MenuBarDetailUiComponent,
    MenuBarUiComponent,
    ButtonUiComponent,
    CalendarUiComponent,
    InputTextareaUiComponent,
    StepperUiComponent,
    AccordionUiComponent,
    EditTabUiComponent,
    GridTabInfinityScrollUiComponent,
    GridViewUiComponent,
    ProcessUiComponent,
    MergetoolComponent,
    KanbanUiComponent,
    KanbanBoardUiComponent,
    KanbanCardUiComponent,
    LocationPanelComponent,
    LocationPanelComponent,
    BannerComponent,
    NotificationComponent,
    BladeUiComponent,
    PrimeBreadcrumbComponent,
    CalendarViewUiComponent,
    CalendarToolbarUiComponent,
    ChartUiComponent,
    PrimeFieldsetComponent,
    SelectOrderComponent,
    IupicsUtilModule,
    IupicsManagerModule,
    AdditionalInfoUiComponent,
    MenuSmartbuttonsUiComponent,
    SmartButtonUiComponent,
    PrimeStepsComponent,
    EmailEditorUiComponent,
    InputChipsUiComponent,
    AccountingViewerUiComponent,
    AccountingViewerWindowUiComponent,
    BpartnerBalanceComponent,
    PriceHistoryComponent,
    KeybindingDirective,
    MaterialModule,
    JoinFileUiComponent,
    ModalUiComponent,
    PrimeChipsComponent,
    PrimeContextMenuSubComponent,
    PrimeContextMenuComponent,
    AccountEditorWindowUiComponent,
    InvoiceMatchingWindowImplComponent,
    MatchingAcctViewerWindowUiComponent,
    CreateFromShipmentWindowUiComponent,
    CreateFromInvoiceWindowUiComponent,
    CreateFromStatementWindowUiComponent,
    PaymentAllocationWindowUiComponent,
    PaymentSelectionWindowUiComponent,
    LoginasUiComponent,
    LoginasFormComponent,
    FastCreateOrderComponent,
    DirectPrintingDocumentsComponent,
    PosUiComponent,
    ImportFileLoaderWindowUiComponent,
    GridTabRowdataComponent,
    ProcessInProgressUiComponent,
    ProcessInProgressWindowUiComponent,
    ProductAttributeComponent,
    CreateFromOpenInvoiceComponent,
    SelectAllHeaderComponent,
    StatusBarCountComponent,
    CommentUiComponent,
    ValuePreferencePanelComponent,
    PreviewDocComponent,
    FilterListPipe,
    FastCreateInvoiceVendorComponent,
    InputTextHtmlUiComponent,
    MaterialTransactionComponent,
    PrimeOverlayComponent,
    TimeFormatPipe,
    Customer360Component,
    TextFieldUiComponent,
    CardsUiComponent,
    AddressCardComponent,
    ContactCardComponent,
    BadgeButtonComponent,
    CustomerProfileComponent,
    QuickActionComponent,
    StatisticsCardComponent,
    InputStarsUiComponent,
    LoadingDirective,
    NumberAnimationDirective,
    NotesPanelUiComponent,
    NoteUiComponent,
    AvatarUiComponent,
    InputMentionUiComponent,
    TagContentUiComponent,
    TagListItemUiComponent,
    DocIconPipe,
  ],
  declarations: [
    InputNumberUiComponent,
    ProcessUiComponent,
    BladeUiComponent,
    GridViewUiComponent,
    MenuBarUiComponent,
    GridTabInfinityScrollUiComponent,
    EditViewUiComponent,
    MenuBarDetailUiComponent,
    GridUiComponent,
    StepperUiComponent,
    AutocompleteUiComponent,
    ButtonUiComponent,
    CalendarUiComponent,
    InputSwitchUiComponent,
    InputTextUiComponent,
    SpecificWindowUiComponent,
    InputTextareaUiComponent,
    EditTabUiComponent,
    AccordionUiComponent,
    RowUiComponent,
    PrimeBreadcrumbComponent,
    PrimeAutocompleteComponent,
    PrimeCalendarComponent,
    PrimeContextMenuSubComponent,
    PrimeContextMenuComponent,
    InfoDialogComponent,
    InputTimeUiComponent,
    InputImageUiComponent,
    InputFileUiComponent,
    PrimeFileuploadComponent,
    InputLocationUiComponent,
    InputProductAttributesUiComponent,
    TreeUiComponent,
    MergetoolComponent,
    KanbanUiComponent,
    KanbanBoardUiComponent,
    KanbanCardUiComponent,
    LocationPanelComponent,
    BannerComponent,
    NotificationComponent,
    CalendarViewUiComponent,
    CalendarToolbarUiComponent,
    ChartUiComponent,
    PrimeFieldsetComponent,
    SelectOrderComponent,
    AdditionalInfoUiComponent,
    MenuSmartbuttonsUiComponent,
    SmartButtonUiComponent,
    PrimeStepsComponent,
    EmailEditorUiComponent,
    InputChipsUiComponent,
    ExportDataUiComponent,
    KeybindingDirective,
    AccountingViewerUiComponent,
    AccountingViewerWindowUiComponent,
    BpartnerBalanceComponent,
    PriceHistoryComponent,
    JoinFileUiComponent,
    ModalUiComponent,
    UniversalFilterUiComponent,
    PrimeChipsComponent,
    AccountEditorWindowUiComponent,
    OperatorFilterPipe,
    InvoiceMatchingWindowImplComponent,
    MatchingAcctViewerWindowUiComponent,
    CreateFromShipmentWindowUiComponent,
    CreateFromInvoiceWindowUiComponent,
    CreateFromStatementWindowUiComponent,
    PaymentAllocationWindowUiComponent,
    PaymentSelectionWindowUiComponent,
    UniversalFilterColFilterTypePipe,
    AutocompleteSuggestionsFilterPipe,
    LoginasUiComponent,
    LoginasFormComponent,
    ProcessLogsUiComponent,
    FastCreateOrderComponent,
    DirectPrintingDocumentsComponent,
    CruiseSituationUiComponent,
    PosUiComponent,
    ImportFileLoaderWindowUiComponent,
    GridTabRowdataComponent,
    ButtonRendererComponent,
    AutocompleteRendererComponent,
    CheckboxRendererComponent,
    NumberRendererComponent,
    TextRendererComponent,
    CalendarRendererComponent,
    NumberEditorComponent,
    ProcessInProgressUiComponent,
    ProcessInProgressWindowUiComponent,
    ProductAttributeComponent,
    ParseInstanceParamsPipe,
    CreateFromOpenInvoiceComponent,
    SelectAllHeaderComponent,
    StatusBarCountComponent,
    CommentUiComponent,
    ColumnGroupFilterPipe,
    OperationManagementUIComponent,
    ProductViewComponent,
    ChangelogWindowUIComponent,
    SpreadOperatorPipe,
    GetPercentInPxCssPipe,
    SafeHtmlPipe,
    TreeMaintenanceUIComponent,
    PrimeOverlayComponent,
    PlanningWindowUiComponent,
    ValuePreferencePanelComponent,
    GanttComponent,
    PreviewDocComponent,
    FilterListPipe,
    FastCreateInvoiceVendorComponent,
    PartnerViewComponent,
    InputTextHtmlUiComponent,
    MaterialTransactionComponent,
    TimeFormatPipe,
    ToDatePipe,
    Customer360Component,
    TextFieldUiComponent,
    CardsUiComponent,
    AddressCardComponent,
    ContactCardComponent,
    BadgeButtonComponent,
    CustomerProfileComponent,
    QuickActionComponent,
    StatisticsCardComponent,
    InputStarsUiComponent,
    LoadingDirective,
    NumberAnimationDirective,
    NotesPanelUiComponent,
    NoteUiComponent,
    AvatarUiComponent,
    InputMentionUiComponent,
    TagContentUiComponent,
    TagListItemUiComponent,
    DocIconPipe,
    CheckboxUiComponent,
  ],
  providers: [
    ContextMenuService,
    CookieService,
    WindowFactoryService,
    ObjectUtils,
    IupicsCookieService,
    DataStore,
    AvatarService,
  ],
})
export class IupicsComponentsModule {}
