import { Pipe, PipeTransform } from '@angular/core';
import { Utils } from '@iupics-util/tools/util';

@Pipe({
  name: 'stringArrayFilter',
})
export class StringArrayFilterPipe implements PipeTransform {
  /**
   * Filtre un tableau de string
   * - 'filtre' = filtre sur le nom du menu
   * - '!filtre' = négation du filtre sur le nom du menu
   * @param array
   * @param filter
   */
  transform(array: string[], filter?: string): any {
    if (filter && filter !== '' && filter !== '!') {
      return array.filter((value) => this.filter(value, filter, filter.startsWith('!')));
    }
    return array;
  }

  /**
   * Filtre par rapport à la valeur passée
   * @param value
   * @param filter
   * @param inverted
   */
  private filter(value: string, filter: string, inverted: boolean = false) {
    filter = inverted ? filter.substring(1, 1 + filter.length) : filter;
    const regex = '.*'.concat(Utils.cleanUpSpecialChars(filter, true).split(' ').join('.*'), '.*');
    return (Utils.cleanUpSpecialChars(value).match(regex) === null) === inverted;
  }
}
