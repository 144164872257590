import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'spreadOperator',
})
export class SpreadOperatorPipe implements PipeTransform {
  transform(arrObj: any[]): any {
    if (arrObj && arrObj.length) {
      return arrObj.reduce((result, current) => Object.assign(result, current), {});
    }
    return arrObj;
  }
}
