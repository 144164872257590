import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'urlToFile',
})
export class UrlToFilePipe implements PipeTransform {
  transform(value: string, args?: any): any {
    return value.split('/').pop();
  }
}
