<div class="apiz-p-grid">
  <div *ngIf="!showRename" class="p-col-11">
    <a data-cy="group-name" style="cursor: pointer" (click)="$event.preventDefault(); selectItemEvent.emit(group.id)">{{
      group.name
    }}</a>
  </div>
  <div *ngIf="showRename" class="p-col-11">
    <input
      #inputText
      type="text"
      (blur)="onRename($event)"
      (keydown.enter)="onRename($event)"
      [(ngModel)]="current_menu_name"
      autofocus />
  </div>
  <div *ngIf="!showRename" class="p-col-1 edit-menu">
    <ul [ngClass]="['edit-tab', showMenu || isMobile ? 'blocked' : '']">
      <li [ngClass]="[showMenu ? 'opened' : '']">
        <a
          style="cursor: pointer"
          class="icon-menu-bullets"
          (click)="$event.preventDefault(); showRename = false"
          iuToggleButton
          [iuToggleButtonSwitch]="true"
          [(iuToggleButtonVar)]="showMenu"
          ><span></span
        ></a>
        <ul>
          <ng-container *ngIf="!showRename">
            <li>
              <a
                data-cy="rename-group"
                style="cursor: pointer"
                (click)="$event.preventDefault(); showRenameCadre(group.name)"
                >{{ 'generic.rename' | translate }}</a
              >
            </li>
            <li>
              <a
                data-cy="delete-group"
                style="cursor: pointer"
                (click)="$event.preventDefault(); deleteEvent.emit(group.id)"
                >{{ 'generic.delete' | translate }}</a
              >
            </li>
          </ng-container>
        </ul>
      </li>
    </ul>
  </div>
</div>
