import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import {
  CompiereNotification,
  CompiereNotificationFileInfo,
  CompiereNotificationPriority,
  CompiereNotificationType,
} from '@compiere-ws/models/compiere-notification-json';
import { PushNotificationsService } from '@compiere-ws/services/push-notifications/push-notifications.service';
import { SocketService } from '@compiere-ws/services/socket/socket.service';
import { FilterListOptions } from '@iupics-components/pipes/filter-list/filter-list.pipe';
import { AppConfig } from '@iupics-config/app.config';
import { NotificationManagerService } from '@iupics-manager/managers/notification-manager/notification-manager.service';
import { Global } from '@iupics-manager/models/global-var';
import * as moment from 'moment';
import { Observable, Subject } from 'rxjs';
@Component({
  selector: 'iu-notification-center-ui',
  templateUrl: './notification-center-ui.component.html',
  styleUrls: ['./notification-center-ui.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NotificationCenterUiComponent implements OnInit, OnDestroy {
  private datas: CompiereNotification[] = [];
  private _datas$: Subject<CompiereNotification[]>;
  datas$: Observable<CompiereNotification[]>;
  areButtonsDisabled = false;
  savedDate: number;
  subscriptions: any[] = [];
  viewAllToolTip: string;
  deleteAllToolTip: string;
  showMessageType: string;
  @Output()
  updateNbNotification: EventEmitter<any> = new EventEmitter();
  @Output()
  toggleNotifCenter: EventEmitter<any> = new EventEmitter();
  timerList: Map<number, any> = new Map<number, any>();
  filterListOptions: FilterListOptions;

  notificationType: CompiereNotificationType = CompiereNotificationType.NOTIF;
  private _notifications: CompiereNotification[] = [];
  private _alerts: CompiereNotification[] = [];
  private _chat: CompiereNotification[] = [];
  nbNotification = 0;
  nbAlerts = 0;
  nbChats = 0;

  constructor(
    private socketService: SocketService,
    private notificationManager: NotificationManagerService,
    private pushNotificationService: PushNotificationsService,
    private config: AppConfig
  ) {
    this.pushNotificationService.requestPermission();
  }

  ngOnInit() {
    this.socketService.initSocket();
    this.subscription();
    Global.notificationCenter = this;
    this._datas$ = new Subject();
    this.datas$ = this._datas$.asObservable();
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => {
      if (sub) {
        sub.unsubscribe();
      }
    });
    this.socketService.disableBroadcastNotifications();
    this.socketService.disablePersonalNotifications();
    this.socketService.disableRoleNotifications();
  }

  /**
   * Initialise les subscriptions
   */
  private subscription() {
    this.enableBroadcastNotificationsSocket();
    this.enablePersonalNotificationsSocket();
    this.enableRoleNotificationsSocket();
    this.load();
    this.subscriptions.push(
      this.notificationManager.onChangeRoleChannel.subscribe({
        next: ({ nextRole }) => {
          this.socketService.disableRoleNotifications();
          this.enableRoleNotificationsSocket(nextRole);
        },
      }),
      this.notificationManager.onRoleChanged.subscribe({
        next: () => this.load(),
      })
    );
  }

  private async enableBroadcastNotificationsSocket() {
    for await (const notification of this.socketService.enableBroadcastNotifications()) {
      this.notificationSocketHandler(notification);
    }
  }

  private async enablePersonalNotificationsSocket() {
    for await (const notification of this.socketService.enablePersonalNotifications()) {
      this.notificationSocketHandler(notification);
    }
  }

  private async enableRoleNotificationsSocket(roleId?: number) {
    for await (const notification of this.socketService.enableRoleNotifications(roleId)) {
      this.notificationSocketHandler(notification);
    }
  }

  private notificationSocketHandler(notification: CompiereNotification) {
    this.newNotif(notification);
    this.sortNotif();
    this.popNotifications(notification);
  }

  /**
   * Load notifications, sort and update notifications counts
   */
  private load() {
    const sub = this.notificationManager.getNotifications().subscribe({
      next: (notifications) => {
        this._notifications = notifications.notificationApiz;
        this._alerts = notifications.notificationAlert;
        this._chat = notifications.notificationChat;
        this.sortNotif();
        this.updateNbNotif();

        if (this.datas.some((n) => !n.closed && n.priority === CompiereNotificationPriority.IMPORTANT)) {
          this.toggleNotifCenter.emit(null);
        }

        sub.unsubscribe();
      },
    });
  }

  private next(data: CompiereNotification[]) {
    this.datas = data;
    if (this._datas$) {
      this._datas$.next(data);
    }
  }

  /**
   * Add new notification
   * @param {CompiereNotification} notification
   */
  private newNotif(notification?: CompiereNotification) {
    if (notification.closed === false && notification.priority === CompiereNotificationPriority.IMPORTANT) {
      this.startTimer(notification);
    }

    this.getNotificationsForType(notification?.notificationType).unshift(notification);
    this.updateNbNotif(notification?.notificationType);
  }

  /**
   * Sort notifications for type by creation date, reading date and priority
   */
  private sortNotif(type?: CompiereNotificationType) {
    const datas = this.getNotificationsForType(type ?? this.notificationType);

    datas.sort((a, b) => {
      const closedA = a.closed ? 0 : 1;
      const closedB = b.closed ? 0 : 1;
      if (closedB < closedA) {
        return -1;
      } else if (closedB > closedA) {
        return 1;
      }

      const momentA = moment(a.created);
      const momentB = moment(b.created);
      if (momentB.isBefore(momentA)) {
        return -1;
      } else if (momentB.isAfter(momentA)) {
        return 1;
      }

      const weightA = this.getWeightPriority(a.priority);
      const weightB = this.getWeightPriority(b.priority);
      if (weightB < weightA) {
        return -1;
      } else if (weightB > weightA) {
        return 1;
      }

      return 0;
    });

    this.next(datas);
  }

  /**
   * Return array reference for this type
   * @param {CompiereNotificationType}type
   * @returns {CompiereNotification[]}
   */
  private getNotificationsForType(type: CompiereNotificationType): CompiereNotification[] {
    if (type === CompiereNotificationType.NOTIF) {
      return this._notifications;
    } else if (type === CompiereNotificationType.CHAT) {
      return this._chat;
    } else if (type === CompiereNotificationType.ALERT) {
      return this._alerts;
    }

    return null;
  }

  /**
   * Give the weight of a priority
   * @param {CompiereNotificationPriority}priority
   */
  private getWeightPriority(priority: CompiereNotificationPriority) {
    switch (priority) {
      case CompiereNotificationPriority.IMPORTANT:
        return 3;
      case CompiereNotificationPriority.HIGH:
        return 2;
      case CompiereNotificationPriority.MEDIUM:
        return 1;
      case CompiereNotificationPriority.LOW:
        return 0;
      default:
        return 0;
    }
  }

  /**
   * change the status of the notification to read
   * @param {Number}request_id
   */
  readNotif(request_id: number) {
    const index = this.datas.findIndex((data) => data.request_id === request_id);
    let type = null;
    if (index >= 0) {
      const data = this.datas[index];
      data.closed = true;
      type = data?.notificationType;
    }
    this.clearTimer(request_id);
    this.updateNbNotif(type);
  }

  /**
   * change the status of the notification to read
   * @param {MouseEvent}event
   * @param {CompiereNotification}item
   */
  public handleNotification(event: MouseEvent, item: CompiereNotification): void {
    event?.stopPropagation();
    if (!item?.closed) {
      this.subscriptions.push(
        this.notificationManager.handleNotification(item).subscribe({
          next: () => this.readNotif(item.request_id),
        })
      );
    }
  }

  /**
   * View all Notifications of the current type
   * @param {MouseEvent}event
   */
  public viewAllNotifications(event: MouseEvent) {
    this.notificationManager.handleAllNotification().subscribe(() => {
      this.datas.forEach((data) => (data.closed = true));
      this.clearAllTimer();
      this.updateNbNotif();
      this.sortNotif();
    });
  }

  /**
   * Clear all timer and reload notifications
   * @param {MouseEvent}event
   */
  public refresh(event: MouseEvent) {
    event?.stopPropagation();
    this.clearAllTimer();
    this.load();
  }

  /**
   * Delete notification
   * @param {CompiereNotification}item
   */
  public deleteNotification(item: CompiereNotification): void {
    this.areButtonsDisabled = true;
    const index = this.datas.findIndex((data) => data.request_id === item.request_id);
    this.datas.splice(index, 1);
    this.updateNbNotif(item?.notificationType);
    this.clearTimer(item.request_id);
    this.areButtonsDisabled = false;
    this.subscriptions.push(this.notificationManager.closeNotification(item).subscribe());
  }

  /**
   * Delete all notifications and update the count of this type
   * @param {MouseEvent}event
   */
  public deleteAllNotifications(event: MouseEvent) {
    event?.stopPropagation();
    this.subscriptions.push(
      this.notificationManager.closeAllNotification(this.notificationType).subscribe(() => {
        if (this.notificationType === CompiereNotificationType.ALERT) {
          this._alerts = [];
        } else if (this.notificationType === CompiereNotificationType.CHAT) {
          this._chat = [];
        } else {
          this._notifications = [];
        }
        this.next(this.getNotificationsForType(this.notificationType));
        this.clearAllTimer();
        this.updateNbNotif(this.notificationType);
        this.setMessageType();
      })
    );
  }

  /**
   * Emit an event to update the number of notifications in the menu-top and in the title
   */
  private updateNbNotif(type?: CompiereNotificationType) {
    const count = (datas: CompiereNotification[]) => datas.filter((n) => !n.closed).length;
    switch (type) {
      case CompiereNotificationType.NOTIF:
        this.nbNotification = count(this._notifications);
        break;
      case CompiereNotificationType.CHAT:
        this.nbChats = count(this._chat);
        break;
      case CompiereNotificationType.ALERT:
        this.nbAlerts = count(this._alerts);
        break;

      default:
        // fallback in case type is not defined
        this.nbNotification = count(this._notifications);
        this.nbChats = count(this._chat);
        this.nbAlerts = count(this._alerts);
        break;
    }

    this.updateNbNotification.emit(this.nbNotification + this.nbAlerts + this.nbChats);
  }

  /**
   * Set a timer for a recall
   * @param {CompiereNotification}notif
   */
  private startTimer(notif: CompiereNotification) {
    const timeout = this.config.getConstant('notificationReminderTimeout') ?? 2;
    const intervalID = setInterval(() => this.popNotifications(notif), timeout * 60000);
    this.timerList.set(notif.request_id, intervalID);
  }

  /**
   * Cancel timer
   * @param {Number}request_id
   */
  private clearTimer(request_id: number) {
    if (this.timerList.get(request_id)) {
      clearInterval(this.timerList.get(request_id));
      this.timerList.delete(request_id);
    }
  }

  /**
   * Cancel all timers
   * @param {Number}request_id
   */
  private clearAllTimer() {
    this.timerList.forEach((id, requestId) => {
      this.clearTimer(requestId);
    });
  }

  /**
   * Download the files passed in parameter
   * @param {CompiereNotification}item
   * @param { string | Array }fileLinks
   */
  public handleDlFile(fileLinks: string | CompiereNotificationFileInfo[], item: CompiereNotification) {
    if (typeof fileLinks === 'string') {
      fileLinks.split(',').forEach((fileLink) => {
        this.notificationManager.downloadReport(fileLink);
      });
    } else {
      fileLinks.forEach((fileLink) => {
        this.notificationManager.downloadReport(fileLink.path);
      });
    }
    this.handleNotification(document.createEvent('MouseEvent'), item);
  }

  /**
   * Show notifications in a pop-up
   * @param {CompiereNotification[]}notifications
   */
  private popNotifications(...notifications: CompiereNotification[]): any {
    if (this.pushNotificationService.permission === 'granted') {
      this.pushNotificationService.generateNotification(notifications);
    }
    this.notificationManager.popNotification(...notifications);
  }

  public setActiveTab(event: MouseEvent, activeTab: string): void {
    event?.stopPropagation();
    if (this.notificationType !== CompiereNotificationType[activeTab]) {
      this.notificationType = activeTab as CompiereNotificationType;
      this.next(this.getNotificationsForType(this.notificationType));
    }
  }

  setMessageType(message?: 'confirmDelete' | 'confirmViewAll') {
    this.showMessageType = message ?? null;
  }
}
