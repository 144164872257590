<span
  class="input-mention-tag"
  (mouseenter)="onMouseEnter($event)"
  (mouseleave)="onMouseLeave($event)"
  [attr.data-expandable]="tagExpansion"
  data-cy="input-mention-tag">
  {{ value[0] !== prefix ? prefix : '' }}{{ value }}
  <ng-container #tagExpandVcr></ng-container>
</span>

<ng-template #tagExpandTemplate>
  <div class="input-mention-tag-expanded" (mouseleave)="onMouseLeave($event)" (mouseenter)="clearTimeout()">
    <div class="avatar">
      <iu-avatar-ui [avatarInfos]="avatarInfos"></iu-avatar-ui>
    </div>
    <div class="infos">
      <span class="display-value">{{ value }}</span>
      <span>{{ userId }}</span>
    </div>
  </div>
</ng-template>
